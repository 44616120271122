import { FC, HTMLAttributes } from "react";
import { classes, style } from "typestyle";
import { useModalContext } from "../../state/common/contexts/modal.context";

export interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const Modal: FC<IProps> = (props) => {
  const { modal } = useModalContext();

  if (!modal) {
    return <></>;
  }
  return (
    <div
      {...{
        ...props.attributes,
        className: classes(styles.modal, props.attributes?.className),
      }}
    >
      <div className={styles.content}>
        <div className={styles.modalCard} style={{ width: modal.width }}>
          {modal.content}
        </div>
      </div>
    </div>
  );
};

export const styles = {
  modal: style({
    position: "fixed",
    overflowY: "auto",
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
    zIndex: 9999,
    background: "#6B7280BF",
  }),

  content: style({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    minHeight: "100vh",
    boxSizing: "border-box",
    padding: "30px 20px",
  }),

  modalCard: style({
    borderRadius: "var(--border-radius-medium)",
    background: "#fff",
    boxShadow: " 0px 10px 10px -5px #0000000A, 0px 20px 25px -5px #0000001A",
  }),
};
