import { FC, useCallback } from "react";
import { style } from "typestyle";
import { useHistory } from "react-router-dom";
import { Page } from "../elements/page.element";
import { Header } from "../elements/header.element";
import { TextLogo } from "../elements/text-logo.element";
import { routes } from "../../state/common/constants/routes.constants";
import { userService } from "../../state/user/user.service";
import { PageBody } from "../elements/page-body.element";
import Toast from "../statics/toast";
import { ForgotPasswordForm } from "../templates/forms/user/forget-password-form.template";
import { Anchor } from "../elements/anchor.element";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { branding } from "../../constants/branding.constants";

export const ForgotPasswordScreen: FC = () => {
  const history = useHistory();

  const submit = useCallback(
    async (formData) => {
      await userService.forgotPassword(formData);
      Toast.info({
        body: dictionary.texts.forgotPasswordEmailSent,
      });
      history.replace(routes.login.path);
    },
    [history],
  );

  return (
    <Page hideNavs className={styles.container}>
      <PageBody fullHeight hiddenNavs className={styles.pageBody}>
        <div className={styles.logoContainer}>
          <TextLogo />
          <Header className={styles.header} size={"regular"}>
            {dictionary.literals.forgotPassword}?
          </Header>
        </div>
        <div className={styles.inputContainer}>
          <ForgotPasswordForm
            onClickBackToLogin={() => history.push(routes.login.path)}
            onSubmit={submit}
          />
        </div>
        <div className={styles.linkContainer}>
          <Anchor
            className={styles.anchorLink}
            href={branding.urls?.homepage ?? "https://hulan.nl"}
          >
            {dictionary.literals.moreInformation}
          </Anchor>
        </div>
      </PageBody>
    </Page>
  );
};

const styles = {
  container: style({
    background:
      "linear-gradient(rgb(var(--rgb-color-primair-shade-1)), rgb(var(--rgb-color-primair-basis)))",
  }),
  pageBody: style({
    paddingLeft: 55,
    paddingRight: 55,
  }),

  logoContainer: style({
    flex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  }),
  header: style({
    color: "rgb(var(--rgb-color-white))",
    textAlign: "center",
  }),
  inputContainer: style({
    flex: 2,
    width: "100%",
    marginTop: "var(--spacing-vertical-small)",
  }),
  linkContainer: style({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    flex: 1,
    color: "rgb(var(--rgb-color-white))",
  }),
  forgotPass: style({
    marginBottom: 14,
  }),
  anchorLink: style({
    color: "rgb(var(--rgb-color-white))",
    fontSize: "0.9rem",
  }),
};
