import { ExerciseType } from "@hulanbv/platformapp";
import { ButtonHTMLAttributes, FC, useMemo } from "react";
import { style } from "typestyle";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { exerciseIcons } from "../../state/common/constants/exercise-icons.constants";
import { InjectionService } from "../../state/common/injection.service";
import { exerciseColors } from "../../constants/exercise-colors.constant";
import { Button } from "./button.element";

interface IProps {
  attributes?: ButtonHTMLAttributes<HTMLButtonElement>;
  exerciseType: ExerciseType;
  link: string;
}

export const ExerciseLinkButton: FC<IProps> = (props) => {
  const buttonText = useMemo(() => {
    const type = props.exerciseType;
    if (type === ExerciseType.GAME) {
      return dictionary.literals.goToGame;
    }
    if (type === ExerciseType.PDF) {
      return dictionary.literals.downloadPdf;
    }
    return dictionary.literals.goToLink;
  }, [props.exerciseType]);

  return (
    <Button
      attributes={{
        className: styles.button,
        onClick: (evt) => {
          if (props.attributes?.onClick) {
            props.attributes.onClick(evt);
          }
          InjectionService.openWebView(props.link);
        },
        style: {
          backgroundColor: exerciseColors[props.exerciseType],
          boxShadow: `0 4px 17px 0 rgba(${exerciseColors[
            props.exerciseType
          ].slice(4, -1)}, 0.5)`,
        },
        ...props.attributes,
      }}
      icon={exerciseIcons[props.exerciseType]}
    >
      {buttonText}
    </Button>
  );
};

const styles = {
  button: style({
    textTransform: "uppercase",
    textAlign: "center",
  }),
};
