import {
  ButtonHTMLAttributes,
  FC,
  FunctionComponent,
  SVGProps,
  useRef,
} from "react";
import { classes, style } from "typestyle";
import { useFormIsDisabled } from "../../state/common/hooks/use-form-is-disabled";
import { LoadingSpinner } from "./loading-spinner.element";
import { SvgIcon } from "./svg-icon.element";

export interface IButtonProps {
  attributes?: ButtonHTMLAttributes<HTMLButtonElement>;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  flavour?: "primary" | "secondary" | "tertiary" | "quaternary" | "danger";
  hideSpinnerOnSubmit?: boolean;
  centerChildren?: boolean;
}

export const Button: FC<IButtonProps> = (props) => {
  const ref = useRef<HTMLButtonElement>(null);
  const isFormDisabled = useFormIsDisabled(ref);
  const isDisabled = isFormDisabled || props.attributes?.disabled;

  return (
    <button
      {...{
        type: "button",
        ...props.attributes,
        disabled: isDisabled,
        className: classes(
          styles.button,
          props.attributes?.className,
          props.flavour ? styles[props.flavour] : styles.primary,
        ),
      }}
      ref={ref}
    >
      {isDisabled === true && (
        <div className={styles.buttonDisabledOverlay}>
          {props.hideSpinnerOnSubmit !== true && (
            <LoadingSpinner color={"white"} />
          )}
        </div>
      )}
      {props.icon && <SvgIcon icon={props.icon} />}
      {props.children && (
        <div
          className={classes(
            props.centerChildren && styles.centerChildren,
            props.centerChildren &&
              props.icon &&
              styles.iconWithCenteredChildren,
            !props.centerChildren && props.icon && styles.iconWithChildren,
          )}
        >
          {props.children}
        </div>
      )}
    </button>
  );
};

const styles = {
  button: style({
    position: "relative",
    display: "inline-flex",
    minHeight: 48,
    width: "100%",
    borderRadius: "var(--border-radius-large)",
    outline: 0,
    padding: "10px 20px",
    alignItems: "center",
    fontFamily: "inherit",
    fontWeight: 700,
    fontSize: "0.93rem",
    textTransform: "uppercase",
    border: "none",
    cursor: "pointer",
    textDecoration: "none",
    textAlign: "left",
    wordBreak: "break-word",
    $nest: {
      "&:disabled": {
        cursor: "default",
        pointerEvents: "none",
      },
    },
  }),
  buttonDisabledOverlay: style({
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    borderRadius: "var(--border-radius-large)",
    alignItems: "center",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(100, 100, 100, 0.5)",
    pointerEvents: "none",
  }),
  primary: style({
    backgroundColor: "rgb(var(--rgb-color-secundair-lighter))",
    boxShadow: "0 4px 17px 0 rgba(var(--rgb-color-secundair-lighter), 0.5)",
    color: "rgb(var(--rgb-color-white))",
  }),
  secondary: style({
    backgroundColor: "rgb(var(--rgb-color-primair-shade-3))",
    boxShadow: "0 4px 17px 0 rgba(var(--rgb-color-primair-shade-3), 0.5)",
    color: "rgb(var(--rgb-color-white))",
  }),
  tertiary: style({
    backgroundColor: "rgb(var(--rgb-color-primair-lighter))",
    boxShadow: "0 4px 17px 0 rgba(var(--rgb-color-primair-lighter), 0.5)",
    color: "rgb(var(--rgb-color-white))",
  }),
  quaternary: style({
    backgroundColor: "rgb(var(--rgb-color-primair-shade-2))",
    boxShadow: "0 4px 17px 0 rgba(var(--rgb-color-primair-shade-4), 0.5)",
    color: "rgb(var(--rgb-color-white))",
  }),
  danger: style({
    backgroundColor: "rgb(var(--rgb-color-dangerous))",
    boxShadow: "0 4px 17px 0 rgba(var(--rgb-color-dangerous), 0.5)",
    color: "rgb(var(--rgb-color-white))",
  }),
  centerChildren: style({
    flex: 1,
    textAlign: "center",
  }),
  iconWithCenteredChildren: style({
    marginRight: "calc(var(--spacing-horizontal-small) + var(--icon-size))",
  }),
  iconWithChildren: style({
    marginLeft: "var(--spacing-horizontal-small)",
  }),
};
