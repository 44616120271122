import {
  ICategoryExerciseCountResponse,
  IExercise,
} from "@hulanbv/platformapp";
import { IHttpOptions } from "nest-utilities-client";
import { useEffect, useState } from "react";
import { exerciseService } from "../exercise.service";

const useExerciseCategories = (
  options?: IHttpOptions<IExercise>,
): ICategoryExerciseCountResponse[] | null => {
  const [categories, setCategories] = useState<
    ICategoryExerciseCountResponse[] | null
  >(null);

  useEffect(() => {
    const fetchExerciseCount = async () => {
      const { data: response } =
        await exerciseService.getCategoriesExerciseCount(options);
      setCategories(response);
    };

    fetchExerciseCount();
  }, [options]);

  return categories;
};

export { useExerciseCategories };
