import { FC, InputHTMLAttributes } from "react";
import { classes, style } from "typestyle";

export interface IProps {
  attributes?: InputHTMLAttributes<HTMLInputElement>;
  percentage?: number;
}

export const ProgressBar: FC<IProps> = (props) => (
  <div className={classes(styles.container, props.attributes?.className)}>
    <div
      className={styles.progress}
      style={{ width: `${props.percentage}%` }}
    />
  </div>
);

const styles = {
  container: style({
    margin: "var(--spacing-horizontal-small) 0px",
    position: "relative",
    width: "100%",
    height: 10,
    borderRadius: 20,
    backgroundColor: "rgba(var(--rgb-color-primair-lighter), 0.15)",
    boxShadow: "0 0 22px 0 rgba(var(--rgb-color-black), 0.15)",
  }),
  progress: style({
    position: "absolute",
    zIndex: 1,
    height: "100%",
    borderRadius: 20,
    width: 0,
    backgroundColor: "rgba(var(--rgb-color-primair-lighter))",
    transition: "width 0.5s ease-in-out",
  }),
};
