import { IModel } from "@hulanbv/platformapp";
import { CrudService, IHttpOptions } from "nest-utilities-client";
import {
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { usePagination } from "../../state/common/hooks/use-pagination.hook";
import { toRecord } from "../../utils/to-record.utils";
import { urlUtils } from "../../utils/url.utils";
import {
  IInputMultiSelectProps,
  InputMultiSelect,
} from "./input-multi-select.element";

interface IInputMultiSelectModelProps<ModelType extends IModel>
  extends Omit<IInputMultiSelectProps<ModelType>, "options"> {
  limit?: number;
  searchKeys?: (keyof ModelType)[];
}

export type InputMultiSelectPropsWithService<ModelType extends IModel> =
  IInputMultiSelectModelProps<ModelType> & {
    service: CrudService<ModelType>;
    httpOptions?: Omit<IHttpOptions<ModelType>, "skip" | "offset" | "limit">;
  };

export type InputMultiSelectPropsWithFetchRequest<ModelType extends IModel> =
  IInputMultiSelectModelProps<ModelType> & {
    fetchRequest: (options?: IHttpOptions<ModelType>) => Promise<ModelType[]>;
  };

export function InputMultiSelectModel<ModelType extends IModel>(
  props:
    | InputMultiSelectPropsWithService<ModelType>
    | InputMultiSelectPropsWithFetchRequest<ModelType>,
): JSX.Element {
  const [httpOptions, setHttpOptions] = useState(
    "httpOptions" in props ? props.httpOptions : {},
  );

  /**
   * Combine options from this component with the skip and limit from the pagination
   */
  const searchedFetchRequest = useCallback(
    async (skip: number, limit: number) => {
      if ("fetchRequest" in props) {
        return props.fetchRequest({ ...httpOptions, skip, limit });
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only depend on httpOptions and props.fetchRequest
    [httpOptions, "fetchRequest" in props && props.fetchRequest],
  );

  const { items, isFetching, hasReachedLimit } = usePagination({
    ...("fetchRequest" in props
      ? { fetchRequest: searchedFetchRequest }
      : { service: props.service, options: httpOptions }),
    limit: props.limit ?? 5,
  });

  const selectItems = useMemo(() => toRecord("id", items), [items]);

  const searchTimeout: MutableRefObject<number | null> = useRef(null);
  const onSearch = useCallback(
    (value: string) => {
      if (searchTimeout.current) {
        window.clearTimeout(searchTimeout.current);
        searchTimeout.current = null;
      }

      searchTimeout.current = window.setTimeout(() => {
        const options = {
          ...(httpOptions ?? {}),
          match: {
            $and: [
              ("httpOptions" in props && props.httpOptions?.match) || {},
              urlUtils.createSearchQuery<ModelType>(
                value,
                props.searchKeys?.map((key) => key.toString()) ?? [],
              ),
            ],
          },
        };

        setHttpOptions(options);
      }, 500);
    },
    [httpOptions, props],
  );

  return (
    <InputMultiSelect<ModelType>
      {...{
        ...props,
        options: selectItems,
        isLimited: hasReachedLimit === false,
        onSearchChange: onSearch,
        showLoadingIndicator: isFetching === true,
      }}
    ></InputMultiSelect>
  );
}
