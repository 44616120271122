import { FC, ImgHTMLAttributes } from "react";
import { classes, style } from "typestyle";

interface IProps {
  attributes: ImgHTMLAttributes<HTMLImageElement>;
}

export const Image: FC<IProps> = (props) => (
  <img
    {...props.attributes}
    alt={props.attributes.alt ?? ""}
    className={classes(styles.image, props.attributes.className)}
  />
);

const styles = {
  image: style({
    position: "relative",
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
  }),
};
