import { FC, useEffect, useRef } from "react";
import { classes, style } from "typestyle";
import { Map, MapboxOptions } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Toast from "../statics/toast";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { environment } from "../../constants/environment.constants";
import { branding } from "../../constants/branding.constants";

export interface IProps {
  className?: string;
  options?: Omit<MapboxOptions, "container">;
  onSetMap?: (map: Map) => void;
}

export const Mapbox: FC<IProps> = (props) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<Map>();

  useEffect(() => {
    try {
      if (mapContainer.current && !map.current) {
        map.current = new Map({
          zoom: 8,
          minZoom: 7,
          dragRotate: false,
          center: {
            lon: branding.map?.initialLongitude ?? 0,
            lat: branding.map?.initialLatitude ?? 0,
          },
          style: branding.map?.mapboxStyle,
          pitchWithRotate: false,
          bearing: 0,
          maxPitch: 0,
          ...props.options,
          container: mapContainer.current,
          accessToken: environment.mapboxToken,
        });
        // need to deconstruct props function call, otherwise full [props] object needs to be defined
        // as dependecy array of useEffect
        const { onSetMap } = props;
        onSetMap?.(map.current);
      }
    } catch {
      Toast.error({ body: dictionary.texts.loadMapError });
    }
  }, [props.options, props.onSetMap, props]);

  return (
    <div
      className={classes(styles.container, props.className)}
      ref={mapContainer}
    />
  );
};

const styles = {
  container: style({
    width: "100%",
  }),
};
