import { IGeoLocation } from "@hulanbv/platformapp";
import {
  EventData,
  LngLat,
  Map,
  MapboxOptions,
  MapMouseEvent,
  Marker,
} from "mapbox-gl";
import { FC, useCallback, useEffect, useState } from "react";
import { mapUtils } from "../../../utils/map.utils";
import { Mapbox } from "../../elements/mapbox.element";

interface IProps {
  className?: string;
  options?: Omit<MapboxOptions, "container">;
  onSelectLocation?: (longLat: LngLat) => void;
  location?: IGeoLocation;
}

export const MapLocationPickerTemplate: FC<IProps> = (props) => {
  const [map, setMap] = useState<Map>();
  const [currentMarker, setCurrentMarker] = useState<Marker>();

  // listen to clicks on the map
  const listener = useCallback(
    (event: MapMouseEvent & EventData) => {
      if (!map) {
        return;
      }
      // need to deconstruct, otherwise full props need to be in dependencies
      const { onSelectLocation } = props;
      onSelectLocation?.(event.lngLat);
    },
    [map, props],
  );

  // set the click listener
  useEffect(() => {
    if (!map) {
      return;
    }
    map.on("click", listener);

    // eslint-disable-next-line consistent-return -- needed to remove listener on unmount
    return () => {
      map?.off("click", listener);
    };
  }, [map, listener]);

  const setMarker = useCallback(
    (map: Map) => {
      if (props.location?.coordinates) {
        if (currentMarker) {
          currentMarker.remove();
        }
        const { coordinates } = props.location;
        // set new marker at props.location
        const newMarker = mapUtils
          .getMarker()
          .setLngLat({ lng: coordinates[0], lat: coordinates[1] });
        setCurrentMarker(newMarker);
        // Add it to the map
        newMarker.addTo(map);
      }
    },
    [currentMarker, props.location],
  );

  // listen to props.location to set a new marker
  useEffect(() => {
    if (!map) {
      return;
    }
    if (props.location?.coordinates) {
      setMarker(map);
    } else {
      currentMarker?.remove();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- only update on location change
  }, [props.location, map]);

  return (
    <Mapbox
      onSetMap={(map) => setMap(map)}
      options={props.options}
      className={props.className}
    />
  );
};
