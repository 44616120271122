import { FC, useCallback, useState } from "react";
import { classes, style } from "typestyle";
import { useParams } from "react-router";
import { userService } from "../../state/user/user.service";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { TextLogo } from "../elements/text-logo.element";
import { ForgotPasswordResetForm } from "../templates/forms/user/forget-password-reset-form.template";
import { Anchor } from "../elements/anchor.element";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { branding } from "../../constants/branding.constants";

export const ForgotPasswordResetScreen: FC = () => {
  const { userId, token } = useParams<{ userId: string; token: string }>();
  const [isResetSuccessful, setIsResetSuccessful] = useState<boolean>();

  const handleOnSubmit = useCallback(
    async (formData: FormData) => {
      try {
        await userService.resetPassword(formData, token, userId);
        setIsResetSuccessful(true);
      } catch {
        setIsResetSuccessful(false);
      }
    },
    [token, userId],
  );

  return (
    <Page hideNavs className={styles.container}>
      <PageBody fullHeight hiddenNavs className={styles.pageBody}>
        <div className={styles.logoContainer}>
          <TextLogo />
          <Header className={styles.header} size={"regular"}>
            {dictionary.literals.newPassword}
          </Header>
        </div>
        <div className={styles.inputContainer}>
          <ForgotPasswordResetForm
            onSubmit={handleOnSubmit}
            attributes={{ disabled: isResetSuccessful }}
          />
          {isResetSuccessful === true && (
            <div className={styles.infoContainer}>
              {dictionary.texts.passwordResetSuccess}
            </div>
          )}
          {isResetSuccessful === false && (
            <div
              className={classes(
                styles.infoContainer,
                styles.infoContainerError,
              )}
            >
              {dictionary.texts.passwordResetErrorLong}
            </div>
          )}
        </div>
        <div className={styles.linkContainer}>
          <Anchor
            className={styles.externalLink}
            href={branding.urls?.homepage ?? "https://hulan.nl"}
          >
            {dictionary.literals.moreInformation}
          </Anchor>
        </div>
      </PageBody>
    </Page>
  );
};

const styles = {
  container: style({
    background:
      "linear-gradient(rgb(var(--rgb-color-primair-shade-1)), rgb(var(--rgb-color-primair-basis)))",
  }),
  pageBody: style({
    paddingLeft: 55,
    paddingRight: 55,
  }),
  inputContainer: style({
    flex: 2,
    width: "100%",
    gap: "var(--spacing-vertical-small)",
  }),
  header: style({
    color: "rgb(var(--rgb-color-white))",
    textAlign: "center",
  }),
  logoContainer: style({
    flex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  }),
  infoContainer: style({
    color: "rgb(var(--rgb-color-white))",
    marginTop: "calc(var(--spacing-vertical-regular) * 2)",
    textAlign: "center",
  }),
  infoContainerError: style({
    color: "rgb(var(--rgb-color-dangerous-lighter))",
  }),
  linkContainer: style({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    flex: 1,
    color: "rgb(var(--rgb-color-white))",
  }),
  externalLink: style({
    color: "rgb(var(--rgb-color-white))",
  }),
};
