import { FC, ImgHTMLAttributes } from "react";
import { classes, style } from "typestyle";
import { Image } from "./image.element";
import { ReactComponent as masterIcon } from "../../assets/graphics/symbols/master.svg";
import { SvgIcon } from "./svg-icon.element";

interface IProps {
  attributes: ImgHTMLAttributes<HTMLImageElement>;
  isMaster?: boolean;
}

export const ProfileImage: FC<IProps> = (props) => (
  <div className={styles.container}>
    <Image
      attributes={{
        ...props.attributes,
        ...{ className: classes(styles.image, props.attributes.className) },
      }}
    />
    {props.isMaster && (
      <div className={styles.masterIcon}>
        <SvgIcon icon={masterIcon} width={20} height={20} />
      </div>
    )}
  </div>
);

const styles = {
  container: style({
    position: "relative",
  }),
  image: style({
    border: "3px solid rgb(var(--rgb-color-primair-basis))",
    borderRadius: "var(--border-radius-round)",
    width: 60,
    height: 60,
    flexShrink: 0,
  }),
  masterIcon: style({
    position: "absolute",
    backgroundColor: "rgb(var(--rgb-color-secundair-lighter))",
    borderRadius: "var(--border-radius-round)",
    padding: 5,
    top: -5,
    left: -5,
    zIndex: 1,
  }),
};
