import { FC, useState, useEffect } from "react";
import { style } from "typestyle";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { routes } from "../../state/common/constants/routes.constants";
import { InjectionService } from "../../state/common/injection.service";
import { Router } from "../elements/router.element";
import Toast from "../statics/toast";
import { useAuthContext } from "../../state/authentication/authentication.context";

export const App: FC = () => {
  const authHook = useAuthContext();
  const [isValidated, setIsValidated] = useState(false);

  useEffect(() => {
    authHook
      .validate()
      .catch(async () => {
        await authHook.logout();
        Toast.error({
          body: dictionary.texts.autoSignInError,
        });
      })
      .finally(() => {
        setIsValidated(true);
        InjectionService.showWebview();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- just run the validation once
  }, []);

  return (
    <div className={styles.app}>
      {isValidated === true && <Router routes={Object.values(routes)} />}
    </div>
  );
};

const styles = {
  app: style({
    display: "flex",
    minHeight: "100vh",
    width: "100%",
    position: "relative",
  }),
};
