import { FC, useMemo } from "react";
import { Role } from "@hulanbv/platformapp";
import styles from "./seen-at.module.css";
import { ReactComponent as Checkmark } from "../../../assets/graphics/symbols/checkmark.svg";
import { dateUtils } from "../../../utils/date.utils";
import { Flex } from "../flex.element";
import { useAuthContext } from "../../../state/authentication/authentication.context";
import { dictionary } from "../../../state/common/constants/dictionary.constants";

interface IProps {
  sentAt: Date;
  seenAt?: Date;
}

const SeenAt: FC<IProps> = (props) => {
  const { session } = useAuthContext();

  const openedSentOnElement = useMemo(() => {
    if (props.seenAt) {
      return (
        <Flex gap={8} justifyContent="flex-start">
          <Checkmark className={styles.darkCheckMark} />
          <p className={styles.rowSubText}>
            {dictionary.literals.viewedOn}{" "}
            {dateUtils.getFormattedDate(props.seenAt)}
          </p>
        </Flex>
      );
    }

    if (session?.user?.role === Role.USER) {
      return (
        <p className={styles.rowSubText}>
          {dictionary.literals.receivedOn}{" "}
          {dateUtils.getFormattedDate(props.sentAt)}
        </p>
      );
    }

    return (
      <p className={styles.rowSubText}>
        {dictionary.literals.sentOn} {dateUtils.getFormattedDate(props.sentAt)}
      </p>
    );
  }, [props.seenAt, props.sentAt, session?.user?.role]);

  return <div className={styles.rowSubTextLine}>{openedSentOnElement}</div>;
};

export { SeenAt };
