import { FC, ReactNode, useMemo } from "react";
import { style } from "typestyle";
import { TableRow } from "./table-row.element";
import {
  ITableRowContentProps,
  TableRowContent,
} from "./table-row-content.element";
import { ExpandArrowDownImage } from "./expand-arrow-image.element";
import { useUrlState } from "../../state/common/hooks/url-state-hook/use-url-state.hook";

export type IProps = ITableRowContentProps & {
  expandedColor?: string;
  expandableItem: ReactNode;
  id: string;
};

export const ExpandableRow: FC<IProps> = (props) => {
  const [isExpanded, setIsExpanded] = useUrlState(`row-${props.id}`, false);

  const tableRowAttributes = useMemo(
    () => ({
      onClick: () => setIsExpanded(!isExpanded),
      style: {
        ...(isExpanded && {
          color: "rgb(var(--rgb-color-white))",
          backgroundColor:
            props.expandedColor || "rgb(var(--rgb-color-primair-lighter))",
        }),
      },
    }),
    [isExpanded, props.expandedColor, setIsExpanded],
  );

  return (
    <>
      <TableRow attributes={tableRowAttributes}>
        <TableRowContent
          {...props}
          controls={
            <>
              {props.controls}
              <div className={styles.expandArrowContainer}>
                <ExpandArrowDownImage isExpanded={isExpanded} />
              </div>
            </>
          }
        />
      </TableRow>
      {isExpanded === true && props.expandableItem}
    </>
  );
};

const styles = {
  expandArrowContainer: style({
    display: "flex",
    justifyContent: "center",
  }),
};
