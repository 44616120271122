import { FC, useCallback, useEffect, useState } from "react";
import {
  ICollection,
  IExercise,
  IExerciseInvite,
  Role,
} from "@hulanbv/platformapp";
import { useParams, useHistory, generatePath } from "react-router";
import { style } from "typestyle";
import { routes } from "../../state/common/constants/routes.constants";
import { useModalContext } from "../../state/common/contexts/modal.context";
import { exerciseCollectionService } from "../../state/exercise/exercise-collection.service";
import { exerciseService } from "../../state/exercise/exercise.service";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import Toast from "../statics/toast";
import { ExerciseTemplate } from "../templates/exercise.template";
import { SelectCollectionsModal } from "../templates/modals/select-collections-modal.template";
import { exerciseInviteService } from "../../state/exercise-invite/exercise-invite.service";
import { Button } from "../elements/button.element";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { dictionary } from "../../state/common/constants/dictionary.constants";

export const ExerciseScreen: FC = () => {
  const { openModal } = useModalContext();
  const { session } = useAuthContext();
  const params = useParams<{ exerciseId: string; exerciseInviteId: string }>();

  const [exercise, setExercise] = useState<IExercise>();
  const [exerciseInvite, setExerciseInvite] = useState<IExerciseInvite>();
  const [surroundingExerciseIds, setSurroundingExerciseIds] =
    useState<[previousId: string | null, nextId: string | null]>();

  const history = useHistory();

  useEffect(() => {
    // Guard statement that returns to the previous page when there is no
    // exerciseInviteId or exerciseId in the params
    if (!params.exerciseInviteId && !params.exerciseId) {
      history.goBack();
    }

    const fetchSelectedExerciseInvite = async () => {
      // If there is an exerciseInviteId, get the exercise from the exerciseInvite
      if (params.exerciseInviteId) {
        const exerciseInvite = await exerciseInviteService.get(
          params.exerciseInviteId,
          {
            populate: ["exercise"],
          },
        );
        setExercise(exerciseInvite.data.exercise);
        setExerciseInvite(exerciseInvite.data);
      }

      // If there is an exerciseId, get the exercise from the exerciseService
      if (params.exerciseId) {
        const exercise = await exerciseService.get(params.exerciseId);
        setExercise(exercise.data);
      }
    };
    fetchSelectedExerciseInvite();
  }, [history, params.exerciseId, params.exerciseInviteId]);

  const showCollectionModal = useCallback(async () => {
    try {
      await openModal<ICollection[] | null>((resolve) => (
        <SelectCollectionsModal
          onSubmit={async (collectionIds) => {
            if (!collectionIds || !exercise) {
              resolve(null);
              return;
            }

            const collections = await Promise.all(
              collectionIds.map((id) =>
                exerciseCollectionService.addToCollection([exercise?._id], id),
              ),
            );
            Toast.info({
              body: dictionary.texts.collectionAddSuccess,
            });
            resolve(collections);
          }}
        />
      ));
    } catch {
      Toast.error({
        body: dictionary.texts.exerciseAddedToCollectionError,
      });
    }
  }, [exercise, openModal]);

  const onSendClick = useCallback(
    () =>
      history.replace(
        generatePath(routes.sendExerciseInvites.path, {
          exerciseId: exercise?.id,
        }),
      ),
    [exercise?.id, history],
  );

  useEffect(() => {
    const fetchNavigationExercises = async (
      exerciseInvite: IExerciseInvite,
    ) => {
      const { data: previousExerciseInvite } =
        await exerciseInviteService.getAll({
          match: {
            inviteeId: session?.user?.id,
            $and: [
              { scheduledDate: { $lte: exerciseInvite?.scheduledDate } },
              { _id: { $lt: exerciseInvite?._id } },
              { scheduledDate: { $lt: new Date().toISOString() } },
            ],
          },
          sort: ["-scheduledDate", "-_id"],
          limit: 1,
          populate: ["exercise"],
          select: ["_id"],
        });

      const { data: nextExerciseInvite } = await exerciseInviteService.getAll({
        match: {
          inviteeId: session?.user?.id,
          $and: [
            { scheduledDate: { $gte: exerciseInvite?.scheduledDate } },
            { _id: { $gt: exerciseInvite?._id } },
            { scheduledDate: { $lt: new Date().toISOString() } },
          ],
        },
        sort: ["scheduledDate", "_id"],
        limit: 1,
        select: ["_id"],
      });

      setSurroundingExerciseIds([
        previousExerciseInvite[0]?._id ?? null,
        nextExerciseInvite[0]?._id ?? null,
      ]);
    };

    // only fetch whenever an exerciseInvite is present
    if (exerciseInvite) {
      fetchNavigationExercises(exerciseInvite);
    }
  }, [exerciseInvite, session?.user?.id]);

  const onNextClick = useCallback(() => {
    if (surroundingExerciseIds?.[1]) {
      history.replace(
        generatePath(routes.exerciseInvite.path, {
          exerciseInviteId: surroundingExerciseIds[1],
        }),
      );
    }
  }, [history, surroundingExerciseIds]);

  const onPreviousClick = useCallback(() => {
    if (surroundingExerciseIds?.[0]) {
      history.replace(
        generatePath(routes.exerciseInvite.path, {
          exerciseInviteId: surroundingExerciseIds[0],
        }),
      );
    }
  }, [history, surroundingExerciseIds]);

  const onNavigateTimelineClick = useCallback(() => {
    history.push(generatePath(routes.timeline.path));
  }, [history]);

  return (
    <Page>
      <PageBody fullWidth className={styles.pageBody}>
        {exercise && (
          <>
            <ExerciseTemplate
              exercise={exercise}
              exerciseInviteId={params.exerciseInviteId}
              onFavouriteClick={showCollectionModal}
              onSendClick={onSendClick}
            />

            {session?.user?.role === Role.USER && params.exerciseInviteId && (
              <div className={styles.buttonContainer}>
                {surroundingExerciseIds?.length && (
                  <>
                    <Button
                      attributes={{
                        className: styles.button,
                        onClick: onPreviousClick,
                        disabled: surroundingExerciseIds[0] === null,
                      }}
                      hideSpinnerOnSubmit
                    >
                      {dictionary.literals.previous}
                    </Button>
                    <Button
                      attributes={{
                        className: styles.button,
                        onClick: onNextClick,
                        disabled: surroundingExerciseIds[1] === null,
                      }}
                      hideSpinnerOnSubmit
                    >
                      {dictionary.literals.next}
                    </Button>
                    <Button
                      attributes={{
                        className: styles.timeLineButton,
                        onClick: onNavigateTimelineClick,
                      }}
                    >
                      {dictionary.literals.timeline}
                    </Button>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </PageBody>
    </Page>
  );
};

const styles = {
  pageBody: style({
    padding: 0,
    paddingBottom: "var(--spacing-vertical-regular)",
  }),
  buttonContainer: style({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 15,
    margin: "var(--spacing-vertical-regular) var(--spacing-horizontal-regular)",
  }),
  button: style({
    width: "40%",
    justifyContent: "center",
  }),
  timeLineButton: style({
    width: "100%",
    justifyContent: "center",
  }),
};
