import { PropsWithChildren, forwardRef, useRef, MutableRefObject } from "react";
import { classes, style } from "typestyle";
import { ReactComponent as searchIcon } from "../../assets/graphics/symbols/search.svg";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { IInputProps, Input } from "./input.element";

interface IProps extends IInputProps {
  inputClassName?: string;
  containerClassName?: string;
  onChangeTimeoutMilliseconds?: number;
  placeholder?: string;
}

export const SearchInput = forwardRef<
  HTMLInputElement,
  PropsWithChildren<IProps>
>((props, ref) => {
  const searchTimeout: MutableRefObject<number | null> = useRef(null);

  return (
    <Input
      ref={ref}
      icon={searchIcon}
      iconColor="rgb(var(--rgb-color-primair-basis))"
      containerClassName={classes(styles.container, props.containerClassName)}
      attributes={{
        ...{
          className: classes(styles.input, props.inputClassName),
          placeholder: props.placeholder ?? dictionary.literals.typeToSearch,
        },
        ...props.attributes,
        ...{
          onChange: (event) => {
            if (props.onChangeTimeoutMilliseconds) {
              // if searchTimeout is set, clear the current time out
              if (searchTimeout.current) {
                window.clearTimeout(searchTimeout.current);
                searchTimeout.current = null;
              }
              searchTimeout.current = window.setTimeout(() => {
                props.attributes?.onChange?.(event);
              }, props.onChangeTimeoutMilliseconds);
            } else {
              props.attributes?.onChange?.(event);
            }
          },
        },
      }}
    />
  );
});

const styles = {
  container: style({
    marginBottom: 15,
  }),
  input: style({
    backgroundColor: "rgb(var(--rgb-color-gray-lighter))",
    border: "1px solid transparent",
    borderRadius: "var(--border-radius-medium)",
    $nest: {
      "&:focus": {
        border: "1px solid rgb(var(--rgb-color-primair-basis))",
      },
      "&::placeholder": {
        color: "rgba(var(--rgb-color-black), 0.5))",
      },
    },
  }),
};
