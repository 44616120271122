import { FC, useEffect, useRef, useState } from "react";
import { classes, style } from "typestyle";
import { IUser } from "@hulanbv/platformapp";
import { ReactComponent as crossIcon } from "../../assets/graphics/symbols/cross.svg";

import { useAuthContext } from "../../state/authentication/authentication.context";
import { userService } from "../../state/user/user.service";
import { Button } from "../elements/button.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import Toast from "../statics/toast";
import { PractitionerLocationFormTemplate } from "../templates/forms/user/practitioner-location-form.template";
import { MapLocationPickerTemplate } from "../templates/maps/mapbox-location-picker.template";
import { dictionary } from "../../state/common/constants/dictionary.constants";

export const ProfileSetLocationScreen: FC = () => {
  const { session } = useAuthContext();
  const [user, setUser] = useState<IUser | null>();
  const coordinates = user?.companyGeolocation?.coordinates;
  const locationFormRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    // we cannot get the user from the auth context because the
    // session.user.companyGeolocation might not contain the latest version
    const getUser = async () => {
      if (!session?.userId) {
        return;
      }
      try {
        const { data } = await userService.get(session?.userId);
        setUser(data);
      } catch {
        Toast.error({
          body: dictionary.texts.getUserError,
        });
      }
    };

    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on mount
  }, []);

  if (!user) {
    return <></>;
  }

  const onSubmit = async () => {
    try {
      const formRef = locationFormRef.current;
      if (formRef?.reportValidity() === true) {
        const formData = new FormData(formRef);
        userService.patch(formData);
      }
    } catch {
      Toast.error({ body: dictionary.texts.locationCreateError });
    }
  };

  const onDelete = async () => {
    if (!user) {
      return;
    }
    try {
      user.companyGeolocation = undefined;
      const { data } = await userService.put(user);
      setUser(data);
    } catch {
      Toast.error({
        body: dictionary.texts.locationRemoveError,
      });
    }
  };

  return (
    <Page>
      <PageBody fullWidth fullHeight className={styles.pageBody}>
        <MapLocationPickerTemplate
          className={styles.map}
          onSelectLocation={(longLat) => {
            if (!user) {
              return;
            }
            const updateUser: IUser = {
              ...user,
              companyGeolocation: {
                type: "Point",
                coordinates: [longLat.lng, longLat.lat],
              },
            };

            // first set the user, this causes the form (hidden inputs) to update
            // their hidden values
            setUser(updateUser);
            // After that call the submit function of the form manually,
            // since no actual form submit click is being performed
            onSubmit();
          }}
          location={user.companyGeolocation}
          options={
            coordinates
              ? { center: { lng: coordinates[0], lat: coordinates[1] } }
              : {}
          }
        />
        <PractitionerLocationFormTemplate
          user={user}
          hideSubmitButton
          attributes={{ className: styles.formArea }}
          ref={locationFormRef}
        >
          <div className={styles.buttonArea}>
            {user.companyGeolocation?.coordinates && (
              <Button
                attributes={{
                  disabled: user?.companyGeolocation?.coordinates.length !== 2,
                  className: classes(styles.button, styles.redButton),
                  onClick: onDelete,
                }}
                icon={crossIcon}
              >
                {dictionary.literals.removeLocation}
              </Button>
            )}
          </div>
        </PractitionerLocationFormTemplate>
      </PageBody>
    </Page>
  );
};

const styles = {
  pageBody: style({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: 0,
    position: "relative",
  }),
  map: style({
    flex: 1,
  }),
  formArea: style({
    position: "absolute",
    bottom: 0,
    padding: "0px var(--spacing-horizontal-small)",
    marginTop: "auto",
    width: "100%",
  }),
  buttonArea: style({ margin: "auto 0 35px 0" }),
  button: style({
    margin: "auto 0 10px 0",
  }),
  redButton: style({
    backgroundColor: "rgb(var(--rgb-color-dangerous))",
  }),
};
