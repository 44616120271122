import { FC, useMemo } from "react";
import {
  IQuestion,
  QuestionSentiment,
  QuestionType,
} from "@hulanbv/platformapp";
import { style } from "typestyle";
import { TableRow } from "../../elements/table-row.element";
import { TableRowContent } from "../../elements/table-row-content.element";
import { ReactComponent as checkmarkIcon } from "../../../assets/graphics/symbols/checkmark.svg";
import { ReactComponent as crossIcon } from "../../../assets/graphics/symbols/cross.svg";

import { SvgIcon } from "../../elements/svg-icon.element";
import { Flex } from "../../elements/flex.element";
import { dictionary } from "../../../state/common/constants/dictionary.constants";
import { GeometricShape } from "../../elements/geometric-shape";

type Props = {
  question: IQuestion;
};

export const QuestionUserAnswerRowTemplate: FC<Props> = (props) => {
  const { query, sentiment, type, isNumericalQuestion } = props.question;
  const userAnswer = props.question.userAnswers?.[0];
  const lastQuestionnaireAnswer =
    props.question.answers?.[props.question.answers.length - 1].value;

  const answerColour = useMemo((): string | null => {
    if (type !== QuestionType.RANGE || isNumericalQuestion !== true) {
      return null;
    }

    let userAnswerColour = "rgb(var(--rgb-color-success))";
    const userAnswerValue = Number(userAnswer?.value[0]);
    const questionPercentage =
      (userAnswerValue / Number(lastQuestionnaireAnswer)) * 100;

    if (sentiment === QuestionSentiment.POSITIVE && questionPercentage <= 20) {
      userAnswerColour = "rgb(var(--rgb-color-dangerous))";
    }

    if (sentiment === QuestionSentiment.NEGATIVE && questionPercentage >= 80) {
      userAnswerColour = "rgb(var(--rgb-color-dangerous))";
    }

    return userAnswerColour;
  }, [
    type,
    isNumericalQuestion,
    userAnswer?.value,
    lastQuestionnaireAnswer,
    sentiment,
  ]);

  const renderedAnswers = ((): JSX.Element => {
    // If the user has not answered the question yet, return a message
    if (!userAnswer?.value) {
      return (
        <p className={styles.noSpacing}>{dictionary.literals.notAnsweredYet}</p>
      );
    }

    // If the question is a range question and is numerical, return the value
    // out of the total value
    if (
      props.question.type === QuestionType.RANGE &&
      props.question.isNumericalQuestion
    ) {
      return (
        <Flex gap={10}>
          <GeometricShape color={answerColour ?? ""} size={15} />
          <p className={styles.noSpacing}>
            {dictionary.texts.xOutOfY(
              userAnswer.value[0],
              lastQuestionnaireAnswer ?? "",
            )}
          </p>
        </Flex>
      );
    }

    // If the question is a multiple choice question, return the answers
    // as a list
    if (props.question.type === QuestionType.MULTIPLE_RESPONSE) {
      return (
        <Flex direction="column" alignItems="flex-start">
          <ul className={styles.noSpacing}>
            {userAnswer.value.map((answer, index) => (
              <li key={index}>{answer}</li>
            ))}
          </ul>
        </Flex>
      );
    }

    // Return all the other question types as a normal paragraph.
    return <p className={styles.noSpacing}>{userAnswer.value[0]}</p>;
  })();

  return (
    <TableRow>
      <TableRowContent
        header={query}
        subHeader={
          <Flex justifyContent="flex-start" direction="row" gap={10}>
            {userAnswer?.isCorrect !== null &&
              userAnswer?.isCorrect !== undefined && (
                <div style={{ width: 15 }}>
                  {userAnswer?.isCorrect === true && (
                    <SvgIcon
                      icon={checkmarkIcon}
                      color={"rgb(var(--rgb-color-success))"}
                      width={"100%"}
                      height={"100%"}
                    />
                  )}
                  {userAnswer?.isCorrect === false && (
                    <SvgIcon
                      icon={crossIcon}
                      color={"rgb(var(--rgb-color-dangerous))"}
                      width={"100%"}
                      height={"100%"}
                    />
                  )}
                </div>
              )}
            {renderedAnswers}
          </Flex>
        }
      />
    </TableRow>
  );
};

const styles = {
  noSpacing: style({
    margin: 0,
  }),
};
