import {
  IExercise,
  IExerciseInvite,
  ScheduleTiming,
} from "@hulanbv/platformapp";
import { useById } from "@hulanbv/nest-utilities-client-state";
import { FC, useState } from "react";
import { useHistory, useParams } from "react-router";
import { style } from "typestyle";
import { Page } from "../elements/page.element";
import { ReactComponent as clientIcon } from "../../assets/graphics/symbols/client.svg";
import { ReactComponent as groupIcon } from "../../assets/graphics/symbols/group.svg";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { exerciseInviteService } from "../../state/exercise-invite/exercise-invite.service";
import { exerciseService } from "../../state/exercise/exercise.service";
import { ActionBar } from "../elements/action-bar.element";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import Toast from "../statics/toast";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { ExerciseInvitesForm } from "../templates/forms/exercise-invites/exercises-invites-form.template";

export const SendExerciseInvitesScreen: FC = () => {
  const params = useParams<{ exerciseId: string }>();
  const { data: fetchedExercise } = useById(
    exerciseService,
    params.exerciseId,
    {
      populate: [{ path: "questionnaire", populate: [{ path: "questions" }] }],
    },
  );
  const history = useHistory();
  const { session } = useAuthContext();

  const [shouldShowGroups, setShouldShowGroups] = useState(false);

  const onSubmit = async (
    exerciseInvite: Partial<IExerciseInvite>,
    inviteeIds: string[],
    exercise?: IExercise | null,
  ) => {
    const { scheduledDate, scheduleTiming } = exerciseInvite;
    let isScheduledInvite = false;

    try {
      if (!fetchedExercise || !session?.userId || !scheduledDate) {
        throw Error();
      }

      // if schedulDate is higher than current date, it's scheduled
      isScheduledInvite = scheduledDate > new Date();

      const invites: IExerciseInvite[] = [];
      // loop through all selected clients/invitees and create seperate exercise-invites for them
      for (const inviteeId of inviteeIds) {
        invites.push({
          ...exerciseInvite,
          inviteeId,
          senderId: session.userId,
          exerciseId: exercise !== null ? exercise?.id : fetchedExercise.id,
          scheduleTiming: scheduleTiming ?? ScheduleTiming.ONCE,
          scheduledDate,
        });
      }

      await exerciseInviteService.createMany(invites);

      Toast.info({
        body: isScheduledInvite
          ? dictionary.texts.exerciseInviteScheduledSuccess
          : dictionary.texts.exerciseInviteSentSuccess,
      });
      history.goBack();
    } catch {
      Toast.error({
        body: isScheduledInvite
          ? dictionary.texts.exerciseInviteScheduledError
          : dictionary.texts.exerciseInviteSentError,
      });
    }
  };

  return (
    <Page>
      <PageBody>
        <Header className={styles.centerHeader}>{fetchedExercise?.name}</Header>
        <ActionBar
          actions={[
            {
              icon: clientIcon,
              onClick: () => setShouldShowGroups(false),
              isActive: shouldShowGroups === false,
            },
            {
              icon: groupIcon,
              onClick: () => setShouldShowGroups(true),
              isActive: shouldShowGroups === true,
            },
          ]}
        />
        {fetchedExercise && (
          <ExerciseInvitesForm
            onSubmit={onSubmit}
            exercise={fetchedExercise}
            showGroupSelect={shouldShowGroups}
          />
        )}
      </PageBody>
    </Page>
  );
};

const styles = {
  centerHeader: style({
    display: "flex",
    justifyContent: "center",
    paddingBottom: 20,
  }),
};
