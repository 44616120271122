import { IExerciseInvite } from "@hulanbv/platformapp";
import { CrudService, IResponse } from "nest-utilities-client";
import { environment } from "../../constants/environment.constants";
import { httpService } from "../common/http.service";

class Service extends CrudService<IExerciseInvite> {
  constructor() {
    super([environment.apiUrl, "exercise-invites"].join("/"), httpService);
  }

  /**
   * Creates many exercise-invites in one request
   *
   * @param data IExerciseInvite[]
   * @returns IExerciseInvite[]
   */
  createMany(
    data: FormData | IExerciseInvite[],
  ): Promise<IResponse<IExerciseInvite[]>> {
    return this.http.post<IExerciseInvite[]>(
      `${this.controller}/many`,
      data,
      {},
    );
  }
}

export const exerciseInviteService = new Service();
