import { FC, HTMLAttributes, ReactNode } from "react";
import { classes, style } from "typestyle";
import { dictionary } from "../../../state/common/constants/dictionary.constants";
import { useModalContext } from "../../../state/common/contexts/modal.context";
import { ReactComponent as Cross } from "../../../assets/graphics/symbols/cross.svg";
import { ReactComponent as Mail } from "../../../assets/graphics/symbols/mail.svg";
import { Button } from "../../elements/button.element";
import { InjectionService } from "../../../state/common/injection.service";
import { branding } from "../../../constants/branding.constants";

export interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  title: string;
  description: ReactNode;
}

export const ExpiredLicenseModal: FC<IProps> = (props) => {
  const { closeModal } = useModalContext();

  return (
    <div
      {...{
        ...props.attributes,
        className: classes(styles.container, props.attributes?.className),
      }}
    >
      <h2 className={styles.header}>{props.title}</h2>
      <div className={styles.description}> {props.description}</div>
      <Button
        flavour={"secondary"}
        icon={Mail}
        centerChildren
        attributes={{
          className: styles.marginBottom,
          onClick: () => {
            InjectionService.mail(
              branding.mailAddresses?.support ?? "info@hulan.nl",
            );
          },
        }}
      >
        <div className={styles.buttonText}>
          {dictionary.literals.getInTouch}
        </div>
      </Button>
      <Button
        flavour={"primary"}
        icon={Cross}
        centerChildren
        attributes={{
          onClick: closeModal,
          className: styles.marginBottom,
        }}
      >
        <div className={styles.buttonText}>{dictionary.literals.close}</div>
      </Button>
    </div>
  );
};

const styles = {
  container: style({
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100%",
    width: "100%",
  }),
  marginBottom: style({ marginBottom: 10 }),
  header: style({
    color: "rgb(var(--rgb-color-primair-basis))",
    textAlign: "center",
    margin: "25px 0",
  }),
  description: style({
    fontSize: "1.06rem",
    margin: "0 0 25px 0",
    textAlign: "center",
  }),
  buttonIcon: style({
    width: 20,
  }),
  buttonText: style({
    fontSize: "0.95rem",
  }),
};
