import { dictionary } from "../state/common/constants/dictionary.constants";

const ordinalRules = new Intl.PluralRules(process.env.REACT_APP_LOCALE, {
  type: "ordinal",
});

const suffixes: Record<Intl.LDMLPluralRule, string> = {
  zero: dictionary.texts.ordinalZeroSuffix,
  one: dictionary.texts.ordinalOneSuffix,
  two: dictionary.texts.ordinalTwoSuffix,
  few: dictionary.texts.ordinalFewSuffix,
  many: dictionary.texts.ordinalManySuffix,
  other: dictionary.texts.ordinalOtherSuffix,
};

/**
 *
 * Returns a ordinal string for language globally set app locale
 *
 * Example for en-US: 1 becomes '1st', 2 becomes '2nd'
 *
 * @param number
 * @returns
 */
export const numberToOrdinal = (number: number): string => {
  const category = ordinalRules.select(number);
  const suffix = suffixes[category];
  return number + suffix;
};
