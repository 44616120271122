import { IUser } from "@hulanbv/platformapp";
import { FC } from "react";
import { ReactComponent as eyeIcon } from "../../../assets/graphics/symbols/eye.svg";
import { TableRow } from "../../elements/table-row.element";
import { TableRowContent } from "../../elements/table-row-content.element";
import { IconButton } from "../../elements/icon-button.element";

interface IProps {
  user: IUser;
  onViewClick?: () => void;
}

export const PractitionerRowTemplate: FC<IProps> = (props) => {
  if (props.user.isMaster === false) {
    return null;
  }
  return (
    <TableRow>
      <TableRowContent
        header={`${props.user.name} ${props.user.lastName ?? ""}`}
        subHeader={props.user.city}
        controls={
          <>
            {props.onViewClick && (
              <IconButton
                icon={eyeIcon}
                attributes={{ onClick: props.onViewClick }}
              />
            )}
          </>
        }
      />
    </TableRow>
  );
};
