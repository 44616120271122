import { locale } from "../constants/locale.constants";

const intl = new Intl.DateTimeFormat(locale, {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});
const getDateInputValue = (date: Date): string => {
  const parts = intl
    .formatToParts(date)
    .reduce<Record<string, string>>((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});

  return `${parts.year}-${parts.month}-${parts.day}`;
};

export { getDateInputValue };
