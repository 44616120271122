import { IModel } from "@hulanbv/platformapp";
import { ModelQuery } from "nest-utilities-client/distribution/types/model-query.type";

export const urlUtils = new (class UrlUtils {
  /**
   * Create a search search query object that can be injected into nest-utilities-client http options.
   * @param search the string to search
   * @param inKeys the keys to search in
   * @example createSearchQuery("foo", ["description", "title"])
   */
  createSearchQuery<Model extends IModel>(
    search?: string | null,
    inKeys?: string[],
  ): ModelQuery<Model> {
    if (!search || !inKeys) {
      return {};
    }

    const searchQuery = search
      // trims all the spaces from the search string
      .trim()
      // Replaces all the special characters from the search string
      .replace(/([.*+?^${}()|[\]\\])/g, "\\$1")
      // replaces all the comma's and spaces with a pipe
      .replace(/, */g, "|")
      // recplaces the pipes that will be at the start and end of the string if needed.
      .replace(/^\||\|$/g, "");

    const searchQueries: ModelQuery<Model>[] = [];
    for (const key of inKeys) {
      searchQueries.push({
        [key]: {
          $regex: searchQuery.toLocaleLowerCase(),
          $options: "i",
        },
      });
    }

    return { $or: searchQueries };
  }
})();
