import { INote, IUser } from "@hulanbv/platformapp";
import {
  FormHTMLAttributes,
  forwardRef,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { classes, style } from "typestyle";
import { ReactComponent as eyeIcon } from "../../../../assets/graphics/symbols/eye.svg";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";
import { Button } from "../../../elements/button.element";
import { Form } from "../../../elements/form.element";
import { Row } from "../../../elements/row.element";
import { TextArea } from "../../../elements/text-area.element";

interface IProps {
  user: IUser;
  client: IUser;
  note?: INote;
  attributes?: Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit">;
  hideSubmitButton?: boolean;
  onSubmit?: (formData: FormData) => void | Promise<void>;
  error?: string;
}

export const NoteForm = forwardRef<HTMLFormElement, PropsWithChildren<IProps>>(
  (props, ref) => {
    const [error, setError] = useState(props.error);

    useEffect(() => {
      setError(props.error);
    }, [props.error]);

    return (
      <Form attributes={props.attributes} onSubmit={props.onSubmit} ref={ref}>
        {props.client?._id && props.user?._id && (
          <>
            <input
              type={"hidden"}
              name={"recipientId"}
              value={props.client._id}
            />
            <input type={"hidden"} name={"authorId"} value={props.user._id} />
            <input type={"hidden"} name={"isPublic"} value={"false"} />
            {props?.note && (
              <input type={"hidden"} name={"id"} value={props.note._id} />
            )}
          </>
        )}
        {/*
          <Row label={"Titel *"}>
            <Input
              attributes={{
                name: "name",
                type: "text",
                placeholder: "Titel notitie...",
                required: true
                //defaultValue: props.user.name
              }}
              error={error}
            />
          </Row>
          */}
        <Row label={`${dictionary.literals.note}`}>
          <TextArea
            attributes={{
              name: "text",
              placeholder: dictionary.texts.typeYourNote,
              required: true,
              className: styles.textArea,
              defaultValue: props.note?.text,
            }}
            error={error}
          />
        </Row>

        {!props.hideSubmitButton && (
          <Row>
            <Button
              attributes={{
                type: "submit",
                className: classes(props.hideSubmitButton && styles.hideButton),
              }}
              icon={eyeIcon}
            >
              {dictionary.literals.submit}
            </Button>
          </Row>
        )}
        {props.children}
      </Form>
    );
  },
);

const styles = {
  hideButton: style({
    visibility: "hidden",
  }),
  textArea: style({
    height: 250,
  }),
};
