import { FC, useCallback } from "react";
import { style } from "typestyle";
import { Role } from "@hulanbv/platformapp";
import { generatePath, useHistory } from "react-router-dom";
import { Button } from "./button.element";
import { ReactComponent as plusIcon } from "../../assets/graphics/symbols/add.svg";
import { dateUtils } from "../../utils/date.utils";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { GoBackButton } from "./go-back-button.element";
import { routes } from "../../state/common/constants/routes.constants";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { branding } from "../../constants/branding.constants";
import { imageSet } from "../../constants/image-set.constants";

interface IProps {
  hideGoBack?: boolean;
}

export const NavigationBar: FC<IProps> = (props) => {
  const { session } = useAuthContext();
  const history = useHistory();

  const getButton = useCallback((): JSX.Element | undefined => {
    const user = session?.user;
    // if the user has role.user and has not set up a link with a professional
    // show a button so the professional can be linked
    if (user?.role === Role.USER && !user.practitioner) {
      return (
        <Button
          attributes={{
            className: styles.button,
            onClick: () => history.push(routes.pairPractitioner.path),
          }}
          flavour={"secondary"}
          icon={plusIcon}
        >
          {dictionary.literals.pairPractitioner}
        </Button>
      );
    }
    if (user?.role === Role.USER && user.practitioner) {
      // if a professional is linked, show the name
      return (
        <Button
          attributes={{
            className: styles.button,
            onClick: () =>
              history.push(
                generatePath(routes.profile.path, {
                  userId: user?.practitionerId,
                }),
              ),
          }}
          flavour={"secondary"}
        >
          {user.practitioner.name} {user.practitioner.lastName ?? ""}
        </Button>
      );
    }
    if (user?.role === Role.PRACTITIONER && user?.license?.isTrial) {
      // Trial period should end in ten days, so calculate how long ago ten days is
      // from start date of the license
      const date = new Date(user?.license.endDate);
      const daysLeft = dateUtils.getDaysAgo(date);

      return (
        <Button attributes={{ className: styles.button }} flavour={"secondary"}>
          {dictionary.texts.trialDaysLeft(daysLeft)}
        </Button>
      );
    }
    if (user?.role === Role.PRACTITIONER) {
      return (
        <Button attributes={{ className: styles.button }} flavour={"secondary"}>
          {dictionary.literals.professionalLicense}
        </Button>
      );
    }
    return <></>;
  }, [session, history]);

  return (
    <div className={styles.container}>
      <div className={styles.profileContainer}>
        {getButton()}
        {/* TODO -- Temporarily disabled due to missing chat functionality */}
        {/* <Button
          attributes={{ className: styles.logoButton }}
          flavour={'secondary'}
        >
          <img
            src={newNotification}
            alt={''}
            className={styles.newNotification}
          />
        </Button> */}
      </div>
      <div className={styles.navContainer}>
        <div className={styles.sideNav}>
          {props.hideGoBack !== true && <GoBackButton />}
        </div>
        <div className={styles.middleNav}>{branding.name ?? "PLAP"}</div>
        <div className={styles.sideNav}></div>
      </div>
    </div>
  );
};

const styles = {
  container: style({
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    padding: "max(calc(env(safe-area-inset-top) + 10px), 20px) 20px 15px",
    height: "calc(var(--nav-bar-height) + env(safe-area-inset-top, 0))",
    width: "100%",
    backgroundImage:
      "linear-gradient(rgb(var(--rgb-color-primair-basis)), rgb(var(--rgb-color-primair-shade-4)))",
    borderBottomLeftRadius: 11,
    borderBottomRightRadius: 11,
    zIndex: 10,
  }),
  profileContainer: style({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  }),
  navContainer: style({
    flex: 1,
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "rgb(var(--rgb-color-white))",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  }),
  sideNav: style({
    flex: 1,
    padding: 5,
    height: "100%",
    display: "flex",
    alignItems: "center",
  }),
  middleNav: style({
    padding: 5,
  }),
  button: style({
    width: "unset",
    fontWeight: 800,
    fontSize: 12,
    padding: "0 10px",
    height: 45,
  }),
  logoButton: style({
    width: 45,
    height: 45,
    backgroundImage: `url(${imageSet.logoNegative})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "relative",
  }),
  newNotification: style({
    width: 14,
    height: 14,
    position: "absolute",
    top: -4,
    right: -4,
  }),
};
