import { useById } from "@hulanbv/nest-utilities-client-state";
import { FC } from "react";
import { useParams } from "react-router";
import { style } from "typestyle";
import { questionnaireService } from "../../state/questionnaire/questionnaire.service";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { QuestionDisplay } from "../elements/question-display.element";
import { Flex } from "../elements/flex.element";

export const QuestionnaireOverviewScreen: FC = () => {
  const params = useParams<{
    questionnaireId: string;
  }>();

  const { data: questionnaire } = useById(
    questionnaireService,
    params.questionnaireId,
    {
      populate: ["questions"],
      select: ["title", "introText", "description"],
    },
  );

  if (!questionnaire?.questions) {
    return <></>;
  }

  return (
    <Page className={styles.page}>
      <PageBody fullHeight>
        <Header size="big" center className={styles.header}>
          {questionnaire.title}
        </Header>
        <Flex direction="column">
          {questionnaire.questions.map((question) => (
            <QuestionDisplay question={question} />
          ))}
        </Flex>
      </PageBody>
    </Page>
  );
};

const styles = {
  page: style({
    $debugName: "questionnaire-intro",
    backgroundImage:
      "linear-gradient(180deg, rgba(var(--rgb-color-primair-shade-4), 0.15), rgba(var(--rgb-color-primair-basis), 0.15))",
  }),
  header: style({
    marginBottom: "var(--spacing-vertical-small)",
  }),
  openQuestion: style({
    color: "rgb(var(--rgb-color-gray))",
  }),
};
