import { FC } from "react";
import { stylesheet } from "typestyle";

export const HorizontalRule: FC<{
  /* */
}> = () => <div className={styles.horizontalRule} />;

const styles = stylesheet({
  horizontalRule: {
    width: "100%",
    height: "1px",
    margin: "20px 0",
    backgroundColor: "rgb(var(--rgb-color-gray-lighter))",
  },
});
