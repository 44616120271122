import {
  forwardRef,
  FormHTMLAttributes,
  PropsWithChildren,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";

import { IUser } from "@hulanbv/platformapp";
import { classes, style } from "typestyle";
import { ReactComponent as eyeIcon } from "../../../../assets/graphics/symbols/eye.svg";
import { Button } from "../../../elements/button.element";
import { Form } from "../../../elements/form.element";
import { Input } from "../../../elements/input.element";
import { Row } from "../../../elements/row.element";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";

interface IProps {
  user: IUser;
  attributes?: Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit">;
  hideSubmitButton?: boolean;
  onSubmit?: (formData: FormData) => void | Promise<void>;
  error?: string;
}

const phoneNumberRegex = /[0-9()+-\s]/g;

export const UserClientForm = forwardRef<
  HTMLFormElement,
  PropsWithChildren<IProps>
>((props, ref) => {
  const phoneNumberInput = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(props.error);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const validatePhoneNumberInput = useCallback(() => {
    if (phoneNumberInput.current === null) {
      return;
    }

    const { value } = phoneNumberInput.current;
    if (phoneNumberRegex.test(value.slice(-1)) === false) {
      phoneNumberInput.current.value = value.replace(/[^0-9()+-\s]/g, "");
    }
  }, []);

  return (
    <Form attributes={props.attributes} onSubmit={props.onSubmit} ref={ref}>
      {props.user?._id && (
        <input type={"hidden"} name={"id"} value={props.user._id} />
      )}
      <Row label={`${dictionary.literals.firstName} *`}>
        <Input
          attributes={{
            name: "name",
            type: "text",
            placeholder: dictionary.literals.firstName,
            required: true,
            defaultValue: props.user.name,
          }}
          error={error}
        />
      </Row>
      <Row label={`${dictionary.literals.lastName} *`}>
        <Input
          attributes={{
            name: "lastName",
            type: "text",
            placeholder: dictionary.literals.lastName,
            required: true,
            defaultValue: props.user.lastName,
          }}
          error={error}
        />
      </Row>
      <Row label={`${dictionary.literals.email} *`}>
        <Input
          attributes={{
            name: "email",
            type: "email",
            placeholder: dictionary.literals.email,
            required: true,
            defaultValue: props.user.email,
          }}
        />
      </Row>
      <Row label={dictionary.literals.phoneNumber}>
        <Input
          ref={phoneNumberInput}
          attributes={{
            name: "phoneNumber",
            type: "text",
            onInput: validatePhoneNumberInput,
            placeholder: dictionary.literals.phoneNumber,
            required: false,
            defaultValue: props.user.phoneNumber,
          }}
          error={error}
        />
      </Row>
      <Row label={dictionary.literals.cityOfResidence}>
        <Input
          attributes={{
            name: "city",
            type: "text",
            placeholder: dictionary.literals.cityOfResidence,
            required: false,
            defaultValue: props.user.city,
          }}
        />
      </Row>

      {!props.hideSubmitButton && (
        <Row>
          <Button
            attributes={{
              type: "submit",
              className: classes(props.hideSubmitButton && styles.hideButton),
            }}
            icon={eyeIcon}
          >
            {dictionary.literals.submit}
          </Button>
        </Row>
      )}
      {props.children}
    </Form>
  );
});

const styles = {
  hideButton: style({
    visibility: "hidden",
  }),
};
