import { FC, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { IRoute } from "../../state/common/interfaces/route.interface";

interface IProps {
  routes: IRoute[];
}

export const Router: FC<IProps> = (props) => {
  const [routes, setRoutes] = useState<IRoute[]>([]);

  useEffect(() => {
    const selectRoutes = async () => {
      const routes: IRoute[] = [];
      for (const route of props.routes) {
        // eslint-disable-next-line no-await-in-loop -- needs await in loop
        const isAccessible = await route.isAccessible?.();
        if (isAccessible !== false) {
          routes.push(route);
        }
      }
      setRoutes(routes);
    };
    selectRoutes();
  }, [props.routes]);

  return (
    <Switch>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          exact
          render={() => <route.component />}
        />
      ))}
    </Switch>
  );
};
