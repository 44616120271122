import { INote } from "@hulanbv/platformapp";
import { CrudService } from "nest-utilities-client";
import { environment } from "../../constants/environment.constants";
import { httpService } from "../common/http.service";

class Service extends CrudService<INote> {
  constructor() {
    super([environment.apiUrl, "notes"].join("/"), httpService);
  }
}

export const noteService = new Service();
