import { FC } from "react";
import { NavLink } from "react-router-dom";
import { classes, style } from "typestyle";
import { InjectionService } from "../../state/common/injection.service";

interface IProps {
  className?: string;
  href: string;
  target?: string;
}

export const Anchor: FC<IProps> = (props) => {
  const isExternal = /https?:\/\//.test(props.href);

  if (isExternal) {
    return (
      <div
        className={classes(styles.anchor, props.className)}
        onClick={() => {
          InjectionService.openWebView(props.href);
        }}
      >
        {props.children}
      </div>
    );
  }

  return (
    <NavLink
      to={props.href}
      className={classes(styles.anchor, props.className)}
    >
      {props.children}
    </NavLink>
  );
};

const styles = {
  anchor: style({
    cursor: "pointer",
    display: "block",
    textDecoration: "none",
    color: "inherit",
  }),
};
