import {
  forwardRef,
  FormHTMLAttributes,
  PropsWithChildren,
  useEffect,
  useState,
  ChangeEvent,
  useCallback,
  useRef,
} from "react";

import { IUser } from "@hulanbv/platformapp";
import { classes, style } from "typestyle";
import { ReactComponent as eyeIcon } from "../../../../assets/graphics/symbols/eye.svg";
import { Button } from "../../../elements/button.element";
import { Form } from "../../../elements/form.element";
import { Input } from "../../../elements/input.element";
import { Row } from "../../../elements/row.element";
import { TextArea } from "../../../elements/text-area.element";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";
import { InputSelect } from "../../../elements/input-select.element";

interface IProps {
  user: IUser;
  attributes?: Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit">;
  hideSubmitButton?: boolean;
  onSubmit?: (formData: FormData) => void | Promise<void>;
  error?: string;
}

const kvkNumberRegex = /[0-9\s]/g;

export const UserPractitionerForm = forwardRef<
  HTMLFormElement,
  PropsWithChildren<IProps>
>((props, ref) => {
  const kvkNumberInput = useRef<HTMLInputElement>(null);
  const [error, setError] = useState(props.error);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === "" || !value?.indexOf("http")) {
      return;
    }

    // eslint-disable-next-line no-param-reassign -- need to re-assign value
    event.target.value = `https://${value}`;
  };

  const validateKvkNumberInput = useCallback(() => {
    if (kvkNumberInput.current === null) {
      return;
    }

    const { value } = kvkNumberInput.current;
    if (kvkNumberRegex.test(value.slice(-1)) === false) {
      kvkNumberInput.current.value = value.replace(/[^0-9\s]/g, "");
    }
  }, []);

  return (
    <Form attributes={props.attributes} onSubmit={props.onSubmit} ref={ref}>
      {props.user?._id && (
        <input type={"hidden"} name={"id"} value={props.user._id} />
      )}
      <Row label={`${dictionary.literals.firstName} *`}>
        <Input
          attributes={{
            name: "name",
            type: "text",
            placeholder: dictionary.literals.firstName,
            required: true,
            defaultValue: props.user.name,
          }}
          error={error}
        />
      </Row>
      <Row label={`${dictionary.literals.lastName} *`}>
        <Input
          attributes={{
            name: "lastName",
            type: "text",
            placeholder: dictionary.literals.lastName,
            required: true,
            defaultValue: props.user.lastName,
          }}
          error={error}
        />
      </Row>
      <Row label={`${dictionary.literals.street} *`}>
        <Input
          attributes={{
            name: "street",
            type: "text",
            placeholder: dictionary.literals.street,
            required: true,
            defaultValue: props.user.street,
          }}
          error={error}
        />
      </Row>
      <Row label={`${dictionary.literals.houseNumber} *`}>
        <Input
          attributes={{
            name: "houseNumber",
            type: "number",
            placeholder: dictionary.literals.houseNumber,
            required: true,
            defaultValue: props.user.houseNumber,
          }}
          error={error}
        />
      </Row>
      <Row label={dictionary.literals.houseNumberAddition}>
        <Input
          attributes={{
            name: "houseNumberAddition",
            type: "text",
            placeholder: dictionary.literals.houseNumberAddition,
            defaultValue: props.user.houseNumberAddition,
          }}
          error={error}
        />
      </Row>
      <Row label={dictionary.literals.cityOfResidence}>
        <Input
          attributes={{
            name: "city",
            type: "text",
            placeholder: dictionary.literals.cityOfResidence,
            defaultValue: props.user.city,
          }}
          error={error}
        />
      </Row>
      <Row label={`${dictionary.literals.zipCode} *`}>
        <Input
          attributes={{
            name: "zipcode",
            type: "text",
            placeholder: dictionary.literals.zipCode,
            required: true,
            defaultValue: props.user.zipcode,
          }}
          error={error}
        />
      </Row>
      <Row label={dictionary.literals.countryOfResidence}>
        <InputSelect
          name="country"
          placeholder={dictionary.literals.countryOfResidence}
          isSearchable
          defaultValue={props.user.country}
          options={Object.keys(dictionary.texts.countryLabels).map((key) => ({
            label: dictionary.texts.countryLabels[key],
            value: key,
          }))}
        />
      </Row>
      <Row label={dictionary.literals.companyNumber}>
        <Input
          ref={kvkNumberInput}
          attributes={{
            name: "kvkNumber",
            type: "text",
            placeholder: dictionary.literals.companyNumber,
            defaultValue: props.user.kvkNumber,
            onInput: validateKvkNumberInput,
          }}
          error={error}
        />
      </Row>
      <Row label={dictionary.literals.website}>
        <Input
          attributes={{
            type: "url",
            name: "website",
            placeholder: dictionary.literals.website,
            defaultValue: props.user.website,
            maxLength: 50,
            onBlur: (blurEvent) => handleBlur(blurEvent),
          }}
          error={error}
        />
      </Row>
      <Row label={dictionary.literals.biography}>
        <TextArea
          attributes={{
            name: "biography",
            placeholder: dictionary.texts.tellSomethingAboutYourself,
            defaultValue: props.user.biography,
            rows: 10,
          }}
          error={error}
        />
      </Row>
      {!props.hideSubmitButton && (
        <Row>
          <Button
            attributes={{
              type: "submit",
              className: classes(props.hideSubmitButton && styles.hideButton),
            }}
            icon={eyeIcon}
          >
            {dictionary.literals.submit}
          </Button>
        </Row>
      )}
      {props.children}
    </Form>
  );
});

const styles = {
  hideButton: style({
    visibility: "hidden",
  }),
};
