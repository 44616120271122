import { FC } from "react";
import { classes, style } from "typestyle";

interface IProps {
  className?: string;
  color?: "white" | "black";
  size?: "tiny" | "small" | "regular" | "medium" | "big";
  weight?: 500 | 600;
  center?: boolean;
}

export const Header: FC<IProps> = (props) => (
  <div
    className={classes(
      styles.sizes[props.size ?? "big"],
      styles.weights[props.weight ?? 600],
      props.className,
      props.color === "white" && styles.white,
      props.center === true && styles.center,
    )}
  >
    {props.children}
  </div>
);

const styles = {
  sizes: {
    tiny: style({
      fontSize: "0.875rem",
    }),
    small: style({
      fontSize: "1rem",
    }),
    regular: style({
      fontSize: "1.125rem",
    }),
    medium: style({
      fontSize: "1.5rem",
    }),
    big: style({
      fontSize: "1.575rem",
    }),
  },
  weights: {
    500: style({
      fontWeight: 500,
    }),
    600: style({
      fontWeight: 600,
    }),
  },
  white: style({
    color: "rgb(var(--rgb-color-white))",
  }),
  center: style({
    textAlign: "center",
  }),
};
