import { IExercise, IExerciseInvite } from "@hulanbv/platformapp";
import { FC, Fragment, useCallback, useEffect, useMemo } from "react";
import { generatePath, useHistory } from "react-router";
import { style } from "typestyle";
import { routes } from "../../state/common/constants/routes.constants";
import { useModalContext } from "../../state/common/contexts/modal.context";
import { exerciseCollectionService } from "../../state/exercise/exercise-collection.service";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import Toast from "../statics/toast";
import { SelectCollectionsModal } from "../templates/modals/select-collections-modal.template";
import bannerImage from "../../assets/graphics/illustrations/client-timeline-banner.svg";
import { SeenAt } from "../elements/seen-at/seen-at.element";
import { exerciseInviteService } from "../../state/exercise-invite/exercise-invite.service";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { ExpandableRow } from "../elements/expandable-row.element";
import { usePagination } from "../../state/common/hooks/use-pagination.hook";
import { Header } from "../elements/header.element";
import { dateUtils } from "../../utils/date.utils";
import { ReactComponent as Checkmark } from "../../assets/graphics/symbols/checkmark.svg";
import { LoadingSpinner } from "../elements/loading-spinner.element";
import { windowUtils } from "../../utils/window.utils";
import { debounce } from "../../utils/debounce.utils";
import { ShowMore } from "../elements/show-more.element";
import { ExerciseRowTemplate } from "../templates/table-rows/exercise-row.template";
import { branding } from "../../constants/branding.constants";

export const TimelineScreen: FC = () => {
  const history = useHistory();
  const { openModal } = useModalContext();

  const memoizedHttpOptions = useMemo(
    () => ({
      match: {
        scheduledDate: { $lt: new Date().toISOString() },
      },

      // scheduledDate is leading so sort by scheduledDate instead of createdAt
      sort: ["-scheduledDate"],
      populate: ["exercise", "sessionInvite"],
    }),
    [],
  );

  const { items, fetchNextItems, hasReachedLimit, isFetching } = usePagination({
    service: exerciseInviteService,
    options: memoizedHttpOptions,
    limit: 10,
  });

  /**
   * Increments the offset whenever the page body is reached and more items should
   * be fetched
   */
  const scrollHandler = useCallback(() => {
    // Do not continue if we did not reach the bottom of the page yet
    const isBodyAtBottom = windowUtils.isBodyScrollAtBottom();
    if (isBodyAtBottom === false) {
      return;
    }
    fetchNextItems();
  }, [fetchNextItems]);

  const debouncedScrollHandler = debounce(scrollHandler, 500);

  /**
   * Make sure the next offset is set whenever user reaches the bottom of the page
   */
  useEffect(() => {
    document.addEventListener("scroll", debouncedScrollHandler);
    // remove event listener after unmounting
    return () => document.removeEventListener("scroll", debouncedScrollHandler);
  }, [debouncedScrollHandler]);

  /**
   * Groups exercise-invites that belong to each other in a multidimensional array
   */
  const groupedExerciseInvites = useMemo(
    () =>
      items.reduce<IExerciseInvite[][]>((previous, invite) => {
        // if no exercise is present, return
        if (!invite.exercise) {
          return previous;
        }

        // Adds a new array if the previous exerciseInvite message or
        // sessionInviteId is not the same as the current.
        if (
          previous.length === 0 ||
          previous[previous.length - 1]?.[0]?.message !== invite.message ||
          previous[previous.length - 1]?.[0]?.sessionInviteId !==
            invite.sessionInviteId
        ) {
          previous.push([]);
        }

        previous[previous.length - 1].push(invite);

        return previous;
      }, []),
    [items],
  );

  const handleFavouriteClick = useCallback(
    async (exercise: IExercise) => {
      try {
        const selectedCollectionIds = await openModal<string[] | null>(
          (resolve) => <SelectCollectionsModal onSubmit={resolve} />,
        );
        if (selectedCollectionIds === null) {
          return;
        }

        if (!selectedCollectionIds?.length) {
          throw Error();
        }

        await Promise.all(
          selectedCollectionIds.map((id) =>
            exerciseCollectionService.addToCollection([exercise._id], id),
          ),
        );

        Toast.info({
          body: dictionary.texts.collectionAddExerciseSuccess,
        });
      } catch {
        Toast.error({ body: dictionary.texts.collectionAddExerciseError });
      }
    },
    [openModal],
  );

  const rowTemplate = useCallback(
    (exerciseInvites: IExerciseInvite[]) => {
      let hasSeenAllInvites = true;

      const expandableItems = exerciseInvites.map((invite) => {
        const { exercise } = invite;
        if (!exercise) {
          return <Fragment key={invite._id}></Fragment>;
        }

        // Set to false if we haven't seen one of the invites
        if (!invite.seenAt) {
          hasSeenAllInvites = false;
        }

        return (
          <ExerciseRowTemplate
            hasSeparationBorder={exerciseInvites.length > 1}
            key={invite._id}
            exercise={exercise}
            {...(invite.scheduledDate && {
              subText: (
                <SeenAt sentAt={invite.scheduledDate} seenAt={invite.seenAt} />
              ),
            })}
            onFavoriteClick={() => handleFavouriteClick(exercise)}
            onViewClick={() => {
              history.push(
                generatePath(routes.exerciseInvite.path, {
                  exerciseInviteId: invite._id,
                }),
              );
            }}
          />
        );
      });

      // Header logic
      const { message, sessionInvite, exercise } = exerciseInvites[0] ?? {};
      const rowHeader =
        message ||
        sessionInvite?.name ||
        exercise?.name ||
        dictionary.literals.exercises;

      // Subheader logic
      const sentAt = exerciseInvites[exerciseInvites.length - 1]?.scheduledDate;
      const rowSubHeader = (
        <>
          {hasSeenAllInvites === true && (
            <Checkmark className={styles.checkmarkImage} />
          )}
          {sentAt && <>{dateUtils.getFormattedDate(sentAt)} - </>}
          {dictionary.texts.xExercises(exerciseInvites.length)}
        </>
      );

      return (
        <ExpandableRow
          header={rowHeader}
          subHeader={rowSubHeader}
          key={exerciseInvites[0]._id}
          id={exerciseInvites[0]._id}
          expandedColor={"rgb(var(--rgb-color-primair-basis)"}
          expandableItem={
            <div className={styles.expandableItemsContainer}>
              {expandableItems}
            </div>
          }
        />
      );
    },
    [handleFavouriteClick, history],
  );

  return (
    <Page>
      <PageBody fullWidth fullHeight className={styles.pageBody}>
        <Header center className={styles.header}>
          {dictionary.literals.timeline}
        </Header>
        {branding.restrictions?.canSeeBannerImage !== false && (
          <img
            className={styles.bannerImage}
            src={bannerImage}
            alt={"timeline"}
          />
        )}
        {groupedExerciseInvites.map((invites: IExerciseInvite[]) =>
          rowTemplate(invites),
        )}
        {items.length !== 0 && hasReachedLimit === false && (
          <ShowMore onClick={fetchNextItems} />
        )}
        {isFetching === true && <LoadingSpinner color="black" />}
      </PageBody>
    </Page>
  );
};

const styles = {
  pageBody: style({
    backgroundImage:
      "linear-gradient(180deg, rgba(var(--rgb-color-primair-shade-4), 0.15), rgba(var(--rgb-color-primair-basis), 0.15))",
  }),
  header: style({
    marginBottom: 25,
  }),
  bannerImage: style({
    width: "75%",
    margin: "0 auto",
    marginBottom: 25,
  }),
  exercisePreview: style({
    borderRadius: "var(--border-radius-large)",
    overflow: "hidden",
    boxShadow: "0px 0px 10px 2px rgba(var(--rgb-color-black), 0.15)",
    marginBottom: "var(--spacing-vertical-small)",
  }),
  expandableItemsContainer: style({
    backgroundColor: "rgb(var(--rgb-color-white))",
  }),
  checkmarkImage: style({
    width: 12,
    height: 12,
    marginRight: "calc(var(--spacing-horizontal-small) / 2)",
    color: "rgb(var(--rgb-color-black))",
  }),
};
