import { Role } from "@hulanbv/platformapp";
import { FC, useCallback, useState } from "react";
import { style } from "typestyle";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { userService } from "../../state/user/user.service";
import { Anchor } from "../elements/anchor.element";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { TextLogo } from "../elements/text-logo.element";
import Toast from "../statics/toast";
import { RegisterForm } from "../templates/forms/user/register-form.template";
import { branding } from "../../constants/branding.constants";

export const RegisterScreen: FC = () => {
  const { login } = useAuthContext();
  const [error, setError] = useState<string | undefined>();

  const submit = useCallback(
    async (formData) => {
      try {
        formData.append("role", Role.USER.toString());
        await userService.register(formData);
        await login(formData);
      } catch {
        setError(dictionary.literals.error);
        Toast.error({
          body: dictionary.texts.registerError,
        });
      }
    },
    [login],
  );

  return (
    <Page hideNavs className={styles.container}>
      <PageBody fullHeight hiddenNavs className={styles.pageBody}>
        <div className={styles.logoContainer}>
          <TextLogo />
          <Header className={styles.header} size={"regular"}>
            {dictionary.literals.registration}
          </Header>
        </div>
        <div className={styles.inputContainer}>
          <RegisterForm onSubmit={submit} error={error} />
        </div>
        <div className={styles.linkContainer}>
          <Anchor
            className={styles.anchorLink}
            href={branding.urls?.homepage ?? "https://hulan.nl"}
          >
            {dictionary.literals.moreInformation}
          </Anchor>
        </div>
      </PageBody>
    </Page>
  );
};

const styles = {
  container: style({
    background:
      "linear-gradient(rgb(var(--rgb-color-primair-shade-1)), rgb(var(--rgb-color-primair-basis)))",
  }),
  pageBody: style({
    paddingLeft: 55,
    paddingRight: 55,
  }),
  content: style({
    padding: 37,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
  logoContainer: style({
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  }),
  inputContainer: style({
    flex: 1,
    width: "100%",
    marginTop: "var(--spacing-vertical-small)",
  }),
  header: style({
    color: "rgb(var(--rgb-color-white))",
    textAlign: "center",
  }),
  linkContainer: style({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    flex: 1,
    color: "rgb(var(--rgb-color-white))",
  }),
  forgotPass: style({
    marginBottom: 14,
  }),
  anchorLink: style({
    color: "rgb(var(--rgb-color-white))",
    fontSize: "0.9rem",
  }),
};
