import { FC } from "react";
import { style } from "typestyle";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { userService } from "../../state/user/user.service";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import Toast from "../statics/toast";
import { PairPractitionerForm } from "../templates/forms/user/pair-practitioner-form.template";

export const PairPractitionerScreen: FC = () => {
  const { session, validate } = useAuthContext();
  const submit = async (formData: FormData) => {
    try {
      const userId = session?.userId;
      const pairingCode = formData.get("pairingCode");
      if (!userId || !pairingCode) {
        throw Error();
      }
      await userService.pairPractitioner(userId, +pairingCode);
      validate();
    } catch {
      Toast.error({
        body: dictionary.texts.pairingError,
      });
    }
  };

  return (
    <Page>
      <PageBody>
        <Header className={styles.header}>
          {dictionary.literals.pairPractitioner}
        </Header>
        <PairPractitionerForm onSubmit={submit} />
      </PageBody>
    </Page>
  );
};

const styles = {
  header: style({
    marginBottom: "var(--spacing-vertical-regular)",
  }),
};
