import { ChangeEvent, FC, useState, useRef } from "react";
import { IUser } from "@hulanbv/platformapp";
import { classes, style } from "typestyle";
import defaultAvatar from "../../../assets/graphics/illustrations/default-avatar.svg";
import { ReactComponent as pencilIcon } from "../../../assets/graphics/symbols/pencil.svg";
import { Button } from "../../elements/button.element";
import { ProfileImage } from "../../elements/profile-image.element";
import { fileService } from "../../../state/file/file.service";
import { BoxShadowHead } from "../../elements/box-shadow-head.element";
import { dictionary } from "../../../state/common/constants/dictionary.constants";

interface IProps {
  user: IUser;
  className?: string;
  onEditClick?: () => void;
  onAssetUploaded?: (asset: { url: string }) => void | Promise<void>;
}

export const ProfileBasicDetailsTemplate: FC<IProps> = (props) => {
  const { user } = props;
  const [asset, setAsset] = useState<{ url: string }>();
  const inputProfileImage = useRef<HTMLInputElement>(null);

  const getProfilePicture = () => {
    if (asset?.url) {
      return asset.url;
    }
    if (user.profilePictureUrl) {
      return user.profilePictureUrl;
    }

    return defaultAvatar;
  };

  const changeProfileImage = () => {
    inputProfileImage.current?.click();
  };

  const updateImageFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file as Blob);

    const { data: asset } = await fileService.upload(formData);
    props.onAssetUploaded?.(asset);
    setAsset(asset);
  };

  return (
    <BoxShadowHead
      attributes={{
        className: classes(styles.container, props.className),
      }}
    >
      <div className={styles.profileImageRow}>
        <ProfileImage
          attributes={{
            src: getProfilePicture(),
            className: styles.profileImage,
          }}
          isMaster={user.isMaster ?? false}
        />
        <div className={styles.actionContainer}>
          {props.onEditClick && (
            <Button
              attributes={{
                onClick: () => props.onEditClick?.(),
              }}
              icon={pencilIcon}
            >
              {dictionary.literals.edit}
            </Button>
          )}
          {props.onAssetUploaded && (
            <>
              <Button
                icon={pencilIcon}
                attributes={{
                  className: styles.button,
                  onClick: changeProfileImage,
                }}
              >
                {dictionary.literals.editPicture}
              </Button>
              <input
                ref={inputProfileImage}
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={updateImageFile}
              />
            </>
          )}
        </div>
      </div>
      <div>
        <div className={styles.name}>
          {props.user.name} {props.user.lastName}
        </div>
        <div className={styles.nameSubText}>{props.user.city}</div>
      </div>
    </BoxShadowHead>
  );
};

const styles = {
  container: style({
    padding: "0 22px 22px",
  }),
  profileImageRow: style({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 18,
  }),
  actionContainer: style({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }),
  profileImage: style({
    flexShrink: 0,
  }),
  name: style({
    fontSize: "1.125rem",
    fontWeight: 600,
    marginBottom: 10,
  }),
  nameSubText: style({
    fontSize: "0.95625rem",
  }),
  button: style({
    width: "100%",
  }),
};
