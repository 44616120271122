import { FC, HTMLAttributes } from "react";
import { classes, style } from "typestyle";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  label?: string;
}

export const Row: FC<IProps> = (props) => (
  <div
    {...{
      ...props.attributes,
      className: classes(styles.row, props.attributes?.className),
    }}
  >
    {props.label && <div className={styles.label}>{props.label}</div>}
    {props.children}
  </div>
);

const styles = {
  row: style({
    marginBottom: 20,
  }),
  label: style({
    marginBottom: 10,
    fontSize: "0.95rem",
  }),
};
