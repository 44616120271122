import { CrudService, IHttpOptions } from "nest-utilities-client";
import {
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";
import { style } from "typestyle";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { usePagination } from "../../state/common/hooks/use-pagination.hook";
import { debounce } from "../../utils/debounce.utils";
import { windowUtils } from "../../utils/window.utils";
import { LoadingSpinner } from "./loading-spinner.element";
import { ShowMore } from "./show-more.element";

export type TableActions = {
  refreshItems: () => void;
};

type Props<DataType> = {
  rowTemplate: (data: DataType, key: number) => ReactElement | undefined;
  emptyContent?: ReactNode;
  actionsRef?: Ref<TableActions>;
  limit?: number;
};

type PropsWithService<DataType> = Props<DataType> & {
  service: CrudService<DataType>;
  options?: Omit<IHttpOptions<DataType>, "skip" | "offset" | "limit">;
};

type PropsWithFetchRequest<DataType> = Props<DataType> & {
  fetchRequest: (skip: number, limit: number) => Promise<DataType[]>;
};

export function Table<DataType>(
  props: PropsWithService<DataType> | PropsWithFetchRequest<DataType>,
): JSX.Element {
  const { fetchNextItems, items, isFetching, refreshItems, hasReachedLimit } =
    usePagination({
      ...("fetchRequest" in props
        ? { fetchRequest: props.fetchRequest }
        : { service: props.service, options: props.options }),
      limit: props.limit ?? 10,
    });

  /**
   * Exposes some actions through the actionsRef which can be called from parent
   */
  useImperativeHandle(props.actionsRef, () => ({ refreshItems }), [
    refreshItems,
  ]);

  /**
   * Increments the offset whenever the page body is reached and more items should
   * be fetched
   */
  const scrollHandler = useCallback(() => {
    // Do not continue if we did not reach the bottom of the page yet
    const isBodyAtBottom = windowUtils.isBodyScrollAtBottom();
    if (isBodyAtBottom === false) {
      return;
    }
    fetchNextItems();
  }, [fetchNextItems]);

  const debouncedScrollHandler = debounce(scrollHandler, 500);

  /**
   * Make sure the next offset is set whenever user reaches the bottom of the page
   */
  useEffect(() => {
    document.addEventListener("scroll", debouncedScrollHandler);
    // remove event listener after unmounting
    return () => document.removeEventListener("scroll", debouncedScrollHandler);
  }, [debouncedScrollHandler]);

  // loop through all items and pass item to props.rowtemplate
  return (
    <div>
      {items?.map((item, key) => props.rowTemplate(item, key))}
      {items.length === 0 && isFetching === false && (
        <div className={styles.emptyContent}>
          {props.emptyContent ?? dictionary.literals.noResultsFound}
        </div>
      )}

      {items.length !== 0 && !isFetching && !hasReachedLimit && (
        <div className={styles.showMoreButtonContainer}>
          <ShowMore onClick={fetchNextItems} />
        </div>
      )}
      {isFetching === true && <LoadingSpinner color={"black"} />}
    </div>
  );
}

const styles = {
  emptyContent: style({
    margin: 20,
    textAlign: "center",
  }),
  showMoreButtonContainer: style({
    display: "flex",
    justifyContent: "center",
    marginTop: "var(--spacing-vertical-regular)",
  }),
  showMoreButton: style({
    width: "unset",
    textAlign: "center",
  }),
};
