import { ComponentProps, FC, MouseEvent } from "react";
import { stylesheet } from "typestyle";
import { BoxShadowHead } from "../elements/box-shadow-head.element";
import { FlexItem } from "../elements/flex-item.element";
import { Flex } from "../elements/flex.element";
import { Header } from "../elements/header.element";
import { IconButton } from "../elements/icon-button.element";
import { SearchInput } from "../elements/search-input.element";
import { ReactComponent as plusIcon } from "../../assets/graphics/symbols/add.svg";

type Props = {
  header?: string;
  headerAttributes?: ComponentProps<typeof Header>;
  onAddButtonClick?: (
    event: MouseEvent<HTMLButtonElement>,
  ) => Promise<void> | void;
  searchInputAttributes?: ComponentProps<typeof SearchInput>["attributes"];
};

export const TableHeadTemplate: FC<Props> = (props) => (
  <BoxShadowHead>
    <Flex direction="column" alignItems="stretch">
      <FlexItem>
        {props.header && (
          <Header center {...props.headerAttributes}>
            {props.header}
          </Header>
        )}
      </FlexItem>
      <Flex gap={10}>
        <FlexItem grow={1}>
          <SearchInput
            attributes={props.searchInputAttributes}
            onChangeTimeoutMilliseconds={500}
            containerClassName={styles.searchInput}
          />
        </FlexItem>
        {props.onAddButtonClick && (
          <FlexItem shrink={0}>
            <IconButton
              attributes={{
                onClick: props.onAddButtonClick,
              }}
              icon={plusIcon}
            />
          </FlexItem>
        )}
      </Flex>
    </Flex>
  </BoxShadowHead>
);

const styles = stylesheet({
  searchInput: {
    marginBottom: 0,
  },
});
