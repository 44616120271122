/**
 * Returns an object representation of FormData keys with their values
 * @param formData
 * @returns
 */
export const formDataToObject = <ReturnType>(
  formData: FormData,
): ReturnType => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- any is fine here
  const object: Record<string, any> = {};
  formData.forEach((value, key) => {
    try {
      const path = key.replace(/\]/g, "").split("[");
      let ref = object;
      // eslint-disable-next-line no-plusplus -- needed for for loop
      for (let i = 0; i < path.length; i++) {
        const isLastKey = i === path.length - 1;
        const isArray = !Number.isNaN(+path[i + 1]);
        if (isLastKey) {
          if (path[i] === "") {
            // push the value into the array if the key is empty ([])
            ref.push?.(value);
          } else {
            // set the value if its the last key
            ref[path[i]] = value;
          }
        } else if (isArray) {
          // define an array if the next key is an index
          ref[path[i]] = ref[path[i]] ?? [];
        } else {
          // define a nested object if more keys are following
          ref[path[i]] = ref[path[i]] ?? {};
        }

        // move the reference along the path
        ref = ref[path[i]];
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- dont know typings over here
    } catch (err: any) {
      // eslint-disable-next-line no-console -- want to log when something goes wrong
      console.error(
        `Parsing formdata to object failed for key ${key}`,
        err.message,
      );
    }
  });

  return object as ReturnType;
};
