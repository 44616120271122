import {
  ICategoryExerciseCountResponse,
  IExercise,
} from "@hulanbv/platformapp";
import { CrudService, IHttpOptions, IResponse } from "nest-utilities-client";
import { environment } from "../../constants/environment.constants";
import { httpService } from "../common/http.service";

class Service extends CrudService<IExercise> {
  constructor() {
    super([environment.apiUrl, "exercises"].join("/"), httpService);
  }

  getCategoriesExerciseCount(
    options?: Pick<IHttpOptions<IExercise>, "match">,
  ): Promise<IResponse<ICategoryExerciseCountResponse[]>> {
    return this.http.get<ICategoryExerciseCountResponse[]>(
      [this.controller, "categories", "count"].join("/"),
      options,
    );
  }

  getInvitedExercises(
    userId: string,
    httpOptions?: IHttpOptions<IExercise>,
  ): Promise<IResponse<IExercise[]>> {
    return this.http.get<IExercise[]>(
      [this.controller, "users", userId, "invited"].join("/"),
      httpOptions,
    );
  }
}

export const exerciseService = new Service();
