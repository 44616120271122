import { FC, FormHTMLAttributes } from "react";
import { style } from "typestyle";
import { Form } from "../../../elements/form.element";
import { InputSelect } from "../../../elements/input-select.element";
import { Row } from "../../../elements/row.element";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";
import { Header } from "../../../elements/header.element";
import { Button } from "../../../elements/button.element";

interface IProps {
  attributes?: Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit">;
  onSubmit?: (formData: FormData) => void | Promise<void>;
  outcomeOptions: string[];
  processOfChangeOptions: string[];
  error: boolean;
}

export const ReportFormTemplate: FC<IProps> = (props) => (
  <Form attributes={props.attributes} onSubmit={props.onSubmit}>
    <Header size="regular">{dictionary.literals.selectVariables}</Header>

    <Row
      attributes={{
        className: styles.spacing,
      }}
      label={dictionary.texts.outcomeTitle}
    >
      <InputSelect
        isRequired
        name="outcome"
        placeholder={dictionary.literals.selectOutcome}
        isSearchable
        defaultValue={props.outcomeOptions[0]}
        options={props.outcomeOptions.map((option) => ({
          label: option,
          value: option,
        }))}
      />
    </Row>
    <Row label={dictionary.literals.processOfChange}>
      <InputSelect
        isRequired
        name="processOfChange"
        placeholder={dictionary.literals.selectProcessOfChange}
        isSearchable
        defaultValue={props.processOfChangeOptions[0]}
        options={props.processOfChangeOptions.map((option) => ({
          label: option,
          value: option,
        }))}
      />
    </Row>
    {props.error && (
      <p className={styles.error}>{dictionary.texts.createReportError}</p>
    )}
    <Row>
      <Button attributes={{ type: "submit" }} centerChildren>
        {dictionary.literals.plotTrend}
      </Button>
    </Row>
  </Form>
);

const styles = {
  error: style({
    color: "red",
    textAlign: "center",
    marginTop: "-15px",
    fontSize: "0.8rem",
  }),
  spacing: style({
    margin: "10px 0px",
  }),
};
