// eslint-disable-next-line eslint-comments/disable-enable-pair -- in this file we want to enable any type
/* eslint-disable @typescript-eslint/no-explicit-any -- some types are unknown */
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { IModal } from "../interfaces/modal.interface";

export interface IModalContextType {
  closeModal: () => void;
  openModal: <ResolveType>(
    content: (resolve: (response: any) => void) => ReactNode,
  ) => Promise<ResolveType | null>;
  modal: IModal | null;
}
/**
 * A hook to manage the modal component
 * @returns
 */
export const useModal = (): IModalContextType => {
  const [modal, setModal] = useState<IModal | null>(null);
  const resolveRef = useRef<((value: any | null) => void) | null>(null);

  useEffect(() => {
    // function that will be called when user navigates away from the page through
    // browser's navigation buttons. This will close the modal and resolve the promise
    // with null
    const closeModalOnNavigation = () => resolveRef.current?.(null);

    // Eventlistener that listens to the popstate event. This event is fired when user
    // navigates away from the page through browser's navigation buttons
    window.addEventListener("popstate", closeModalOnNavigation);

    return () => {
      // removes the event listener when the component is unmounted
      window.removeEventListener("popstate", closeModalOnNavigation);
    };
  }, []);

  const openModal = useCallback(async function openModal<ResolveType = null>(
    content: (resolve: (response: ResolveType | null) => void) => ReactNode,
    width?: number,
  ) {
    // resolve already opened modals
    resolveRef.current?.(null);

    // wait for the modal to be resolved
    return new Promise<ResolveType | null>((resolve) => {
      resolveRef.current = (response: ResolveType | null) => {
        // clean up the resolved modal
        resolveRef.current = null;
        setModal(null);

        // reset html/body styling so it can be scrolled
        document.body.style.overflow = "";

        // send the response
        resolve(response);
      };

      // set the new modal and move the resolve method to the state
      setModal({ content: content(resolveRef.current), width: width ?? 600 });

      // make sure the body cannot be scrolled
      document.body.style.overflow = "hidden";
    });
  },
  []);

  return {
    modal,
    openModal,

    /**
     * Closes any opened modals and resolves them with null
     */
    closeModal: () => {
      resolveRef.current?.(null);
    },
  };
};

/**
 * Returns a context aware useModal function
 * @returns
 */
export function useModalContext(): IModalContextType {
  return useContext(ModalContext);
}

export const ModalContext = createContext<ReturnType<typeof useModal>>({
  closeModal: () => null,
  openModal: async (content: (resolve: (response: any) => void) => ReactNode) =>
    null,
  modal: null,
});
