import { IQuestionnaire } from "@hulanbv/platformapp";
import { CrudService } from "nest-utilities-client";
import { httpService } from "../common/http.service";

class Service extends CrudService<IQuestionnaire> {
  constructor() {
    super(
      [process.env.REACT_APP_API_URL, "questionnaires"].join("/"),
      httpService,
    );
  }
}

export const questionnaireService = new Service();
