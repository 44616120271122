import { FC, ReactNode, HTMLAttributes } from "react";
import { classes, stylesheet } from "typestyle";

interface ICard {
  attributes?: HTMLAttributes<HTMLDivElement>;
  header?: ReactNode;
  showNextCard?: boolean;
}

export const Card: FC<ICard> = (props) => (
  <div
    {...{
      ...props.attributes,
      className: classes(styles.container, props.attributes?.className),
    }}
  >
    {props.showNextCard && (
      <div className={classes(styles.card, styles.backCard)} />
    )}
    <div className={styles.card}>
      {props.header && (
        <div
          className={classes(
            styles.header,
            !props.children && styles.headerOnly,
          )}
        >
          {props.header}
        </div>
      )}
      {props.children && <div className={styles.content}>{props.children}</div>}
    </div>
  </div>
);

const styles = stylesheet({
  container: {
    position: "relative",
    margin: "var(--spacing-vertical-small) 0px",
    width: "100%",
  },
  card: {
    position: "relative",
    width: "100%",
    borderRadius: 15,
    boxShadow: "0 2px 5px 0 rgba(var(--rgb-color-black), 0.1)",
    backgroundColor: "rgb(var(--rgb-color-white))",
  },
  backCard: {
    position: "absolute",
    bottom: -20,
    height: 50,
    width: "97%",
    margin: "0 auto",
    left: 0,
    right: 0,
  },
  header: {
    backgroundColor: "rgb(var(--rgb-color-primair-basis))",
    color: "rgb(var(--rgb-color-white))",
    padding: "var(--spacing-horizontal-regular)",
    borderRadius: "15px 15px 0 0",
  },
  headerOnly: {
    borderRadius: "15px",
    padding: "var(--spacing-horizontal-small)",
  },
  content: {
    padding: "var(--spacing-horizontal-regular)",
  },
});
