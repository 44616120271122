import { FC, Fragment, ReactNode, useCallback, useMemo } from "react";
import { classes, style } from "typestyle";
import { useLocation } from "react-router";
import { Role } from "@hulanbv/platformapp";
import { ReactComponent as HomeIcon } from "../../assets/graphics/symbols/home.svg";
import { ReactComponent as ProfilePractitionerIcon } from "../../assets/graphics/symbols/practitioner.svg";
import { ReactComponent as SearchIcon } from "../../assets/graphics/symbols/search.svg";
import { ReactComponent as CollectionsIcon } from "../../assets/graphics/symbols/favorite.svg";
import { ReactComponent as ProfileIcon } from "../../assets/graphics/symbols/profile.svg";
import { ReactComponent as TabActiveTop } from "../../assets/graphics/symbols/active-tab-item-top.svg";
import { routes } from "../../state/common/constants/routes.constants";
import { Anchor } from "./anchor.element";
import { useAuthContext } from "../../state/authentication/authentication.context";

interface ITabItem {
  activePaths?: string[];
  path: string;
  iconElement: ReactNode;
}

export const TabBar: FC = () => {
  const location = useLocation();
  const { session } = useAuthContext();

  const tabItems = useMemo<ITabItem[]>(() => {
    const tabItems: ITabItem[] = [
      {
        path: "/",
        iconElement: <HomeIcon />,
      },
    ];

    // When the user is a client and is not paired with a practitioner than
    // it will show the practitioner search menu item.
    if (session?.user?.role === Role.USER && !session.user.practitionerId) {
      tabItems.push({
        path: routes.search.path.replace(":tab?", ""),
        activePaths: ["", "map", "list", "external-search"].map((tab) =>
          routes.search.path.replace(":tab?", tab),
        ),
        iconElement: <SearchIcon />,
      });
    }

    // When the user is a practitioner the client menu item will be visible.
    if (session?.user?.role !== Role.USER) {
      tabItems.push({
        path: routes.clients.path,
        iconElement: <ProfileIcon />,
      });
    }

    tabItems.push({
      path: routes.collections.path,
      iconElement: <CollectionsIcon />,
    });
    tabItems.push({
      path: routes.myProfile.path,
      iconElement: <ProfilePractitionerIcon />,
    });

    return tabItems;
  }, [session?.user?.role, session?.user?.practitionerId]);

  /** returns all the tabbar-items, function changes whenever location.pathname changes,
   * because function needs to respond to active path (location.pathname)
   */
  const getTabItems = useCallback(
    (tabItems: ITabItem[]): JSX.Element[] =>
      tabItems.map((tabItem) => {
        let isActive = false;
        if (tabItem.activePaths) {
          isActive = tabItem.activePaths.includes(location.pathname);
        } else {
          isActive = location.pathname === tabItem.path;
        }

        return (
          <Anchor
            href={tabItem.path}
            className={styles.tabItemContainer}
            key={tabItem.path}
          >
            {isActive && (
              <Fragment>
                <div className={styles.tabItemActiveBackground} />
                <div className={styles.tabItemTop}>
                  <TabActiveTop width={"100%"} height={"100%"} />
                </div>
              </Fragment>
            )}
            <div
              className={classes(
                styles.tabIcon,
                isActive && styles.tabIconActive,
              )}
            >
              {tabItem.iconElement}
            </div>
          </Anchor>
        );
      }),

    [location.pathname],
  );

  return (
    <div className={styles.container}>{tabItems && getTabItems(tabItems)}</div>
  );
};

const styles = {
  container: style({
    position: "fixed",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    bottom: 0,
    boxShadow: "0 0 22px 0 rgba(var(--rgb-color-black), 0.15)",
    backgroundColor: "rgb(var(--rgb-color-white))",
    zIndex: 5,
  }),
  tabItemContainer: style({
    position: "relative",
    width: "33%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "content-box",
    height: "var(--tab-bar-height)",
    paddingBottom: "env(safe-area-inset-bottom, 0)",
  }),
  tabItemActiveBackground: style({
    position: "absolute",
    top: 15,
    left: 0,
    bottom: 0,
    width: "100%",
    background: "rgb(var(--rgb-color-primair-lighter))",
  }),
  tabItemTop: style({
    position: "absolute",
    height: 30,
    width: "100%",
    right: 0,
    top: -15,
    boxSizing: "content-box",
    color: "rgb(var(--rgb-color-primair-lighter))",
  }),
  tabIcon: style({
    display: "inline-block",
    height: 33,
    width: 33,
    zIndex: 1,
    color: "rgb(var(--rgb-color-primair-lighter))",
    $nest: {
      svg: {
        height: "100%",
        width: "100%",
      },
    },
  }),
  tabIconActive: style({
    color: "rgb(var(--rgb-color-white))",
  }),
};
