export const windowUtils = new (class WindowUtils {
  /**
   * Returns if the body is scrolled to the bottom
   *
   * @returns boolean
   */
  isBodyScrollAtBottom(): boolean {
    const { innerHeight, scrollY } = window;
    const { scrollHeight } = document.body;

    // We need to math ceil the window.scrollY because some browsers use subpixel
    // precision on window.scrollY, this can cause the bottom of the page to not
    // be correctly detected
    const windowScrollY = Math.ceil(scrollY);
    const hasReachedPageBottom = innerHeight + windowScrollY >= scrollHeight;

    return hasReachedPageBottom;
  }
})();
