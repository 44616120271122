import { ExerciseType } from "@hulanbv/platformapp";

export const exerciseColors: Record<ExerciseType, string> = {
  // TODO -- Dynamic Branding: Move these colors to their own color variables
  [ExerciseType.TEXT]: "rgb(0, 40, 255)",
  [ExerciseType.IMAGE]: "rgb(149, 207, 198)",
  [ExerciseType.VIDEO]: "rgb(255, 192, 51)",
  [ExerciseType.AUDIO]: "rgb(101, 56, 135)",
  [ExerciseType.PDF]: "rgb(234, 95, 26)",
  [ExerciseType.GAME]: "rgb(234, 95, 26)",
  [ExerciseType.URL]: "rgb(0, 170, 251)",
};
