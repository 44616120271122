import {
  FocusEvent,
  FormHTMLAttributes,
  forwardRef,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { style } from "typestyle";

import { ReactComponent as sendIcon } from "../../../../assets/graphics/symbols/send.svg";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";
import { routes } from "../../../../state/common/constants/routes.constants";
import { InjectionService } from "../../../../state/common/injection.service";
import { Anchor } from "../../../elements/anchor.element";
import { Button } from "../../../elements/button.element";
import { Checkbox } from "../../../elements/checkbox.element";
import { Form } from "../../../elements/form.element";
import { Input } from "../../../elements/input.element";
import { Row } from "../../../elements/row.element";
import { branding } from "../../../../constants/branding.constants";

interface IProps {
  attributes?: Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit">;
  onSubmit?: (formData: FormData) => void | Promise<void>;
  error?: string;
}

export const RegisterForm = forwardRef<
  HTMLFormElement,
  PropsWithChildren<IProps>
>((props, ref) => {
  const [error, setError] = useState(props.error);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const onFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (error) {
        // eslint-disable-next-line no-param-reassign -- needed to reset input field
        event.currentTarget.value = "";
      }
    },
    [error],
  );

  return (
    <Form {...props.attributes} onSubmit={props.onSubmit} ref={ref}>
      <Row>
        <Input
          attributes={{
            name: "name",
            type: "text",
            placeholder: dictionary.literals.firstName,
            required: true,
            onFocus,
            className: styles.input,
          }}
          error={error}
        />
      </Row>
      <Row>
        <Input
          attributes={{
            name: "lastName",
            type: "text",
            placeholder: dictionary.literals.lastName,
            required: true,
            onFocus,
            className: styles.input,
          }}
          error={error}
        />
      </Row>
      <Row>
        <Input
          attributes={{
            name: "email",
            type: "email",
            placeholder: dictionary.literals.email,
            required: true,
            onFocus,
            className: styles.input,
          }}
          error={error}
        />
      </Row>
      <Row>
        <Input
          attributes={{
            name: "password",
            type: "password",
            placeholder: dictionary.literals.password,
            required: true,
            onFocus,
            className: styles.input,
          }}
          error={error}
        />
      </Row>
      <Row>
        <Checkbox
          label={
            <div className={styles.labelContainer}>
              {dictionary.texts.iAgreeToX(
                <span
                  className={styles.labelLink}
                  onClick={() =>
                    InjectionService.openWebView(
                      branding.urls?.termsAndConditions ??
                        "https://hulan.nl/legal",
                    )
                  }
                  key={0}
                >
                  {dictionary.literals.termsOfService.toLowerCase()}
                </span>,
              )}
            </div>
          }
          attributes={{ required: true }}
        ></Checkbox>
      </Row>
      <Row>
        <Button attributes={{ type: "submit" }} centerChildren icon={sendIcon}>
          {dictionary.literals.register}
        </Button>
      </Row>
      <Row>
        <Anchor href={routes.login.path}>
          <Button
            centerChildren
            flavour={"primary"}
            hideSpinnerOnSubmit
            icon={sendIcon}
          >
            {dictionary.literals.backToLogin}
          </Button>
        </Anchor>
      </Row>
    </Form>
  );
});

const styles = {
  input: style({
    backgroundColor: "rgb(var(--rgb-color-white))",
    fontWeight: 400,
    $nest: {
      "&:focus": {
        border: "1.5px solid transparent",
      },
    },
  }),
  labelContainer: style({
    flex: 1,
    color: "rgb(var(--rgb-color-white))",
  }),
  labelLink: style({
    fontWeight: "bold",
  }),
};
