import { IUserDeletionRequest } from "@hulanbv/platformapp";
import { CrudService } from "nest-utilities-client";
import { environment } from "../../constants/environment.constants";
import { httpService } from "../common/http.service";

class Service extends CrudService<IUserDeletionRequest> {
  constructor() {
    super(
      [environment.apiUrl, "user-deletion-requests"].join("/"),
      httpService,
    );
  }
}

export const userDeletionRequestService = new Service();
