import { CrudService, IHttpOptions } from "nest-utilities-client";
import { useCallback, useEffect, useMemo, useState } from "react";
import Toast from "../../../components/statics/toast";
import { dictionary } from "../constants/dictionary.constants";

type ReturnType<DataType> = {
  /**
   * All items fetched in pagination
   */
  items: DataType[];
  /**
   * Whenever called it will fetch the next items, based on skip and limit
   */
  fetchNextItems: () => void;
  /**
   * Will set skip to zero and fetch with current options or fetchRequest
   */
  refreshItems: () => void;
  /**
   * Will be true whenever the maximum amount of fetched items is reached
   */
  hasReachedLimit: boolean;
  /**
   * Will be true whenever hook is busy fetching next items
   */
  isFetching: boolean;
};

type Props = {
  /**
   * The amount of items that will be fetched each time
   */
  limit: number;
};

type PropsWithService<DataType> = Props & {
  /**
   * Service to be used for fetching items
   */
  service: CrudService<DataType>;
  /**
   * HTTP options without offset, skip or limit
   *
   * Limit can be set through props.limit
   */
  options?: Omit<IHttpOptions<DataType>, "offset" | "skip" | "limit">;
};

type PropsWithFetchRequest<DataType> = Props & {
  /**
   * Whenever custom fetch request is defined the hook will use this to fetch the next items
   * The next skip and current limit are passed in the callback
   */
  fetchRequest: (skip: number, limit: number) => Promise<DataType[]>;
};

type HttpOptionsSkipLimitRequired<DataType> = IHttpOptions<DataType> & {
  skip: number;
  limit: number;
};

export function usePagination<DataType>(
  props: PropsWithService<DataType> | PropsWithFetchRequest<DataType>,
): ReturnType<DataType> {
  const [items, setItems] = useState<DataType[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [paginationState, setPaginationState] = useState({
    skip: 0,
    limit: props.limit,
  });

  const [hasReachedLimit, setHasReachedLimit] = useState(false);

  const memoizedHttpSkipOptions = useMemo(
    (): HttpOptionsSkipLimitRequired<DataType> => ({
      ...("options" in props ? props.options : {}),
      skip: paginationState.skip,
      limit: paginationState.limit,
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps -- do not feel props for memoized instance
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps -- needed to check for changes in props.options
      "options" in props && props.options,
      paginationState.skip,
      paginationState.limit,
    ],
  );

  /**
   * Respond to changes in props.options and fetchRequest function. If one of these changes:
   * reset the pagination
   *
   * Difference between props.options and memoizedSkipOptions is the skip value
   */
  useEffect(() => {
    refreshItems();
    /* eslint-disable react-hooks/exhaustive-deps -- only refresh items when options or fetchRequest changes */
  }, [
    "options" in props && props.options,
    "fetchRequest" in props && props.fetchRequest,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps -- enable for rest of file again */

  /**
   * Respond to changes in memoizedSkipOptions or fetchRequest
   *
   * Whenever memoizedHttpSkipOptions (because of skip state) or the props.fetchRequest changes, call fetchItems
   */
  useEffect(() => {
    fetchItems(memoizedHttpSkipOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only fetch items when memoized options or fetchRequest changes
  }, [memoizedHttpSkipOptions, "fetchRequest" in props && props.fetchRequest]);

  /**
   * Fetches the items, sets the response in the items and response state
   */
  const fetchItems = useCallback(
    async (options: HttpOptionsSkipLimitRequired<DataType>) => {
      try {
        const { skip, limit } = options;
        let responseData: DataType[] = [];

        setIsFetching(true);

        // If a service is defined make a call through the getAll of the service
        if ("service" in props) {
          responseData = (
            await props.service.getAll({ ...options, skip, limit })
          ).data;

          // if current response is empty, the limit is reached
          const hasReachedLimit = responseData.length < limit;
          setHasReachedLimit(hasReachedLimit);
        } else {
          // otherwise make the call by using custom defined fetchRequest
          responseData = await props.fetchRequest(skip, limit);

          // if current response is empty, the limit is reached
          const hasReachedLimit = responseData.length < limit;
          setHasReachedLimit(hasReachedLimit);
        }

        // Merge the response data with the already set state items
        // If skip is equal to zero, start with an empty array
        setItems((items) =>
          (skip === 0 ? [] : items).concat(responseData ?? []),
        );
      } catch {
        Toast.error({
          body: dictionary.texts.itemsFetchError,
        });
      } finally {
        setIsFetching(false);
      }
    },
    [props],
  );

  /**
   * Refreshes items by starting with the skip set to zero again
   *
   * Whenever forceUpdate is set to true
   */
  const refreshItems = useCallback(
    (forceUpdate?: boolean) => {
      // always set hasReachedLimit to false because we are resetting the pagination
      setHasReachedLimit(false);
      // If skip isn't set to zero, we can start over again by simply setting the skip state back to zero
      if (paginationState.skip !== 0) {
        // Changing the skip value to zero will trigger the above useEffect followed by a fetchRequest
        setPaginationState({ ...paginationState, skip: 0 });
      } else if (forceUpdate === true) {
        // If fetch is already set to zero, it won't trigger a fetch request (because memoized httpOptions object will stay the same)
        // But in some cases you still want to fetch the newest items, if this function is called with forceUpdate set to true
        // it will manually perform a fetch
        fetchItems(memoizedHttpSkipOptions);
      }
    },
    [fetchItems, memoizedHttpSkipOptions, paginationState],
  );

  /**
   * When called it will fetch the next items (current skip + limit)
   * Won't do anything whenever the limit is already reached
   */
  const fetchNextItems = useCallback(() => {
    // don't update the progress if we still have a request pending or the limit is reached
    if (hasReachedLimit === true || isFetching === true) {
      return;
    }
    const { limit, skip } = paginationState;
    const nextSkip = skip + limit;
    setPaginationState({ limit, skip: nextSkip });
  }, [hasReachedLimit, isFetching, paginationState]);

  return {
    items,
    hasReachedLimit,
    fetchNextItems,
    refreshItems: () => refreshItems(true),
    isFetching,
  };
}
