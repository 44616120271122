import { ExerciseType } from "@hulanbv/platformapp";
import { FunctionComponent, SVGProps } from "react";
import { ReactComponent as Audio } from "../../../assets/graphics/symbols/audio.svg";
import { ReactComponent as Game } from "../../../assets/graphics/symbols/game.svg";
import { ReactComponent as Image } from "../../../assets/graphics/symbols/image.svg";
import { ReactComponent as Pdf } from "../../../assets/graphics/symbols/pdf.svg";
import { ReactComponent as Text } from "../../../assets/graphics/symbols/text.svg";
import { ReactComponent as Url } from "../../../assets/graphics/symbols/url.svg";
import { ReactComponent as Video } from "../../../assets/graphics/symbols/video.svg";

export const exerciseIcons: Record<
  ExerciseType,
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  [ExerciseType.AUDIO]: Audio,
  [ExerciseType.GAME]: Game,
  [ExerciseType.IMAGE]: Image,
  [ExerciseType.PDF]: Pdf,
  [ExerciseType.TEXT]: Text,
  [ExerciseType.URL]: Url,
  [ExerciseType.VIDEO]: Video,
};
