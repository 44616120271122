import { FC, useCallback, useState } from "react";
import { classes, style } from "typestyle";
import { Page } from "../elements/page.element";
import { TextLogo } from "../elements/text-logo.element";
import { LoginForm } from "../templates/forms/user/login-form.template";
import { routes } from "../../state/common/constants/routes.constants";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { Anchor } from "../elements/anchor.element";
import { PageBody } from "../elements/page-body.element";
import Toast from "../statics/toast";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { branding } from "../../constants/branding.constants";

export const LoginScreen: FC = () => {
  const { login } = useAuthContext();
  const [error, setError] = useState<string | undefined>();

  const handleLogin = useCallback(
    async (formData: FormData) => {
      try {
        setError(undefined);
        await login(formData);
      } catch (err) {
        if (err instanceof Error) {
          if (err.message) {
            setError(err.message);
            Toast.error({ body: err.message });
          }
        } else {
          setError("Error");
          Toast.error({ body: dictionary.texts.wrongLoginCombination });
        }
      }
    },
    [login],
  );

  return (
    <Page hideNavs className={styles.container}>
      <PageBody fullHeight hiddenNavs className={styles.pageBody}>
        <div className={styles.logoContainer}>
          <TextLogo />
        </div>
        <div className={styles.inputContainer}>
          <LoginForm onSubmit={handleLogin} error={error} />
        </div>
        <div className={styles.linkContainer}>
          <Anchor
            className={classes(styles.anchorLink, styles.forgotPass)}
            href={routes.forgotPassword.path}
          >
            {dictionary.literals.forgotPassword}?
          </Anchor>
          <Anchor
            className={styles.anchorLink}
            href={branding.urls?.homepage ?? "https://hulan.nl"}
          >
            {dictionary.literals.moreInformation}
          </Anchor>
        </div>
      </PageBody>
    </Page>
  );
};

const styles = {
  container: style({
    background:
      "linear-gradient(rgb(var(--rgb-color-primair-shade-1)), rgb(var(--rgb-color-primair-basis)))",
  }),
  pageBody: style({
    paddingLeft: 55,
    paddingRight: 55,
  }),
  logoContainer: style({
    flex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  }),
  inputContainer: style({
    flex: 2,
    width: "100%",
    marginTop: "var(--spacing-vertical-small)",
  }),
  header: style({
    color: "rgb(var(--rgb-color-white))",
    textAlign: "center",
  }),
  linkContainer: style({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    flex: 1,
    color: "rgb(var(--rgb-color-white))",
  }),
  anchorLink: style({
    color: "rgb(var(--rgb-color-white))",
    fontSize: "0.9rem",
  }),
  forgotPass: style({
    marginBottom: 14,
  }),
};
