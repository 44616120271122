import { FormEvent, useCallback, useState } from "react";
import { IModel } from "@hulanbv/platformapp";
import { ReactComponent as crossIcon } from "../../../assets/graphics/symbols/cross.svg";
import { ReactComponent as checkIcon } from "../../../assets/graphics/symbols/checkmark.svg";
import { Button } from "../../elements/button.element";
import { FlexItem } from "../../elements/flex-item.element";
import { Flex } from "../../elements/flex.element";
import { Form } from "../../elements/form.element";
import { Header } from "../../elements/header.element";
import {
  InputMultiSelectModel,
  InputMultiSelectPropsWithFetchRequest,
  InputMultiSelectPropsWithService,
} from "../../elements/input-multi-select-model.element";
import Toast from "../../statics/toast";
import { dictionary } from "../../../state/common/constants/dictionary.constants";

type IProps<UserModelType extends IModel> = {
  header?: string;
  inputMultiSelectModelAttributes:
    | InputMultiSelectPropsWithFetchRequest<UserModelType>
    | InputMultiSelectPropsWithService<UserModelType>;
  onSubmit?: (selectedUsers: UserModelType[]) => void;
  onCancelClick?: () => void;
};

export function SelectUsersModal<UserModelType extends IModel>(
  props: IProps<UserModelType>,
): JSX.Element {
  const [selectedUsers, setSelectedUsers] = useState<UserModelType[]>();
  const onSubmit = (formData: FormData, event: FormEvent) => {
    if (!selectedUsers?.length) {
      Toast.error({ body: dictionary.literals.noUsersSelected });
      return;
    }
    props.onSubmit?.(selectedUsers);
  };

  const handleOnChange = useCallback(
    (selectedItems: UserModelType[]) => {
      setSelectedUsers(selectedItems);
      props.inputMultiSelectModelAttributes.onChange?.(selectedItems);
    },
    [props.inputMultiSelectModelAttributes],
  );

  return (
    <Form onSubmit={onSubmit}>
      <Flex direction="column" alignItems="stretch" padding={20}>
        <FlexItem>
          <Header center>
            {props.header ?? dictionary.literals.selectUsers}
          </Header>
        </FlexItem>
        <FlexItem grow={1}>
          <InputMultiSelectModel
            {...{
              ...props.inputMultiSelectModelAttributes,
              onChange: handleOnChange,
              ...("fetchRequest" in props.inputMultiSelectModelAttributes
                ? {
                    fetchRequest:
                      props.inputMultiSelectModelAttributes.fetchRequest,
                  }
                : {
                    service: props.inputMultiSelectModelAttributes.service,
                    options: {
                      ...props.inputMultiSelectModelAttributes.httpOptions,
                    },
                  }),
            }}
          />
        </FlexItem>
        <FlexItem shrink={0}>
          <Flex direction="column" gap={10}>
            <Button
              flavour="primary"
              attributes={{ type: "submit" }}
              icon={checkIcon}
            >
              {dictionary.literals.confirm}
            </Button>
            <Button
              flavour="secondary"
              attributes={{ onClick: props.onCancelClick }}
              icon={crossIcon}
            >
              {dictionary.literals.cancel}
            </Button>
          </Flex>
        </FlexItem>
      </Flex>
    </Form>
  );
}
