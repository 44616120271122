// import { Role } from "@hulanbv/platformapp";
// import { useAll } from "@hulanbv/nest-utilities-client-state";
import { IUser } from "@hulanbv/platformapp";
import { FetchTiming, useAll } from "@hulanbv/nest-utilities-client-state";
import { Map, Marker } from "mapbox-gl";
import { IHttpOptions } from "nest-utilities-client";
import { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import mapViewIcon from "../../../assets/graphics/legacy/map-view-icon.svg";
import { routes } from "../../../state/common/constants/routes.constants";
import { userService } from "../../../state/user/user.service";
import { mapUtils } from "../../../utils/map.utils";
import { Mapbox } from "../../elements/mapbox.element";

interface IProps {
  httpOptions?: IHttpOptions<IUser>;
  className?: string;
}

export const MapUserTemplate: FC<IProps> = (props) => {
  const history = useHistory();
  const [httpOptions, setHttpOptions] = useState<IHttpOptions<IUser>>({
    ...props.httpOptions,
    match: {
      ...props.httpOptions?.match,
      companyGeolocation: { $exists: true },
    },
  });

  const [map, setMap] = useState<Map>();

  const { data, call } = useAll(userService, httpOptions, {
    fetchTiming: FetchTiming.ON_CALL,
  });

  const markers = useRef<Marker[]>();

  useEffect(() => {
    if (props.httpOptions) {
      setHttpOptions(props.httpOptions);
    }
  }, [props.httpOptions]);

  useEffect(() => {
    call();
  }, [httpOptions, call]);

  useEffect(() => {
    if (!map) {
      return;
    }
    // first remove the old markers
    markers.current?.forEach((marker) => marker.remove());
    // create new markers based on incoming data
    const newMarkers: Marker[] = [];
    data?.forEach((user) => {
      if (!user.companyGeolocation?.coordinates) {
        return;
      }
      const { coordinates } = user.companyGeolocation;

      const newMarker = mapUtils
        .getMarker()
        .setLngLat({ lng: coordinates[0], lat: coordinates[1] })
        .setPopup(
          mapUtils.getPopup({
            header: `${user.name ?? ""} ${user.lastName ?? ""}`,
            subTexts: [user.city ?? "", user.website ?? ""],
            actions: [
              {
                icon: mapViewIcon,
                action: () =>
                  history.push(
                    routes.profile.path.replace(":userId", user._id),
                  ),
              },
            ],
          }),
        );
      // add the markers to the map
      newMarker.addTo(map);
      newMarkers.push(newMarker);
    });

    // set markers in ref so we can remove them on the next batch of incoming data
    markers.current = newMarkers;

    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on data change
  }, [data, map]);

  return (
    <Mapbox
      className={props.className}
      onSetMap={(map) => {
        setMap(map);
        call();
      }}
    />
  );
};
