/**
 *
 * This utility will take in two identifiers and calculate the strength of the
 * relationship between the two identifiers. This will be done by a created
 * formula that will take in the two identifiers and return a number between -1
 * and 1. The closer the number is to 1, the stronger the relationship between.
 *
 * @param firstIdentifier
 * @param secondIdentifier
 * @returns the strength of the relationship between the two identifiers
 *
 */

export const calculateCorrelationCoefficient = (
  xValues: number[],
  yValues: number[],
): number => {
  // Gets the number of data points
  const arrayLength = xValues.length;

  // Calculate the sum of the x values and the sum of the y values
  const sumX = xValues.reduce((prev, xValue) => xValue + prev, 0);
  const sumY = yValues.reduce((prev, yValue) => yValue + prev, 0);

  // Calculate the sum of the cross-products of x and y values
  const sumXY = xValues.reduce(
    (sum, xValue, index) => sum + xValue * yValues[index],
    0,
  );

  // Calculate the sum of the squared x values and the sum of the squared y
  // values
  const sumXSquared = xValues.reduce((sum, xValue) => sum + xValue ** 2, 0);
  const sumYSquared = yValues.reduce((sum, yValue) => sum + yValue ** 2, 0);

  // Calculate the numerator of the correlation coefficient formula
  const numerator = arrayLength * sumXY - sumX * sumY;
  // Calculate the denominator of the correlation coefficient formula
  const denominator = Math.sqrt(
    (arrayLength * sumXSquared - sumX ** 2) *
      (arrayLength * sumYSquared - sumY ** 2),
  );

  // Calculate the correlation coefficient and return it
  const correlationCoefficient = numerator / denominator;
  return Number(correlationCoefficient.toFixed(2));
};
