import { DeviceOS } from "@hulanbv/platformapp";
import Toast from "../../components/statics/toast";
import { dictionary } from "./constants/dictionary.constants";

/* eslint-disable @typescript-eslint/no-explicit-any -- needed for IWindow typings */
declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- need window typings
  interface Window {
    android: any;
    webkit: any;
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention -- need for event window typings
  interface WindowEventMap {
    DEVICE_TOKEN: CustomEvent;
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any -- needed for IWindow typings */
// eslint-enable-next-line @typescript-eslint/naming-convention -- need window typings

export interface IDeviceResponse {
  deviceToken: string;
  deviceOS: DeviceOS;
}
export class InjectionService {
  public static getDeviceToken(): Promise<IDeviceResponse | null> {
    return new Promise((resolve, reject) => {
      // Make sure window["android"] or window["ios"] exists, otherwise we are not on an android or ios device !!
      if (window.android || window.webkit) {
        const deviceOS = window.android ? DeviceOS.ANDROID : DeviceOS.IOS;

        // Create the event for "DEVICE_TOKEN"
        window.addEventListener(
          "DEVICE_TOKEN",
          (evt: CustomEvent) => {
            resolve({ deviceToken: evt.detail, deviceOS });
          },
          { once: true },
        );
        // Call the android or ios native context, this causes the above eventListener to be called!
        this.sendMessage("getDeviceToken");
      } else {
        resolve(null);
      }
    });
  }

  public static playSound(url: string): void {
    this.sendMessage("playSound", url, true);
  }

  public static pauseSound(): void {
    this.sendMessage("pauseSound");
  }

  public static resumeSound(): void {
    this.sendMessage("resumeSound");
  }

  public static stopSound(): void {
    this.sendMessage("stopSound");
  }

  public static requestNotificationAuthorization(): void {
    this.sendMessage("requestNotificationAuthorization");
  }

  public static hapticFeedback(strength: "light" | "medium" | "heavy"): void {
    this.sendMessage("hapticFeedback", strength, false);
  }

  public static setStatusBarStyle(style: "default" | "lightContent"): void {
    this.sendMessage("setStatusBarStyle", style, false);
  }

  public static call(phonenumber: string): void {
    if (window.android || window.webkit) {
      this.sendMessage("call", phonenumber, true);
    } else {
      window.open(`tel:${phonenumber}`);
    }
  }

  public static mail(mail: string): void {
    if (window.android || window.webkit) {
      this.sendMessage("mail", mail, true);
    } else {
      window.open(`mailto:${mail}`);
    }
  }

  public static showWebview(): void {
    this.sendMessage("showWebview", undefined, false);
  }

  public static openWebView(url: string): void {
    if (window?.webkit) {
      this.sendWebKitMessage("openSVC", url, true);
    } else if (window.android && "openExternalUrl" in window.android) {
      this.sendAndroidMessage("openExternalUrl", url, true);
    } else {
      window.open(url, "_blank");
    }
  }

  public static requestReview(): void {
    this.sendMessage("requestReview");
  }

  private static sendMessage(
    name: string,
    body?: string,
    showError = true,
  ): void {
    if (window.webkit) {
      this.sendWebKitMessage(name, body, showError);
    } else if (window.android) {
      this.sendAndroidMessage(name, body, showError);
    }
  }

  private static sendWebKitMessage(
    name: string,
    body?: string,
    showError?: boolean,
  ): void {
    try {
      window.webkit.messageHandlers[name].postMessage(body || "");
    } catch (err) {
      if (showError === true) {
        Toast.error({ body: dictionary.texts.updateAppNotification });
      }
    }
  }

  private static sendAndroidMessage(
    name: string,
    body?: string,
    showError?: boolean,
  ): void {
    try {
      if (!body || body.length === 0) {
        window.android[name]();
      } else {
        window.android[name](body);
      }
    } catch (err) {
      if (showError === true) {
        Toast.error({ body: dictionary.texts.updateAppNotification });
      }
    }
  }
}
