import { IClientGroup, IUser, Role } from "@hulanbv/platformapp";
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { generatePath, useHistory, useParams } from "react-router";
import { IHttpOptions } from "nest-utilities-client";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { Table, TableActions } from "../elements/table.element";
import { Flex } from "../elements/flex.element";
import { useModalContext } from "../../state/common/contexts/modal.context";
import { clientGroupService } from "../../state/client-group/client-group.service";
import { TableHeadTemplate } from "../templates/table-head.template";
import { useUrlState } from "../../state/common/hooks/url-state-hook/use-url-state.hook";
import { ClientRowTemplate } from "../templates/table-rows/client-row.template";
import { SelectUsersModal } from "../templates/modals/select-users-modal.template";
import Toast from "../statics/toast";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { ConfirmModal } from "../templates/modals/confirm-modal.template";
import { routes } from "../../state/common/constants/routes.constants";

export const ClientGroupMembersScreen: FC = () => {
  const { openModal } = useModalContext();
  const params = useParams<{ clientGroupId: string }>();
  const [searchParamValue, setSearchParamValue] =
    useUrlState<string>("search-filter");

  const [clientGroup, setClientGroup] = useState<IClientGroup>();

  const tableActionsRef = useRef<TableActions>(null);
  const history = useHistory();

  useEffect(() => {
    // fetch client-group so we can show the name of the client-group on the top of the page
    const fetchClientGroup = async () => {
      try {
        const { data: clientGroup } = await clientGroupService.get(
          params.clientGroupId,
          { select: ["name", "clientIds"] },
        );
        setClientGroup(clientGroup);
      } catch {
        Toast.error({
          body: dictionary.texts.retrievingClientGroupError,
        });
      }
    };
    fetchClientGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only execute on initial render
  }, []);

  /**
   * Whenever a user searches for new members to add to their group we want to call
   * a custom created endpoint. This endpoint will get all the members that are not within
   * this group + additional options
   */
  const findNewClientFetchRequest = useCallback(
    async (options?: IHttpOptions<IUser>) => {
      const { data: nonGroupMembers } =
        await clientGroupService.getNonMembers<IUser>(params.clientGroupId, {
          ...options,
          match: { ...options?.match, role: Role.USER },
          addFields: {
            fullName: {
              $concat: ["$name", " ", "$lastName"],
            },
          },
          select: ["fullName"],
        });
      return nonGroupMembers;
    },
    [params.clientGroupId],
  );

  const handleOnAddButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      openModal((resolve) => (
        <SelectUsersModal<IUser & { fullName?: string }>
          header={dictionary.literals.addClients}
          inputMultiSelectModelAttributes={{
            searchKeys: ["fullName"],
            fetchRequest: findNewClientFetchRequest,
            formatOption: (user) =>
              user.fullName || `${user.name} ${user.lastName}`,
          }}
          // attributes={{ formatOption: (value) => value }}
          onSubmit={async (selectedUsers) => {
            try {
              // if no client-group is present, throw error
              if (!clientGroup?._id) {
                throw Error();
              }
              // combine clientIds from model and selected
              const selectedUserIds = [
                ...(selectedUsers?.map((user) => user._id) ?? []),
                ...clientGroup.clientIds,
              ];

              const { data: patchedClientGroup } =
                await clientGroupService.patch(
                  {
                    _id: clientGroup._id,
                    clientIds: selectedUserIds,
                  },
                  { select: ["name", "clientIds"] },
                );

              // Make sure to refresh the table so the clients are visible
              tableActionsRef.current?.refreshItems();
              // set recently patched client group, so whenever new clients are selected the $match: {$nin [clientGroup.clientIds]}
              // is up-to-date
              setClientGroup(patchedClientGroup);

              // Close the modal
              resolve(null);

              Toast.info({
                body: dictionary.texts.addClientsToGroupSuccess,
              });
            } catch {
              Toast.error({
                body: dictionary.texts.addClientsToGroupError,
              });
            }
          }}
          onCancelClick={() => resolve(null)}
        />
      ));
    },
    [
      clientGroup?._id,
      clientGroup?.clientIds,
      findNewClientFetchRequest,
      openModal,
    ],
  );

  const handleOnRemoveClientFromGroupClick = async (clientId: string) => {
    try {
      const isConfirmed = await openModal<boolean>((resolve) => (
        <ConfirmModal
          resolve={resolve}
          title={dictionary.literals.confirm}
          description={dictionary.texts.clientGroupDeleteUserConfirm}
          type="danger"
        />
      ));

      if (isConfirmed !== true) {
        return;
      }

      // gets the current clientGroup with the clientGroupId from the url
      const { data: group } = await clientGroupService.get(
        params.clientGroupId,
      );
      // filters out the client that is being removed
      group.clientIds = group.clientIds.filter((id) => id !== clientId);
      // put the updated clientGroup to the server
      await clientGroupService.put(group);
      // refresh the table so the client is removed
      tableActionsRef.current?.refreshItems();
      Toast.info({
        body: dictionary.texts.clientGroupDeleteUserSuccess,
      });
    } catch (error) {
      Toast.error({
        body: dictionary.texts.clientGroupDeleteUserError,
      });
    }
  };

  const fetchRequest = useCallback(
    async (skip: number, limit: number) => {
      const splittedSearchParam = searchParamValue?.trim()?.split(" ");
      const regex = splittedSearchParam?.join("|");
      const fetchOptions: IHttpOptions = {
        populate: [
          {
            path: "clients",
            limit,
            skip,
            ...(splittedSearchParam?.length && {
              match: {
                [splittedSearchParam?.length === 1 ? "$or" : "$and"]: [
                  {
                    name: { $regex: regex, $options: "i" },
                  },
                  {
                    lastName: { $regex: regex, $options: "i" },
                  },
                ],
              },
            }),
          },
        ],
      };
      const response = await clientGroupService.get(
        params.clientGroupId,
        fetchOptions,
      );
      return response.data.clients ?? [];
    },
    [params.clientGroupId, searchParamValue],
  );

  const handleOnViewClientClick = (user: IUser) => {
    const clientProfilePath = generatePath(routes.clientProfile.path, {
      userId: user._id,
    });
    history.push(clientProfilePath);
  };

  return (
    <Page>
      <PageBody fullWidth>
        <TableHeadTemplate
          header={dictionary.texts.clientGroupX(clientGroup?.name ?? "")}
          searchInputAttributes={{
            onChange: (event) => {
              setSearchParamValue(event.target.value);
            },
            defaultValue: searchParamValue ?? "",
          }}
          onAddButtonClick={handleOnAddButtonClick}
        />
        <Table<IUser>
          actionsRef={tableActionsRef}
          rowTemplate={(user) => (
            <ClientRowTemplate
              user={user}
              key={user._id}
              onRemoveClick={() => handleOnRemoveClientFromGroupClick(user._id)}
              onViewClick={() => handleOnViewClientClick(user)}
            />
          )}
          fetchRequest={fetchRequest}
          emptyContent={
            <Flex justifyContent="center">
              {dictionary.texts.noClientsAddedToGroup}
            </Flex>
          }
        ></Table>
      </PageBody>
    </Page>
  );
};
