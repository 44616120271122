import { FC, useEffect, useRef, useState } from "react";
import { IExerciseInvite } from "@hulanbv/platformapp";
import { classes, style } from "typestyle";
import { IHttpOptions } from "nest-utilities-client";
import { useHistory } from "react-router-dom";
import { exerciseInviteService } from "../../../state/exercise-invite/exercise-invite.service";
import { PageBody } from "../../elements/page-body.element";
import { Page } from "../../elements/page.element";
import previewImage from "../../../assets/graphics/illustrations/client-timeline-preview.svg";
import { ReactComponent as searchIcon } from "../../../assets/graphics/symbols/search.svg";
import { ReactComponent as bookIcon } from "../../../assets/graphics/symbols/book.svg";
import { ClientTimelinePreview } from "../../templates/client-timeline-preview.template";
import { useAuthContext } from "../../../state/authentication/authentication.context";
import { Header } from "../../elements/header.element";
import { dictionary } from "../../../state/common/constants/dictionary.constants";
import { branding } from "../../../constants/branding.constants";
import { VideoPlayer } from "../../elements/video-player/video-player.element";
import { Button } from "../../elements/button.element";
import { routes } from "../../../state/common/constants/routes.constants";
import { graphicSet } from "../../../constants/graphic-set.constants";
import { GeometricShape } from "../../elements/geometric-shape";
import { SvgIcon } from "../../elements/svg-icon.element";
import { Anchor } from "../../elements/anchor.element";

// eslint-disable-next-line complexity -- We need to have this many conditions
export const HomeClientScreen: FC = () => {
  const { session } = useAuthContext();
  const history = useHistory();

  const [highlightedExerciseInvite, setHighlightedExerciseInvite] =
    useState<IExerciseInvite | null>(null);

  useEffect(() => {
    const exerciseInviteOptions: IHttpOptions<IExerciseInvite> = {
      limit: 1,
      sort: ["-scheduledDate"],
      populate: ["exercise"],
      match: {
        scheduledDate: { $lt: new Date().toISOString() },
      },
    };

    const getHighlight = async () => {
      const unseenInvitesOptions: IHttpOptions<IExerciseInvite> = {
        ...exerciseInviteOptions,
        ...{
          match: {
            ...exerciseInviteOptions.match,
            seenAt: { $exists: false },
          },
        },
      };

      const { data: lastUnseenInvites } = await exerciseInviteService.getAll(
        unseenInvitesOptions,
      );

      if (lastUnseenInvites.length) {
        setHighlightedExerciseInvite(lastUnseenInvites[0]);
        return;
      }

      const { data: lastInvites } = await exerciseInviteService.getAll(
        exerciseInviteOptions,
      );
      setHighlightedExerciseInvite(lastInvites[0] ?? null);
    };

    getHighlight();
  }, [session?.userId]);

  const clientTimeLineRef = useRef<HTMLDivElement>(null);

  return (
    <Page hideGoBack>
      <PageBody
        fullWidth={session?.user?.practitioner !== null}
        className={session?.user?.practitioner ? styles.noSpacing : undefined}
      >
        {!session?.user?.practitioner && (
          <>
            <div className={styles.navButtonsContainer}>
              {branding.features?.externalPurchaseButton && (
                <Anchor
                  href={branding.features.externalPurchaseButton.url}
                  className={classes(
                    !branding.features.externalSearch && styles.halfWidth,
                    styles.geometricShapeContainer,
                  )}
                >
                  <GeometricShape
                    color={"rgb(var(--rgb-color-primair-lighter))"}
                    className={
                      branding.features?.externalSearch
                        ? styles.geometricShapeSide
                        : styles.middleGeometricShape
                    }
                  >
                    <SvgIcon icon={bookIcon} width={40} height={40} />
                  </GeometricShape>
                  <div className={styles.geometricShapeLabel}>
                    {branding.features?.externalPurchaseButton?.title}
                  </div>
                </Anchor>
              )}

              {branding.features?.hasIntroductionContent === true && (
                <div
                  className={classes(
                    (!branding.features?.externalSearch ||
                      !branding.features?.externalPurchaseButton) &&
                      styles.halfWidth,
                    styles.geometricShapeContainer,
                  )}
                  onClick={() =>
                    history.push(routes.clientIntroductionContent.path)
                  }
                >
                  <GeometricShape
                    color={"rgb(var(--rgb-color-primair-lighter))"}
                    className={styles.middleGeometricShape}
                  >
                    <SvgIcon
                      icon={graphicSet.trainingsGraphic}
                      width={40}
                      height={40}
                    />
                  </GeometricShape>
                  <div className={styles.geometricShapeLabel}>
                    {dictionary.literals.freeContent}
                  </div>
                </div>
              )}

              {branding.features?.externalSearch && (
                <Anchor
                  href={branding.features.externalSearch.url ?? ""}
                  className={classes(
                    !branding.features.externalPurchaseButton &&
                      styles.halfWidth,
                    styles.geometricShapeContainer,
                  )}
                >
                  <GeometricShape
                    color={"rgb(var(--rgb-color-primair-lighter))"}
                    className={
                      branding.features?.externalPurchaseButton
                        ? styles.geometricShapeSide
                        : styles.middleGeometricShape
                    }
                  >
                    <SvgIcon icon={searchIcon} width={40} height={40} />
                  </GeometricShape>
                  <div className={styles.geometricShapeLabel}>
                    {branding.features.externalSearch.title}
                  </div>
                </Anchor>
              )}
            </div>

            <div className={styles.introductionContainer}>
              <Header className={styles.header} center>
                {dictionary.texts.joinAppForFree(branding.name ?? "PLAP")}
              </Header>
              {branding.videoUrls?.introduction && (
                <VideoPlayer url={branding.videoUrls.introduction} />
              )}
              <div>
                {branding.restrictions?.canRegisterAsProfessional !== false && (
                  <Button
                    attributes={{
                      onClick: () =>
                        history.push(routes.registerPractitioner.path),
                    }}
                  >
                    {dictionary.texts.clickToRegisterAsProfessional}
                  </Button>
                )}
                {highlightedExerciseInvite?.exercise && (
                  <div className={styles.container} ref={clientTimeLineRef}>
                    <ClientTimelinePreview
                      exerciseInvite={highlightedExerciseInvite}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {session?.user?.practitioner && (
          <div className={styles.container} ref={clientTimeLineRef}>
            <Header center size="big" className={styles.header}>
              {dictionary.literals.myPracticeEnvironment}
            </Header>
            <div className={styles.previewImageContainer}>
              {branding.restrictions?.canSeeBannerImage !== false && (
                <img
                  src={previewImage}
                  alt="client-timeline"
                  className={styles.previewImage}
                />
              )}
              {!highlightedExerciseInvite?.exercise && (
                <div className={styles.textContainer}>
                  <p>{dictionary.texts.noExercisesReady}</p>
                </div>
              )}
            </div>

            {highlightedExerciseInvite?.exercise && (
              <ClientTimelinePreview
                exerciseInvite={highlightedExerciseInvite}
              />
            )}
          </div>
        )}
      </PageBody>
    </Page>
  );
};
const styles = {
  container: style({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(180deg, rgba(var(--rgb-color-primair-shade-4), 0.15), rgba(var(--rgb-color-primair-basis), 0.15))",
    padding: "35px 0",
  }),
  navButtonsContainer: style({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  }),
  geometricShapeContainer: style({
    display: "flex",
    flexDirection: "column",
    maxWidth: "33%",
    alignItems: "center",
    cursor: "pointer",
  }),
  halfWidth: style({
    maxWidth: "50%",
  }),
  geometricShapeLabel: style({
    textAlign: "center",
    fontSize: 17,
    paddingTop: 10,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
  }),
  geometricShapeSide: style({
    width: 95,
    height: 106,
  }),
  middleGeometricShape: style({
    width: 107.3,
    height: 120,
  }),
  header: style({
    marginBottom: 25,
  }),
  previewImageContainer: style({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
  }),
  previewImage: style({
    width: "50%",
    margin: "0 auto",
    marginBottom: 25,
  }),
  textContainer: style({
    textAlign: "center",
    padding: "0px 50px",
  }),
  noSpacing: style({
    paddingTop: 0,
    paddingBottom: 0,
    height: "100%",
  }),
  introductionContainer: style({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 40,
    paddingTop: 20,
  }),
};
