import { IExercise } from "@hulanbv/platformapp";
import { FC } from "react";
import { classes, style } from "typestyle";
import { GeometricShape } from "./geometric-shape";
import { MarkdownToHtml } from "./markdown-to-html.element";

export interface IExerciseTextualContentProps {
  message?: string;
  exercise: IExercise;
  color?: string;
  className?: string;
  subText?: JSX.Element | string;
  shouldTruncateDescription?: boolean;
}

export const ExerciseTextualContent: FC<IExerciseTextualContentProps> = (
  props,
) => {
  const { name } = props.exercise;
  const color = props.color ?? "rgb(var(--rgb-color-primair-basis))";
  return (
    <div className={classes(styles.container, props.className)}>
      {props.message && <div className={styles.message}>{props.message}</div>}
      <div className={styles.geometricShapeTopContainer}>
        <GeometricShape color={color} className={styles.geometricShape} />
        <div className={styles.subText}>{name}</div>
      </div>

      {props.exercise?.description && (
        <div className={styles.descriptionContainer}>
          <div
            className={styles.descriptionSideLine}
            style={{ backgroundColor: color }}
          />
          <div className={styles.description}>
            <MarkdownToHtml
              markdown={props.exercise.description}
              isTruncated={props.shouldTruncateDescription}
            />
          </div>
        </div>
      )}
      <div className={styles.geometricShapeBottomContainer}>
        <GeometricShape color={color} className={styles.geometricShape} />
        <div className={styles.subText}>{props.subText}</div>
      </div>
    </div>
  );
};

const descriptionSideLineWidth = 6;
const subTextGeometricShapeWidth = 17.76;
const defaultMargin = 13;

const styles = {
  container: style({
    padding: 24,
  }),
  message: style({
    marginBottom: "var(--spacing-vertical-small)",
  }),
  titleContainer: style({
    display: "flex",
    alignItems: "center",
    marginBottom: defaultMargin,
  }),
  title: style({
    marginLeft: 20,
  }),
  subTitle: style({
    marginBottom: defaultMargin,
  }),
  descriptionContainer: style({
    display: "flex",
  }),
  descriptionSideLine: style({
    width: descriptionSideLineWidth,
    backgroundColor: "rgb(var(--rgb-color-primair-lighter))",
    marginLeft: subTextGeometricShapeWidth / 2 - descriptionSideLineWidth / 2,
    marginRight: 21,
    borderRadius: "var(--border-radius-small)",
    flexShrink: 0,
  }),
  description: style({
    margin: "5px 0px",
  }),
  geometricShapeTopContainer: style({
    display: "flex",
    marginBottom: defaultMargin,
  }),
  geometricShapeBottomContainer: style({
    display: "flex",
    marginTop: defaultMargin,
  }),
  geometricShape: style({
    width: subTextGeometricShapeWidth,
    height: 20,
    marginRight: 15,
  }),
  subText: style({
    fontWeight: 700,
    fontSize: "0.75rem",
    margin: "auto 0",
    display: "block",
  }),
};
