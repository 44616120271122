import "./styling/global.css";
// import "./constants/branding-overwrites.constants";
import ReactDOM from "react-dom";
import { forceRenderStyles } from "typestyle";

import { StrictMode } from "react";
import { Main } from "./components/main";
import { TestEnvironmentBar } from "./components/statics/test-environment-bar.static";
import Toast from "./components/statics/toast";
// we need to import authentication service so we don't get any errors when
// importing it in the http service

ReactDOM.render(
  <StrictMode>
    <Main />
    <TestEnvironmentBar />
    <Toast />
  </StrictMode>,
  document.getElementById("root"),
);
forceRenderStyles();
