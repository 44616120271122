import {
  FC,
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  SVGProps,
  useCallback,
  useState,
} from "react";
import { classes, style } from "typestyle";
import { Button } from "../../elements/button.element";
import { ReactComponent as crossIcon } from "../../../assets/graphics/symbols/cross.svg";
import { ReactComponent as checkIcon } from "../../../assets/graphics/symbols/checkmark.svg";
import { Input } from "../../elements/input.element";
import { dictionary } from "../../../state/common/constants/dictionary.constants";

export interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  resolve: (value: boolean | null) => void;
  title: string;
  description: ReactNode;
  type: "success" | "danger";
  confirmButtonIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  cancelButtonIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmInputText?: string;
  confirmInputPlaceholder?: string;
}

export const ConfirmModal: FC<IProps> = (props) => {
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(
    props.confirmInputText !== undefined,
  );

  const handleConfirmTextChange = useCallback(
    (event) => {
      setIsConfirmButtonDisabled(event.target.value !== props.confirmInputText);
    },
    [props.confirmInputText],
  );

  return (
    <div
      {...{
        ...props.attributes,
        className: classes(styles.container, props.attributes?.className),
      }}
    >
      <h2 className={styles.headerText}>{props.title}</h2>
      <div className={styles.description}> {props.description}</div>
      {props.confirmInputText !== undefined && (
        <Input
          attributes={{
            type: "text",
            placeholder:
              props.confirmInputPlaceholder ?? props.confirmInputText,
            className: styles.confirmInput,
            onChange: handleConfirmTextChange,
          }}
        />
      )}
      <div className={styles.buttonsContainer}>
        <Button
          flavour={props.type === "danger" ? "danger" : "primary"}
          icon={props.confirmButtonIcon ?? checkIcon}
          attributes={{
            onClick: () => props.resolve(true),
            className: styles.marginBottom,
            disabled: isConfirmButtonDisabled,
          }}
          hideSpinnerOnSubmit={props.confirmInputText !== undefined}
        >
          <div className={styles.buttonText}>
            {props.confirmButtonText ?? dictionary.literals.confirm}
          </div>
        </Button>
        <Button
          flavour={"secondary"}
          icon={props.cancelButtonIcon ?? crossIcon}
          attributes={{
            onClick: () => props.resolve(false),
          }}
        >
          <div className={styles.buttonText}>
            {props.cancelButtonText ?? dictionary.literals.cancel}
          </div>
        </Button>
      </div>
    </div>
  );
};

const styles = {
  container: style({
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100%",
    width: "100%",
  }),
  confirmInput: style({ margin: "10px 0 10px 0" }),
  marginBottom: style({ marginBottom: 10 }),
  description: style({
    fontSize: "1.06rem",
    margin: "0 0 25px 0",
    textAlign: "center",
  }),
  icon: style({
    width: 25,
    height: 25,
    marginRight: 10,
  }),
  headerText: style({
    color: "rgb(var(--rgb-color-primair-basis))",
    textAlign: "center",
    margin: "25px 0",
  }),
  buttonsContainer: style({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-evenly",
  }),
  buttonIcon: style({
    width: 20,
  }),
  buttonText: style({
    fontSize: "0.95rem",
  }),
};
