import { branding } from "./branding.constants";

import logoDefaultFull from "../assets/images/logo-default-full.png";
import logoDefaultColor from "../assets/images/logo-default-color.png";
import logoDefaultNegative from "../assets/images/logo-default-negative.png";

import logoActguideFull from "../assets/images/logo-actguide-full.png";
import logoActguideColor from "../assets/images/logo-actguide-color.png";
import logoActguideNegative from "../assets/images/logo-actguide-negative.png";

import logoPsychflexFull from "../assets/images/logo-psychflex-full.png";
import logoPsychflexColor from "../assets/images/logo-psychflex-color.png";
import logoPsychflexNegative from "../assets/images/logo-psychflex-negative.png";

import logoMindgrapherFull from "../assets/images/logo-mindgrapher-full.png";
import logoMindgrapherColor from "../assets/images/logo-mindgrapher-color.png";
import logoMindgrapherNegative from "../assets/images/logo-mindgrapher-negative.png";

type ImageSet = {
  logoFull: string;
  logoColor: string;
  logoNegative: string;
};

const imageSets: { [name: string]: ImageSet } = {
  default: {
    logoFull: logoDefaultFull,
    logoColor: logoDefaultColor,
    logoNegative: logoDefaultNegative,
  },
  actguide: {
    logoFull: logoActguideFull,
    logoColor: logoActguideColor,
    logoNegative: logoActguideNegative,
  },
  psychflex: {
    logoFull: logoPsychflexFull,
    logoColor: logoPsychflexColor,
    logoNegative: logoPsychflexNegative,
  },
  mindgrapher: {
    logoFull: logoMindgrapherFull,
    logoColor: logoMindgrapherColor,
    logoNegative: logoMindgrapherNegative,
  },
};

const imageSet = imageSets[branding.imageSet ?? "default"] || imageSets.default;

export { imageSet };
