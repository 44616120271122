import { FC, HTMLAttributes } from "react";
import { classes, style } from "typestyle";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const TableRow: FC<IProps> = (props) => {
  const { attributes } = props;

  return (
    <div
      {...{
        ...attributes,
        className: classes(styles.container, attributes?.className),
      }}
    >
      {props.children}
    </div>
  );
};

const styles = {
  container: style({
    display: "flex",
    flexDirection: "column",
    minHeight: 83,
    padding:
      "var(--spacing-vertical-regular) var(--spacing-horizontal-regular)",
    $nest: {
      "&:nth-child(odd)": {
        backgroundColor: "rgb(var(--rgb-color-gray-lighter))",
      },
      "&:nth-child(even)": {
        backgroundImage:
          "linear-gradient(180deg, rgba(var(--rgb-color-primair-shade-4), 0.15), rgba(var(--rgb-color-secundair-lighter), 0.15))",
      },
    },
  }),
};
