import { CSSProperties, ReactNode, useMemo } from "react";

interface IProps {
  children: ReactNode;
  direction?: "row" | "column";
  mobileDirection?: "row" | "column";
  flex?: string | number;
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around";
  alignItems?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
  gap?: number;
  minHeight?: number | string;
  maxWidth?: number | string;
  padding?: number | string;
  style?: CSSProperties;
}

function Flex(props: IProps): JSX.Element {
  const style = useMemo<CSSProperties>(
    () => ({
      display: "flex",
      flex: props.flex,
      flexDirection: props.direction ?? "row",
      justifyContent: props.justifyContent ?? "center",
      alignItems: props.alignItems ?? "center",
      gap: props.gap ?? 20,
      padding: props.padding,
      minHeight: props.minHeight,
      maxWidth: props.maxWidth,
      ...props.style,
    }),
    [props],
  );

  return <div style={style} children={props.children} />;
}

export { Flex };
