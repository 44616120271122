import { FC, ReactNode } from "react";
import { style } from "typestyle";
import { GeometricShape } from "./geometric-shape";

interface IProps {
  label: string;
  value?: ReactNode;
}

export const ProfileRow: FC<IProps> = (props) => (
  <div className={styles.row}>
    <div className={styles.label}>{props.label}</div>
    <div className={styles.value}>
      <GeometricShape
        color={"rgb(var(--rgb-color-primair-shade-2))"}
        className={styles.geometricShape}
      />
      {props.value}
    </div>
  </div>
);

const styles = {
  label: style({
    marginBottom: 4,
    fontSize: "0.93rem",
    fontWeight: 400,
  }),
  row: style({
    marginBottom: 25,
  }),
  value: style({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    fontSize: "1.01rem",
    fontWeight: 500,
    $nest: {
      "& p:first-child": {
        marginTop: 0,
      },
      "& p:last-child": {
        marginBottom: 0,
      },
    },
  }),
  geometricShape: style({
    width: 18,
    height: 20,
    marginRight: 6,
  }),
};
