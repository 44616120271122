import { Role } from "@hulanbv/platformapp";
import { FetchState, useById } from "@hulanbv/nest-utilities-client-state";
import { FC, useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { classes, style } from "typestyle";
import { ReactComponent as checkIcon } from "../../assets/graphics/symbols/checkmark.svg";
import { ReactComponent as crossIcon } from "../../assets/graphics/symbols/cross.svg";
import { routes } from "../../state/common/constants/routes.constants";
import { useFormIsDisabled } from "../../state/common/hooks/use-form-is-disabled";
import { userService } from "../../state/user/user.service";
import { Button } from "../elements/button.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import Toast from "../statics/toast";
import { UserClientForm } from "../templates/forms/user/user-client-form.template";
import { UserPractitionerForm } from "../templates/forms/user/practitioner-form.template";
import { ProfileBasicDetailsTemplate } from "../templates/profiles/profile-basic-details.template";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { useModalContext } from "../../state/common/contexts/modal.context";
import { ConfirmModal } from "../templates/modals/confirm-modal.template";
import { userDeletionRequestService } from "../../state/user-deletion-request/user-deletion-request.service";
import { InfoModal } from "../templates/modals/info-modal.template";

import { LoadingSpinner } from "../elements/loading-spinner.element";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { branding } from "../../constants/branding.constants";

export const ProfileEditScreen: FC = () => {
  const { session, validate, logout } = useAuthContext();
  const { data, fetchState } = useById(
    userService,
    session?.userId,
    {},
    { cache: false, distinct: true },
  );
  const history = useHistory();
  const coordinates = data?.companyGeolocation?.coordinates;
  const [asset, setAsset] = useState<{ url: string }>();
  const clientFormRef = useRef<HTMLFormElement>(null);
  const practitionerFormRef = useRef<HTMLFormElement>(null);
  const isClientFormDisabled = useFormIsDisabled(clientFormRef);
  const isPractitionerFormDisabled = useFormIsDisabled(practitionerFormRef);
  const { openModal } = useModalContext();

  const onSubmitForm = useCallback(
    async (formData: FormData) => {
      try {
        await userService.patch(formData);
        await validate();
        history.goBack();
        Toast.info({
          body: dictionary.texts.profileUpdatedSuccess,
        });
      } catch {
        Toast.error({
          body: dictionary.texts.profileUpdatedError,
        });
      }
    },
    [history, validate],
  );

  const deleteAccount = useCallback(async () => {
    const isConfirmed = await openModal<boolean>((resolve) => (
      <ConfirmModal
        resolve={resolve}
        title={dictionary.texts.deleteAccountPermanently}
        description={dictionary.texts.deleteAccountPermanentlyDescription}
        confirmButtonText={dictionary.literals.delete}
        confirmInputPlaceholder={dictionary.literals.enterFirstName}
        confirmInputText={data?.name}
        type={"danger"}
      />
    ));

    if (isConfirmed && data) {
      await userDeletionRequestService.post({
        userId: data._id,
      });

      openModal(() => (
        <InfoModal
          title={dictionary.literals.requestSubmitted}
          description={dictionary.texts.requestSubmittedDescription}
          confirmButtonText={dictionary.literals.confirm}
        />
      ));
      logout();
    }
  }, [data, logout, openModal]);

  return (
    <Page>
      <PageBody fullWidth>
        {data && (
          <ProfileBasicDetailsTemplate
            className={styles.basicDetailsContainer}
            onAssetUploaded={setAsset}
            user={data}
          />
        )}
        {fetchState === FetchState.Pending && <LoadingSpinner color="black" />}
        {fetchState === FetchState.Fulfilled && (
          <div className={styles.formContainer}>
            {data?.role === Role.PRACTITIONER && (
              <UserPractitionerForm
                user={data}
                hideSubmitButton
                ref={practitionerFormRef}
                onSubmit={onSubmitForm}
              >
                {asset && (
                  <input
                    type="hidden"
                    name="profilePictureUrl"
                    value={asset.url}
                  />
                )}
                <div className={styles.buttonContainer}>
                  <Button
                    icon={checkIcon}
                    attributes={{
                      type: "submit",
                      className: classes(styles.button, styles.marginBottom),
                    }}
                  >
                    {dictionary.literals.save}
                  </Button>
                  <Button
                    icon={crossIcon}
                    attributes={{
                      className: classes(styles.button, styles.marginBottom),
                      onClick: history.goBack,
                      disabled: isPractitionerFormDisabled,
                    }}
                    hideSpinnerOnSubmit
                    flavour={"secondary"}
                  >
                    {dictionary.literals.cancel}
                  </Button>
                </div>
                {data.isMaster === true &&
                  branding.features?.isMapEnabled === true && (
                    <Button
                      icon={checkIcon}
                      attributes={{
                        className: classes(styles.button, styles.marginBottom),
                        onClick: () => history.push(routes.editLocation.path),
                        disabled: isPractitionerFormDisabled,
                      }}
                      hideSpinnerOnSubmit
                    >
                      {coordinates?.length === 2
                        ? dictionary.literals.editLocation
                        : dictionary.literals.setLocation}
                    </Button>
                  )}
              </UserPractitionerForm>
            )}
            {data?.role === Role.USER && (
              <UserClientForm
                user={data}
                ref={clientFormRef}
                hideSubmitButton
                onSubmit={onSubmitForm}
              >
                {asset && (
                  <input
                    type="hidden"
                    name="profilePictureUrl"
                    value={asset.url}
                  />
                )}
                <div className={styles.buttonContainer}>
                  <Button
                    icon={checkIcon}
                    attributes={{
                      type: "submit",
                      className: classes(styles.button, styles.marginBottom),
                      disabled: isClientFormDisabled,
                    }}
                  >
                    {dictionary.literals.save}
                  </Button>
                  <Button
                    icon={crossIcon}
                    attributes={{
                      className: classes(styles.button, styles.marginBottom),
                      onClick: history.goBack,
                      disabled: isClientFormDisabled,
                    }}
                    flavour={"secondary"}
                    hideSpinnerOnSubmit
                  >
                    {dictionary.literals.cancel}
                  </Button>
                </div>
              </UserClientForm>
            )}
            <Button
              icon={crossIcon}
              attributes={{
                className: classes(styles.button, styles.marginBottom),
                onClick: deleteAccount,
                disabled: isClientFormDisabled || isPractitionerFormDisabled,
              }}
              flavour={"danger"}
              hideSpinnerOnSubmit
            >
              {dictionary.literals.deleteAccount}
            </Button>
          </div>
        )}
      </PageBody>
    </Page>
  );
};

const styles = {
  formContainer: style({
    margin: "var(--spacing-vertical-regular)",
  }),
  basicDetailsContainer: style({
    paddingLeft: "var(--spacing-horizontal-regular)",
  }),
  buttonContainer: style({
    display: "flex",
    flexWrap: "wrap",
    marginTop: 40,
    gap: 10,
  }),
  button: style({
    flex: 1,
  }),
  marginBottom: style({
    marginBottom: 30,
  }),
};
