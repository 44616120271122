import { IQuestion } from "@hulanbv/platformapp";
import { CrudService, IHttpOptions, IResponse } from "nest-utilities-client";
import { httpService } from "../common/http.service";

class Service extends CrudService<IQuestion> {
  constructor() {
    super([process.env.REACT_APP_API_URL, "questions"].join("/"), httpService);
  }

  getAnsweredQuestions(
    userId: string,
    httpOptions?: IHttpOptions<IQuestion>,
  ): Promise<IResponse<IQuestion[]>> {
    return this.http.get<IQuestion[]>(
      [this.controller, "users", userId, "answered"].join("/"),
      httpOptions,
    );
  }
}

export const questionService = new Service();
