import { FC } from "react";
import { classes, style } from "typestyle";

interface IProps {
  className?: string;
  hasNoBorder?: boolean;
}

export const Description: FC<IProps> = (props) => (
  <div className={classes(styles.container, props.className)}>
    {!props.hasNoBorder && <div className={styles.border}></div>}
    <div
      className={classes(
        styles.content,
        !props.hasNoBorder && styles.leftSpacing,
      )}
    >
      {props.children}
    </div>
  </div>
);

const styles = {
  container: style({
    display: "flex",
    flexDirection: "row",
  }),
  border: style({
    width: 6.66,
    backgroundColor: "rgb(var(--rgb-color-primair-lighter))",
    borderRadius: "var(--border-radius-large)",
    flexShrink: 0,
  }),
  content: style({
    paddingTop: 5,
    paddingBottom: 5,
    flex: 1,
    fontSize: "1.06rem",
  }),
  leftSpacing: style({
    paddingLeft: 20,
  }),
};
