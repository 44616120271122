import { FC, InputHTMLAttributes } from "react";
import { classes, style } from "typestyle";

export interface IProps {
  attributes?: InputHTMLAttributes<HTMLInputElement>;
  label: string;
}

export const RadioButton: FC<IProps> = (props) => (
  <div className={styles.checkboxContainer}>
    <input
      {...{
        ...props.attributes,
        type: "radio",
        className: classes(props.attributes?.className, styles.radiobutton),
      }}
    />
    <div className={styles.label}>{props.label}</div>
  </div>
);

const styles = {
  checkboxContainer: style({
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  }),
  label: style({
    marginLeft: 26,
    fontSize: "1.06rem",
    textTransform: "capitalize",
  }),
  radiobutton: style({
    flexShrink: 0,
    height: 24,
    width: 24,
    outline: "none",
    borderRadius: "var(--border-radius-round)",
    border: "2px solid rgb(var(--rgb-color-primair-basis))",
    backgroundColor: "rgb(var(--rgb-color-white))",
    appearance: "none",
    "-webkit-appearance": "none",
    $nest: {
      "&:checked": {
        border: "8px solid rgb(var(--rgb-color-primair-basis))",
      },
    },
  }),
};
