export const localStorageUtils = new (class LocalStorageUtils {
  /**
   *
   * @param key
   * @returns localstorage item as object/string
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- any is fine here
  getItem<T = any>(key: string): T | null {
    // catch block in case localstorage is not available or incorrect data is stored
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }

    return null;
  }

  /**
   * Stringifies the value and sets it in the localstorage
   *
   * @param key localstorage key
   * @param value the value that will be set
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- any is fine here
  setItem(key: string, value: any): void {
    const stringified = JSON.stringify(value);
    localStorage.setItem(key, stringified);
  }
})();
