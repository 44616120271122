import { FetchState, useAll } from "@hulanbv/nest-utilities-client-state";
import { ICollection, IExercise, Role } from "@hulanbv/platformapp";
import { FC, Fragment, useCallback } from "react";
import { generatePath, useHistory } from "react-router";
import { style } from "typestyle";
import { ReactComponent as sendIcon } from "../../assets/graphics/symbols/send.svg";
import { ReactComponent as pencilIcon } from "../../assets/graphics/symbols/pencil.svg";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { routes } from "../../state/common/constants/routes.constants";
import { exerciseCollectionService } from "../../state/exercise/exercise-collection.service";
import { ExpandableRow } from "../elements/expandable-row.element";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { VideoPlayer } from "../elements/video-player/video-player.element";
import Toast from "../statics/toast";
import { useModalContext } from "../../state/common/contexts/modal.context";
import { EditCollectionModal } from "../templates/modals/edit-collections-modal.template";
import { usePermissions } from "../../state/common/hooks/use-permissions.hook";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { IconButton } from "../elements/icon-button.element";
import { branding } from "../../constants/branding.constants";
import { ExerciseRowTemplate } from "../templates/table-rows/exercise-row.template";

const buttonSize = 50;

export const CollectionsScreen: FC = () => {
  const { openModal } = useModalContext();
  const history = useHistory();
  const { session } = useAuthContext();
  const {
    data: collections,
    call,
    fetchState,
  } = useAll(exerciseCollectionService, {
    match: { ownerId: session?.userId },
    populate: ["exercises"],
  });

  const { hasSendExerciseInvitePermission } = usePermissions();

  const onSendClick = (collectionId: string) => {
    history.push(
      generatePath(routes.sendcollectionInvites.path, {
        collectionId,
      }),
    );
  };

  const removeExerciseFromCollection = useCallback(
    async (exercise: IExercise, collectionId: string) => {
      try {
        await exerciseCollectionService.removeFromCollection(
          exercise.id,
          collectionId,
        );
        Toast.info({
          title: `${dictionary.literals.success}!`,
          body: dictionary.texts.removeExerciseFromCollectionSuccess,
        });
        call();
      } catch {
        Toast.error({
          body: dictionary.texts.removeExerciseFromCollectionError,
        });
      }
    },
    [call],
  );

  const onEditClick = async (collection: ICollection) => {
    await openModal(() => <EditCollectionModal collection={collection} />);

    call();
  };

  return (
    <Page hideGoBack>
      <PageBody fullWidth>
        <Header className={styles.centerHeader}>
          {dictionary.literals.collections}
        </Header>
        {collections?.length === 0 && fetchState === FetchState.Fulfilled && (
          <>
            <div className={styles.textContainer}>
              <p className={styles.text}>
                {dictionary.texts.noCollectionsCreated}
              </p>
            </div>
            <div className={styles.videoPlayer}>
              {session?.user?.role === Role.USER &&
                branding.videoUrls?.addCollectionClientInstruction && (
                  <VideoPlayer
                    url={branding.videoUrls.addCollectionClientInstruction}
                  />
                )}
              {session?.user?.role !== Role.USER &&
                branding.videoUrls?.addCollectionPractitionerInstruction && (
                  <VideoPlayer
                    url={
                      branding.videoUrls.addCollectionPractitionerInstruction
                    }
                  />
                )}
            </div>
          </>
        )}
        {collections?.map((collection) => (
          <ExpandableRow
            header={collection.name}
            key={collection._id}
            id={collection.id}
            controls={
              <>
                {session?.user?.role !== Role.USER && (
                  <IconButton
                    icon={pencilIcon}
                    attributes={{
                      className: styles.controlButton,
                      onClick: (event) => {
                        event.stopPropagation();
                        onEditClick(collection);
                      },
                    }}
                  />
                )}
                {hasSendExerciseInvitePermission && (
                  <IconButton
                    icon={sendIcon}
                    attributes={{
                      className: styles.button,
                      onClick: (event) => {
                        event.stopPropagation();
                        onSendClick(collection._id);
                      },
                    }}
                  />
                )}
              </>
            }
            expandableItem={
              <>
                {collection.exercises?.map((exercise) => (
                  <ExerciseRowTemplate
                    hasSeparationBorder={
                      (collection.exercises?.length ?? 0) > 1
                    }
                    key={exercise._id}
                    exercise={exercise}
                    onRemoveClick={() =>
                      removeExerciseFromCollection(exercise, collection._id)
                    }
                    onSendClick={() =>
                      history.push(
                        generatePath(routes.sendExerciseInvites.path, {
                          exerciseId: exercise._id,
                        }),
                      )
                    }
                    onViewClick={() =>
                      history.push(
                        generatePath(routes.exercise.path, {
                          exerciseId: exercise._id,
                        }),
                      )
                    }
                  />
                ))}
              </>
            }
          />
        ))}
      </PageBody>
    </Page>
  );
};

const styles = {
  centerHeader: style({
    display: "flex",
    justifyContent: "center",
    paddingBottom: 20,
  }),
  centerButtonContainer: style({
    display: "flex",
    justifyContent: "space-between",
    marginTop: "var(--spacing-vertical-regular)",
  }),
  videoPlayer: style({
    margin: "var(--spacing-vertical-regular) var(--spacing-horizontal-regular)",
  }),
  textContainer: style({
    textAlign: "center",
    padding: "0px 30px 20px",
  }),
  text: style({
    margin: 0,
  }),
  button: style({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: buttonSize,
    height: buttonSize,
  }),
  controlButton: style({
    padding: "0px 12.5px",
    width: buttonSize,
    height: buttonSize,
  }),
};
