import { FC } from "react";
import { INote } from "@hulanbv/platformapp";
import { style } from "typestyle";
import { ReactComponent as pencilIcon } from "../../../assets/graphics/symbols/pencil.svg";
import { stringUtils } from "../../../utils/string.utils";
import { IconButton } from "../../elements/icon-button.element";

interface IProps {
  note: INote;
  onClick?: () => void;
}

export const NoteRowTemplate: FC<IProps> = (props) => (
  <div className={styles.rowContainer}>
    <div className={styles.rowLeftText}>
      {stringUtils.truncate(props.note.text, 75)}
    </div>
    <div className={styles.rowRightAction}>
      <IconButton
        flavour={"primary"}
        icon={pencilIcon}
        attributes={{ className: styles.button, onClick: props.onClick }}
      />
    </div>
  </div>
);

const styles = {
  rowContainer: style({
    display: "flex",
    padding: "25px 34px 25px 65px",
    $nest: {
      "&:nth-child(odd)": {
        backgroundColor: "rgb(var(--rgb-color-gray-lighter))",
      },
    },
  }),
  rowHeader: style({
    fontSize: "1.5rem",
    fontWeight: 600,
    color: "rgb(var(--rgb-color-primair-basis))",
    paddingBottom: 18,
  }),
  rowSubTextLine: style({
    fontSize: "1.125rem",
    fontWeight: 500,
    paddingTop: 10,
  }),
  rowLeftText: style({
    width: "80%",
    marginRight: 20,
  }),
  rowRightAction: style({
    display: "flex",
    alignItems: "center",
    width: "20%",
    justifyContent: "flex-end",
  }),
  button: style({
    padding: "15px 15px",
    width: "unset",
    height: "unset",
  }),
};
