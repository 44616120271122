import { IUser, Role } from "@hulanbv/platformapp";
import { IHttpOptions } from "nest-utilities-client";
import { FC, useMemo } from "react";
import { generatePath, useHistory } from "react-router";
import { useAuthContext } from "../../../state/authentication/authentication.context";
import { routes } from "../../../state/common/constants/routes.constants";
import { useUrlState } from "../../../state/common/hooks/url-state-hook/use-url-state.hook";
import { userService } from "../../../state/user/user.service";
import { Flex } from "../../elements/flex.element";
import { Table } from "../../elements/table.element";
import { Tab } from "../../screens/client-profile.screen";
import { ClientRowTemplate } from "../table-rows/client-row.template";
import { urlUtils } from "../../../utils/url.utils";
import { TableHeadTemplate } from "../table-head.template";
import { dictionary } from "../../../state/common/constants/dictionary.constants";

export const ClientsTableTemplate: FC = () => {
  const { session } = useAuthContext();
  const history = useHistory();
  const [clientsSearchParamValue, setClientsSearchParamValue] =
    useUrlState<string>("client-filter");

  const memoizedHttpOptions: IHttpOptions<IUser> = useMemo(
    () => ({
      match: {
        practitionerId: session?.userId,
        role: Role.USER,
        ...urlUtils.createSearchQuery(clientsSearchParamValue, [
          "fullName",
          "street",
          "email",
        ]),
      },
      sort: ["-createdAt"],
      addFields: {
        fullName: {
          $concat: ["$name", " ", "$lastName"],
        },
      },
    }),
    [clientsSearchParamValue, session?.userId],
  );

  return (
    <>
      <TableHeadTemplate
        searchInputAttributes={{
          defaultValue: clientsSearchParamValue ?? "",
          onChange: (event) => setClientsSearchParamValue(event.target.value),
        }}
        onAddButtonClick={() => history.push(routes.pairClient.path)}
      />
      <Table<IUser>
        rowTemplate={(user) => (
          <ClientRowTemplate
            user={user}
            key={user._id}
            onViewClick={() => {
              const tab: Tab = "exercise-invites";
              const clientPath = generatePath(routes.clientProfile.path, {
                userId: user._id,
                tab,
              });
              history.push(clientPath);
            }}
          />
        )}
        service={userService}
        options={memoizedHttpOptions}
        emptyContent={
          <Flex>
            <p>{dictionary.literals.clientsNotFound}</p>
          </Flex>
        }
      ></Table>
    </>
  );
};
