import { useById } from "@hulanbv/nest-utilities-client-state";
import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { style } from "typestyle";
import { userService } from "../../state/user/user.service";
import { ReactComponent as notesIcon } from "../../assets/graphics/symbols/notes.svg";
import { ReactComponent as sendIcon } from "../../assets/graphics/symbols/send.svg";
import { ReactComponent as eyeIcon } from "../../assets/graphics/symbols/eye.svg";
import { ReactComponent as questionnaireIcon } from "../../assets/graphics/symbols/questionnaire.svg";
import { ReactComponent as scheduledIcon } from "../../assets/svgs/scheduled-exercise-invite.svg";
import { ReactComponent as mindgrapherIcon } from "../../assets/graphics/symbols/mindgrapher-icon.svg";
import { routes } from "../../state/common/constants/routes.constants";
import { ActionBar } from "../elements/action-bar.element";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { ClientExerciseInvitesTable } from "../templates/tables/clientprofile/client-exercise-invites-table.template";
import { ClientNotesTable } from "../templates/tables/clientprofile/client-notes-table.template";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { ClientQuestionnairesTable } from "../templates/tables/clientprofile/client-questionnaires-table.template";

import { ClientScheduledExerciseInvitesTable } from "../templates/tables/clientprofile/client-scheduled-exercise-invites-table.template";
import { branding } from "../../constants/branding.constants";

export type Tab =
  | "notes"
  | "exercise-invites"
  | "questionnaires"
  | "scheduled-invites"
  | ":tab";

export const ClientProfileScreen: FC = () => {
  const params = useParams<{ userId: string; tab: Tab }>();
  const { data: client } = useById(
    userService,
    params.userId,
    {},
    { distinct: true },
  );
  const history = useHistory();

  const navigateToTab = (tab: Tab) => {
    const pathClient = routes.clientProfile.path.replace(
      ":userId",
      params.userId,
    );
    const pathTab = pathClient.replace(":tab", tab);
    history.replace(pathTab);
  };

  return (
    <Page>
      <PageBody fullWidth>
        <Header className={styles.centerHeader}>
          {client?.name} {client?.lastName}
        </Header>
        <div className={styles.wideContainer}>
          <div className={styles.buttonRow}>
            <ActionBar
              actions={[
                {
                  isActive: !params.tab || params.tab === "exercise-invites",
                  icon: sendIcon,
                  onClick: () => navigateToTab("exercise-invites"),
                },
                {
                  isActive: params.tab === "scheduled-invites",
                  icon: scheduledIcon,
                  onClick: () => navigateToTab("scheduled-invites"),
                },
                {
                  isActive: params.tab === "notes",
                  icon: notesIcon,
                  onClick: () => navigateToTab("notes"),
                },
                {
                  isActive: params.tab === "questionnaires",
                  icon: questionnaireIcon,
                  onClick: () => navigateToTab("questionnaires"),
                },
                ...(branding.features?.hasWeekResultReports === true
                  ? [
                      {
                        icon: mindgrapherIcon,
                        onClick: () =>
                          history.push(
                            routes.weekResultsReportCreate.path.replace(
                              ":userId",
                              client?._id,
                            ),
                          ),
                      },
                    ]
                  : []),
                {
                  icon: eyeIcon,
                  onClick: () =>
                    history.push(
                      routes.profile.path.replace(":userId", client?._id),
                    ),
                },
              ]}
            />
          </div>
          {client?.lastActiveAt && (
            <p className={styles.centerText}>
              {dictionary.texts.lastOpenedOnX(new Date(client.lastActiveAt))}
            </p>
          )}
        </div>
        {(!params.tab || params.tab === "exercise-invites") && client && (
          <ClientExerciseInvitesTable client={client} />
        )}
        {params.tab === "scheduled-invites" && client && (
          <ClientScheduledExerciseInvitesTable client={client} />
        )}
        {params.tab === "notes" && client && (
          <ClientNotesTable client={client} />
        )}
        {params.tab === "questionnaires" && client && (
          <ClientQuestionnairesTable clientId={client._id} />
        )}
      </PageBody>
    </Page>
  );
};

const styles = {
  wideContainer: style({
    margin: "0px var(--spacing-horizontal-small) var(--spacing-vertical-small)",
  }),
  exercisesTitle: style({
    marginTop: 30,
    marginLeft: 20,
  }),
  centerText: style({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1rem",
    fontWeight: 400,
  }),
  buttonRow: style({
    margin: "auto",
    width: "70%",
  }),
  centerHeader: style({
    display: "flex",
    justifyContent: "center",
    paddingBottom: 20,
  }),
};
