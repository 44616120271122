import {
  FC,
  useCallback,
  useState,
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
  useRef,
  useMemo,
} from "react";
import { stylesheet } from "typestyle";
import { Header } from "./header.element";
import { GeometricShape } from "./geometric-shape";

interface IProps {
  options: string[];
  isNumericalQuestion?: boolean;
  name?: string;
  defaultValue?: string;
  labels?: (string | undefined)[];
  onChange?: (value: string | null) => void;
}

/**
 * Contains the provided percentages between from 0 up to 100
 * @param percentage
 * @returns
 */
const containPercentage = (percentage: number) =>
  Math.max(0, Math.min(100, percentage));

/**
 * Returns the x position of the input event
 * @param event
 * @returns
 */
const getInputXPosition = (
  event: ReactMouseEvent | ReactTouchEvent | MouseEvent | TouchEvent,
) => {
  if ("pageX" in event) {
    return event.pageX;
  }
  return event.touches[0]?.pageX;
};

// eslint-disable-next-line complexity -- We need to have this many conditions
export const RangeSlider: FC<IProps> = (props) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [hasTransition, setHasTransition] = useState(false);
  const [value, setValue] = useState<string | null>(props.defaultValue ?? null);

  const [thumbXPercentage, setThumbXPercentage] = useState(
    Math.max(
      0,
      (100 / (props.options.length - 1)) *
        props.options.indexOf(props.defaultValue ?? props.options[0]),
    ),
  );

  const getRangeValues = useMemo(() => {
    if (!props.isNumericalQuestion) {
      return [];
    }

    const { options } = props;
    const rangeValues = [];

    const numberOfBullets = [4, 3, 2].find((bullets) =>
      Number.isInteger((options.length - 1) / bullets),
    );

    if (numberOfBullets) {
      const step = Math.round((options.length - 1) / numberOfBullets);

      rangeValues.push(...options.filter((value, index) => index % step === 0));

      return rangeValues;
    }

    return [];
  }, [props]);

  const getOptionIndexByPosition = useCallback(
    (xPercentage: number): number =>
      Math.round(xPercentage / (100 / (props.options.length - 1))),
    [props.options.length],
  );

  const startDragging = useCallback(
    (downEvent: ReactMouseEvent | ReactTouchEvent) => {
      if (!sliderRef.current) {
        return;
      }

      const clickedPosition =
        getInputXPosition(downEvent) -
        sliderRef.current.getBoundingClientRect().left;
      const clickedPercentage = containPercentage(
        (clickedPosition / sliderRef.current.clientWidth) * 100,
      );

      setThumbXPercentage(clickedPercentage);
      setValue(props.options[getOptionIndexByPosition(clickedPercentage)]);
      props.onChange?.(value);

      setHasTransition(false);

      let currentPercentage = clickedPercentage;
      const drag = (moveEvent: MouseEvent | TouchEvent) => {
        if (!sliderRef.current) {
          return;
        }

        const movePosition =
          getInputXPosition(moveEvent) -
          sliderRef.current.getBoundingClientRect().left;
        currentPercentage = containPercentage(
          (movePosition / sliderRef.current.clientWidth) * 100,
        );

        setValue(props.options[getOptionIndexByPosition(currentPercentage)]);
        setThumbXPercentage(currentPercentage);
      };

      const stopDragging = () => {
        const optionPosition =
          (100 / (props.options.length - 1)) *
          getOptionIndexByPosition(currentPercentage);

        setHasTransition(true);
        setThumbXPercentage(optionPosition);
        props.onChange?.(value);

        document.removeEventListener("mousemove", drag);
        document.removeEventListener("touchmove", drag);
        document.removeEventListener("mouseup", stopDragging);
        document.removeEventListener("touchend", stopDragging);
      };

      document.addEventListener("mousemove", drag);
      document.addEventListener("touchmove", drag);
      document.addEventListener("mouseup", stopDragging);
      document.addEventListener("touchend", stopDragging);
    },
    [getOptionIndexByPosition, props, value],
  );

  return (
    <div className={styles.container}>
      {props.name && (
        <input type="hidden" value={value ?? ""} name={props.name} />
      )}

      <Header size="small" className={styles.header} center>
        {value}
      </Header>
      <div
        className={styles.slider}
        onTouchStart={startDragging}
        onMouseDown={startDragging}
        ref={sliderRef}
      >
        <div className={styles.options}>
          {props.isNumericalQuestion && (
            <>
              <GeometricShape
                className={styles.outerOptions}
                key={props.options[0]}
                size={20}
                color={"rgba(var(--rgb-color-primair-lighter)"}
              />
              {getRangeValues.slice(1, -1).map((value) => (
                <GeometricShape
                  className={styles.innerOptions}
                  key={value}
                  size={15}
                  color={"rgba(var(--rgb-color-primair-lighter), 0.5)"}
                />
              ))}
              <GeometricShape
                className={styles.outerOptions}
                key={props.options[props.options.length - 1]}
                size={20}
                color={"rgba(var(--rgb-color-primair-lighter)"}
              />
            </>
          )}
          {!props.isNumericalQuestion &&
            props.options.map((value) => (
              <GeometricShape
                className={styles.outerOptions}
                key={value}
                size={20}
                color={"rgba(var(--rgb-color-primair-lighter)"}
              />
            ))}
        </div>
        {value && (
          <div
            className={styles.thumb}
            onMouseDown={startDragging}
            onTouchStart={startDragging}
            style={{
              left: `${thumbXPercentage}%`,
              transition: hasTransition ? ".1s left" : undefined,
            }}
          />
        )}
      </div>

      <div className={styles.labelWrapper}>
        {/* Whenever there are labels, we will use them, and as a fallback we
        will use the options. */}
        {props.labels?.[0] && (
          <>
            <div>{props.labels[0]}</div>
            <div>{props.labels[props.labels.length - 1]}</div>
          </>
        )}
        {/* Whenever there are no labels, we will use the range values. */}
        {!props.labels?.[0] && (
          <>
            {getRangeValues.map((value) => (
              <div key={value} className={styles.rangeSteps}>
                {value}
              </div>
            ))}
          </>
        )}
        {/* When the range question is not numerical, we will use the provided
           options. */}
        {!props.isNumericalQuestion &&
          props.options.length <= 3 &&
          !props.labels?.[0] && (
            <>
              {props.options.map((value) => (
                <div key={value} className={styles.rangeSteps}>
                  {value}
                </div>
              ))}
            </>
          )}
        {/* When the range question is not numerical and there are more than 3
           options, we will use the first and last option. */}
        {!props.isNumericalQuestion && props.options.length > 3 && (
          <>
            <div>{props.options[0]}</div>
            <div>{props.options[props.options.length - 1]}</div>
          </>
        )}
      </div>
    </div>
  );
};

const styles = stylesheet({
  container: {},
  slider: {
    position: "relative",
  },
  header: {
    $nest: {
      "&::after": {
        content: "' '",
        whiteSpace: "pre",
      },
    },
  },
  options: {
    display: "flex",
    justifyContent: "space-between",
    gap: 0,
    touchAction: "none",
    backgroundColor: "rgba(var(--rgb-color-primair-lighter), 0.15)",
    boxShadow: "0 0 22px 0 rgba(var(--rgb-color-black), 0.15)",
    height: 8,
    margin: "40px 0px 40px -5px",
  },
  thumb: {
    position: "absolute",
    backgroundColor: "rgb(var(--rgb-color-white))",
    height: 35,
    width: 35,
    borderRadius: "100%",
    boxShadow: "0px 0px 10px rgba(var(--rgb-color-black), 0.2)",
    transform: "translateX(-20px)",
    top: -15,
    touchAction: "none",
  },
  outerOptions: {
    position: "relative",
    top: -6,
    $nest: {
      "&:first-child": {
        marginRight: -10,
      },
      "&:last-child": {
        marginLeft: -10,
      },
    },
  },
  innerOptions: {
    position: "relative",
    top: -4,
  },
  labelWrapper: {
    display: "flex",
    justifyContent: "space-between",
    gap: 0,
  },
  rangeSteps: {
    flex: 2,
    textAlign: "center",
    $nest: {
      "&:first-child": {
        textAlign: "left",
        flex: 1,
      },
      "&:last-child": {
        textAlign: "right",
        flex: 1,
      },
    },
  },
});
