import { FC } from "react";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";

export const ChatScreen: FC = () => (
  <Page hideGoBack>
    <PageBody>
      <div>{dictionary.literals.chat}</div>
    </PageBody>
  </Page>
);
