import { forwardRef, FormHTMLAttributes, PropsWithChildren } from "react";

import { IUser } from "@hulanbv/platformapp";
import { classes, style } from "typestyle";
import { ReactComponent as eyeIcon } from "../../../../assets/graphics/symbols/eye.svg";
import { Button } from "../../../elements/button.element";
import { Form } from "../../../elements/form.element";
import { Row } from "../../../elements/row.element";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";

interface IProps {
  user: IUser;
  attributes?: Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit">;
  hideSubmitButton?: boolean;
  onSubmit?: (formData: FormData) => void | Promise<void>;
}

export const PractitionerLocationFormTemplate = forwardRef<
  HTMLFormElement,
  PropsWithChildren<IProps>
>((props, ref) => (
  <Form attributes={props.attributes} onSubmit={props.onSubmit} ref={ref}>
    {props.user?._id && (
      <input type={"hidden"} name={"id"} value={props.user._id} required />
    )}
    {props.user.companyGeolocation && (
      <>
        <input
          type={"hidden"}
          name={"companyGeolocation[coordinates][0]"}
          value={props.user.companyGeolocation.coordinates[0]}
          required
        />
        <input
          type={"hidden"}
          name={"companyGeolocation[coordinates][1]"}
          value={props.user.companyGeolocation.coordinates[1]}
          required
        />
        <input
          type={"hidden"}
          name={"companyGeolocation[type]"}
          value={"Point"}
          required
        />
      </>
    )}

    {!props.hideSubmitButton && (
      <Row>
        <Button
          attributes={{
            type: "submit",
            className: classes(props.hideSubmitButton && styles.hideButton),
          }}
          icon={eyeIcon}
        >
          {dictionary.literals.submit}
        </Button>
      </Row>
    )}

    {props.children}
  </Form>
));

const styles = {
  hideButton: style({
    visibility: "hidden",
  }),
};
