/**
 * Maps array items to a record by the provided key
 * @param key
 * @param objects
 * @returns
 */
export const toRecord = <ObjectType = unknown>(
  key: string,
  objects: ObjectType[],
): Record<string, ObjectType> => {
  const record: Record<string, ObjectType> = {};
  for (const object of objects) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- any is fine here
    record[(object as any)[key]] = object;
  }

  return record;
};
