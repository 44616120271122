import { useById } from "@hulanbv/nest-utilities-client-state";
import { FC } from "react";
import { useHistory, useParams } from "react-router-dom";
import { classes, style } from "typestyle";
import { ReactComponent as checkIcon } from "../../assets/graphics/symbols/checkmark.svg";
import { ReactComponent as trashIcon } from "../../assets/graphics/symbols/trash.svg";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { noteService } from "../../state/notes/note.service";
import { Button } from "../elements/button.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import Toast from "../statics/toast";
import { NoteForm } from "../templates/forms/notes/note-form.template";

export const NoteEditScreen: FC = () => {
  const params = useParams<{ noteId: string }>();
  const { data: note } = useById(
    noteService,
    params.noteId,
    { populate: ["recipient"] },
    { distinct: true },
  );

  const history = useHistory();

  const { session } = useAuthContext();

  const onRemoveNote = async (id: string) => {
    try {
      await noteService.delete(id);
      history.goBack();
      Toast.info({
        body: dictionary.texts.noteRemovedSuccess,
      });
    } catch {
      Toast.error({
        body: dictionary.texts.noteRemovedError,
      });
    }
  };

  const onSubmitForm = async (formData: FormData) => {
    try {
      await noteService.patch(formData);
      history.goBack();
      Toast.info({
        body: dictionary.texts.noteUpdatedSuccess,
      });
    } catch {
      Toast.error({
        body: dictionary.texts.noteUpdatedError,
      });
    }
  };

  return (
    <Page>
      <PageBody>
        {note?.recipient && session?.user && (
          <NoteForm
            user={session.user}
            client={note?.recipient}
            hideSubmitButton
            onSubmit={onSubmitForm}
            note={note}
          >
            <div className={styles.buttonContainer}>
              <Button
                attributes={{
                  type: "submit",
                  className: classes(styles.button, styles.marginBottom),
                }}
                icon={checkIcon}
              >
                {dictionary.literals.save}
              </Button>
              <Button
                attributes={{
                  className: classes(styles.button, styles.marginBottom),
                  onClick: () => onRemoveNote(note.id),
                }}
                hideSpinnerOnSubmit
                flavour={"danger"}
                icon={trashIcon}
              >
                {dictionary.literals.delete}
              </Button>
            </div>
          </NoteForm>
        )}
      </PageBody>
    </Page>
  );
};

const styles = {
  buttonContainer: style({
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
  }),
  button: style({
    width: "100%",
  }),
  marginBottom: style({
    marginBottom: 30,
  }),
};
