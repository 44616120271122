import { FC, HTMLAttributes } from "react";
import { classes, style } from "typestyle";
import { ReactComponent as crossIcon } from "../../../assets/graphics/symbols/cross.svg";
import { VideoPlayer } from "../../elements/video-player/video-player.element";
import { SvgIcon } from "../../elements/svg-icon.element";
import { FlexItem } from "../../elements/flex-item.element";
import { useModalContext } from "../../../state/common/contexts/modal.context";

export interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  url: string;
}

export const VideoPlayerModal: FC<IProps> = (props) => {
  const { closeModal } = useModalContext();

  return (
    <div
      {...{
        ...props.attributes,
        className: classes(styles.container, props.attributes?.className),
      }}
    >
      <FlexItem alignSelf="flex-end">
        <SvgIcon
          icon={crossIcon}
          attributes={{
            onClick: closeModal,
            className: styles.icon,
          }}
          color="rgb(var(--rgb-color-black))"
          width={20}
          height={20}
        />
      </FlexItem>
      <VideoPlayer url={props.url} />
    </div>
  );
};

const styles = {
  container: style({
    padding: "20px 20px 50px 20px",
    display: "flex",
    gap: "20px",
    flexDirection: "column",
  }),
  icon: style({
    cursor: "pointer",
  }),
};
