import { FC, FormHTMLAttributes } from "react";
import { Button } from "../../../elements/button.element";
import { Form } from "../../../elements/form.element";
import { Input } from "../../../elements/input.element";
import { Row } from "../../../elements/row.element";
import { ReactComponent as sendIcon } from "../../../../assets/graphics/symbols/send.svg";
import { routes } from "../../../../state/common/constants/routes.constants";
import { Anchor } from "../../../elements/anchor.element";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";

interface IProps {
  attributes?: Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit">;
  onSubmit?: (formData: FormData) => void | Promise<void>;
  onClickBackToLogin?: () => void;
}

export const ForgotPasswordForm: FC<IProps> = (props) => (
  <Form {...props.attributes} onSubmit={props.onSubmit}>
    <Row>
      <Input
        attributes={{
          name: "email",
          type: "email",
          placeholder: dictionary.literals.email,
          required: true,
        }}
      />
    </Row>
    <Row>
      <Button attributes={{ type: "submit" }} centerChildren icon={sendIcon}>
        {dictionary.literals.send}
      </Button>
    </Row>
    <Row>
      <Anchor href={routes.login.path}>
        <Button centerChildren icon={sendIcon}>
          {dictionary.literals.backToLogin}
        </Button>
      </Anchor>
    </Row>
  </Form>
);
