/**
 * Directly throws an error informing the user that the environment is not set
 * up correctly.
 * @param key The key of the environment variable
 */
function throwEnvironmentException(key: string): never {
  throw new Error(`Environment variable ${key} is not defined`);
}

/**
 * Environment variables defined in the dot env file.
 */
const environment = {
  environment:
    process.env.REACT_APP_ENVIRONMENT ||
    throwEnvironmentException("REACT_APP_ENVIRONMENT"),
  apiUrl:
    process.env.REACT_APP_API_URL ||
    throwEnvironmentException("REACT_APP_API_URL"),
  mapboxToken:
    process.env.REACT_APP_MAPBOX_TOKEN ||
    throwEnvironmentException("REACT_APP_MAPBOX_TOKEN"),
  locale:
    process.env.REACT_APP_LOCALE ||
    throwEnvironmentException("REACT_APP_LOCALE"),
  branding:
    process.env.REACT_APP_BRANDING ||
    throwEnvironmentException("REACT_APP_BRANDING"),
};

export { environment };
