import { FC, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { stylesheet } from "typestyle";
import { userService } from "../../state/user/user.service";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import Toast from "../statics/toast";
import { PasswordForm } from "../templates/forms/user/password-form.template";
import { Header } from "../elements/header.element";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { dictionary } from "../../state/common/constants/dictionary.constants";

export const ProfileResetPasswordScreen: FC = () => {
  const history = useHistory();
  const { session } = useAuthContext();
  const handleFormSubmit = useCallback(
    async (formData: FormData) => {
      try {
        await userService.patch(formData);

        history.goBack();
        Toast.info({
          body: dictionary.texts.passwordResetSuccess,
        });
      } catch {
        Toast.error({
          body: dictionary.texts.passwordResetError,
        });
      }
    },
    [history],
  );

  return (
    <Page>
      <PageBody>
        {session?.userId && (
          <>
            <Header center className={styles.header}>
              {dictionary.texts.setOwnPassword}
            </Header>
            <PasswordForm userId={session.userId} onSubmit={handleFormSubmit} />
          </>
        )}
      </PageBody>
    </Page>
  );
};

const styles = stylesheet({
  header: {
    marginBottom: "var(--spacing-vertical-regular)",
  },
});
