import { FC, HTMLAttributes } from "react";
import { classes, style } from "typestyle";
import { useHistory } from "react-router-dom";
import { routes } from "../../state/common/constants/routes.constants";
import { GeometricShape } from "../elements/geometric-shape";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { SvgIcon } from "../elements/svg-icon.element";
import { ReactComponent as mindgrapherGraphic } from "../../assets/graphics/symbols/mindgrapher-icon.svg";
import { graphicSet } from "../../constants/graphic-set.constants";
import { branding } from "../../constants/branding.constants";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const HomeProfessionalTemplate: FC<IProps> = (props) => {
  const history = useHistory();

  return (
    <div
      {...props.attributes}
      className={classes(styles.container, props.attributes?.className)}
    >
      <div
        className={classes(
          styles.navButtonsContainer,
          branding.features?.hasMindgrapherContent && styles.paddingForChildren,
        )}
      >
        <div
          className={styles.geometricShapeContainer}
          onClick={() => history.push(routes.trainings.path)}
        >
          <GeometricShape
            color={"rgb(var(--rgb-color-primair-lighter))"}
            className={classes(
              styles.geometricShape,
              !branding.features?.hasMindgrapherContent &&
                styles.geometricShapeSide,
            )}
          >
            <SvgIcon
              icon={graphicSet.trainingsGraphic}
              width={40}
              height={40}
            />
          </GeometricShape>
          <div className={styles.geometricShapeLabel}>
            {dictionary.literals.trainings}
          </div>
        </div>
        <div
          className={styles.geometricShapeContainer}
          onClick={() => history.push(routes.playground.path)}
        >
          <GeometricShape
            color={"rgb(var(--rgb-color-primair-basis))"}
            className={classes(
              styles.geometricShape,
              !branding.features?.hasMindgrapherContent &&
                styles.middleGeometricShape,
            )}
          >
            <SvgIcon
              icon={graphicSet.playgroundGraphic}
              width={branding.features?.hasMindgrapherContent ? 40 : 50}
              height={branding.features?.hasMindgrapherContent ? 40 : 50}
            />
          </GeometricShape>
          <div className={styles.geometricShapeLabel}>
            {dictionary.literals.playground}
          </div>
        </div>
        {branding.features?.hasMindgrapherContent && (
          <div
            className={styles.geometricShapeContainer}
            onClick={() => history.push(routes.mindgrapherContent.path)}
          >
            <GeometricShape
              color={"rgb(var(--rgb-color-primair-shade-1))"}
              className={classes(styles.geometricShape)}
            >
              <SvgIcon icon={mindgrapherGraphic} width={40} height={40} />
            </GeometricShape>
            <div className={styles.geometricShapeLabel}>
              {dictionary.literals.mindgrapher}
            </div>
          </div>
        )}
        <div
          className={styles.geometricShapeContainer}
          onClick={() => history.push(routes.elearning.path)}
        >
          <GeometricShape
            color={"rgb(var(--rgb-color-primair-shade-4))"}
            className={classes(
              styles.geometricShape,
              !branding.features?.hasMindgrapherContent &&
                styles.geometricShapeSide,
            )}
          >
            <SvgIcon
              icon={graphicSet.eLearningGraphic}
              width={40}
              height={40}
            />
          </GeometricShape>
          <div className={styles.geometricShapeLabel}>
            {dictionary.literals.eLearning}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: style({
    display: "flex",
    flexDirection: "column",
    gap: "var(--spacing-vertical-regular)",
  }),
  navButtonsContainer: style({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  }),
  paddingForChildren: style({
    $nest: {
      "& > div": {
        padding: "0 var(--spacing-vertical-regular)",
      },
    },
  }),
  // end top level div containers
  geometricShapeContainer: style({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "50%",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: 10,
  }),
  geometricShape: style({
    flex: 5,
  }),
  geometricShapeLabel: style({
    flex: 1,
    textAlign: "center",
    fontSize: 17,
    paddingTop: 10,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
  }),
  geometricShapeSide: style({
    width: 95,
    height: 106,
  }),
  middleGeometricShape: style({
    width: 107.3,
    height: 120,
  }),
};
