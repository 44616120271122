import {
  Fragment,
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { classes, style } from "typestyle";
import { ReactComponent as plusIcon } from "../../assets/graphics/symbols/add.svg";
import { ReactComponent as problemIcon } from "../../assets/graphics/symbols/problem.svg";
import { ReactComponent as checkmarkIcon } from "../../assets/graphics/symbols/checkmark.svg";
import { useFormIsDisabled } from "../../state/common/hooks/use-form-is-disabled";
import { LoadingSpinner } from "./loading-spinner.element";
import { styles as inputStyles } from "./input.element";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { SvgIcon } from "./svg-icon.element";

export interface IInputMultiSelectProps<ValueType> {
  attributes?: Omit<HTMLAttributes<HTMLDivElement>, "placeholder">;
  emptyPlaceholder?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  isLimited?: boolean;
  onChange?: (selectedValues: ValueType[]) => void;
  onSearchChange?: (search: string) => void | Promise<void>;
  options: Record<string, ValueType>;
  formatOption?: (value: ValueType) => string;
  showLoadingIndicator?: boolean;
}

export function InputMultiSelect<ValueType = string>(
  props: IInputMultiSelectProps<ValueType>,
): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const isFormDisabled = useFormIsDisabled(containerRef);

  const dropdownId = "dropdown";
  const searchInputId = "search-input";

  const [shouldShowDropdown, setShouldShowDropdown] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<
    Record<string, ValueType>
  >({});
  const [filteredOptions, setFilteredOptions] = useState<
    Record<string, ValueType>
  >(props.options);

  useEffect(() => {
    setFilteredOptions(props.options);
  }, [props.options]);

  const onSelectOption = (key: string, value: ValueType) => {
    const selectedRecords = { ...selectedOptions };
    const record = selectedRecords[key];
    if (record) {
      // if the record is found by key, delete it
      delete selectedRecords[key];
    } else {
      // otherwise set the value
      selectedRecords[key] = value;
    }

    setSelectedOptions(selectedRecords);
    props.onChange?.(Object.values(selectedRecords));
  };

  const onSearch = (value: string) => {
    const query = value.toLowerCase()?.trim() ?? "";

    /** if props.onSearchChange is defined, searching and updating the props.options
     * is handled through this function
     */
    if (props.onSearchChange) {
      props.onSearchChange(query);
    } else {
      const options: Record<string, ValueType> = {};
      for (const [key, value] of Object.entries(props.options)) {
        if (JSON.stringify(value).toLowerCase().includes(query)) {
          options[key] = value;
        }
      }
      setFilteredOptions(options);
    }
  };

  const getPlaceholder = useCallback(() => {
    const optionLength = Object.entries(selectedOptions).length;

    if (optionLength > 0) {
      return dictionary.texts.xItems(optionLength);
    }
    return props.emptyPlaceholder ?? dictionary.literals.selectItems;
  }, [selectedOptions, props.emptyPlaceholder]);

  return (
    <>
      <div
        {...{
          ...props.attributes,
          className: classes(
            shouldShowDropdown && styles.inputContainerFocus,
            styles.inputContainer,
            props.attributes?.className,
          ),
        }}
        onFocus={() => setShouldShowDropdown(true)}
        onClick={() => searchRef.current?.focus()}
        onBlur={(event) => {
          if (event.relatedTarget instanceof Element) {
            // if the focus event is on the dropdown or the searchinput: do not set isFocused to false
            // in al other events, set isFocused to false
            const targetIds = [dropdownId, searchInputId];
            if (targetIds.includes(event.relatedTarget.id)) {
              return;
            }
          }
          setShouldShowDropdown(false);
        }}
        ref={containerRef}
      >
        {!shouldShowDropdown && (
          <div className={styles.placeholder}>{getPlaceholder()}</div>
        )}

        <div
          contentEditable={true}
          className={classes(
            styles.searchInput,
            !shouldShowDropdown && styles.hideSearchInput,
          )}
          ref={searchRef}
          id={searchInputId}
          onInput={(event) => {
            onSearch(event.currentTarget.innerText);
          }}
        />

        {props.required && !Object.entries(selectedOptions).length && (
          <input
            className={styles.requiredInput}
            tabIndex={-1}
            onFocus={() => searchRef.current?.focus()}
            type="text"
            required
          />
        )}

        <div className={styles.plusIcon}>
          <SvgIcon icon={plusIcon} />
        </div>

        {shouldShowDropdown && (
          <div
            className={styles.dropdownContainer}
            id={dropdownId}
            tabIndex={0}
          >
            {/** tabIndex added so relatedTarget works for focus event */}
            {props.showLoadingIndicator === false &&
              Object.entries(filteredOptions).map(([key, value], i) => {
                /** if option can be found by key in selectedOptions, it means it's selected */
                const isSelected = selectedOptions[key] !== undefined;
                return (
                  <div
                    className={classes(styles.dropdownOption)}
                    onClick={() => {
                      onSelectOption(key, value);
                      searchRef.current?.focus();
                    }}
                    key={key}
                  >
                    {props.formatOption?.(value) ?? JSON.stringify(value)}
                    {isSelected && (
                      <div className={styles.checkIconContainer}>
                        <SvgIcon
                          icon={checkmarkIcon}
                          color={"rgb(var(--rgb-color-success))"}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            {props.isLimited === true &&
              props.showLoadingIndicator === false && (
                <div className={styles.hintIndicator}>
                  {dictionary.texts.enterSearchSpecificResults}
                </div>
              )}
            {Object.keys(filteredOptions).length === 0 && (
              <div className={styles.noClientsAvailable}>
                {dictionary.literals.noResultsFound}
              </div>
            )}
            {props.showLoadingIndicator === true && (
              <div className={styles.loadingIndicatorContainer}>
                <LoadingSpinner color={"black"} />
              </div>
            )}
          </div>
        )}
      </div>

      {Object.entries.length > 0 && (
        <div className={styles.selectedOptionsContainer}>
          {Object.entries(selectedOptions).map(([key, value], i) => (
            <Fragment key={key}>
              <div
                className={styles.selectedOption}
                onClick={() => onSelectOption(key, value)}
              >
                {props.formatOption?.(value) ?? JSON.stringify(value)}
                <div className={styles.crossIconContainer}>
                  <SvgIcon
                    icon={problemIcon}
                    color={"rgb(var(--rgb-color-dangerous))"}
                  />
                </div>
                {/* <Problem className={styles.crossImage} /> */}
              </div>
              {props.name && !isFormDisabled && !props.disabled && (
                <input type="hidden" name={`${props.name}[${i}]`} value={key} />
              )}
            </Fragment>
          ))}
        </div>
      )}
    </>
  );
}

const selectItemHeight = 55;

const styles = {
  inputContainer: classes(
    inputStyles.input,
    style({
      display: "flex",
      position: "relative",
      alignItems: "center",
      cursor: "text",
    }),
  ),
  inputContainerFocus: style({
    border: "1.5px solid rgb(var(--rgb-color-primair-basis)) !important",
  }),
  plusIcon: style({
    margin: "auto 0 auto auto",
    color: "rgb(var(--rgb-color-primary-basis))",
  }),
  searchInput: style({
    outline: 0,
    minWidth: 10,
  }),
  hideSearchInput: style({
    opacity: 0,
  }),
  placeholder: style({
    color: "rgba(var(--rgb-color-black), 0.5)",
  }),
  requiredInput: style({
    height: 0,
    opacity: 0,
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    border: 0,
  }),
  dropdownContainer: style({
    position: "absolute",
    top: "100%",
    left: 0,
    borderRadius: "var(--border-radius-medium)",
    width: "100%",
    minHeight: selectItemHeight,
    maxHeight: 220,
    backgroundColor: "rgb(var(--rgb-color-white))",
    overflow: "auto",
    zIndex: 1,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
  }),
  dropdownOption: style({
    display: "flex",
    alignItems: "center",
    padding: "0 14px",
    minHeight: 56,
    maxHeight: 56,
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
  noClientsAvailable: style({
    padding: "19px 14px",
    textAlign: "center",
    color: "rgb(var(--rgb-color-gray))",
    fontWeight: 300,
  }),
  hintIndicator: style({
    padding: "10px 14px",
    fontSize: "0.75rem",
    color: "rgb(var(--rgb-color-gray))",
    textAlign: "center",
    fontWeight: 300,
  }),
  checkmarkContainer: style({
    justifyContent: "flex-end",
  }),
  checkIconContainer: style({
    margin: "auto 0 auto auto",
    width: 21,
    height: 21,
  }),
  selectedOptionsContainer: style({
    display: "flex",
    margin: "10px 0 10px 20px",
    flexFlow: "wrap",
  }),
  selectedOption: style({
    display: "flex",
    alignItems: "center",
    backgroundColor: "rgb(var(--rgb-color-theme-1))",
    borderRadius: "var(--border-radius-medium)",
    padding: "5px 12px",
    fontSize: "0.93rem",
    marginRight: 10,
    marginBottom: 10,
    userSelect: "none",
    cursor: "pointer",
  }),
  crossIconContainer: style({
    marginLeft: 10,
    width: 21,
    height: 21,
  }),
  loadingIndicatorContainer: style({
    height: selectItemHeight,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
};
