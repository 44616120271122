import { FC } from "react";
import { Button } from "../../../elements/button.element";
import { Form, IFormProps } from "../../../elements/form.element";
import { Input } from "../../../elements/input.element";
import { Row } from "../../../elements/row.element";
import { ReactComponent as sendIcon } from "../../../../assets/graphics/symbols/send.svg";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";

export const InvitationForm: FC<IFormProps> = (props) => (
  <Form {...props.attributes} onSubmit={props.onSubmit}>
    <Row>
      <Input
        attributes={{
          name: "name",
          type: "text",
          placeholder: dictionary.literals.firstName,
          required: true,
        }}
      />
    </Row>
    <Row>
      <Input
        attributes={{
          name: "lastName",
          type: "text",
          placeholder: dictionary.literals.lastName,
          required: true,
        }}
      />
    </Row>
    <Row>
      <Input
        attributes={{
          name: "email",
          type: "email",
          placeholder: dictionary.literals.email,
          required: true,
        }}
      />
    </Row>
    <Row>
      <Button attributes={{ type: "submit" }} icon={sendIcon}>
        {dictionary.literals.connectClient}
      </Button>
    </Row>
  </Form>
);
