import {
  Fragment,
  useCallback,
  useMemo,
  useRef,
  useState,
  ChangeEvent,
  useEffect,
  FC,
} from "react";
import { classes, keyframes, style } from "typestyle";
import { dictionary } from "../../state/common/constants/dictionary.constants";

interface IOption {
  value: string;
  label: string;
}

export interface IInputProps {
  placeholder: string;
  options: IOption[];
  isRequired?: boolean;
  name?: string;
  emptyOptionLabel?: string;
  className?: string;
  isSearchable?: boolean;
  isDisabled?: boolean;
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
}

export const InputSelect: FC<IInputProps> =
  // eslint-disable-next-line complexity -- complex input select component
  (props) => {
    const searchInputElement = useRef<HTMLParagraphElement>(null);
    const containerElement = useRef<HTMLDivElement>(null);

    const [value, setValue] = useState<string | undefined>(
      props.value ?? props.defaultValue,
    );
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState<string>("");

    const filteredOptions = useMemo(
      () =>
        props.options.filter((option) =>
          option.label.toLowerCase().includes(searchValue?.toLowerCase()),
        ),
      [props.options, searchValue],
    );

    const selectedOption = useMemo(() => {
      const option =
        props.options.find((option) => option.value === value) ?? null;
      if (option === null && value) {
        setValue(props.emptyOptionLabel ?? undefined);
      }

      return option;
    }, [props.emptyOptionLabel, props.options, value]);

    const closeOptions = useCallback(() => {
      setSearchValue("");
      setIsOpen(false);

      if (containerElement.current) {
        containerElement.current.blur();
      }
    }, []);

    const handleBodyClick = useCallback(
      (event: MouseEvent) => {
        if (
          containerElement.current?.contains(event.target as Node) === false
        ) {
          closeOptions();
        }
      },
      [closeOptions],
    );

    const handleOpenOptions = useCallback(() => {
      setIsOpen(true);
    }, []);

    const handleOnClickOption = useCallback(
      (option: IOption | null) => {
        setValue(option?.value ?? props.emptyOptionLabel);
        if (option?.label) {
          props.onChange?.(option.value);
        }
        closeOptions();
      },
      [closeOptions, props],
    );

    const handleOnClickClear = useCallback(() => {
      handleOnClickOption(null);
      if (searchInputElement.current !== null && props.isSearchable === true) {
        searchInputElement.current.textContent = props.placeholder;
      }
    }, [handleOnClickOption, props.isSearchable, props.placeholder]);

    const handleOnInput = useCallback(
      (event: ChangeEvent<HTMLParagraphElement>) => {
        setSearchValue(event.currentTarget.textContent ?? "");
      },
      [],
    );

    useEffect(() => {
      if (props.value !== undefined) {
        setValue(props.value);
      }
    }, [props.value]);

    useEffect(() => {
      if (
        isOpen === true &&
        searchInputElement.current !== null &&
        props.isSearchable === true
      ) {
        searchInputElement.current.focus();
      }
    }, [isOpen, props.isSearchable]);

    useEffect(() => {
      document.addEventListener("click", handleBodyClick);
      return () => {
        document.removeEventListener("click", handleBodyClick);
      };
    }, [handleBodyClick]);

    return (
      <Fragment>
        {props.name !== undefined && (
          <input
            type="hidden"
            name={props.name}
            value={selectedOption?.value ?? "{{undefined}}"}
          />
        )}
        <div
          ref={containerElement}
          className={classes(
            styles.inputContainer,
            props.className,
            props.isDisabled && styles.isDisabled,
          )}
        >
          <div
            className={classes(styles.select, isOpen && styles.onInputFocus)}
            onClick={handleOpenOptions}
          >
            {(isOpen === false || !props.isSearchable) && (
              <p
                className={classes(
                  value === undefined ? styles.placeholder : "",
                  styles.text,
                )}
              >
                {selectedOption?.label}
                {value === props.emptyOptionLabel && props.emptyOptionLabel}
                {value === undefined && props.placeholder}
              </p>
            )}
            {isOpen === true && props.isSearchable && (
              <p
                ref={searchInputElement}
                className={styles.text}
                contentEditable={props.isSearchable}
                onInput={handleOnInput}
              />
            )}
          </div>
          {isOpen === true && (
            <div className={styles.options}>
              {props.isRequired !== true && (
                <div className={styles.option} onClick={handleOnClickClear}>
                  <p>
                    {props.emptyOptionLabel ??
                      dictionary.literals.clearSelection}
                  </p>
                </div>
              )}
              {filteredOptions.map((option) => (
                <div
                  className={styles.option}
                  key={option.value}
                  onClick={() => handleOnClickOption(option)}
                >
                  <p>{option.label}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </Fragment>
    );
  };

const slideIn = keyframes({
  from: {
    transform: "translateY(-10px)",
    opacity: 0,
  },
  to: {
    transform: "translateY(0)",
    opacity: 1,
  },
});

const styles = {
  inputContainer: style({
    position: "relative",
  }),
  select: style({
    width: "100%",
    cursor: "pointer",
    outline: "none",
    padding: "0px 14px",
    height: 54,
    border: "1.5px solid transparent",
    borderRadius: "var(--border-radius-large)",
    backgroundColor: "rgb(var(--rgb-color-gray-lighter))",
  }),
  isDisabled: style({
    pointerEvents: "none",
    backgroundColor: "rgb(232, 232, 232) !important",
  }),
  placeholder: style({
    color: "rgb(var(--rgb-color-gray))",
  }),
  onInputFocus: style({
    border: "1.5px solid rgb(var(--rgb-color-primair-basis))",
  }),
  options: style({
    position: "absolute",
    outline: "none",
    top: "95%",
    left: 0,
    borderRadius: "var(--border-radius-medium)",
    width: "100%",
    minHeight: 55,
    maxHeight: 150,
    backgroundColor: "rgb(var(--rgb-color-white))",
    overflow: "auto",
    animationName: slideIn,
    animationDuration: "0.2s",
    animationTimingFunction: "ease-in-out",
    zIndex: 9,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)",
  }),
  option: style({
    display: "flex",
    alignItems: "center",
    padding: "0 14px",
    minHeight: 56,
    maxHeight: 56,
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    $nest: {
      "&:hover": {
        backgroundColor: "#eee",
      },
    },
  }),
  text: style({
    outline: "none",
    pointerEvents: "none",
  }),
  inputError: style({
    backgroundColor: "rgb(var(--rgb-color-dangerous-lighter))",
    border: "1px solid rgb(var(--rgb-color-dangerous))",
    color: "rgb(var(--rgb-color-dangerous))",
  }),
};
