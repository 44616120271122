import { FC, ImgHTMLAttributes } from "react";
import { classes, keyframes, style } from "typestyle";

interface IProps {
  attributes?: ImgHTMLAttributes<HTMLImageElement>;
  color?: "white" | "black";
}

export const LoadingSpinner: FC<IProps> = (props) => (
  <div
    {...props.attributes}
    className={classes(
      styles.spinner,
      props.color === "black" && styles.black,
      props.attributes?.className,
    )}
  />
);

const spinnerAnimation = keyframes({
  from: { transform: "rotate(360deg)" },
  to: { transform: "rotate(0deg);" },
});

const styles = {
  spinner: style({
    // eslint-disable-next-line quotes -- needed for empty content
    content: `''`,
    width: 25,
    height: 25,
    border: "4px solid transparent",
    borderTopColor: "rgb(var(--rgb-color-white))",
    borderLeftColor: "rgb(var(--rgb-color-white))",
    borderRadius: "var(--border-radius-round)",
    animation: `${spinnerAnimation} 0.8s ease infinite`,
    margin: "var(--spacing-vertical-small) auto",
  }),
  black: style({
    borderTopColor: "rgb(var(--rgb-color-black))",
    borderLeftColor: "rgb(var(--rgb-color-black))",
  }),
};
