import { FC } from "react";
import { classes, stylesheet } from "typestyle";
import { dictionary } from "../../state/common/constants/dictionary.constants";

interface IProps {
  onClick: () => void;
  className?: string;
}

export const ShowMore: FC<IProps> = (props) => (
  <div
    className={classes(styles.showMore, props.className)}
    onClick={props.onClick}
  >
    {dictionary.literals.showMore}
  </div>
);

const styles = stylesheet({
  showMore: {
    cursor: "pointer",
    fontSize: "0.8rem",
    fontWeight: 700,
    textTransform: "uppercase",
    margin: "var(--spacing-vertical-regular) var(--spacing-horizontal-regular)",
    textAlign: "center",
    textDecoration: "underline",
    textUnderlineOffset: 6,
  },
});
