import { FC, ImgHTMLAttributes } from "react";
import { classes, style } from "typestyle";
import { ReactComponent as ExpandDown } from "../../assets/graphics/symbols/expand-down.svg";
import { ReactComponent as ExpandUp } from "../../assets/graphics/symbols/expand-up.svg";

interface IProps {
  attributes?: ImgHTMLAttributes<HTMLImageElement>;
  isExpanded?: boolean;
}

export const ExpandArrowDownImage: FC<IProps> = (props) => {
  if (props.isExpanded === true) {
    return <ExpandUp className={styles.element} />;
  }
  return (
    <ExpandDown className={classes(styles.element, styles.elementExpanded)} />
  );
};

const styles = {
  element: style({
    width: 20,
    color: "white",
  }),
  elementExpanded: style({
    color: "rgb(var(--color-primary))",
  }),
};
