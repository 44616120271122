import { FC, useMemo } from "react";
import { IExerciseInvite, ScheduleTiming } from "@hulanbv/platformapp";
import { TableRow } from "../../elements/table-row.element";
import { TableRowContent } from "../../elements/table-row-content.element";
import { dictionary } from "../../../state/common/constants/dictionary.constants";
import { IconButton } from "../../elements/icon-button.element";
import { ReactComponent as eyeIcon } from "../../../assets/graphics/symbols/eye.svg";
import { ReactComponent as trashIcon } from "../../../assets/graphics/symbols/trash.svg";
import { numberToOrdinal } from "../../../utils/number-to-ordinal.utils";
import { dateToWeekday } from "../../../utils/date-to-weekday.utils";

interface IProps {
  exerciseInvite: IExerciseInvite;
  onClickRemove?: () => Promise<void> | void;
  onClickView?: () => Promise<void> | void;
}

export const ScheduledExerciseInviteRowTemplate: FC<IProps> = (props) => {
  const {
    exercise,
    scheduledDate,
    scheduleTiming,
    scheduleOccurrenceCount,
    scheduledEndDate,
  } = props.exerciseInvite;

  // eslint-disable-next-line complexity -- This is a complex function that needs to be tested
  const memoizedSubheader = useMemo(() => {
    const date = new Date(scheduledDate);

    // Add some more details to the subheader depending on ScheduleTiming
    if (scheduleTiming === ScheduleTiming.ONCE) {
      if (scheduledEndDate && scheduleOccurrenceCount) {
        const endDate = new Date(scheduledEndDate);
        return dictionary.texts.multipleScheduleTimingOnceTableRowHeader(
          date,
          scheduleOccurrenceCount,
          endDate,
        );
      }
      // Do not use prefix subheader when it starts for ScheduleTiming.ONCE since its redundant
      return dictionary.texts.scheduleTimingOnceTableRowHeader(date);
    }

    const subHeader = [];
    // If scheduledDate is in the future, add a prefix to the subheader containing the date it starts
    if (date > new Date()) {
      subHeader.push(dictionary.texts.startsOn(date));
    }

    if (props.exerciseInvite.scheduleRecurrenceEndDate) {
      const endDate = new Date(props.exerciseInvite.scheduleRecurrenceEndDate);
      if (endDate < new Date()) {
        subHeader.push(dictionary.texts.endedOn(endDate));
      } else {
        subHeader.push(dictionary.texts.endsOn(endDate));
      }
    }

    if (scheduleTiming === ScheduleTiming.DAILY) {
      if (scheduledEndDate && scheduleOccurrenceCount) {
        const endDate = new Date(scheduledEndDate);
        subHeader.push(
          dictionary.texts.multipleScheduleTimingDailyTableRowHeader(
            date,
            scheduleOccurrenceCount,
            endDate,
          ),
        );
      } else {
        subHeader.push(
          dictionary.texts.scheduleTimingDailyTableRowHeader(date),
        );
      }
    }
    if (scheduleTiming === ScheduleTiming.WEEKLY) {
      const nameOfDay = dateToWeekday(date);
      if (scheduledEndDate && scheduleOccurrenceCount) {
        const endDate = new Date(scheduledEndDate);
        subHeader.push(
          dictionary.texts.multipleScheduleTimingWeeklyTableRowHeader(
            nameOfDay,
            date,
            scheduleOccurrenceCount,
            endDate,
          ),
        );
      } else {
        subHeader.push(
          dictionary.texts.scheduleTimingWeeklyTableRowHeader(nameOfDay, date),
        );
      }
    }
    if (scheduleTiming === ScheduleTiming.MONTHLY) {
      const ordinalDay = numberToOrdinal(date.getDate());
      if (scheduledEndDate && scheduleOccurrenceCount) {
        const endDate = new Date(scheduledEndDate);
        subHeader.push(
          dictionary.texts.multipleScheduleTimingWeeklyTableRowHeader(
            ordinalDay,
            date,
            scheduleOccurrenceCount,
            endDate,
          ),
        );
      } else {
        subHeader.push(
          dictionary.texts.scheduleTimingMonthlyTableRowHeader(
            ordinalDay,
            date,
          ),
        );
      }
    }

    return subHeader.join(" - ");
  }, [
    scheduledDate,
    scheduleTiming,
    props.exerciseInvite.scheduleRecurrenceEndDate,
    scheduledEndDate,
    scheduleOccurrenceCount,
  ]);

  return (
    <TableRow>
      <TableRowContent
        header={exercise?.name}
        subHeader={memoizedSubheader}
        controls={
          <>
            {props.onClickRemove && (
              <IconButton
                flavour={"danger"}
                icon={trashIcon}
                attributes={{
                  onClick: props.onClickRemove,
                }}
              />
            )}
            {props.onClickView && (
              <IconButton
                flavour={"primary"}
                icon={eyeIcon}
                attributes={{
                  onClick: props.onClickView,
                }}
              />
            )}
          </>
        }
      />
    </TableRow>
  );
};
