import { ITraining } from "@hulanbv/platformapp";
import { CrudService } from "nest-utilities-client";
import { environment } from "../../constants/environment.constants";
import { httpService } from "../common/http.service";

class Service extends CrudService<ITraining> {
  constructor() {
    super([environment.apiUrl, "trainings"].join("/"), httpService);
  }
}

export const trainingService = new Service();
