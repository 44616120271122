import { useAll } from "@hulanbv/nest-utilities-client-state";
import { IExercise, ILabel } from "@hulanbv/platformapp";
import { IHttpOptions } from "nest-utilities-client";
import { FC, useCallback, useState } from "react";
import { classes, style } from "typestyle";
import { ReactComponent as checkIcon } from "../../../assets/graphics/symbols/checkmark.svg";
import { ReactComponent as crossIcon } from "../../../assets/graphics/symbols/cross.svg";
import { dictionary } from "../../../state/common/constants/dictionary.constants";
import { useExerciseCategories } from "../../../state/exercise/hooks/use-exercise-categories.hook";
import { labelService } from "../../../state/label/label.service";
import { Button } from "../../elements/button.element";
import { Checkbox } from "../../elements/checkbox.element";
import { Header } from "../../elements/header.element";
import { UrlIconButton } from "../../elements/url-icon-button.element";

export interface ISelectExerciseFilters {
  selectedCategories?: string[];
  selectedLabelId?: string;
  isOnlyOwnExercisesSelected?: boolean;
}

interface IProps {
  categoryOptions?: IHttpOptions<IExercise>;
  labelOptions?: IHttpOptions<ILabel>;
  onSubmit: (selectExerciseFilters: ISelectExerciseFilters) => void;
  onCancelClick: () => void;
  selectExerciseFilters: ISelectExerciseFilters;
}

export const SelectExerciseFilters: FC<IProps> = (props) => {
  const exerciseCategories = useExerciseCategories(props.categoryOptions);
  const { data: labels } = useAll(labelService, {
    ...props.labelOptions,
    populate: [...(props.labelOptions?.populate ?? []), "icon"],
  });

  const [selectExerciseFilters, setSelectExerciseFilters] = useState(
    props.selectExerciseFilters,
  );

  const onSubmit = useCallback(() => {
    props.onSubmit(selectExerciseFilters);
  }, [props, selectExerciseFilters]);

  const handleOnClickCategory = (category: string) => {
    let { selectedCategories } = selectExerciseFilters;

    if (selectedCategories?.includes(category)) {
      const index = selectedCategories.indexOf(category);
      selectedCategories.splice(index, 1);

      if (!selectedCategories.length) {
        selectedCategories = undefined;
      }
      setSelectExerciseFilters({
        ...selectExerciseFilters,
        selectedCategories,
      });
    } else {
      setSelectExerciseFilters((previousSelectExerciseFilters) => ({
        ...previousSelectExerciseFilters,
        selectedCategories: [
          ...(previousSelectExerciseFilters.selectedCategories ?? []),
          category,
        ],
      }));
    }
  };

  const handleOnClickLabel = (labelId: string) => {
    let selectedLabelId: string | undefined = labelId;
    if (selectExerciseFilters.selectedLabelId === selectedLabelId) {
      selectedLabelId = undefined;
    }
    setSelectExerciseFilters({
      ...selectExerciseFilters,
      selectedLabelId,
    });
  };

  const handleOnClickMyExercises = useCallback(() => {
    const { isOnlyOwnExercisesSelected } = selectExerciseFilters;
    setSelectExerciseFilters({
      ...selectExerciseFilters,
      isOnlyOwnExercisesSelected: !isOnlyOwnExercisesSelected,
    });
  }, [selectExerciseFilters]);

  return (
    <>
      <div className={styles.content}>
        <Header className={styles.mainHeader}>
          {dictionary.literals.filter}
        </Header>
        {/* Labels */}
        <Header size={"medium"} className={styles.subHeader}>
          {dictionary.literals.labels}
        </Header>
        <div className={styles.labelButtonContainer}>
          {labels?.map((label) => (
            <UrlIconButton
              attributes={{
                onClick: () => handleOnClickLabel(label._id),
                className: classes(
                  selectExerciseFilters.selectedLabelId === label._id &&
                    styles.buttonSelected,
                ),
              }}
              iconUrl={label.icon?.lightImagePath ?? ""}
              key={label._id}
            />
          ))}
        </div>
        {/* Categories */}
        <Header size={"medium"} className={styles.subHeader}>
          {dictionary.literals.categories}
        </Header>
        <div className={styles.categoryFilterContainer}>
          {(exerciseCategories ?? []).map((exerciseCategory) => (
            <div
              key={exerciseCategory.categoryName}
              className={styles.checkboxContainer}
            >
              <Checkbox
                label={`${exerciseCategory.categoryName} (${exerciseCategory.exerciseCount})`}
                attributes={{
                  onChange: () =>
                    handleOnClickCategory(exerciseCategory.categoryName),
                  isDefaultChecked:
                    !!selectExerciseFilters.selectedCategories?.includes(
                      exerciseCategory.categoryName,
                    ),
                }}
              />
            </div>
          ))}
        </div>
        {/* My exercises */}
        <div className={styles.myExerciseFilterContainer}>
          <Checkbox
            label={dictionary.literals.onlyMyOwnExercises}
            attributes={{
              onChange: handleOnClickMyExercises,
              isDefaultChecked:
                !!selectExerciseFilters.isOnlyOwnExercisesSelected,
            }}
          />
        </div>
        {/* Apply and cancel filters button */}
        <div className={styles.buttonsContainer}>
          <Button
            flavour="primary"
            attributes={{
              onClick: onSubmit,
            }}
            icon={checkIcon}
          >
            {dictionary.literals.confirm}
          </Button>
          <Button
            flavour="secondary"
            attributes={{
              onClick: () => props.onCancelClick(),
            }}
            icon={crossIcon}
          >
            {dictionary.literals.cancel}
          </Button>
        </div>
      </div>
    </>
  );
};

const styles = {
  content: style({
    padding: 20,
  }),
  buttonsContainer: style({
    display: "flex",
    flexWrap: "wrap",
    marginTop: 20,
    gap: 10,
  }),
  mainHeader: style({
    color: "rgb(var(--rgb-color-primair-basis))",
    textAlign: "center",
    margin: "0 0 20px",
  }),
  subHeader: style({
    margin: "0 0 15px",
  }),
  description: style({
    margin: "0 0 20px 0",
  }),
  labelButtonContainer: style({
    display: "flex",
    flexWrap: "wrap",
    gap: "var(--spacing-horizontal-small)",
    marginBottom: 15,
  }),
  categoryFilterContainer: style({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  }),
  myExerciseFilterContainer: style({
    marginTop: "var(--spacing-vertical-small)",
    paddingTop: "var(--spacing-vertical-small)",
    borderTop: "1px solid rgba(var(--rgb-color-black), 0.1)",
  }),
  checkboxContainer: style({
    width: "50%",
    wordBreak: "break-word",
  }),
  buttonSelected: style({
    backgroundColor: "rgb(var(--rgb-color-secundair))",
  }),
};
