import {
  IExercise,
  IExerciseInvite,
  ScheduleTiming,
} from "@hulanbv/platformapp";
import { useById } from "@hulanbv/nest-utilities-client-state";
import { FC, useState } from "react";
import { useHistory, useParams } from "react-router";
import { style } from "typestyle";
import { ActionBar } from "../elements/action-bar.element";
import { Header } from "../elements/header.element";
import { ReactComponent as clientIcon } from "../../assets/graphics/symbols/client.svg";
import { ReactComponent as groupIcon } from "../../assets/graphics/symbols/group.svg";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { exerciseCollectionService } from "../../state/exercise/exercise-collection.service";
import { useAuthContext } from "../../state/authentication/authentication.context";
import Toast from "../statics/toast";
import { exerciseInviteService } from "../../state/exercise-invite/exercise-invite.service";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { ExerciseInvitesForm } from "../templates/forms/exercise-invites/exercises-invites-form.template";

export const SendCollectionInvitesScreen: FC = () => {
  const params = useParams<{ collectionId: string }>();
  const { data } = useById(exerciseCollectionService, params.collectionId, {
    populate: ["exercises"],
  });
  const history = useHistory();
  const { session } = useAuthContext();

  const [shouldShowGroups, setShouldShowGroups] = useState(false);

  const onSubmit = async (
    exerciseInvite: Partial<IExerciseInvite>,
    inviteeIds: string[],
  ) => {
    const fetchedExercises = data?.exercises;
    const { scheduledDate, scheduleTiming } = exerciseInvite;
    const collectionInvites: IExerciseInvite[] = [];
    let isScheduledInvite = false;

    try {
      if (!fetchedExercises?.length || !session?.userId || !scheduledDate) {
        throw Error();
      }

      // if schedulDate is higher than current date, it's scheduled
      isScheduledInvite = scheduledDate > new Date();

      // loop through all selected inviteeIds
      for (const inviteeId of inviteeIds) {
        // create an invite for each exercise in the session
        const invites = fetchedExercises.map<IExerciseInvite>(
          (exercise: IExercise) => ({
            ...exerciseInvite,
            inviteeId,
            senderId: session.userId,
            exerciseId: exercise.id,
            scheduledDate,
            scheduleTiming: scheduleTiming ?? ScheduleTiming.ONCE,
          }),
        );
        collectionInvites.push(...invites);
      }

      await exerciseInviteService.createMany(collectionInvites);

      Toast.info({
        body: isScheduledInvite
          ? dictionary.texts.collectionInviteScheduledSuccess
          : dictionary.texts.collectionInvitesSentSuccess,
      });

      history.goBack();
    } catch {
      Toast.error({
        body: isScheduledInvite
          ? dictionary.texts.collectionInviteScheduledError
          : dictionary.texts.collectionSentError,
      });
    }
  };

  return (
    <Page>
      <PageBody>
        <Header className={styles.centerHeader}>{data?.name ?? ""}</Header>
        <ActionBar
          actions={[
            {
              icon: clientIcon,
              onClick: () => setShouldShowGroups(false),
              isActive: shouldShowGroups === false,
            },
            {
              icon: groupIcon,
              onClick: () => setShouldShowGroups(true),
              isActive: shouldShowGroups === true,
            },
          ]}
        />
        <ExerciseInvitesForm
          onSubmit={onSubmit}
          showGroupSelect={shouldShowGroups}
        />
      </PageBody>
    </Page>
  );
};

const styles = {
  centerHeader: style({
    display: "flex",
    justifyContent: "center",
    paddingBottom: 20,
  }),
};
