import { FC, useCallback, useEffect, useState } from "react";
import { style } from "typestyle";
import { PbatType, QuestionnaireType } from "@hulanbv/platformapp";
import { generatePath, useHistory, useParams } from "react-router";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { Description } from "../elements/description.element";
import { ReportFormTemplate } from "../templates/forms/report/report-form.template";
import { questionService } from "../../state/question/question.service";
import { routes } from "../../state/common/constants/routes.constants";
import { ActionBar } from "../elements/action-bar.element";
import { ReactComponent as questionnaireIcon } from "../../assets/graphics/symbols/questionnaire.svg";
import { ReactComponent as mindgrapherIcon } from "../../assets/graphics/symbols/mindgrapher-icon.svg";
import { ClientQuestionnairesTable } from "../templates/tables/clientprofile/client-questionnaires-table.template";

// undefined will be used to indicate that no tab is selected
type Tab = undefined | "intakes";

const questionnaireMatchOptions = {
  "exercise.questionnaire.type": QuestionnaireType.INTAKE,
};

export const ClientMindgrapherScreen: FC = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const history = useHistory();
  const params = useParams<{ userId: string }>();
  const [activeTab, setActiveTab] = useState<Tab>(
    (searchParams.get("tab") as Tab) ?? undefined,
  );

  const [resultIdentifiers, setResultIdentifiers] = useState<string[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);

  const [processOfChangeOptions, setProcessOfChangeOptions] = useState<
    string[]
  >([]);

  const navigateToTab = (tab: Tab) => {
    // build an url to replace the current one containing the current tab in a query parameter
    const searchParams = new URLSearchParams(window.location.search);
    if (tab === undefined) {
      searchParams.delete("tab");
    } else {
      searchParams.set("tab", tab);
    }

    // preserve the current path, but replace the query parameters
    history.replace({
      search: searchParams.toString(),
    });
    setActiveTab(tab);
  };

  useEffect(() => {
    (async () => {
      const { data: answeredIdentifierQuestions } =
        await questionService.getAnsweredQuestions(params.userId, {
          match: {
            resultIdentifier: { $exists: true },
          },
          distinct: "resultIdentifier",
          sort: ["resultIdentifier"],
        });

      const resultIdentifiers = answeredIdentifierQuestions.map(
        (question) => question.resultIdentifier ?? "",
      );

      const processOfChangeResultIdentifiers = answeredIdentifierQuestions
        .filter((question) => question.pbatType === PbatType.PROCESS_OF_CHANGE)
        .map((question) => question.resultIdentifier ?? "");

      setProcessOfChangeOptions(processOfChangeResultIdentifiers);
      setResultIdentifiers(resultIdentifiers);
    })();
  }, [params.userId]);

  const submit = useCallback(
    async (formData: FormData) => {
      const processOfChange = formData.get("processOfChange") ?? "";
      const outcome = formData.get("outcome") ?? "";

      if ((processOfChange || outcome) === "{{undefined}}") {
        setHasError(true);
        return;
      }

      const searchParams = new URLSearchParams(
        window.location.search,
      ).toString();

      history.push({
        pathname: generatePath(routes.weekResultsReport.path, {
          userId: params.userId,
          resultIdentifier1: outcome.toString(),
          resultIdentifier2: processOfChange.toString(),
        }),
        search: searchParams,
      });
    },
    [history, params.userId],
  );

  return (
    <Page>
      <PageBody>
        <ActionBar
          actions={[
            {
              isActive: !activeTab,
              icon: mindgrapherIcon,
              onClick: () => navigateToTab(undefined),
            },
            {
              isActive: activeTab === "intakes",
              icon: questionnaireIcon,
              onClick: () => navigateToTab("intakes"),
            },
          ]}
        />
        {activeTab === undefined && (
          <>
            <Header className={styles.centerHeader}>
              {dictionary.literals.mindgrapherReport}
            </Header>
            <Description className={styles.spacing}>
              {dictionary.texts.reportDisclaimer}
            </Description>
            <ReportFormTemplate
              outcomeOptions={resultIdentifiers}
              processOfChangeOptions={processOfChangeOptions}
              onSubmit={submit}
              error={hasError}
            />
          </>
        )}
        {activeTab === "intakes" && (
          <ClientQuestionnairesTable
            title={dictionary.literals.intakes}
            clientId={params.userId}
            match={questionnaireMatchOptions}
          />
        )}
      </PageBody>
    </Page>
  );
};

const styles = {
  centerHeader: style({
    display: "flex",
    justifyContent: "center",
    paddingBottom: 20,
  }),
  spacing: style({
    marginBottom: 50,
  }),
};
