import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { style } from "typestyle";
import { InjectionService } from "../../state/common/injection.service";
import { ReactComponent as playIcon } from "../../assets/graphics/symbols/media-play.svg";
import { ReactComponent as pauseIcon } from "../../assets/graphics/symbols/media-pause.svg";
import { ReactComponent as stopIcon } from "../../assets/graphics/symbols/media-stop.svg";
import { isNativeApp } from "../../utils/is-native-app.utils";
import { SvgIcon } from "./svg-icon.element";

interface IProps {
  url: string;
}

enum PlayBackStatus {
  PAUSE,
  PLAY,
  IDLE,
}

export const AudioPlayer: FC<IProps> = (props) => {
  const [playbackStatus, setPlaybackStatus] = useState<PlayBackStatus>(
    PlayBackStatus.IDLE,
  );

  const audio = useMemo(() => {
    const audio = new Audio();
    audio.onended = () => setPlaybackStatus(PlayBackStatus.IDLE);

    return audio;
  }, []);

  const handleStopAudio = useCallback(() => {
    // Stopping audio that isn't playing yet causes native apps to crash
    if (playbackStatus === PlayBackStatus.IDLE) {
      return;
    }

    setPlaybackStatus(PlayBackStatus.IDLE);
    if (isNativeApp === true) {
      InjectionService.stopSound();
      return;
    }

    audio.pause();
    audio.currentTime = 0;
  }, [audio, playbackStatus]);

  useEffect(() => {
    // stop playing current audio so we can load a new audio src
    handleStopAudio();
    // set new audio src
    audio.src = props.url;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- do not execute when handleStopAudio changes
  }, [audio, props.url]);

  useEffect(() => {
    // register event listener to update state whenever audio has ended
    audio.addEventListener("ended", handleStopAudio);
    return () => {
      audio.removeEventListener("ended", handleStopAudio);
      // stop playing audio when component unmounts
      handleStopAudio();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps -- only listen to audio changes
  }, [audio]);

  const handleOnPlayClick = useCallback(() => {
    setPlaybackStatus(PlayBackStatus.PLAY);
    if (isNativeApp === true) {
      InjectionService.playSound(props.url);
      return;
    }
    audio.play();
  }, [props.url, audio]);

  const handleOnPauseClick = useCallback(() => {
    setPlaybackStatus(PlayBackStatus.PAUSE);
    if (isNativeApp === true) {
      InjectionService.pauseSound();
      return;
    }
    audio.pause();
  }, [audio]);

  const handleOnResumeClick = useCallback(() => {
    setPlaybackStatus(PlayBackStatus.PLAY);
    if (isNativeApp === true) {
      InjectionService.resumeSound();
      return;
    }
    audio.play();
  }, [audio]);

  return (
    <div className={styles.audioContainer}>
      {playbackStatus === PlayBackStatus.IDLE && (
        <div className={styles.buttonArea} onClick={handleOnPlayClick}>
          <SvgIcon icon={playIcon} color={"rgb(var(--color-black))"} />
        </div>
      )}
      {playbackStatus === PlayBackStatus.PLAY && (
        <>
          <div className={styles.buttonArea} onClick={handleOnPauseClick}>
            <SvgIcon icon={pauseIcon} color={"rgb(var(--color-black))"} />
          </div>
          <div className={styles.buttonArea} onClick={handleStopAudio}>
            <SvgIcon icon={stopIcon} color={"rgb(var(--color-black))"} />
          </div>
        </>
      )}
      {playbackStatus === PlayBackStatus.PAUSE && (
        <>
          <div className={styles.buttonArea} onClick={handleOnResumeClick}>
            <SvgIcon icon={playIcon} color={"rgb(var(--color-black))"} />
          </div>
          <div className={styles.buttonArea} onClick={handleStopAudio}>
            <SvgIcon icon={stopIcon} color={"rgb(var(--color-black))"} />
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  audioContainer: style({
    height: 50,
    width: "100%",
    display: "flex",
    backgroundColor: "rgba(var(--rgb-color-primair-shade-4), 0.1)",
    borderRadius: "var(--border-radius-large)",
    padding: "15px 25px",
  }),
  buttonArea: style({
    flex: 1,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  }),
  icon: style({
    display: "block",
    height: "100%",
    width: "100%",
    color: "rgb(var(--rgb-color-primair-basis))",
  }),
};
