import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { classes, style } from "typestyle";

interface IProps {
  markdown: string;
  isTruncated?: boolean;
}

export const MarkdownToHtml: FC<IProps> = (props) => (
  <div className={classes(props.isTruncated && styles.truncated)}>
    <ReactMarkdown children={props.markdown} />
  </div>
);

const lineHeight = 19;

const styles = {
  truncated: style({
    overflow: "hidden",
    // force truncated markdown to only show 6 lines
    maxHeight: `${lineHeight * 6}px`,
    lineHeight: `${lineHeight}px`,
    // to show as much information as possible in a truncated version:
    // - make sure all elements have the same font size so that the line height is correct
    // - reset margin and padding to zero for all elements
    $nest: {
      "*": {
        fontSize: "1rem",
        margin: 0,
        padding: 0,
      },
    },
  }),
};
