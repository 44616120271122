import { classes, style } from "typestyle";

import { FC } from "react";
import { ReactComponent as crossIcon } from "../../../../assets/graphics/symbols/cross.svg";
import { ReactComponent as checkIcon } from "../../../../assets/graphics/symbols/checkmark.svg";

import { useAuthContext } from "../../../../state/authentication/authentication.context";
import { Button } from "../../../elements/button.element";
import { Form } from "../../../elements/form.element";
import { Header } from "../../../elements/header.element";
import { Input } from "../../../elements/input.element";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";

export interface IProps {
  onSubmit: (formData: FormData | null) => void;
  onCancelClick: () => void;
}

export const AddCollectionsForm: FC<IProps> = (props) => {
  const { session } = useAuthContext();

  return (
    <div
      {...{
        className: classes(styles.container),
      }}
    >
      <Header className={styles.headerText}>
        {dictionary.literals.newCollection}
      </Header>
      <>
        <Form onSubmit={props.onSubmit}>
          <input type={"hidden"} name={"ownerId"} value={session?.userId} />
          <Input
            attributes={{
              name: "name",
              type: "text",
              placeholder: dictionary.literals.name,
              required: true,
              className: styles.marginBottom,
            }}
          />
          <Button
            flavour={"primary"}
            icon={checkIcon}
            attributes={{
              type: "submit",
              className: styles.marginBottom,
            }}
          >
            <div className={styles.buttonText}>
              {dictionary.literals.confirm}
            </div>
          </Button>
          <Button
            flavour={"secondary"}
            icon={crossIcon}
            attributes={{
              onClick: () => props.onCancelClick(),
              className: styles.marginBottom,
            }}
          >
            {dictionary.literals.cancel}
          </Button>
        </Form>
      </>
    </div>
  );
};

const styles = {
  container: style({
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100%",
    width: "100%",
  }),
  marginBottom: style({ marginBottom: 10 }),
  icon: style({
    width: 25,
    height: 25,
    marginRight: 10,
  }),
  headerText: style({
    color: "rgb(var(--rgb-color-primair-basis))",
    textAlign: "center",
    margin: "25px 0",
  }),
  buttonIcon: style({
    width: 25,
  }),
  buttonText: style({
    fontSize: "0.95rem",
  }),
};
