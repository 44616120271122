import { FC, Fragment, ReactNode, useCallback, useState } from "react";
import { style, classes } from "typestyle";
import { ReactComponent as CheckmarkSvg } from "../../assets/graphics/symbols/checkmark.svg";
import { SvgIcon } from "./svg-icon.element";

export interface ICheckboxProps {
  attributes?: {
    isDefaultChecked?: boolean;
    onChange?: (isChecked: boolean) => void;
    required?: boolean;
    value?: string;
    name?: string;
  };
  label: ReactNode;
}
// TODO -- remove checkbox-group.component,
// on change: find closest form element and check if group items are required:
// if so change required to false
export const Checkbox: FC<ICheckboxProps> = (props) => {
  const [isChecked, setIsChecked] = useState<boolean>(
    props.attributes?.isDefaultChecked ?? false,
  );

  const handleOnClickToggle = useCallback(() => {
    const isCheckToggled = !isChecked;

    setIsChecked(isCheckToggled);
    props.attributes?.onChange?.(isCheckToggled);
  }, [isChecked, props]);

  return (
    <Fragment>
      {props.attributes?.name && (
        <input
          type="hidden"
          value={isChecked.toString()}
          name={props.attributes.name}
        />
      )}
      <div className={styles.checkboxContainer} onClick={handleOnClickToggle}>
        <div className={classes(styles.checkbox, isChecked && styles.checked)}>
          {isChecked === true && (
            <SvgIcon
              color="rgb(var(--rgb-color-white))"
              icon={CheckmarkSvg}
              width={16}
              height={16}
            />
          )}
        </div>
        {props.label}
      </div>
    </Fragment>
  );
};

export const checkboxSize = 24;

const styles = {
  checkboxContainer: style({
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    textAlign: "left",
    flex: 1,
    cursor: "pointer",
  }),
  checkbox: style({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: checkboxSize,
    minHeight: checkboxSize,
    width: checkboxSize,
    minWidth: checkboxSize,
    marginRight: 15,
    outline: "none",
    borderRadius: "var(--border-radius-small)",
    border: "2px solid rgb(var(--rgb-color-primair-basis))",
    appearance: "none",
    "-webkit-appearance": "none",
  }),
  checked: style({
    backgroundColor: "rgb(var(--rgb-color-primair-basis))",
    transition: "all 300ms",
  }),
};
