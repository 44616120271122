import { IExercise, LicenseType } from "@hulanbv/platformapp";
import { useCallback } from "react";
import { useAuthContext } from "../../authentication/authentication.context";

type Permission = {
  /**
   * Whether the user has permission to send exercise-invites.
   */
  hasSendExerciseInvitePermission: boolean;

  /**
   * Returns true if user has permissions to access the exercise.
   */
  hasViewExercisePermission: (exercise: IExercise) => boolean;
};

/**
 * Permissions are based on the current user in the auth context.
 *
 * @returns The current user's permissions.
 */
const usePermissions = (): Permission => {
  const { session } = useAuthContext();

  /**
   * Only practitioners with license type 'master', 'business' or 'pro' are allowed to send exercise invites.
   *
   */
  const license = session?.user?.license;
  const hasSendExerciseInvitePermission =
    !!license &&
    [
      LicenseType.BUSINESS,
      LicenseType.MASTER,
      LicenseType.IN_TRAINING,
      LicenseType.BUSINESS_IN_TRAINING,
      LicenseType.PRO,
    ].includes(license.type);

  /**
   *
   * If the exercise has the isDefaultLocked set, it's only visible if its in
   * the logged in user.eligbleExerciseIds
   *
   * @param exercise
   * @returns
   */
  const hasViewExercisePermission = useCallback(
    (exercise: IExercise) => {
      if (exercise.isDefaultLocked) {
        return !!session?.user?.eligbleExerciseIds?.includes(exercise.id);
      }

      return true;
    },
    [session?.user?.eligbleExerciseIds],
  );

  return {
    hasSendExerciseInvitePermission,
    hasViewExercisePermission,
  };
};

export { usePermissions };
