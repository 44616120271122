import {
  InputHTMLAttributes,
  PropsWithChildren,
  forwardRef,
  useRef,
  FunctionComponent,
  SVGProps,
} from "react";
import { classes, style } from "typestyle";
import { useFormIsDisabled } from "../../state/common/hooks/use-form-is-disabled";
import { ReactComponent as problemIcon } from "../../assets/graphics/symbols/problem.svg";
import { SvgIcon } from "./svg-icon.element";

export interface IInputProps {
  attributes?: InputHTMLAttributes<HTMLInputElement>;
  containerClassName?: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  iconColor?: string;
  error?: string;
}

export const Input = forwardRef<
  HTMLInputElement,
  PropsWithChildren<IInputProps>
>((props, ref) => {
  const localRef = useRef<HTMLInputElement | null>(null);
  const isFormDisabled = useFormIsDisabled(localRef);
  const isDisabled = isFormDisabled || props.attributes?.disabled;

  return (
    <div className={classes(styles.inputContainer, props.containerClassName)}>
      <input
        {...{
          ...props.attributes,
          disabled: isDisabled,
          className: classes(
            styles.input,
            props.attributes?.className,
            !!props.error && styles.inputError,
          ),
        }}
        ref={(node) => {
          localRef.current = node;

          if (!ref) {
            return;
          }

          if (typeof ref === "function") {
            ref(node);
          } else {
            // eslint-disable-next-line no-param-reassign -- need to re-assign ref if not a function
            ref.current = node;
          }
        }}
      ></input>
      <div className={styles.iconContainer}>
        {props.icon && !props.error && (
          <SvgIcon icon={props.icon} color={props.iconColor} />
        )}
        {props.error && <SvgIcon icon={problemIcon} />}
      </div>
    </div>
  );
});

export const styles = {
  inputContainer: style({
    position: "relative",
  }),
  input: style({
    fontFamily: "inherit",
    position: "relative",
    outline: "none",
    fontSize: "0.93rem",
    fontWeight: 400,
    color: "rgb(var(--rgb-color-black))",
    padding: "0px var(--spacing-horizontal-small)",
    height: 49,
    border: "1.5px solid transparent",
    borderRadius: "var(--border-radius-medium)",
    width: "100%",
    backgroundColor: "rgb(var(--rgb-color-gray-lighter))",
    $nest: {
      "&:focus": {
        border: "1.5px solid rgb(var(--rgb-color-primair-basis))",
      },
      "&:disabled": {
        pointerEvents: "none",
        backgroundColor: "rgb(232, 232, 232) !important",
      },
      "&::placeholder": {
        color: "rgba(var(--rgb-color-black), 0.5)",
      },
    },
  }),
  inputError: style({
    backgroundColor: "rgb(var(--rgb-color-dangerous-lighter)) !important",
    border: "1px solid rgb(var(--rgb-color-dangerous))",
    color: "rgb(var(--rgb-color-dangerous))",
  }),
  iconContainer: style({
    position: "absolute",
    display: "flex",
    alignItems: "center",
    right: 16,
    top: 0,
    bottom: 0,
  }),
};
