import { classes, style } from "typestyle";
import { ExerciseTag, IExercise } from "@hulanbv/platformapp";
import {
  FC,
  HTMLAttributes,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { ExerciseTypeListTile } from "../../elements/exercise-type-list-tile.element";
import { ExpandArrowDownImage } from "../../elements/expand-arrow-image.element";
import { exerciseColors } from "../../../constants/exercise-colors.constant";
import { IconButton } from "../../elements/icon-button.element";
import { ReactComponent as sendIcon } from "../../../assets/graphics/symbols/send.svg";
import { ReactComponent as eyeIcon } from "../../../assets/graphics/symbols/eye.svg";
import { Button } from "../../elements/button.element";
import { ExerciseTextualContent } from "../../elements/exercise-textual-content.element";
import { TableRow } from "../../elements/table-row.element";
import { dictionary } from "../../../state/common/constants/dictionary.constants";
import { useAuthContext } from "../../../state/authentication/authentication.context";
import { ReactComponent as lockedIcon } from "../../../assets/graphics/symbols/locked.svg";
import { ReactComponent as favoriteIcon } from "../../../assets/graphics/symbols/favorite.svg";
import { ReactComponent as removeIcon } from "../../../assets/graphics/symbols/trash.svg";
import { usePermissions } from "../../../state/common/hooks/use-permissions.hook";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  /** The exercise that needs to be shown */
  exercise: IExercise;
  /** Whenever a practioner adds a message for the client to be seen */
  message?: string;
  /** Subtext that will mainly contain the delivery/seen date of the exercise */
  subText?: JSX.Element | string;
  /** If set to true, the exercise content will be expanded by default */
  isDefaultExpanded?: boolean;
  /** If set to true, a separation border will be shown below the exercise */
  hasSeparationBorder?: boolean;
  /** Favorite button will not be shown and function will not be executed when exercise contains e-learning tag */
  onFavoriteClick?: (
    event: MouseEvent<HTMLButtonElement>,
  ) => Promise<void> | void;
  /** Function will not be executed when exercise is locked */
  onViewClick?: (event: MouseEvent<HTMLButtonElement>) => Promise<void> | void;
  /** Button will not be shown and function will not be executed when logged in user doesn't have the correct permissions  */
  onSendClick?: (event: MouseEvent<HTMLButtonElement>) => Promise<void> | void;
  /** Button will only be shown and function on the collection screen when a exercise is added to a collection. */
  onRemoveClick?: () => Promise<void> | void;
}

export const ExerciseRowTemplate: FC<IProps> = (props) => {
  const { session } = useAuthContext();
  const { exercise } = props;
  const [isExpanded, setIsExpanded] = useState(props.isDefaultExpanded);
  const { hasSendExerciseInvitePermission } = usePermissions();
  /**
   * Determine if exercise is locked depending on exercise and logged in user
   * details
   */
  const isExerciseLocked = useMemo(() => {
    const { tags, isDefaultLocked, id } = exercise;
    if (!isDefaultLocked || !tags.includes(ExerciseTag.ELEARNING)) {
      return false;
    }

    if (session?.user?.eligbleExerciseIds?.includes(id)) {
      return false;
    }

    return true;
  }, [exercise, session?.user?.eligbleExerciseIds]);

  const onClickExpand = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      setIsExpanded(!isExpanded);
      props.attributes?.onClick?.(event);
    },
    [isExpanded, props.attributes],
  );

  const attributes: HTMLAttributes<HTMLDivElement> = useMemo(
    () => ({
      ...props.attributes,
      className: classes(props.attributes, styles.tableRow),
      onClick: onClickExpand,
      ...(isExpanded && {
        style: { backgroundColor: exerciseColors[exercise.type] },
      }),
    }),
    [exercise.type, isExpanded, onClickExpand, props.attributes],
  );

  return (
    <>
      <TableRow attributes={attributes}>
        <ExerciseTypeListTile exerciseType={exercise.type} />
        <div className={styles.rowContent}>
          <div
            className={classes(
              styles.title,
              isExpanded && styles.titleExpanded,
            )}
          >
            {exercise.name}
          </div>
          <div className={styles.expandArrow}>
            <ExpandArrowDownImage isExpanded={isExpanded} />
          </div>
        </div>
      </TableRow>
      {isExpanded && (
        <>
          <ExerciseTextualContent
            exercise={exercise}
            message={props.message}
            subText={props.subText}
            className={styles.exerciseTextualContent}
            shouldTruncateDescription
          />
          <div
            className={classes(
              styles.actionBar,
              (props.hasSeparationBorder ?? true) && styles.separationBorder,
            )}
            style={{
              backgroundColor: exerciseColors[exercise.type],
            }}
          >
            {props.onFavoriteClick &&
              !props.exercise.tags.includes(ExerciseTag.ELEARNING) && (
                <IconButton
                  icon={favoriteIcon}
                  attributes={{
                    onClick: props.onFavoriteClick,
                  }}
                />
              )}
            {props.onRemoveClick &&
              !props.exercise.tags.includes(ExerciseTag.ELEARNING) && (
                <IconButton
                  icon={removeIcon}
                  attributes={{
                    onClick: props.onRemoveClick,
                  }}
                />
              )}
            {props.onSendClick && hasSendExerciseInvitePermission && (
              <IconButton
                icon={sendIcon}
                attributes={{
                  onClick: props.onSendClick,
                }}
              />
            )}
            {props.onViewClick && (
              <Button
                flavour="primary"
                icon={isExerciseLocked ? lockedIcon : eyeIcon}
                attributes={{
                  ...(isExerciseLocked && {
                    className: styles.lockedButton,
                  }),
                  ...(!isExerciseLocked && {
                    onClick: props.onViewClick,
                  }),
                }}
                centerChildren
              >
                {dictionary.literals.view}
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
};

const descriptionContentPadding = 42;

const styles = {
  tableRow: style({
    display: "flex",
    flexDirection: "row",
    padding: "unset",
  }),
  rowContent: style({
    display: "flex",
    flex: 1,
    padding: 20,
    cursor: "pointer",
    overflow: "hidden",
  }),
  title: style({
    fontSize: "1.125rem",
    fontWeight: 600,
    margin: "auto 0",
    alignItems: "center",
    flex: "4 1 0",
  }),
  expandArrow: style({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 1 0",
  }),
  exerciseTextualContent: style({
    paddingLeft: descriptionContentPadding,
    paddingRight: descriptionContentPadding,
  }),
  titleExpanded: style({
    color: "rgb(var(--rgb-color-white))",
    textShadow: "0px 1px 10px rgba(0,0,0,0.4)",
  }),
  actionBar: style({
    display: "flex",
    padding: `22px ${descriptionContentPadding}px`,
    gap: 12,
  }),
  separationBorder: style({
    borderBottom: "3px solid rgba(0,0,0,0.2)",
  }),
  lockedButton: style({
    cursor: "none",
    backgroundColor: "rgb(var(--rgb-color-black))",
  }),
};
