import { IUser, Role } from "@hulanbv/platformapp";
import { IHttpOptions } from "nest-utilities-client";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { style } from "typestyle";
import { ReactComponent as bookIcon } from "../../assets/graphics/symbols/book.svg";
import { ReactComponent as mapIcon } from "../../assets/graphics/symbols/map.svg";
import { ReactComponent as internetIcon } from "../../assets/graphics/symbols/internet.svg";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { routes } from "../../state/common/constants/routes.constants";
import { useUrlState } from "../../state/common/hooks/url-state-hook/use-url-state.hook";
import { userService } from "../../state/user/user.service";
import { urlUtils } from "../../utils/url.utils";
import { ActionBar } from "../elements/action-bar.element";
import { Button } from "../elements/button.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { Table } from "../elements/table.element";
import { MapUserTemplate } from "../templates/maps/map-user.template";
import { PractitionerRowTemplate } from "../templates/table-rows/practitioner-row.template";
import { branding } from "../../constants/branding.constants";
import { TableHeadTemplate } from "../templates/table-head.template";
import { Flex } from "../elements/flex.element";
import { Description } from "../elements/description.element";
import { Header } from "../elements/header.element";
import { Anchor } from "../elements/anchor.element";

type Tab = "list" | "map" | "external-search";

export const PractitionerSearchScreen: FC = () => {
  const params = useParams<{ tab: Tab }>();

  const [searchParamValue, setSearchParamValue] =
    useUrlState<string>("search-filter");

  const httpOptions: IHttpOptions<IUser> = useMemo(
    () => ({
      match: {
        ...(searchParamValue &&
          urlUtils.createSearchQuery(searchParamValue, [
            "name",
            "city",
            "lastName",
          ])),
        role: Role.PRACTITIONER,
        isMaster: true,
        companyGeolocation: { $exists: true },
      },
    }),
    [searchParamValue],
  );

  const history = useHistory();

  const onRowClick = useCallback(
    (practitionerId: string) => {
      history.push(routes.profile.path.replace(":userId", practitionerId));
    },
    [history],
  );

  useEffect(() => {
    if (branding.features?.isMapEnabled === true && !params.tab) {
      history.replace(routes.search.path.replace(":tab?", "map"));
    }

    if (!branding.features?.isMapEnabled && !params.tab) {
      history.replace(routes.search.path.replace(":tab?", "external-search"));
    }
  }, [params.tab, history]);

  const navigateToTab = (tab: Tab) =>
    history.push(routes.search.path.replace(":tab?", tab));

  return (
    <Page hideGoBack>
      <PageBody fullWidth className={styles.pageBody}>
        {branding.features?.isMapEnabled === true && (
          <ActionBar
            actions={[
              {
                isActive: params.tab === "map",
                icon: mapIcon,
                onClick: () => navigateToTab("map"),
              },
              {
                isActive: params.tab === "list",
                icon: bookIcon,
                onClick: () => navigateToTab("list"),
              },
              ...(branding.features?.externalSearch
                ? [
                    {
                      isActive: params.tab === "external-search",
                      icon: internetIcon,
                      onClick: () => navigateToTab("external-search"),
                    },
                  ]
                : []),
            ]}
          />
        )}
        {params.tab !== "external-search" && (
          <TableHeadTemplate
            searchInputAttributes={{
              defaultValue: searchParamValue ?? "",
              onChange: (event) => setSearchParamValue(event.target.value),
            }}
          />
        )}
        {params.tab === "map" && branding.features?.isMapEnabled === true && (
          <div className={styles.mapContainer}>
            <MapUserTemplate httpOptions={httpOptions} />
            {branding.restrictions?.canRegisterAsProfessional !== false && (
              <div className={styles.professionalButtonContainer}>
                <Button
                  attributes={{
                    className: styles.professionalButton,
                    onClick: () =>
                      history.push(routes.registerPractitioner.path),
                  }}
                >
                  {dictionary.texts.areYouAProfessional}
                </Button>
              </div>
            )}
          </div>
        )}
        {params.tab === "list" && branding.features?.isMapEnabled === true && (
          <Table<IUser>
            rowTemplate={(user) => (
              <PractitionerRowTemplate
                user={user}
                key={user._id}
                onViewClick={() => onRowClick(user._id)}
              />
            )}
            service={userService}
            options={httpOptions}
            emptyContent={
              <Flex>{dictionary.literals.practitionersNotFound}</Flex>
            }
          />
        )}
        {params.tab === "external-search" &&
          branding.features?.externalSearch && (
            <div className={styles.linkContainer}>
              <Header size="big">
                {branding.features.externalSearch.title}
              </Header>
              <Description>{branding.features.externalSearch.body}</Description>
              <Anchor href={branding.features.externalSearch.url}>
                <Button centerChildren>{dictionary.literals.goToLink}</Button>
              </Anchor>
            </div>
          )}
      </PageBody>
    </Page>
  );
};

const styles = {
  pageBody: style({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flex: 1,
    paddingBottom: 0,
  }),
  mapContainer: style({
    position: "relative",
    display: "flex",
    flex: 1,
  }),
  professionalButtonContainer: style({
    position: "absolute",
    bottom: 35,
    left: "var(--spacing-horizontal-small)",
    right: "var(--spacing-horizontal-small)",
  }),
  professionalButton: style({
    textAlign: "center",
  }),
  linkContainer: style({
    display: "flex",
    flexDirection: "column",
    gap: "var(--spacing-vertical-regular)",
    padding: "var(--spacing-horizontal-regular)",
    height: "100%",
  }),
};
