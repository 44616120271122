import { LicenseType } from "@hulanbv/platformapp";
import { FC, useEffect } from "react";
import { useHistory } from "react-router";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { ReactComponent as clientIcon } from "../../assets/graphics/symbols/client.svg";
import { ReactComponent as groupIcon } from "../../assets/graphics/symbols/group.svg";
import { ActionBar } from "../elements/action-bar.element";
import { ClientsTableTemplate } from "../templates/tables/clients-table.template";
import { useUrlState } from "../../state/common/hooks/url-state-hook/use-url-state.hook";
import { useModalContext } from "../../state/common/contexts/modal.context";
import { RestrictedAreaForm } from "../templates/forms/common/restricted-area-form.template";
import { Header } from "../elements/header.element";
import { ClientGroupsTableTemplate } from "../templates/tables/client-groups-table.template";
import { dictionary } from "../../state/common/constants/dictionary.constants";

export const ClientsScreen: FC = () => {
  const { session } = useAuthContext();
  const history = useHistory();
  const { openModal } = useModalContext();

  // there are two tabs in this view, the client and client-groups tab
  // switching between those will set the tab param value
  const [tabParamValue, setTabParamValue] = useUrlState<
    "clients" | "client-groups"
  >("tab");

  const isClientTabActive = !tabParamValue || tabParamValue === "clients";

  useEffect(() => {
    // Whenever a user has a license with type LicenseType.START:
    // Show a popup to the user to tell them that they have no access to this page.
    const license = session?.user?.license;
    if (
      !license ||
      ![
        LicenseType.BUSINESS,
        LicenseType.MASTER,
        LicenseType.IN_TRAINING,
        LicenseType.BUSINESS_IN_TRAINING,
        LicenseType.PRO,
      ].includes(license.type)
    ) {
      openModal((resolve) => (
        <RestrictedAreaForm
          onSubmit={() => {
            // Whenever the user clicks on the back button, navigate to previous page
            history.goBack();
            // and close the modal
            resolve(null);
          }}
        >
          <Header center>{dictionary.literals.lookOut}!</Header>
          <p>{dictionary.texts.usingStartLicense}</p>
          <p>{dictionary.texts.masterOrProLicenseSuggestion}</p>
        </RestrictedAreaForm>
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on render
  }, []);

  return (
    <Page>
      <PageBody fullWidth>
        <ActionBar
          actions={[
            {
              icon: clientIcon,
              onClick: () => setTabParamValue("clients"),
              isActive: isClientTabActive === true,
            },
            {
              icon: groupIcon,
              onClick: () => setTabParamValue("client-groups"),
              isActive: isClientTabActive === false,
            },
          ]}
        />

        {isClientTabActive === true && <ClientsTableTemplate />}
        {isClientTabActive === false && <ClientGroupsTableTemplate />}
      </PageBody>
    </Page>
  );
};
