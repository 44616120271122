import { FC, HTMLAttributes } from "react";
import { classes, style } from "typestyle";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
}

export const BoxShadowHead: FC<IProps> = (props) => (
  <div
    {...props.attributes}
    className={classes(styles.container, props.attributes?.className)}
  >
    {props.children}
  </div>
);

const styles = {
  container: style({
    position: "relative",
    padding: "0 20px 20px",
    // only show box shadow at the bottom of the table head
    boxShadow:
      "0px 10px 10px -10px rgba(var(--rgb-color-primair-lighter), 0.15)",
  }),
};
