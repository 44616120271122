import { CSSProperties, FC, FunctionComponent, SVGProps } from "react";
import { style } from "typestyle";

type IProps = {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  attributes?: SVGProps<SVGSVGElement>;
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  color?: CSSProperties["color"];
};

export const SvgIcon: FC<IProps> = ({
  // eslint-disable-next-line @typescript-eslint/naming-convention -- needed to render component
  icon: SvgIcon,
  attributes,
  width,
  height,
  color,
}) => (
  <div className={styles.icon} style={{ width, height, color }}>
    <SvgIcon {...attributes} width={"100%"} height={"100%"} />
  </div>
);

const styles = {
  icon: style({
    width: "var(--icon-size)",
    height: "var(--icon-size)",
    color: "rgb(var(--rgb-color-white))",
  }),
};
