export const stringUtils = new (class StringUtils {
  /**
   * Returns single string with the first letter capitalized
   * @param enumArray
   * @param enumArray
   */
  public capitalizeFirstLetter(value: string): string {
    return value && value[0].toUpperCase() + value.slice(1).toLowerCase();
  }

  /**
   *
   * Only if max characters is reached this function will return a truncated string
   * The truncated string ends with three dots
   *
   * @param value
   * @param maxCharacters
   * @returns truncated string with dots at the end
   */
  public truncate(value: string, maxCharacters: number): string {
    if (!value || !value.length) {
      return "";
    }

    if (value.length < maxCharacters) {
      return value;
    }

    return `${value.slice(0, maxCharacters)}...`;
  }
})();
