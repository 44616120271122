import { CSSProperties, ReactNode, useMemo } from "react";

type Props = {
  children?: ReactNode;
  grow?: number;
  shrink?: number;
  basis?: string;
  order?: number;
  alignSelf?: CSSProperties["alignSelf"];
  $debug?: boolean;
};
const FlexItem = (props: Props): JSX.Element => {
  const style = useMemo<CSSProperties>(() => {
    const style: CSSProperties = {
      flexGrow: props.grow,
      flexShrink: props.shrink,
      flexBasis: props.basis,
      order: props.order,
      alignSelf: props.alignSelf,
      border: props.$debug === true ? "1px dashed black" : undefined,
    };
    return style;
  }, [props]);
  return <div style={style}>{props.children}</div>;
};
export { FlexItem };
