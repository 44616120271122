import { FC, useCallback } from "react";
import { style } from "typestyle";
import { ICollection, IExerciseInvite } from "@hulanbv/platformapp";
import { generatePath, useHistory } from "react-router-dom";
import { ReactComponent as timelineIcon } from "../../assets/graphics/symbols/temple.svg";
import { routes } from "../../state/common/constants/routes.constants";
import { Button } from "../elements/button.element";
import { exerciseCollectionService } from "../../state/exercise/exercise-collection.service";
import Toast from "../statics/toast";
import { SelectCollectionsModal } from "./modals/select-collections-modal.template";
import { useModalContext } from "../../state/common/contexts/modal.context";
import { SeenAt } from "../elements/seen-at/seen-at.element";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { ExerciseRowTemplate } from "./table-rows/exercise-row.template";

interface IProps {
  exerciseInvite: IExerciseInvite;
}

export const ClientTimelinePreview: FC<IProps> = (props) => {
  const history = useHistory();
  const { exercise, message } = props.exerciseInvite;
  const { openModal } = useModalContext();

  const handleFavouriteClick = useCallback(async () => {
    try {
      await openModal<ICollection[] | null>((resolve) => (
        <SelectCollectionsModal
          onSubmit={async (collectionIds) => {
            if (!collectionIds?.length) {
              resolve(null);
              return;
            }

            const collections = await Promise.all(
              collectionIds.map((id) =>
                exerciseCollectionService.addToCollection([exercise?._id], id),
              ),
            );
            Toast.info({
              body: dictionary.texts.collectionAddSuccess,
            });
            resolve(collections);
          }}
        />
      ));
    } catch {
      Toast.error({
        body: dictionary.texts.collectionAddError,
      });
    }
  }, [exercise?._id, openModal]);

  const handleViewClick = useCallback(() => {
    history.push(
      generatePath(routes.exerciseInvite.path, {
        exerciseInviteId: props.exerciseInvite._id,
      }),
    );
  }, [history, props.exerciseInvite._id]);

  if (!exercise) {
    return <></>;
  }

  return (
    <>
      <ExerciseRowTemplate
        hasSeparationBorder={false}
        isDefaultExpanded
        exercise={exercise}
        message={message}
        {...(props.exerciseInvite.createdAt && {
          subText: props.exerciseInvite.seenAt ? (
            <SeenAt
              sentAt={props.exerciseInvite.createdAt}
              seenAt={props.exerciseInvite.seenAt}
            />
          ) : (
            <SeenAt sentAt={props.exerciseInvite.createdAt} />
          ),
        })}
        onFavoriteClick={handleFavouriteClick}
        onViewClick={handleViewClick}
      />

      <div className={styles.buttonContainer}>
        <Button
          icon={timelineIcon}
          attributes={{ onClick: () => history.push(routes.timeline.path) }}
        >
          {dictionary.literals.timeline}
        </Button>
      </div>
    </>
  );
};

const styles = {
  padding: style({
    padding: 35,
  }),
  tile: style({
    color: "rgb(var(--rgb-color-white))",
  }),
  buttonContainer: style({
    display: "flex",
    flexDirection: "column",
    margin: "0 var(--spacing-horizontal-regular)",
    marginTop: 20,
  }),
  button: style({
    marginBottom: 17,
  }),
  exercisePreview: style({
    marginBottom: 12,
    borderRadius: "var(--border-radius-large)",
    overflow: "hidden",
  }),
};
