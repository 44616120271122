import { useById } from "@hulanbv/nest-utilities-client-state";
import { FC, useCallback } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import { style } from "typestyle";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { questionnaireService } from "../../state/questionnaire/questionnaire.service";
import { Button } from "../elements/button.element";
import { Card } from "../elements/card.element";
import { Flex } from "../elements/flex.element";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { ReactComponent as fastForwardIcon } from "../../assets/graphics/symbols/fast-forward.svg";
import { routes } from "../../state/common/constants/routes.constants";

export const QuestionnaireEndScreen: FC = () => {
  const history = useHistory();
  const params = useParams<{
    exerciseInviteId: string;
    questionnaireId: string;
  }>();
  const { data: questionnaire } = useById(
    questionnaireService,
    params.questionnaireId,
  );

  const navigateToExercise = useCallback(() => {
    const exercisePath = generatePath(routes.exerciseInvite.path, {
      exerciseInviteId: params.exerciseInviteId,
    });
    history.replace(exercisePath);
  }, [history, params.exerciseInviteId]);

  return (
    <Page hideGoBack className={styles.page}>
      <PageBody fullHeight>
        {questionnaire && (
          <Card header={<Header size="regular">{questionnaire.title}</Header>}>
            <Flex direction="column" alignItems="stretch">
              <div>
                {dictionary.texts.endQuestionnaireText(questionnaire.title)}!
              </div>
              <Button
                icon={fastForwardIcon}
                attributes={{ onClick: navigateToExercise }}
              >
                {dictionary.texts.endQuestionnaireButtonText}
              </Button>
            </Flex>
          </Card>
        )}
      </PageBody>
    </Page>
  );
};

const styles = {
  page: style({
    backgroundImage:
      "linear-gradient(180deg, rgba(var(--rgb-color-primair-shade-4), 0.15), rgba(var(--rgb-color-primair-basis), 0.15))",
  }),
};
