import { Role } from "@hulanbv/platformapp";
import { localStorageUtils } from "../../utils/local-storage.utils";
import { authenticationService } from "../authentication/authentication.service";

export interface IUserDetails {
  lastActiveChallengeIndex: number;
  lastActiveChallengeVisitedAt?: Date;
}

export const userUtils = new (class UserUtils {
  private usersStorageKey = "usersStorage";

  /**
   * Initializes the local storage userStore with some default values
   */
  initializeUserStore() {
    const user = authenticationService.getSession()?.user;
    const localStorageDetails = this.getLocalStorageDetails(user?._id);
    const lastStoredIndex = localStorageDetails?.lastActiveChallengeIndex;
    // if no challengeIds are set and the logged in user has role type user:
    // initialize the localstorage file
    if (user?.role === Role.USER && lastStoredIndex === undefined) {
      this.setLocalStorageDetails(user?._id, {
        lastActiveChallengeIndex: 0,
        ...localStorageDetails,
      });
    }
  }

  /**
   * Returns the saved userStorage for userId
   *
   * @param userId
   * @returns
   */
  getLocalStorageDetails(userId: string): IUserDetails | undefined {
    const usersStorage = localStorageUtils.getItem<
      Record<string, IUserDetails>
    >(this.usersStorageKey);
    // First convert the storage item back to a map, before getting the details for the specific userId.
    // Each userId will be seperately saved in a map to prevent data loss (overwriting of the localstorage data)
    // whenever a new user logs in on the same device
    return usersStorage?.[userId];
  }

  /**
   * Sets the user details for a specific userId in the localstorage
   *
   * @param userId
   * @param details
   */
  setLocalStorageDetails(userId: string, details: IUserDetails): IUserDetails {
    const usersStorage =
      localStorageUtils.getItem<Record<string, IUserDetails>>(
        this.usersStorageKey,
      ) ?? {};

    usersStorage[userId] = { ...usersStorage?.[userId], ...details };

    localStorageUtils.setItem(this.usersStorageKey, usersStorage);

    return usersStorage[userId];
  }
})();
