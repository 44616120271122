import { IUser, Role } from "@hulanbv/platformapp";
import { FC, FormHTMLAttributes, useCallback } from "react";

import { style } from "typestyle";
import { Button } from "../../../elements/button.element";
import { Checkbox } from "../../../elements/checkbox.element";
import { Form } from "../../../elements/form.element";
import { Input } from "../../../elements/input.element";
import { Row } from "../../../elements/row.element";
import { InjectionService } from "../../../../state/common/injection.service";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";
import { branding } from "../../../../constants/branding.constants";

interface IProps {
  attributes?: Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit">;
  onSubmit?: (
    formData: FormData,
    shouldSubcribeToNewsletter: boolean,
  ) => void | Promise<void>;
  user: IUser;
}

const checkboxNewsletterName = "newsletter-subscription";

export const RegisterPractitionerForm: FC<IProps> = (props) => {
  const handleOnSubmit = useCallback(
    (formData: FormData) => {
      const shouldSubscribeToNewsletter: boolean =
        formData.get(checkboxNewsletterName) === "on";

      // do not submit checkbox value
      formData.delete(checkboxNewsletterName);

      props.onSubmit?.(formData, shouldSubscribeToNewsletter);
    },
    [props],
  );

  return (
    <Form {...props.attributes} onSubmit={handleOnSubmit}>
      <Input
        attributes={{
          name: "_id",
          type: "hidden",
          required: true,
          value: props.user._id,
        }}
      />
      <Input
        attributes={{
          name: "role",
          type: "hidden",
          required: true,
          value: Role.PRACTITIONER,
        }}
      />
      <Row label={`${dictionary.literals.firstName} *`}>
        <Input
          attributes={{
            name: "name",
            type: "text",
            placeholder: dictionary.literals.firstName,
            required: true,
            defaultValue: props.user?.name,
          }}
        />
      </Row>
      <Row label={`${dictionary.literals.lastName} *`}>
        <Input
          attributes={{
            name: "lastName",
            type: "text",
            placeholder: dictionary.literals.lastName,
            required: true,
            defaultValue: props.user?.lastName,
          }}
        />
      </Row>
      <Row label={`${dictionary.literals.cityOfResidence} *`}>
        <Input
          attributes={{
            name: "city",
            type: "text",
            placeholder: dictionary.literals.cityOfResidence,
            required: true,
            defaultValue: props.user?.city,
          }}
        />
      </Row>
      <Row>
        <Checkbox
          label={
            <div>
              {dictionary.texts.confirmAgreement(
                <span
                  className={styles.text}
                  onClick={() =>
                    InjectionService.openWebView(
                      branding.urls?.termsAndConditions ??
                        "https://hulan.nl/legal",
                    )
                  }
                  key={0}
                >
                  {dictionary.literals.licenseAgreement.toLowerCase()}
                </span>,
              )}
            </div>
          }
          attributes={{ required: true }}
        />
      </Row>
      <Row>
        <Checkbox
          label={<div>{dictionary.texts.confirmNewsletterSubscription}</div>}
          attributes={{ name: checkboxNewsletterName }}
        />
      </Row>
      <Row>
        <Button
          attributes={{ type: "submit", className: styles.button }}
          centerChildren
        >
          {dictionary.texts.confirmRegisterTrial(30)}
        </Button>
      </Row>
    </Form>
  );
};

const styles = {
  button: style({
    placeContent: "center",
  }),
  text: style({
    display: "inline-block",
    cursor: "pointer",
    color: "rgb(var(--rgb-color-primair-basis))",
  }),
  externalLink: style({
    textAlign: "center",
    color: "rgb(var(--rgb-color-black))",
  }),
};
