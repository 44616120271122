import { FC, useMemo } from "react";
import { style } from "typestyle";

interface IProps {
  xLabels?: string[];
  yLabels?: string[];
  data: {
    color: string;
    label: string;
    values: number[];
  }[];
}

export const LineChart: FC<IProps> = (props) => {
  const incrementor = useMemo(() => {
    const highestNumber = Math.max(
      ...props.data.reduce<number[]>(
        (previous, current) => previous.concat(current.values),
        [],
      ),
    );

    const highestRounded = Math.ceil(highestNumber / 10) * 10;
    const increment = Math.ceil(highestRounded / 5 / 10) * 10;

    return increment;
  }, [props.data]);

  const maxValueLength = useMemo(
    () => Math.max(...props.data.map((data) => data.values.length)),
    [props.data],
  );

  const xAxisLabels: string[] = useMemo(() => {
    if (props.xLabels !== undefined) {
      return props.xLabels;
    }

    return Array(maxValueLength)
      .fill(0)
      .map((x, index) => `${index + 1}`);
  }, [maxValueLength, props.xLabels]);

  const yAxisLabels: (number | string)[] = useMemo(() => {
    if (props.yLabels !== undefined) {
      return props.yLabels;
    }

    return Array(6)
      .fill(0)
      .map((x, index) => index * (incrementor || 20));
  }, [incrementor, props.yLabels]);

  const chartPoints = useMemo(
    () =>
      props.data.map((data) =>
        data.values.map((value, index) => {
          const x =
            index * ((width - padding * 2) / (maxValueLength - 1)) + padding;

          const y =
            height -
            Math.max(value * (height - padding * 2), 1) /
              Math.max((yAxisLabels.length - 1) * incrementor, 1) -
            padding;

          return `${x}, ${y}`;
        }),
      ),
    [incrementor, maxValueLength, props.data, yAxisLabels.length],
  );

  return (
    <svg viewBox={`0 0 ${width} ${svgHeight}`} className={styles.graph}>
      {/* Y axis labels and line for graph */}
      {yAxisLabels.map((x, index) => (
        <polyline
          fill="none"
          stroke="rgba(var(--rgb-color-gray),0.5)"
          strokeWidth="1"
          key={index}
          points={`${padding}, ${
            height -
            index * ((height - padding) / (yAxisLabels.length ?? 0)) -
            padding
          } ${width - padding}, ${
            height -
            index * ((height - padding) / (yAxisLabels.length ?? 0)) -
            padding
          }`}
        />
      ))}
      {/* X axis labels and line for graph */}
      {xAxisLabels.map((x, index) => (
        <text
          x={index * ((width - padding * 2) / (maxValueLength - 1)) + padding}
          y={height - padding + 10}
          key={index}
          dominantBaseline="middle"
          fontSize="12"
          fill="rgb(var(--rgb-color-black))"
          transform={`rotate(40, ${
            index * ((width - padding * 2) / (maxValueLength - 1)) + padding
          }, ${height - padding + 10})`}
        >
          {x}
        </text>
      ))}
      {/* Y axis labels and line for graph */}
      <polyline
        fill="none"
        stroke="rgba(var(--rgb-color-gray),0.5)"
        strokeWidth="2"
        points={`${padding}, ${padding} ${padding}, ${height - padding}`}
      />
      <polyline
        fill="none"
        stroke="rgba(var(--rgb-color-gray),0.5)"
        strokeWidth="1"
        points={`${width - padding}, ${padding} ${width - padding}, ${
          height - padding
        }`}
      />
      {yAxisLabels.map((y, index) => (
        <text
          x={padding - 20}
          y={
            height -
            index * ((height - padding) / (yAxisLabels.length ?? 0)) -
            padding
          }
          key={index}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="12"
          fill="rgb(var(--rgb-color-black))"
        >
          {y}
        </text>
      ))}

      {/* Graph lines */}
      {chartPoints.map((points, index) => (
        <polyline
          fill="none"
          stroke={props.data[index].color}
          strokeWidth="3"
          points={points.join(" ")}
          key={index}
        />
      ))}

      {/* Graph points */}
      {chartPoints.map((points, chartPointsIndex) =>
        points.map((point, index) => (
          <circle
            cx={point.split(",")[0]}
            cy={point.split(",")[1]}
            r="5"
            fill={props.data[chartPointsIndex].color}
            key={index}
          />
        )),
      )}

      {/* Legenda to see which identifier is which */}
      {props.data.map((data, index) => (
        <g
          key={index}
          transform={`translate(${padding}, ${index * 25 + height})`}
        >
          <line
            x1="0"
            y1="10"
            x2="20"
            y2="10"
            stroke={data.color}
            strokeWidth="3"
          />
          <circle cx="10" cy="10" r="5" fill={data.color} />
          <text x="30" y="15" fontSize="15">
            {data.label}
          </text>
        </g>
      ))}
    </svg>
  );
};

const padding = 50;
const width = 450;
const height = 350;
const svgHeight = 400;

const styles = {
  graph: style({
    width: "100%",
    height: "100%",
  }),
};
