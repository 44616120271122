import { IExerciseInvite } from "@hulanbv/platformapp";
import { IHttpOptions, ModelQuery } from "nest-utilities-client";
import { FC, useCallback, useMemo } from "react";
import { generatePath, useHistory } from "react-router";
import { useUrlState } from "../../../../state/common/hooks/url-state-hook/use-url-state.hook";
import { urlUtils } from "../../../../utils/url.utils";
import { Table } from "../../../elements/table.element";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";
import { TableHeadTemplate } from "../../table-head.template";
import { exerciseInviteService } from "../../../../state/exercise-invite/exercise-invite.service";
import { QuestionnaireRowTemplate } from "../../table-rows/questionnaire-row.template";
import { routes } from "../../../../state/common/constants/routes.constants";

interface IProps {
  clientId: string;
  title?: string;
  match?: ModelQuery<IExerciseInvite>;
}

export const ClientQuestionnairesTable: FC<IProps> = (props: IProps) => {
  const [searchParamValue, setSearchParamValue] =
    useUrlState<string>("search-filter");
  const history = useHistory();

  const memoizedHttpOptions: IHttpOptions<IExerciseInvite> = useMemo(
    () => ({
      match: {
        $and: [
          {
            inviteeId: props.clientId,
            "exercise.questionnaireId": { $exists: true },
            ...(searchParamValue &&
              urlUtils.createSearchQuery(searchParamValue, [
                "exercise.questionnaire.title",
              ])),
          },
          props.match ?? {},
        ],
      },
      populate: ["exercise.questionnaire"],
      sort: ["-createdAt"],
    }),
    [props.clientId, props.match, searchParamValue],
  );

  const questionnaireRowTemplate = useCallback(
    (invite: IExerciseInvite) => {
      const questionnaire = invite.exercise?.questionnaire;
      if (!questionnaire) {
        return <></>;
      }

      return (
        <QuestionnaireRowTemplate
          questionnaire={questionnaire}
          inviteDate={invite.createdAt}
          key={questionnaire._id}
          onViewClick={() => {
            const path = generatePath(routes.questionnaireResults.path, {
              questionnaireId: questionnaire.id,
              userId: invite.inviteeId,
              exerciseInviteId: invite.id,
            });
            history.push(path);
          }}
        />
      );
    },
    [history],
  );

  return (
    <div>
      <TableHeadTemplate
        header={props.title ?? dictionary.literals.questionnaires}
        headerAttributes={{ center: false }}
        searchInputAttributes={{
          defaultValue: searchParamValue ?? "",
          onChange: (event) => setSearchParamValue(event.target.value),
        }}
      />
      <Table<IExerciseInvite>
        service={exerciseInviteService}
        options={memoizedHttpOptions}
        rowTemplate={questionnaireRowTemplate}
      />
    </div>
  );
};
