import { ScheduleTiming } from "@hulanbv/platformapp";
import { dictionary } from "./dictionary.constants";

const ScheduleTimingLabels: Record<ScheduleTiming, string> = {
  [ScheduleTiming.ONCE]: dictionary.literals.once,
  [ScheduleTiming.DAILY]: dictionary.literals.daily,
  [ScheduleTiming.WEEKLY]: dictionary.literals.weekly,
  [ScheduleTiming.MONTHLY]: dictionary.literals.monthly,
};

export { ScheduleTimingLabels };
