import { FC } from "react";
import { IUser } from "@hulanbv/platformapp";
import { TableRow } from "../../elements/table-row.element";
import { TableRowContent } from "../../elements/table-row-content.element";
import { ReactComponent as eyeIcon } from "../../../assets/graphics/symbols/eye.svg";
import { ReactComponent as trashIcon } from "../../../assets/graphics/symbols/trash.svg";
import { IconButton } from "../../elements/icon-button.element";

type Props = {
  user: IUser;
  onRemoveClick?: () => Promise<void> | void;
  onViewClick?: () => Promise<void> | void;
};

export const ClientRowTemplate: FC<Props> = (props) => (
  <TableRow>
    <TableRowContent
      header={`${props.user.name} ${props.user.lastName ?? ""}`}
      subHeader={props.user.email}
      controls={
        <>
          {props.onViewClick && (
            <IconButton
              icon={eyeIcon}
              attributes={{ onClick: props.onViewClick }}
            />
          )}
          {props.onRemoveClick && (
            <IconButton
              icon={trashIcon}
              flavour="danger"
              attributes={{ onClick: props.onRemoveClick }}
            />
          )}
        </>
      }
    />
  </TableRow>
);
