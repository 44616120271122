import { IExerciseInvite, IUser, ScheduleTiming } from "@hulanbv/platformapp";
import { FC, useCallback, useMemo, useRef } from "react";
import { useHistory } from "react-router";
import { useAuthContext } from "../../../../state/authentication/authentication.context";
import { exerciseInviteService } from "../../../../state/exercise-invite/exercise-invite.service";
import { urlUtils } from "../../../../utils/url.utils";
import { Table, TableActions } from "../../../elements/table.element";
import { useUrlState } from "../../../../state/common/hooks/url-state-hook/use-url-state.hook";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";
import { TableHeadTemplate } from "../../table-head.template";
import { ScheduledExerciseInviteRowTemplate } from "../../table-rows/scheduled-exercise-invite-row.template";
import { useModalContext } from "../../../../state/common/contexts/modal.context";
import { ConfirmModal } from "../../modals/confirm-modal.template";
import { ReactComponent as crossIcon } from "../../../../assets/graphics/symbols/cross.svg";
import { ReactComponent as checkIcon } from "../../../../assets/graphics/symbols/checkmark.svg";
import Toast from "../../../statics/toast";
import { routes } from "../../../../state/common/constants/routes.constants";

interface IProps {
  client: IUser;
}

export const ClientScheduledExerciseInvitesTable: FC<IProps> = (props) => {
  const tableActionsRef = useRef<TableActions>(null);
  const { session } = useAuthContext();
  const history = useHistory();
  const { openModal } = useModalContext();
  const [searchParamValue, setSearchParamValue] =
    useUrlState<string>("search-filter");

  const httpOptions = useMemo(
    () => ({
      match: {
        senderId: session?.userId,
        inviteeId: props.client.id,
        ...(searchParamValue &&
          urlUtils.createSearchQuery(searchParamValue, ["exercise.name"])),
        $or: [
          // only show invites with ScheduleTiming.ONCE if they are still need to be scheduled
          {
            scheduleTiming: ScheduleTiming.ONCE,
            scheduledDate: { $gt: new Date().toISOString() },
          },
          // The other ScheduleTiming invites can always be shown
          {
            scheduleTiming: {
              $ne: ScheduleTiming.ONCE,
            },
          },
        ],
      },
      sort: ["-createdAt"],
      populate: ["exercise"],
    }),
    [props.client.id, session?.userId, searchParamValue],
  );

  const onClickRemove = useCallback(
    async (invite: IExerciseInvite) => {
      try {
        const isConfirmed = await openModal<boolean>((resolve) => (
          <ConfirmModal
            resolve={resolve}
            title={dictionary.literals.confirm}
            description={dictionary.texts.confirmDeleteScheduledExerciseInvite}
            type="danger"
            confirmButtonText={dictionary.literals.yes}
            cancelButtonText={dictionary.literals.cancel}
            confirmButtonIcon={checkIcon}
            cancelButtonIcon={crossIcon}
          />
        ));

        if (!isConfirmed) {
          return;
        }

        // if scheduled date is not present or scheduledDate is in the future, delete the invite
        const { scheduledDate } = invite;
        const shouldDelete = new Date(scheduledDate) > new Date();

        if (shouldDelete) {
          await exerciseInviteService.delete(invite.id);
        } else {
          // Otherwise instead of deleting, patch the invite with ScheduleTiming.ONCE
          // This will make sure no more invites are being created from this scheduled invite
          await exerciseInviteService.patch({
            id: invite._id,
            scheduleTiming: ScheduleTiming.ONCE,
          });
        }

        tableActionsRef.current?.refreshItems();

        Toast.info({
          body: dictionary.texts.deleteScheduledExerciseInviteSuccess,
        });
      } catch {
        Toast.error({
          body: dictionary.texts.deleteScheduledExerciseInviteError,
        });
      }
    },
    [openModal],
  );

  const rowTemplate = useCallback(
    (exerciseInvite: IExerciseInvite) => (
      <ScheduledExerciseInviteRowTemplate
        exerciseInvite={exerciseInvite}
        key={exerciseInvite.id}
        onClickRemove={() => onClickRemove(exerciseInvite)}
        onClickView={() => {
          history.push(
            routes.exercise.path.replace(
              ":exerciseId",
              exerciseInvite.exerciseId,
            ),
          );
        }}
      />
    ),
    [history, onClickRemove],
  );

  return (
    <div>
      <TableHeadTemplate
        header={dictionary.literals.scheduledExercises}
        headerAttributes={{ center: false }}
        searchInputAttributes={{
          defaultValue: searchParamValue ?? "",
          onChange: (event) => setSearchParamValue(event.target.value),
        }}
      />
      <Table<IExerciseInvite>
        service={exerciseInviteService}
        options={httpOptions}
        rowTemplate={rowTemplate}
        actionsRef={tableActionsRef}
      ></Table>
    </div>
  );
};
