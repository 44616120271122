import { FC } from "react";

import { style } from "typestyle";
import { useById } from "@hulanbv/nest-utilities-client-state";
import { useHistory } from "react-router";
import { ReactComponent as telephoneIcon } from "../../assets/graphics/symbols/telephone.svg";
import { ReactComponent as mailIcon } from "../../assets/graphics/symbols/mail.svg";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { routes } from "../../state/common/constants/routes.constants";
import { userService } from "../../state/user/user.service";
import { Button } from "../elements/button.element";
import { Description } from "../elements/description.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { Row } from "../elements/row.element";
import Toast from "../statics/toast";
import { RegisterPractitionerForm } from "../templates/forms/user/register-practitioner-form.template";
import { InjectionService } from "../../state/common/injection.service";
import { Header } from "../elements/header.element";
import { Anchor } from "../elements/anchor.element";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { branding } from "../../constants/branding.constants";

export const RegisterPractitionerScreen: FC = () => {
  const { session, validate } = useAuthContext();
  const { data } = useById(userService, session?.userId, {
    populate: ["license"],
  });

  const history = useHistory();

  if (!session?.userId || !data) {
    return <></>;
  }

  const onSubmit = async (
    formData: FormData,
    shouldSubcribeToNewsletter: boolean,
  ) => {
    try {
      // use specific endpoint to register client as practitioner
      await userService.registerClientAsPractitioner();

      // patch the user with filled in form data
      const { data: user } = await userService.patch(formData);

      if (shouldSubcribeToNewsletter) {
        await userService.subscribeNewsletter(user._id);
      }

      // validate the user so session and route permissions will be reinitialized
      await validate();

      // navigate to home professional path
      history.push(routes.homeProfessional.path);
      // show message to inform user about the upgrade
      Toast.info({ body: dictionary.texts.haveFunTrialLicense });
    } catch {
      Toast.error({
        body: dictionary.texts.updateUserError,
      });
    }
  };

  return (
    <Page>
      <PageBody>
        <Header className={styles.header}>
          {dictionary.texts.companyProfessional(branding.name ?? "PLAP")}
        </Header>
        <div className={styles.descriptionContainer}>
          <Description>
            {dictionary.texts.appDescription}
            <Anchor href={branding.urls?.homepage ?? "https://hulan.nl"}>
              {dictionary.texts.moreInformation}
            </Anchor>
          </Description>
        </div>
        {data && <RegisterPractitionerForm onSubmit={onSubmit} user={data} />}
        <div className={styles.contactText}>
          {dictionary.texts.areYouInterested}
        </div>
        <Row attributes={{ className: styles.buttonContainer }}>
          <Button
            flavour="secondary"
            icon={mailIcon}
            centerChildren
            attributes={{
              className: styles.contactButton,
              onClick: () => {
                InjectionService.mail(branding.mailAddresses?.support ?? "");
              },
            }}
          >
            Mail
          </Button>
          <Button
            flavour="secondary"
            icon={telephoneIcon}
            centerChildren
            attributes={{
              className: styles.contactButton,
              onClick: () => {
                InjectionService.call("+31402094081");
              },
            }}
          >
            {dictionary.literals.call}
          </Button>
        </Row>
      </PageBody>
    </Page>
  );
};

const styles = {
  page: style({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flex: 1,
    paddingBottom: 0,
  }),
  header: style({
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 15,
  }),
  descriptionContainer: style({
    margin: "0 20px 20px 10px",
  }),
  contactText: style({
    textAlign: "center",
    fontStyle: "italic",
    marginBottom: 5,
  }),
  buttonContainer: style({
    display: "flex",
    placeContent: "space-between",
    marginTop: 10,
  }),
  contactButton: style({
    width: "45%",
  }),
};
