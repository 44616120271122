import { useById } from "@hulanbv/nest-utilities-client-state";
import { FC } from "react";
import { IUser, Role } from "@hulanbv/platformapp";
import { style } from "typestyle";
import { useHistory } from "react-router";
import { useAuthContext } from "../../../state/authentication/authentication.context";
import { ProfileRow } from "../../elements/profile-row.element";
import { LogoutButton } from "../buttons/logout-button.template";
import { ProfileBasicDetailsTemplate } from "./profile-basic-details.template";
import { userService } from "../../../state/user/user.service";
import { dateUtils } from "../../../utils/date.utils";
import { Button } from "../../elements/button.element";
import { Anchor } from "../../elements/anchor.element";
import { MarkdownToHtml } from "../../elements/markdown-to-html.element";
import { dictionary } from "../../../state/common/constants/dictionary.constants";
import { routes } from "../../../state/common/constants/routes.constants";
import { formatPairingCode } from "../../../utils/format-pairing-code.utils";
import { branding } from "../../../constants/branding.constants";

interface IProps {
  user: IUser;
  onEditClick?: () => void;
}

// eslint-disable-next-line complexity -- Disable complexity check for this component
export const ProfileProfessionalTemplate: FC<IProps> = (props) => {
  const history = useHistory();
  const { session } = useAuthContext();
  const { user } = props;
  const { data } = useById(userService, user.id, {
    populate: ["license"],
  });

  const isLoggedInUser = session?.user?.role === Role.USER;

  return (
    <div className={styles.profileContainer}>
      <ProfileBasicDetailsTemplate
        user={user}
        onEditClick={props.onEditClick}
      />
      <div className={styles.marginSide}>
        <div className={styles.bottomContainer}>
          {user.pairingCode && (
            <ProfileRow
              label={dictionary.literals.pairingCode}
              value={formatPairingCode(user.pairingCode)}
            />
          )}

          {!isLoggedInUser && (
            <>
              <ProfileRow
                label={dictionary.literals.email}
                value={user.email}
              />
            </>
          )}
          {session?.user?.role === Role.PRACTITIONER && (
            <ProfileRow
              label={dictionary.literals.address}
              value={
                !user.street
                  ? undefined
                  : `${user.street} ${user.houseNumber ?? ""} ${
                      user.houseNumberAddition ?? ""
                    }`
              }
            />
          )}
          <ProfileRow
            label={dictionary.literals.cityOfResidence}
            value={user.city}
          />
          {!isLoggedInUser && (
            <ProfileRow
              label={dictionary.literals.zipCode}
              value={user.zipcode}
            />
          )}
          {user.country && (
            <ProfileRow
              label={dictionary.literals.countryOfResidence}
              value={dictionary.texts.countryLabels[user.country]}
            />
          )}
          <ProfileRow
            label={dictionary.literals.website}
            value={
              user.website && (
                <Anchor href={user.website}>
                  {user.website?.replace(/(https?:\/\/)?(www\.)?/, "")}
                </Anchor>
              )
            }
          />

          <ProfileRow
            label={dictionary.literals.biography}
            value={<MarkdownToHtml markdown={user.biography ?? ""} />}
          />
          {!isLoggedInUser && (
            <>
              <ProfileRow
                label={dictionary.literals.companyNumber}
                value={user.kvkNumber}
              />
              <ProfileRow
                label={dictionary.literals.amountOfClients}
                value={user.clients?.length.toString() ?? "0"}
              />
              {branding.features?.isMapEnabled && (
                <ProfileRow
                  label={dictionary.literals.visibleOnMap}
                  value={
                    user.companyGeolocation?.coordinates
                      ? dictionary.literals.yes
                      : dictionary.literals.no
                  }
                />
              )}
              {data?.license && (
                <ProfileRow
                  label={dictionary.literals.expirationDateLicense}
                  value={dateUtils.getFormattedDate(data.license.endDate)}
                />
              )}
            </>
          )}
        </div>
        <div className={styles.buttonContainer}>
          {session?.userId === props.user._id && (
            <Button
              flavour={"primary"}
              attributes={{
                onClick: () => history.push(routes.myProfileEditPassword.path),
              }}
            >
              {dictionary.texts.setOwnPassword}
            </Button>
          )}

          <Anchor
            href={branding.urls?.privacyPolicy ?? "https://hulan.nl/legal"}
          >
            <Button flavour="secondary">
              {dictionary.texts.companyNameFormal(branding.name ?? "PLAP")}
            </Button>
          </Anchor>
          {session?.userId === props.user._id && <LogoutButton />}
        </div>
      </div>
    </div>
  );
};

const styles = {
  profileContainer: style({ width: "100%" }),
  marginSide: style({
    margin: "0 22px",
  }),
  bottomContainer: style({
    padding: "29px 18px 0px",
  }),
  buttonContainer: style({
    paddingTop: "var(--spacing-vertical-small)",
    display: "flex",
    flexDirection: "column",
    gap: "var(--spacing-vertical-small)",
  }),
};
