import { FC, useCallback, useRef } from "react";
import { Button } from "../../../elements/button.element";
import { Form, IFormProps } from "../../../elements/form.element";
import { Input } from "../../../elements/input.element";
import { Row } from "../../../elements/row.element";
import { ReactComponent as sendIcon } from "../../../../assets/graphics/symbols/send.svg";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";

interface IProps {
  attributes?: IFormProps;
  onSubmit?: (formData: FormData) => void | Promise<void>;
}

const repeatPasswordInputName = "repeatPassword";
const minimumInputLength = 5;

export const ForgotPasswordResetForm: FC<IProps> = (props) => {
  const passwordInputRef = useRef<HTMLInputElement | null>(null);
  const repeatPasswordInputRef = useRef<HTMLInputElement | null>(null);

  const onSubmit = useCallback(
    (formData: FormData) => {
      const password = passwordInputRef.current?.value;
      const newPassword = repeatPasswordInputRef.current?.value;

      // show a custom html message whenever passwords are not equal
      if (password !== newPassword) {
        repeatPasswordInputRef.current?.setCustomValidity(
          dictionary.texts.passwordsNotIdentical,
        );
        repeatPasswordInputRef.current?.reportValidity();
        return;
      }

      // delete the repeat password input form entry
      formData.delete(repeatPasswordInputName);

      props.onSubmit?.(formData);

      // clear input values after submit
      if (repeatPasswordInputRef.current) {
        repeatPasswordInputRef.current.value = "";
      }
      if (passwordInputRef.current) {
        passwordInputRef.current.value = "";
      }
    },
    [props],
  );

  return (
    <Form {...props.attributes} onSubmit={onSubmit}>
      <Row>
        <Input
          attributes={{
            name: "newPassword",
            type: "password",
            placeholder: dictionary.literals.password,
            minLength: minimumInputLength,
            required: true,
          }}
          ref={passwordInputRef}
        />
      </Row>
      <Row>
        <Input
          attributes={{
            name: repeatPasswordInputName,
            type: "password",
            placeholder: dictionary.literals.repeatPassword,
            minLength: minimumInputLength,
            required: true,
            onChange: () =>
              repeatPasswordInputRef.current?.setCustomValidity(""),
          }}
          ref={repeatPasswordInputRef}
        />
      </Row>
      <Row>
        <Button
          attributes={{ type: "submit" }}
          icon={sendIcon}
          hideSpinnerOnSubmit
        >
          {dictionary.literals.submit}
        </Button>
      </Row>
    </Form>
  );
};
