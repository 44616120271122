import { ComponentProps, FC } from "react";
import { style } from "typestyle";
import { Checkbox } from "../elements/checkbox.element";

interface IProps {
  label: string;
  attributes?: ComponentProps<typeof Checkbox>["attributes"];
}

export const CheckboxRowTemplate: FC<IProps> = (props) => (
  <div className={styles.rowContainer}>
    <Checkbox label={props.label} attributes={props.attributes} />
  </div>
);

const styles = {
  rowContainer: style({
    padding: "10px 10px 1px 10px",
    $nest: {
      "&:nth-child(odd)": {
        backgroundColor: "rgb(var(--rgb-color-gray-lighter))",
      },
    },
  }),
};
