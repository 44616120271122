import { FC } from "react";
import { IQuestionnaire } from "@hulanbv/platformapp";
import { TableRow } from "../../elements/table-row.element";
import { TableRowContent } from "../../elements/table-row-content.element";
import { ReactComponent as eyeIcon } from "../../../assets/graphics/symbols/eye.svg";
import { IconButton } from "../../elements/icon-button.element";
import { dictionary } from "../../../state/common/constants/dictionary.constants";
import { dateUtils } from "../../../utils/date.utils";

type Props = {
  questionnaire: IQuestionnaire;
  inviteDate?: Date;
  onViewClick?: () => Promise<void> | void;
};

export const QuestionnaireRowTemplate: FC<Props> = (props) => (
  <TableRow>
    <TableRowContent
      header={props.questionnaire.title}
      subHeader={`${dictionary.literals.sentOn} ${dateUtils.getFormattedDate(
        props.inviteDate ?? new Date(),
      )}`}
      subHeaderSize="tiny"
      controls={
        <>
          {props.onViewClick && (
            <IconButton
              icon={eyeIcon}
              attributes={{ onClick: props.onViewClick }}
            />
          )}
        </>
      }
    />
  </TableRow>
);
