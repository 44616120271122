import { forwardRef, PropsWithChildren } from "react";
import { classes, style } from "typestyle";

interface IProps {
  fullWidth?: boolean;
  fullHeight?: boolean;
  hiddenNavs?: boolean;
  className?: string;
}

export const PageBody = forwardRef<HTMLDivElement, PropsWithChildren<IProps>>(
  (props, ref) => (
    <div
      className={classes(
        styles.content,
        props.fullWidth && styles.fullWidth,
        props.fullHeight && styles.fullHeight,
        props.hiddenNavs && styles.hiddenNavsFullHeight,
        props.className,
      )}
      ref={ref}
    >
      {props.children}
    </div>
  ),
);

const styles = {
  content: style({
    maxWidth: "var(--max-ui-width)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    padding:
      "var(--spacing-vertical-regular) var(--spacing-horizontal-regular)",
  }),
  fullWidth: style({
    paddingLeft: 0,
    paddingRight: 0,
  }),
  // using min-height on the container makes sure the container is still able to grow
  // whenever it overflows
  fullHeight: style({
    minHeight: "calc(100vh - (var(--tab-bar-height) + var(--nav-bar-height)))",
  }),
  hiddenNavsFullHeight: style({
    minHeight: "100vh",
  }),
};
