import { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { ModalContext, useModal } from "../state/common/contexts/modal.context";
import { Modal } from "./elements/modal.element";
import { App } from "./templates/app.template";
import {
  useAuth,
  AuthContext,
} from "../state/authentication/authentication.context";
import { httpService } from "../state/common/http.service";

export const Main: FC = () => {
  const modalHook = useModal();
  const authHook = useAuth(modalHook);
  httpService.authContext = authHook;

  return (
    <AuthContext.Provider value={authHook}>
      <ModalContext.Provider value={modalHook}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <Modal />
      </ModalContext.Provider>
    </AuthContext.Provider>
  );
};
