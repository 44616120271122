import { useById } from "@hulanbv/nest-utilities-client-state";
import { IUserAnswer } from "@hulanbv/platformapp";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import { style } from "typestyle";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { routes } from "../../state/common/constants/routes.constants";
import { questionnaireService } from "../../state/questionnaire/questionnaire.service";
import { userAnswerService } from "../../state/user-answer/user-answer.service";
import { formDataToObject } from "../../utils/form-data-to-object.utils";
import { Card } from "../elements/card.element";
import { Flex } from "../elements/flex.element";
import { Header } from "../elements/header.element";
import { GeometricShape } from "../elements/geometric-shape";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { ProgressBar } from "../elements/progress-bar.element";
import Toast from "../statics/toast";
import { UserAnswerForm } from "../templates/forms/user/user-answer-form.template";

export const QuestionnaireScreen: FC = () => {
  // start at the first answer
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const history = useHistory();

  const { session } = useAuthContext();

  const params = useParams<{
    exerciseInviteId: string;
    questionnaireId: string;
  }>();

  const { data: questionnaire, call: fetchQuestionnaire } = useById(
    questionnaireService,
    params.questionnaireId,
    {
      populate: [
        {
          path: "questions",
          sort: ["questionIndex"],
          populate: [
            {
              path: "userAnswers",
              match: {
                userId: session?.userId,
                exerciseInviteId: params.exerciseInviteId,
              },
            },
          ],
        },
      ],
    },
    { distinct: true },
  );

  /**
   * Start questionnaire at first unanswered question
   */
  useEffect(() => {
    if (!questionnaire?.questions?.length) {
      return;
    }

    // find the last answered question
    const questionIndex = questionnaire.questions.reduce(
      (maxIndex, question, index) => {
        if ((question.userAnswers?.length ?? 0) > 0) {
          return index;
        }
        return maxIndex;
      },
      -1,
    );

    // start at the next question
    setCurrentQuestionIndex(
      Math.min(questionIndex + 1, questionnaire.questions.length - 1),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only rerun on number of questions change
  }, [questionnaire?.questions?.length]);

  const currentQuestion = useMemo(
    () => questionnaire?.questions?.[currentQuestionIndex],
    [currentQuestionIndex, questionnaire?.questions],
  );

  const currentUserAnswer = useMemo(() => {
    const userAnswers = (questionnaire?.questions ?? []).flatMap(
      (question) => question.userAnswers,
    );
    return userAnswers?.find(
      (answer) => answer?.questionId === currentQuestion?._id,
    );
  }, [currentQuestion?._id, questionnaire?.questions]);

  const handleOnSubmitAnswer = useCallback(
    async (formData: FormData, skip = false) => {
      try {
        if (!skip) {
          const userAnswer = formDataToObject<IUserAnswer>(formData);

          const foundQuestionAnswer = currentQuestion?.answers?.find((answer) =>
            userAnswer.value.includes(answer.value),
          );

          if (userAnswer.value.length === 0) {
            Toast.error({
              body: dictionary.texts.userHasNotAnsweredYet,
            });
            return;
          }

          // Because isCorrect also accepts undefined, we surround the value by brackets
          // Everything inside brackets will converted by the dto to a primitive value
          formData.append("isCorrect", `{{${foundQuestionAnswer?.isCorrect}}}`);

          // Added the exerciseInviteId to the formData. This is needed to later
          // to get the right userAnswers in the questionnaire results view.
          formData.append("exerciseInviteId", params.exerciseInviteId);

          // determine if we need to update or create the userAnswer
          if (userAnswer._id) {
            await userAnswerService.patch(formData);
          } else {
            await userAnswerService.post(formData);
          }

          await fetchQuestionnaire();
        }

        // Set next question after successful user-answer submission
        const nextIndex = currentQuestionIndex + 1;
        if (questionnaire?.questions?.[nextIndex]) {
          setCurrentQuestionIndex(nextIndex);
          return;
        }

        const questionnaireEnd = generatePath(routes.questionnaireEnd.path, {
          exerciseInviteId: params.exerciseInviteId,
          questionnaireId: params.questionnaireId,
        });
        history.push(questionnaireEnd);
      } catch {
        Toast.error({
          body: dictionary.texts.userAnswerSubmitError,
        });
      }
    },
    [
      currentQuestion?.answers,
      params.exerciseInviteId,
      params.questionnaireId,
      fetchQuestionnaire,
      currentQuestionIndex,
      questionnaire?.questions,
      history,
    ],
  );

  const handleOnPreviousQuestion = useCallback(() => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  }, [currentQuestionIndex]);

  const progressBarPercentage = useMemo(() => {
    const questionsLength = questionnaire?.questions?.length;
    if (!questionsLength) {
      return 0;
    }
    const percentagePerQuestion = 100 / questionsLength;
    return Math.ceil((currentQuestionIndex + 1) * percentagePerQuestion);
  }, [currentQuestionIndex, questionnaire?.questions?.length]);

  return (
    <Page hideGoBack className={styles.page}>
      <PageBody fullHeight>
        <ProgressBar percentage={progressBarPercentage} />
        {currentQuestion && (
          <Card
            showNextCard={
              !!questionnaire?.questions?.[currentQuestionIndex + 1]
            }
          >
            <Flex direction="column" alignItems="stretch">
              <Flex justifyContent="flex-start">
                <GeometricShape
                  className={styles.geometricShape}
                  color={"rgba(var(--rgb-color-primair-lighter)"}
                  size={60}
                >
                  <Header color="white" size={"small"} weight={500}>
                    {currentQuestionIndex + 1}
                  </Header>
                </GeometricShape>
                <Header size={"small"}>{currentQuestion.query}</Header>
              </Flex>
              <UserAnswerForm
                key={currentQuestion.id}
                userAnswer={currentUserAnswer}
                question={currentQuestion}
                questionIndex={currentQuestionIndex}
                onSubmit={(formdata) => handleOnSubmitAnswer(formdata, false)}
                submitButtonText={
                  currentQuestionIndex + 1 === questionnaire?.questions?.length
                    ? dictionary.literals.finish
                    : dictionary.literals.next
                }
                onPrevious={handleOnPreviousQuestion}
                onSkip={() => handleOnSubmitAnswer(new FormData(), true)}
              />
            </Flex>
          </Card>
        )}
      </PageBody>
    </Page>
  );
};

const styles = {
  page: style({
    backgroundImage:
      "linear-gradient(180deg, rgba(var(--rgb-color-primair-shade-4), 0.15), rgba(var(--rgb-color-primair-basis), 0.15))",
  }),
  geometricShape: style({
    minWidth: 40,
  }),
};
