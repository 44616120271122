import { HttpService, IResponse } from "nest-utilities-client";
import { useAuth } from "../authentication/authentication.context";
import { authenticationService } from "../authentication/authentication.service";

class Service extends HttpService {
  public authContext: ReturnType<typeof useAuth> | null = null;

  getHeaders(url: string, init: RequestInit) {
    return {
      authorization: authenticationService.getSession()?.token ?? "",
    };
  }

  onRequestError(error: IResponse<Error>) {
    // clear the session token if its invalid
    if (error.status === 401) {
      this.authContext?.logout();
    }
  }
}

export const httpService = new Service();
