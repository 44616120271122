import { ComponentProps, FC, useCallback } from "react";
import { style } from "typestyle";
import { ICollection, IExercise, IQuestion } from "@hulanbv/platformapp";
import { ReactComponent as AddToCollectionIcon } from "../../assets/graphics/symbols/favorite.svg";
import { Checkbox } from "../elements/checkbox.element";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { Button } from "../elements/button.element";
import { Description } from "../elements/description.element";
import { Input } from "../elements/input.element";
import { QuestionDisplay } from "../elements/question-display.element";
import { Row } from "../elements/row.element";
import { exerciseCollectionService } from "../../state/exercise/exercise-collection.service";
import Toast from "../statics/toast";
import { SelectCollectionsModal } from "./modals/select-collections-modal.template";
import { useModalContext } from "../../state/common/contexts/modal.context";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { createExerciseWithQuestionnaire } from "../../utils/create-exercise-with-questionnaire.utils";
import { TextArea } from "../elements/text-area.element";

interface IProps {
  attributes?: ComponentProps<typeof Checkbox>["attributes"];
  exercise?: IExercise;
  title: string;
  description: string;
  selectedQuestions: IQuestion[];
  onQuestionSelect: (question: IQuestion) => void;
  onTitleChange: (title: string) => void;
  onDescriptionChange: (description: string) => void;
  onExerciseCreated?: (exercise: IExercise) => void;
}

export const CustomizeQuestionnaireFormTemplate: FC<IProps> = (props) => {
  const { openModal } = useModalContext();
  const { session } = useAuthContext();

  const showCollectionModal = useCallback(async () => {
    try {
      await openModal<ICollection[] | null>((resolve) => (
        <SelectCollectionsModal
          onSubmit={async (collectionIds) => {
            const exercise = await createExerciseWithQuestionnaire(
              props.title,
              props.description,
              props.selectedQuestions,
              session?.userId ?? "",
            );

            if (!collectionIds || !exercise) {
              resolve(null);
              return;
            }

            const collections = await Promise.all(
              collectionIds.map((id) =>
                exerciseCollectionService.addToCollection([exercise?._id], id),
              ),
            );
            Toast.info({
              body: dictionary.texts.collectionAddSuccess,
            });
            resolve(collections);
            // Sends the created exercise to the parent component
            props.onExerciseCreated?.(exercise);
          }}
        />
      ));
    } catch {
      Toast.error({
        body: dictionary.texts.exerciseAddedToCollectionError,
      });
    }
  }, [openModal, props, session?.userId]);

  return (
    <div className={styles.container}>
      <Description
        children={<p>{dictionary.texts.customizeAssessmentDescription}</p>}
        className={styles.description}
      />
      <Row
        attributes={{
          className: styles.fullWidth,
        }}
        label={`${dictionary.literals.title}`}
      >
        <Input
          attributes={{
            type: "text",
            required: true,
            placeholder: dictionary.literals.title,
            defaultValue: props.title,
            onChange: (event) => props.onTitleChange?.(event.target.value),
          }}
        />
      </Row>
      <Row
        attributes={{
          className: styles.fullWidth,
        }}
        label={`${dictionary.literals.description}`}
      >
        <TextArea
          attributes={{
            placeholder: dictionary.literals.description,
            defaultValue: props.description,
            required: true,
            onChange: (event) =>
              props.onDescriptionChange?.(event.target.value),
          }}
        />
      </Row>
      {props.exercise?.questionnaire?.questions?.map((question) => (
        <Checkbox
          key={question.id}
          label={
            <QuestionDisplay
              question={question}
              shouldHideAnswers
              isSelected={props.selectedQuestions.includes(question)}
            />
          }
          attributes={{
            onChange: () => props.onQuestionSelect(question),
            isDefaultChecked: props.selectedQuestions.includes(question),
          }}
        />
      ))}
      <Button
        hideSpinnerOnSubmit
        attributes={{
          onClick: () => showCollectionModal(),
          disabled:
            !props.title ||
            !props.description ||
            !props.selectedQuestions.length,
        }}
        icon={AddToCollectionIcon}
      >
        {dictionary.literals.addToCollection}
      </Button>
    </div>
  );
};

const styles = {
  container: style({
    display: "flex",
    flexDirection: "column",
    paddingBottom: 20,
  }),
  description: style({
    paddingBottom: 20,
  }),
  fullWidth: style({
    width: "100%",
  }),
};
