import { RefObject, useEffect, useState } from "react";

/**
 * Stateful representation of the data-disabled attribute of the closest form parent.
 * @param ref A RefObject pointing to the form child.
 * @returns boolean. True if the data-disabled attribute value equals "true"
 * and false in case of "false".
 */
export const useFormIsDisabled = (ref: RefObject<HTMLElement>): boolean => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const form = ref.current?.closest("form");
    if (!form) {
      return;
    }

    const validate = () => setIsDisabled(form.dataset.disabled === "true");

    const observer = new MutationObserver(() => validate());
    observer.observe(form, { attributes: true });
    validate();

    // eslint-disable-next-line consistent-return -- needs to return for cleanup
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isDisabled;
};
