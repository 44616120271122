import { FC, useCallback, useMemo } from "react";
import { style } from "typestyle";
import { ExerciseTag, IExercise } from "@hulanbv/platformapp";
import { generatePath, useHistory } from "react-router";
import { IHttpOptions, ModelQuery } from "nest-utilities-client";
import { exerciseService } from "../../state/exercise/exercise.service";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { SearchInput } from "../elements/search-input.element";
import { Table } from "../elements/table.element";
import { Button } from "../elements/button.element";
import { ReactComponent as filter } from "../../assets/graphics/symbols/filter.svg";
import { useModalContext } from "../../state/common/contexts/modal.context";
import {
  ISelectExerciseFilters,
  SelectExerciseFilters,
} from "../templates/modals/select-exercise-filters.template";
import { ExerciseRowTemplate } from "../templates/table-rows/exercise-row.template";
import { routes } from "../../state/common/constants/routes.constants";
import { useUrlState } from "../../state/common/hooks/url-state-hook/use-url-state.hook";
import { urlUtils } from "../../utils/url.utils";
import { BoxShadowHead } from "../elements/box-shadow-head.element";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { AddToCollectionsModal } from "../templates/modals/add-to-collections-modal.template";

export const PlaygroundScreen: FC = () => {
  const { openModal } = useModalContext();
  const history = useHistory();
  const { session } = useAuthContext();
  const [searchParamValue, setSearchParamValue] =
    useUrlState<string>("search-filter");
  const [labelIdParamValue, setLabelIdParamValue] = useUrlState<
    string | undefined
  >("label-id");
  const [isOnlyOwnExercisesParamValue, setIsOnlyOwnExercisesParamValue] =
    useUrlState<boolean | undefined>("is-only-own-exercises");
  const [categoryIdsParamValues, setCategoryIdsParamValues] = useUrlState<
    string[] | undefined
  >("category-ids");

  const httpOptions = useMemo<IHttpOptions>(() => {
    const match: ModelQuery<IExercise> = {
      ...(searchParamValue &&
        urlUtils.createSearchQuery(searchParamValue, ["name", "description"])),
      tags: ExerciseTag.PLAYGROUND,
    };

    if (isOnlyOwnExercisesParamValue) {
      match.ownerId = session?.userId;
    }

    // if we selected categories and labels to filter on, supplement the match query
    if (categoryIdsParamValues) {
      match.categories = {
        $in: categoryIdsParamValues,
      };
    }
    if (labelIdParamValue) {
      match.labelIds = labelIdParamValue;
    }

    return { match };
  }, [
    searchParamValue,
    isOnlyOwnExercisesParamValue,
    categoryIdsParamValues,
    labelIdParamValue,
    session?.userId,
  ]);

  const handleExerciseFilterSubmit = useCallback(
    (filters: ISelectExerciseFilters) => {
      const {
        selectedLabelId,
        selectedCategories,
        isOnlyOwnExercisesSelected,
      } = filters;

      setLabelIdParamValue(selectedLabelId);
      setCategoryIdsParamValues(selectedCategories);
      setIsOnlyOwnExercisesParamValue(isOnlyOwnExercisesSelected);
    },
    [
      setCategoryIdsParamValues,
      setIsOnlyOwnExercisesParamValue,
      setLabelIdParamValue,
    ],
  );

  const handleOnFilterClick = useCallback(() => {
    openModal((resolve) => (
      <SelectExerciseFilters
        selectExerciseFilters={{
          selectedCategories: categoryIdsParamValues,
          selectedLabelId: labelIdParamValue,
          isOnlyOwnExercisesSelected: isOnlyOwnExercisesParamValue,
        }}
        categoryOptions={{
          match: {
            tags: ExerciseTag.PLAYGROUND,
          },
        }}
        labelOptions={{
          sort: ["sortOrder"],
        }}
        onSubmit={(filters) => {
          handleExerciseFilterSubmit(filters);
          resolve(null);
        }}
        onCancelClick={() => {
          resolve(null);
        }}
      />
    ));
  }, [
    categoryIdsParamValues,
    handleExerciseFilterSubmit,
    isOnlyOwnExercisesParamValue,
    labelIdParamValue,
    openModal,
  ]);

  const exerciseRowTemplate = useCallback(
    (exercise: IExercise) => (
      <ExerciseRowTemplate
        key={exercise._id}
        exercise={exercise}
        onFavoriteClick={() => {
          openModal((resolve) => (
            <AddToCollectionsModal resolve={resolve} exercise={exercise} />
          ));
        }}
        onSendClick={() =>
          history.push(
            generatePath(routes.sendExerciseInvites.path, {
              exerciseId: exercise._id,
            }),
          )
        }
        onViewClick={() =>
          history.push(
            generatePath(routes.exercise.path, {
              exerciseId: exercise._id,
            }),
          )
        }
      />
    ),
    [history, openModal],
  );

  return (
    <Page>
      <PageBody fullWidth>
        <BoxShadowHead attributes={{ className: styles.searchContainer }}>
          <SearchInput
            onChangeTimeoutMilliseconds={500}
            placeholder={dictionary.literals.searchByNameAndContent}
            attributes={{
              defaultValue: searchParamValue ?? "",
              onChange: (event) => setSearchParamValue(event.target.value),
            }}
          />
          <Button
            flavour="tertiary"
            attributes={{
              className: styles.button,
              onClick: handleOnFilterClick,
            }}
            icon={filter}
            centerChildren
          >
            {dictionary.literals.filters}
          </Button>
        </BoxShadowHead>
        <Table<IExercise>
          service={exerciseService}
          options={httpOptions}
          rowTemplate={exerciseRowTemplate}
        />
      </PageBody>
    </Page>
  );
};

const styles = {
  searchContainer: style({
    margin: "0 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  }),
  button: style({
    alignSelf: "center",
    maxWidth: 250,
    width: "100%",
  }),
};
