import { Marker, MarkerOptions, Popup, PopupOptions } from "mapbox-gl";
import { style } from "typestyle";
import mapMarker from "../assets/graphics/legacy/map-marker.svg";

export const mapUtils = new (class MapUtils {
  getMarker(options?: MarkerOptions): Marker {
    const element = document.createElement("div");
    element.className = styles.marker;
    return new Marker(element, {
      ...options,
      offset: [0, -(markerHeight / 2)],
    });
  }

  getPopup(
    content: {
      header: string;
      subTexts?: string[];
      actions?: [{ icon: string; action: () => void }];
    },
    options?: PopupOptions,
  ): Popup {
    // create main container
    const container = document.createElement("div");
    container.className = styles.popupContainer;

    // create header (flex) container
    const headerContainer = document.createElement("div");
    headerContainer.className = styles.popupHeaderContainer;

    // first header flex child is the header with text
    const headerElement = document.createElement("div");
    // append header text
    headerElement.appendChild(document.createTextNode(content.header));
    headerElement.className = styles.header;

    // append the first flex child to the header-container
    headerContainer.appendChild(headerElement);

    // the second header flex child is a div with the actions
    const actionsElement = document.createElement("div");
    actionsElement.className = styles.actionsContainer;

    if (content.actions?.length) {
      content.actions.forEach((action) => {
        const actionElement = document.createElement("img");
        actionElement.src = action.icon;
        actionElement.onclick = action.action;
        actionElement.className = styles.image;
        actionsElement.appendChild(actionElement);
      });

      // append the second flex child to the header container
      headerContainer.appendChild(actionsElement);
    }

    // add header container to main element as child
    container.appendChild(headerContainer);

    content.subTexts?.forEach((text) => {
      // create a subTextElement
      const subTextElement = document.createElement("div");
      // append styling
      subTextElement.className = styles.popupSubText;
      // append text to div
      subTextElement.appendChild(document.createTextNode(text));
      // append created sub text element to main container
      container.appendChild(subTextElement);
    });

    return new Popup(options).setDOMContent(container);
  }
})();

const markerHeight = 50;

const styles = {
  marker: style({
    width: 45,
    height: markerHeight,
    backgroundImage: `url(${mapMarker})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  }),
  popupContainer: style({
    width: "100%",
  }),
  popupHeaderContainer: style({
    fontWeight: 600,
    fontSize: 24,
    color: "rgb(var(--rgb-color-primair-basis))",
    marginBottom: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  header: style({
    flex: 1,
  }),
  actionsContainer: style({
    flexShrink: 0,
    marginLeft: 15,
  }),
  image: style({
    width: 30,
    height: 30,
    objectFit: "contain",
  }),
  popupSubText: style({
    fontSize: 18,
  }),
};
