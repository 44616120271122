import { FC } from "react";
import { classes, style } from "typestyle";
import { NavigationBar } from "./navigation-bar.element";
import { TabBar } from "./tab-bar.element";

interface IProps {
  hideNavs?: boolean;
  hideGoBack?: boolean;
  className?: string;
}

export const Page: FC<IProps> = (props) => (
  <>
    {props.hideNavs !== true && (
      <>
        <NavigationBar hideGoBack={props.hideGoBack} />
        <div className={classes(styles.content, props.className)}>
          {props.children}
        </div>
        <TabBar />
      </>
    )}
    {props.hideNavs && (
      <div
        className={classes(styles.content, styles.hiddenNavs, props.className)}
      >
        {props.children}
      </div>
    )}
  </>
);

const styles = {
  content: style({
    marginTop: "calc(var(--nav-bar-height) + env(safe-area-inset-top))",
    marginBottom: "calc(var(--tab-bar-height) + env(safe-area-inset-bottom))",
    width: "100%",
  }),
  hiddenNavs: style({
    marginTop: 0,
    marginBottom: 0,
  }),
};
