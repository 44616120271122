import {
  FC,
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  SVGProps,
} from "react";
import { classes, style } from "typestyle";
import { Button } from "../../elements/button.element";
import { ReactComponent as checkIcon } from "../../../assets/graphics/symbols/checkmark.svg";
import { useModalContext } from "../../../state/common/contexts/modal.context";
import { dictionary } from "../../../state/common/constants/dictionary.constants";

export interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  title: string;
  description: ReactNode;
  confirmButtonText?: string;
  confirmButtonIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const InfoModal: FC<IProps> = (props) => {
  const { closeModal } = useModalContext();

  return (
    <div
      {...{
        ...props.attributes,
        className: classes(styles.container, props.attributes?.className),
      }}
    >
      <h2 className={styles.header}>{props.title}</h2>
      <div className={styles.description}> {props.description}</div>
      <Button
        flavour={"primary"}
        icon={props.confirmButtonIcon ?? checkIcon}
        attributes={{
          onClick: closeModal,
          className: styles.marginBottom,
        }}
      >
        <div className={styles.buttonText}>
          {props.confirmButtonText ?? dictionary.literals.close}
        </div>
      </Button>
    </div>
  );
};

const styles = {
  container: style({
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100%",
    width: "100%",
  }),
  marginBottom: style({ marginBottom: 10 }),
  header: style({
    color: "rgb(var(--rgb-color-primair-basis))",
    textAlign: "center",
    margin: "25px 0",
  }),
  description: style({
    fontSize: "1.06rem",
    margin: "0 0 25px 0",
    textAlign: "center",
  }),
  buttonIcon: style({
    width: 20,
  }),
  buttonText: style({
    fontSize: "0.95rem",
  }),
};
