// eslint-disable-next-line eslint-comments/disable-enable-pair -- needed for whole file
/* eslint-disable no-restricted-syntax -- fix this problem, and move logic to functional component */
import { classes, style } from "typestyle";
import { Component } from "react";
import { ReactComponent as Checkmark } from "../../assets/graphics/symbols/checkmark.svg";
import { ReactComponent as Problem } from "../../assets/graphics/symbols/problem.svg";
import { ReactComponent as Cross } from "../../assets/graphics/symbols/cross.svg";
import { dictionary } from "../../state/common/constants/dictionary.constants";

interface IToastContent {
  title?: string;
  body?: string;
}

interface IState extends IToastContent {
  isVisible: boolean;
  type?: "info" | "error";
}
export default class Toast extends Component<IToastContent, IState> {
  private static instance: Toast;

  private hideAfterSeconds = 5;

  private hideTimeOut: number | null = null;

  public state: IState = {
    isVisible: false,
  };

  constructor(props: IToastContent) {
    super(props);
    Toast.instance = this;
  }

  public static info(content: IToastContent): void {
    const { instance } = Toast;
    instance.show(content, "info");
  }

  public static error(content: IToastContent): void {
    const { instance } = Toast;
    instance.show(content, "error");
  }

  private show(content: IToastContent, type: "info" | "error"): void {
    // make sure no animation request will be done when still animating
    if (this.state.isVisible === true) {
      return;
    }

    this.setState({
      title:
        content.title ??
        (type === "info"
          ? `${dictionary.literals.success}!`
          : `${dictionary.texts.toastErrorHeader}!`),
      body:
        content.body ??
        (type === "info"
          ? dictionary.literals.requestSuccessful
          : dictionary.texts.requestError),
      isVisible: true,
      type,
    });

    this.hideTimeOut = window.setTimeout(() => {
      Toast.hide();
    }, this.hideAfterSeconds * 1000);
  }

  public static hide(): void {
    const { instance } = Toast;
    if (instance.hideTimeOut) {
      window.clearTimeout(instance.hideTimeOut);
    }
    instance.setState({ isVisible: false });
  }

  render(): JSX.Element {
    const { type, title, body, isVisible } = this.state;
    const isInfo = type === "info";

    return (
      <div
        className={classes(
          isVisible === true ? styles.containerVisible : "",
          styles.container,
        )}
        onClick={() => Toast.hide()}
      >
        <div className={styles.iconContainer}>
          {isInfo ? (
            <Checkmark
              className={classes(styles.checkCrossImage, styles.success)}
            />
          ) : (
            <Problem
              className={classes(styles.checkCrossImage, styles.error)}
            />
          )}
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.header}>{title}</div>
          <div className={styles.content}>
            {body ??
              (isInfo
                ? dictionary.literals.requestSuccessful
                : dictionary.texts.requestError)}
          </div>
        </div>
        <div className={styles.closeContainer}>
          <Cross className={styles.closeImage} />
        </div>
      </div>
    );
  }
}

const styles = {
  container: style({
    position: "fixed",
    display: "flex",
    bottom: "calc(var(--tab-bar-height) + 10px)",
    left: 0,
    right: 0,
    margin: "auto",
    width: "80vw",
    minHeight: 115,
    maxWidth: 600,
    backgroundColor: "rgb(var(--rgb-color-background))",
    borderRadius: "var(--border-radius-large)",
    cursor: "pointer",
    padding: 20,
    opacity: 0,
    pointerEvents: "none",
    transition: "opacity 1s ease-in-out",
    zIndex: 20,
  }),
  containerVisible: style({
    opacity: 1,
    pointerEvents: "auto",
  }),
  iconContainer: style({
    width: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  contentContainer: style({
    display: "flex",
    flexDirection: "column",
    width: "70%",
    justifyContent: "center",
    padding: 10,
  }),
  checkCrossImage: style({
    maxWidth: 26,
  }),
  success: style({
    color: "rgb(var(--rgb-color-success))",
  }),
  error: style({
    color: "rgb(var(--rgb-color-dangerous))",
  }),
  header: style({
    fontWeight: 600,
    fontSize: 22,
    paddingBottom: 5,
  }),
  content: style({
    fontSize: 14,
  }),
  closeContainer: style({
    width: "10%",
    height: "100%",
  }),
  closeImage: style({
    display: "block",
    marginLeft: "auto",
  }),
};
