import { ISession } from "@hulanbv/platformapp";
import { FC, HTMLAttributes } from "react";
import { TableRow } from "../../elements/table-row.element";
import { IconButton } from "../../elements/icon-button.element";
import { ReactComponent as sendIcon } from "../../../assets/graphics/symbols/send.svg";
import { ReactComponent as eyeIcon } from "../../../assets/graphics/symbols/eye.svg";
import { TableRowContent } from "../../elements/table-row-content.element";
import { dictionary } from "../../../state/common/constants/dictionary.constants";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  session: ISession;
  onSendClick?: () => void;
  onViewClick?: () => void;
}

export const SessionRowTemplate: FC<IProps> = (props) => {
  const { session } = props;
  const { exercise, exercises } = dictionary.literals;

  return (
    <TableRow {...props.attributes}>
      <TableRowContent
        header={session.name}
        subHeader={`${session.exerciseIds?.length ?? 0} ${(session.exerciseIds
          ?.length === 1
          ? exercise
          : exercises
        ).toLowerCase()}`}
        subHeaderWeight={500}
        controls={
          <>
            {props.onSendClick && (
              <IconButton
                icon={sendIcon}
                attributes={{ onClick: props.onSendClick }}
              />
            )}
            {props.onViewClick && (
              <IconButton
                icon={eyeIcon}
                attributes={{ onClick: props.onViewClick }}
              />
            )}
          </>
        }
      />
    </TableRow>
  );
};
