import { FC, InputHTMLAttributes } from "react";
import { IQuestion, QuestionType } from "@hulanbv/platformapp";
import { style } from "typestyle";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { Card } from "./card.element";
import { Flex } from "./flex.element";
import { GeometricShape } from "./geometric-shape";
import { Header } from "./header.element";

export interface IProps {
  attributes?: InputHTMLAttributes<HTMLInputElement>;
  question: IQuestion;
  shouldHideAnswers?: boolean;
  isSelected?: boolean;
}

export const QuestionDisplay: FC<IProps> = (props) => (
  <Card
    header={<Header size="regular">{props.question.query}</Header>}
    attributes={{
      style: props.isSelected === false ? { opacity: 0.5 } : { opacity: 1 },
      className: styles.card,
    }}
  >
    {props.shouldHideAnswers !== true && (
      <Flex gap={15} alignItems="flex-start" direction="column">
        <Header size="regular">{dictionary.literals.answers}:</Header>
        {props.question.answers?.map((answer, index) => (
          <Flex key={index} direction="row" gap={10}>
            <GeometricShape
              size={16}
              color={"rgb(var(--rgb-color-primair-basis))"}
            />
            <div>{answer.value}</div>
          </Flex>
        ))}
        {props.question.type === QuestionType.OPEN && (
          <div className={styles.openQuestion}>
            {dictionary.literals.openQuestion}
          </div>
        )}
      </Flex>
    )}
  </Card>
);

const styles = {
  card: style({
    opacity: 1,
    margin: 0,
    transition: "opacity 0.3s",
  }),
  openQuestion: style({
    color: "rgb(var(--rgb-color-gray))",
  }),
};
