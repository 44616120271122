const dutchCountryLabels: Record<string, string> = {
  /* eslint-disable @typescript-eslint/naming-convention -- naming convention is not respected country labels */
  AF: "Afghanistan",
  AX: "Ålandeilanden",
  AL: "Albanië",
  DZ: "Algerije",
  AS: "Amerikaans-Samoa",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctica",
  AG: "Antigua en Barbuda",
  AR: "Argentinië",
  AM: "Armenië",
  AW: "Aruba",
  AU: "Australië",
  AT: "Oostenrijk",
  AZ: "Azerbeidzjan",
  BS: "Bahama's",
  BH: "Bahrein",
  BD: "Bangladesh",
  BB: "Barbados",
  BY: "Wit-Rusland",
  BE: "België",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivia",
  BA: "Bosnië en Herzegovina",
  BW: "Botswana",
  BV: "Bouveteiland",
  BR: "Brazilië",
  IO: "Brits Indische Oceaanterritorium",
  BN: "Brunei Darussalam",
  BG: "Bulgarije",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodja",
  CM: "Kameroen",
  CA: "Canada",
  CV: "Kaapverdië",
  KY: "Caymaneilanden",
  CF: "Centraal-Afrikaanse Republiek",
  TD: "Tsjaad",
  CL: "Chili",
  CN: "China",
  CX: "Christmaseiland",
  CC: "Cocoseilanden",
  CO: "Colombia",
  KM: "Comoren",
  CG: "Congo",
  CK: "Cookeilanden",
  CR: "Costa Rica",
  CI: "Ivoorkust",
  HR: "Kroatië",
  CU: "Cuba",
  CY: "Cyprus",
  CZ: "Tsjechië",
  DK: "Denemarken",
  DJ: "Djibouti",
  DM: "Dominica",
  DO: "Dominicaanse Republiek",
  EC: "Ecuador",
  EG: "Egypte",
  SV: "El Salvador",
  GQ: "Equatoriaal-Guinea",
  ER: "Eritrea",
  EE: "Estland",
  ET: "Ethiopië",
  FK: "Falklandeilanden (Malvinas)",
  FO: "Faeröer",
  FJ: "Fiji",
  FI: "Finland",
  FR: "Frankrijk",
  GF: "Frans-Guyana",
  PF: "Frans-Polynesië",
  TF: "Franse Zuidelijke Gebieden",
  GA: "Gabon",
  GM: "Gambia",
  GE: "Georgië",
  DE: "Duitsland",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Griekenland",
  GL: "Groenland",
  GD: "Grenada",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinee",
  GW: "Guinee-Bissau",
  GY: "Guyana",
  HT: "Haïti",
  HM: "Heard en McDonaldeilanden",
  VA: "Heilige Stoel (Vaticaanstad)",
  HN: "Honduras",
  HK: "Hongkong",
  HU: "Hongarije",
  IS: "IJsland",
  IN: "India",
  ID: "Indonesië",
  IR: "Iran, Islamitische Republiek",
  IQ: "Irak",
  IE: "Ierland",
  IM: "Isle of Man",
  IL: "Israël",
  IT: "Italië",
  JM: "Jamaica",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordanië",
  KZ: "Kazachstan",
  KE: "Kenia",
  KI: "Kiribati",
  KR: "Zuid-Korea",
  KP: "Noord-Korea",
  KW: "Koeweit",
  KG: "Kirgizië",
  LA: "Laos, Democratische Volksrepubliek",
  LV: "Letland",
  LB: "Libanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libië",
  LI: "Liechtenstein",
  LT: "Litouwen",
  LU: "Luxemburg",
  MO: "Macao",
  MK: "Noord-Macedonië",
  MG: "Madagaskar",
  MW: "Malawi",
  MY: "Maleisië",
  MV: "Maldiven",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshalleilanden",
  MQ: "Martinique",
  MR: "Mauritanië",
  MU: "Mauritius",
  YT: "Mayotte",
  MX: "Mexico",
  FM: "Micronesië, Federale Staten",
  MD: "Moldavië",
  MC: "Monaco",
  MN: "Mongolië",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Marokko",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibië",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Nederland",
  AN: "Nederlandse Antillen",
  NC: "Nieuw-Caledonië",
  NZ: "Nieuw-Zeeland",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolkeiland",
  MP: "Noordelijke Marianen",
  NO: "Noorwegen",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palestijns gebied, bezet",
  PA: "Panama",
  PG: "Papoea-Nieuw-Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Filipijnen",
  PN: "Pitcairn",
  PL: "Polen",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Roemenië",
  RU: "Russische Federatie",
  RW: "Rwanda",
  BL: "Saint-Barthélemy",
  SH: "Sint-Helena",
  KN: "Saint Kitts en Nevis",
  LC: "Saint Lucia",
  MF: "Saint-Martin",
  PM: "Saint Pierre en Miquelon",
  VC: "Saint Vincent en de Grenadines",
  WS: "Samoa",
  SM: "San Marino",
  ST: "Sao Tomé en Principe",
  SA: "Saoedi-Arabië",
  SN: "Senegal",
  RS: "Servië",
  SC: "Seychellen",
  SL: "Sierra Leone",
  SG: "Singapore",
  SK: "Slowakije",
  SI: "Slovenië",
  SB: "Salomonseilanden",
  SO: "Somalië",
  ZA: "Zuid-Afrika",
  GS: "Zuid-Georgia en de Zuidelijke Sandwicheilanden",
  ES: "Spanje",
  LK: "Sri Lanka",
  SD: "Soedan",
  SR: "Suriname",
  SJ: "Spitsbergen en Jan Mayen",
  SZ: "Swaziland",
  SE: "Zweden",
  CH: "Zwitserland",
  SY: "Syrische Arabische Republiek",
  TW: "Taiwan",
  TJ: "Tadzjikistan",
  TZ: "Tanzania, Verenigde Republiek",
  TH: "Thailand",
  TL: "Oost-Timor",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad en Tobago",
  TN: "Tunesië",
  TR: "Turkije",
  TM: "Turkmenistan",
  TC: "Turks- en Caicoseilanden",
  TV: "Tuvalu",
  UG: "Oeganda",
  UA: "Oekraïne",
  AE: "Verenigde Arabische Emiraten",
  GB: "Verenigd Koninkrijk",
  US: "Verenigde Staten",
  UM: "Kleine afgelegen eilanden van de Verenigde Staten",
  UY: "Uruguay",
  UZ: "Oezbekistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Vietnam",
  VG: "Britse Maagdeneilanden",
  VI: "Amerikaanse Maagdeneilanden",
  WF: "Wallis en Futuna",
  EH: "Westelijke Sahara",
  YE: "Jemen",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  /* eslint-enable @typescript-eslint/naming-convention -- naming convention is not respected country labels */
};

export { dutchCountryLabels };
