import {
  ICollection,
  ISession,
  Role,
  TrainingType,
} from "@hulanbv/platformapp";
import { ChangeEvent, FC, useCallback, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import { stylesheet } from "typestyle";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { routes } from "../../state/common/constants/routes.constants";
import { useUrlState } from "../../state/common/hooks/url-state-hook/use-url-state.hook";
import { sessionService } from "../../state/session/session.service";
import { BoxShadowHead } from "../elements/box-shadow-head.element";
import { Header } from "../elements/header.element";
import { GeometricShape } from "../elements/geometric-shape";
import { IconButton } from "../elements/icon-button.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { SearchInput } from "../elements/search-input.element";
import { Table } from "../elements/table.element";
import Toast from "../statics/toast";
import { ExerciseRowTemplate } from "../templates/table-rows/exercise-row.template";
import { ReactComponent as sendIcon } from "../../assets/graphics/symbols/send.svg";
import { useModalContext } from "../../state/common/contexts/modal.context";
import { AddToCollectionsModal } from "../templates/modals/add-to-collections-modal.template";
import { urlUtils } from "../../utils/url.utils";
import { useAuthContext } from "../../state/authentication/authentication.context";

export const TrainingSessionScreen: FC = () => {
  const history = useHistory();
  const { session: sessionToken } = useAuthContext();
  const { openModal } = useModalContext();
  const params = useParams<{ sessionId: string }>();
  const [searchParamValue, setSearchParamValue] = useUrlState<string>("search");
  const [session, setSession] = useState<ISession>();
  const isPractitioner = sessionToken?.user?.role === Role.PRACTITIONER;
  const isSendableSession =
    session && session.training?.type !== TrainingType.ELEARNING;

  const fetchRequest = useCallback(
    async (skip: number) => {
      try {
        if (skip !== 0) {
          return [];
        }

        const { data } = await sessionService.get(params.sessionId, {
          populate: [
            {
              path: "exercises",
              ...(searchParamValue && {
                match: urlUtils.createSearchQuery(searchParamValue, ["name"]),
              }),
            },
            {
              path: "training",
            },
          ],
        });

        // session is only needed for session.training.name and session.name
        // therefore we only have to set it once
        if (!session) {
          setSession(data);
        }

        return data.exercises ?? [];
      } catch {
        Toast.error({ body: dictionary.texts.itemsFetchError });
        return [];
      }
    },
    [params.sessionId, session, searchParamValue],
  );

  const handleOnSendSessionClick = useCallback(() => {
    const path = generatePath(routes.sendSessionInvites.path, {
      sessionId: params.sessionId,
    });
    history.push(path);
  }, [history, params.sessionId]);

  const onSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchParamValue(event.target.value);
    },
    [setSearchParamValue],
  );

  return (
    <Page>
      <PageBody fullWidth>
        {session && (
          <BoxShadowHead>
            <div className={styles.container}>
              <div className={styles.row}>
                <GeometricShape
                  color={"rgb(var(--rgb-color-primair-basis))"}
                  className={styles.geometricShape}
                />
                <div className={styles.headers}>
                  <Header size={"medium"}>{session.name}</Header>
                  <Header size={"tiny"}>{session.training?.name ?? ""}</Header>
                  <Header size={"tiny"}>
                    {dictionary.texts.xExercises(
                      session?.exerciseIds?.length ?? 0,
                    )}
                  </Header>
                </div>
                {isSendableSession && isPractitioner && (
                  <div className={styles.action}>
                    <IconButton
                      icon={sendIcon}
                      attributes={{ onClick: handleOnSendSessionClick }}
                    />
                  </div>
                )}
              </div>
              <SearchInput
                onChangeTimeoutMilliseconds={500}
                attributes={{
                  onChange: onSearchInputChange,
                  defaultValue: searchParamValue,
                }}
              />
            </div>
          </BoxShadowHead>
        )}

        <Table
          fetchRequest={fetchRequest}
          rowTemplate={(exercise) => (
            <ExerciseRowTemplate
              key={exercise._id}
              exercise={exercise}
              onViewClick={() =>
                history.push(
                  generatePath(routes.exercise.path, {
                    exerciseId: exercise._id,
                  }),
                )
              }
              onFavoriteClick={() => {
                openModal<ICollection[] | null>((resolve) => (
                  <AddToCollectionsModal
                    exercise={exercise}
                    resolve={resolve}
                  />
                ));
              }}
              {...(isSendableSession && {
                onSendClick: () =>
                  history.push(
                    generatePath(routes.sendExerciseInvites.path, {
                      exerciseId: exercise._id,
                    }),
                  ),
              })}
            />
          )}
        />
      </PageBody>
    </Page>
  );
};

const styles = stylesheet({
  row: {
    display: "flex",
    alignItems: "center",
    gap: "var(--spacing-horizontal-small)",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "var(--spacing-vertical-small)",
  },
  geometricShape: {
    flexShrink: 0,
    width: 50,
    height: 50,
  },
  headers: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  action: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "auto",
  },
});
