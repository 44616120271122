import { FC } from "react";
import { classes, style } from "typestyle";
import { Button, IButtonProps } from "./button.element";

export interface IIconUrlButtonProps extends IButtonProps {
  iconUrl: string;
}

export const UrlIconButton: FC<IIconUrlButtonProps> = (props) => (
  <Button
    {...props}
    attributes={{
      ...props.attributes,
      className: classes(styles.button, props.attributes?.className),
      style: { backgroundImage: `url('${props.iconUrl}')` },
    }}
  />
);

const styles = {
  button: style({
    padding: 0,
    // prevent shrinking of button when in flexbox container
    flexShrink: 0,
    width: 50,
    height: 50,
    justifyContent: "center",
    backgroundSize: "var(--icon-size)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  }),
};
