import { IClientGroup } from "@hulanbv/platformapp";
import { IHttpOptions } from "nest-utilities-client";
import { FC, useCallback, useMemo, useRef } from "react";
import { generatePath, useHistory } from "react-router";
import { stylesheet } from "typestyle";
import { useAuthContext } from "../../../state/authentication/authentication.context";
import { Flex } from "../../elements/flex.element";
import { Table, TableActions } from "../../elements/table.element";
import { ClientGroupRowTemplate } from "../table-rows/client-group-row.template";
import { clientGroupService } from "../../../state/client-group/client-group.service";
import { useUrlState } from "../../../state/common/hooks/url-state-hook/use-url-state.hook";
import { urlUtils } from "../../../utils/url.utils";
import { useModalContext } from "../../../state/common/contexts/modal.context";
import Toast from "../../statics/toast";
import { ClientGroupForm } from "../forms/client-group/client-group-form.template";
import { routes } from "../../../state/common/constants/routes.constants";
import { TableHeadTemplate } from "../table-head.template";
import { IconButton } from "../../elements/icon-button.element";
import { ReactComponent as eyeIcon } from "../../../assets/graphics/symbols/eye.svg";
import { ReactComponent as trashIcon } from "../../../assets/graphics/symbols/trash.svg";
import { ConfirmModal } from "../modals/confirm-modal.template";
import { dictionary } from "../../../state/common/constants/dictionary.constants";

export const ClientGroupsTableTemplate: FC = () => {
  const { session } = useAuthContext();
  const [clientGroupSearchParamValue, setClientGroupSearchParamValue] =
    useUrlState<string>("client-group-filter");
  const { openModal } = useModalContext();
  const history = useHistory();

  const tableActionsRef = useRef<TableActions>(null);

  const memoizedHttpOptions: IHttpOptions<IClientGroup> = useMemo(
    () => ({
      match: {
        ...urlUtils.createSearchQuery(clientGroupSearchParamValue, ["name"]),
        ownerId: session?.userId,
      },
      sort: ["-createdAt"],
    }),
    [clientGroupSearchParamValue, session?.userId],
  );

  const handleOnAddGroupClick = useCallback(() => {
    openModal((resolve) => (
      <ClientGroupForm
        onSubmit={async (formData) => {
          try {
            if (!formData) {
              throw Error;
            }
            await clientGroupService.post(formData);
            await tableActionsRef.current?.refreshItems();
            Toast.info({
              body: dictionary.texts.clientGroupAddSuccess,
            });
          } catch {
            Toast.error({
              body: dictionary.texts.clientGroupAddError,
            });
          } finally {
            resolve(null);
          }
        }}
        onCancelClick={() => resolve(null)}
      />
    ));
  }, [openModal]);

  const handleOnRemoveClientGroupClick = async (clientGroupId: string) => {
    try {
      const isConfirmed = await openModal<boolean>((resolve) => (
        <ConfirmModal
          resolve={resolve}
          title={dictionary.literals.confirm}
          description={dictionary.texts.clientGroupDeleteConfirm}
          type="danger"
        />
      ));

      if (isConfirmed !== true) {
        return;
      }

      await clientGroupService.delete(clientGroupId);
      tableActionsRef.current?.refreshItems();
      Toast.info({
        body: dictionary.texts.clientGroupDeleteSuccess,
      });
    } catch (error) {
      Toast.error({
        body: dictionary.texts.clientGroupDeleteError,
      });
    }
  };

  return (
    <>
      <TableHeadTemplate
        searchInputAttributes={{
          defaultValue: clientGroupSearchParamValue ?? "",
          onChange: (event) =>
            setClientGroupSearchParamValue(event.target.value),
        }}
        onAddButtonClick={handleOnAddGroupClick}
      />
      <Table<IClientGroup>
        actionsRef={tableActionsRef}
        service={clientGroupService}
        options={memoizedHttpOptions}
        rowTemplate={(group) => (
          <ClientGroupRowTemplate
            clientGroup={group}
            key={group._id}
            controls={
              <div className={styles.controls}>
                <IconButton
                  icon={eyeIcon}
                  attributes={{
                    onClick: () =>
                      history.push(
                        generatePath(routes.clientGroupMembers.path, {
                          clientGroupId: group._id,
                        }),
                      ),
                  }}
                />
                <IconButton
                  icon={trashIcon}
                  flavour="danger"
                  attributes={{
                    onClick: () => handleOnRemoveClientGroupClick(group._id),
                  }}
                />
              </div>
            }
            onViewClick={() =>
              history.push(
                generatePath(routes.clientGroupMembers.path, {
                  clientGroupId: group._id,
                }),
              )
            }
          />
        )}
        emptyContent={
          <Flex>
            <p>{dictionary.literals.clientGroupsNotFound}</p>
          </Flex>
        }
      />
    </>
  );
};

const styles = stylesheet({
  controls: {
    display: "flex",
    gap: "var(--spacing-horizontal-small)",
  },
  removeButton: {
    backgroundColor: "rgb(var(--rgb-color-dangerous))",
  },
});
