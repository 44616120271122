import { IExercise, ICollection } from "@hulanbv/platformapp";
import { FC, useCallback } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { style } from "typestyle";
import { dictionary } from "../../../state/common/constants/dictionary.constants";
import { routes } from "../../../state/common/constants/routes.constants";
import { useModalContext } from "../../../state/common/contexts/modal.context";
import { exerciseCollectionService } from "../../../state/exercise/exercise-collection.service";
import { PageBody } from "../../elements/page-body.element";
import { Page } from "../../elements/page.element";
import Toast from "../../statics/toast";
import { HomeProfessionalHighlightsTemplate } from "../../templates/home-professional-highlights.template";
import { HomeProfessionalTemplate } from "../../templates/home-professional.template";
import { SelectCollectionsModal } from "../../templates/modals/select-collections-modal.template";

export const HomeProfessionalScreen: FC = () => {
  const history = useHistory();

  const { openModal } = useModalContext();

  const onSendClick = useCallback(
    (exercise: IExercise) => {
      history.push(
        generatePath(routes.sendExerciseInvites.path, {
          exerciseId: exercise._id,
        }),
      );
    },
    [history],
  );

  const onViewClick = useCallback(
    (exercise) => {
      history.push(
        generatePath(routes.exercise.path, { exerciseId: exercise._id }),
      );
    },
    [history],
  );

  const onFavouriteClick = useCallback(
    async (exercise) => {
      try {
        await openModal<ICollection[] | null>((resolve) => (
          <SelectCollectionsModal
            onSubmit={async (collectionIds) => {
              if (!collectionIds?.length) {
                resolve(null);
                return;
              }

              const collections = (
                await Promise.all(
                  collectionIds.map((id) =>
                    exerciseCollectionService.addToCollection(
                      [exercise._id],
                      id,
                    ),
                  ),
                )
              ).map((res) => res.data);
              Toast.info({
                body: dictionary.texts.collectionAddSuccess,
              });
              resolve(collections);
            }}
          />
        ));
      } catch {
        Toast.error({
          body: dictionary.texts.collectionAddError,
        });
      }
    },
    [openModal],
  );

  return (
    <Page hideGoBack>
      <PageBody>
        <HomeProfessionalTemplate
          attributes={{ className: styles.homeProfessionalContainer }}
        />
      </PageBody>
      <PageBody fullWidth>
        <HomeProfessionalHighlightsTemplate
          onFavouriteClick={onFavouriteClick}
          onSendClick={onSendClick}
          onViewClick={onViewClick}
        />
      </PageBody>
    </Page>
  );
};

const styles = {
  homeProfessionalContainer: style({
    flexGrow: 1,
  }),
};
