import { ICollection } from "@hulanbv/platformapp";
import { CrudService, IResponse } from "nest-utilities-client";
import { environment } from "../../constants/environment.constants";
import { httpService } from "../common/http.service";

class Service extends CrudService<ICollection> {
  constructor() {
    super([environment.apiUrl, "collections"].join("/"), httpService);
  }

  async addToCollection(
    exerciseIds: string[],
    collectionId: string,
  ): Promise<IResponse<ICollection>> {
    const { data: collection } = await this.get(collectionId);
    if (!collection) {
      throw new Error("");
    }
    collection.exerciseIds = Array.from(
      new Set(collection.exerciseIds.concat(exerciseIds)),
    );
    return this.patch(collection);
  }

  async removeFromCollection(
    exerciseId: string,
    collectionId: string,
  ): Promise<IResponse<ICollection>> {
    const { data: collection } = await this.get(collectionId);
    if (!collection) {
      throw new Error("");
    }

    collection.exerciseIds = collection.exerciseIds.filter(
      (id) => id !== exerciseId,
    );
    return this.patch(collection);
  }
}

export const exerciseCollectionService = new Service();
