import {
  IExercise,
  IExerciseInvite,
  ScheduleTiming,
} from "@hulanbv/platformapp";
import { useById } from "@hulanbv/nest-utilities-client-state";
import { FC, useState } from "react";
import { style } from "typestyle";
import { useHistory, useParams } from "react-router";
import { ReactComponent as clientIcon } from "../../assets/graphics/symbols/client.svg";
import { ReactComponent as groupIcon } from "../../assets/graphics/symbols/group.svg";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { exerciseInviteService } from "../../state/exercise-invite/exercise-invite.service";
import { sessionService } from "../../state/session/session.service";
import { ActionBar } from "../elements/action-bar.element";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import Toast from "../statics/toast";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { ExerciseInvitesForm } from "../templates/forms/exercise-invites/exercises-invites-form.template";

export const SendSessionInvitesScreen: FC = () => {
  const params = useParams<{ sessionId: string }>();
  const { data } = useById(sessionService, params.sessionId, {
    populate: ["exercises"],
  });
  const history = useHistory();
  const { session: userSession } = useAuthContext();
  const [shouldShowGroups, setShouldShowGroups] = useState(false);

  const onSubmit = async (
    exerciseInvite: Partial<IExerciseInvite>,
    inviteeIds: string[],
  ) => {
    const sortedExercises = data?.exerciseIds
      ?.map((id) => data.exercises?.find((exercise) => exercise.id === id))
      .filter(Boolean) as IExercise[] | undefined;
    const fetchedSessionId = data?.id;
    const { scheduledDate, scheduleTiming } = exerciseInvite;

    let isScheduledInvite = false;

    try {
      if (!sortedExercises?.length || !userSession?.userId || !scheduledDate) {
        throw Error();
      }

      // if schedulDate is higher than current date, it's scheduled
      isScheduledInvite = scheduledDate > new Date();

      // loop through all selected inviteeIds
      for (const exercise of sortedExercises) {
        // create an invite for each exercise in the session
        const invites = inviteeIds.map<IExerciseInvite>((inviteeId) => ({
          ...exerciseInvite,
          inviteeId,
          senderId: userSession.userId,
          sessionInviteId: fetchedSessionId,
          exerciseId: exercise.id,
          scheduledDate,
          scheduleTiming: scheduleTiming ?? ScheduleTiming.ONCE,
        }));
        // eslint-disable-next-line no-await-in-loop -- invites need to be created chronologically
        await exerciseInviteService.createMany(invites);
      }

      Toast.info({
        body: isScheduledInvite
          ? dictionary.texts.sessionInviteScheduledSuccess
          : dictionary.texts.sessionInviteSentSuccess,
      });

      history.goBack();
    } catch {
      Toast.error({
        body: isScheduledInvite
          ? dictionary.texts.sessionInviteScheduledError
          : dictionary.texts.sessionInviteSentError,
      });
    }
  };

  return (
    <Page>
      <PageBody>
        <Header className={styles.centerHeader}>{data?.name ?? ""}</Header>
        <ActionBar
          actions={[
            {
              icon: clientIcon,
              onClick: () => setShouldShowGroups(false),
              isActive: shouldShowGroups === false,
            },
            {
              icon: groupIcon,
              onClick: () => setShouldShowGroups(true),
              isActive: shouldShowGroups === true,
            },
          ]}
        />

        <ExerciseInvitesForm
          onSubmit={onSubmit}
          showGroupSelect={shouldShowGroups}
        />
      </PageBody>
    </Page>
  );
};

const styles = {
  centerHeader: style({
    display: "flex",
    justifyContent: "center",
    paddingBottom: 20,
  }),
};
