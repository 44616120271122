import { INote, IUser } from "@hulanbv/platformapp";
import { useById } from "@hulanbv/nest-utilities-client-state";
import { IHttpOptions } from "nest-utilities-client";
import { FC, useCallback, useMemo } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../../../state/authentication/authentication.context";
import { routes } from "../../../../state/common/constants/routes.constants";
import { useUrlState } from "../../../../state/common/hooks/url-state-hook/use-url-state.hook";
import { noteService } from "../../../../state/notes/note.service";
import { userService } from "../../../../state/user/user.service";
import { urlUtils } from "../../../../utils/url.utils";
import { Table } from "../../../elements/table.element";
import { NoteRowTemplate } from "../../table-rows/note-row.template";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";
import { TableHeadTemplate } from "../../table-head.template";

interface IProps {
  client: IUser;
}

export const ClientNotesTable: FC<IProps> = (props: IProps) => {
  const { session } = useAuthContext();
  const params = useParams<{ userId: string }>();
  const { data: client } = useById(
    userService,
    params.userId,
    {},
    { cache: false, distinct: true },
  );
  const [searchParamValue, setSearchParamValue] =
    useUrlState<string>("search-filter");

  const defaultHttpOptions: IHttpOptions<INote> = useMemo(
    () => ({
      match: {
        authorId: session?.userId,
        recipientId: params.userId,
      },
      sort: ["-createdAt"],
    }),
    [session?.userId, params.userId],
  );

  const history = useHistory();

  const httpOptions = useMemo(
    () => ({
      ...defaultHttpOptions,
      match: {
        ...defaultHttpOptions.match,
        ...(searchParamValue &&
          urlUtils.createSearchQuery(searchParamValue, ["text"])),
      },
    }),
    [defaultHttpOptions, searchParamValue],
  );

  const noteRowTemplate = useCallback(
    (note: INote) => (
      <NoteRowTemplate
        note={note}
        key={note._id}
        onClick={() =>
          history.push(routes.noteEdit.path.replace(":noteId", note._id))
        }
      />
    ),
    [history],
  );

  const handleOnAddClick = useCallback(() => {
    history.push(routes.noteCreate.path.replace(":userId", client?._id));
  }, [client?._id, history]);

  return (
    <div>
      <TableHeadTemplate
        header={dictionary.literals.notes}
        headerAttributes={{ center: false }}
        searchInputAttributes={{
          defaultValue: searchParamValue ?? "",
          onChange: (event) => setSearchParamValue(event.target.value),
        }}
        onAddButtonClick={handleOnAddClick}
      />
      <Table<INote>
        service={noteService}
        options={httpOptions}
        rowTemplate={noteRowTemplate}
      ></Table>
    </div>
  );
};
