import { IFileUploadResponse } from "@hulanbv/platformapp";
import { IResponse } from "nest-utilities-client";
import { environment } from "../../constants/environment.constants";
import { httpService } from "../common/http.service";

class Service {
  private url = [environment.apiUrl, "files"].join("/");

  /**
   * Upload a file to the server
   * @param data
   * @returns
   */
  upload(data: FormData): Promise<IResponse<IFileUploadResponse>> {
    return httpService.post<IFileUploadResponse>(this.url, data);
  }
}

export const fileService = new Service();
