import { FetchState, useById } from "@hulanbv/nest-utilities-client-state";
import { FC, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { stylesheet } from "typestyle";
import { userService } from "../../state/user/user.service";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import Toast from "../statics/toast";
import { PasswordForm } from "../templates/forms/user/password-form.template";
import { Header } from "../elements/header.element";
import { LoadingSpinner } from "../elements/loading-spinner.element";
import { dictionary } from "../../state/common/constants/dictionary.constants";

export const ProfileEditClientPasswordScreen: FC = () => {
  const params = useParams<{ userId: string }>();
  const { data: user, fetchState } = useById(userService, params.userId);

  const history = useHistory();

  const handleFormSubmit = useCallback(
    async (formData: FormData) => {
      try {
        await userService.patch(formData);

        history.goBack();
        Toast.info({
          body: dictionary.texts.passwordResetSuccessLong,
        });
      } catch {
        Toast.error({
          body: dictionary.texts.passwordResetError,
        });
      }
    },
    [history],
  );

  return (
    <Page>
      <PageBody>
        {fetchState === FetchState.Pending && <LoadingSpinner color="black" />}
        {user && (
          <>
            <Header center className={styles.header}>
              {dictionary.texts.setPasswordForX(
                `${user.name} ${user.lastName}`,
              )}
            </Header>
            <PasswordForm userId={user._id} onSubmit={handleFormSubmit} />
          </>
        )}
      </PageBody>
    </Page>
  );
};

const styles = stylesheet({
  header: {
    marginBottom: "var(--spacing-vertical-regular)",
  },
});
