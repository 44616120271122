import { FC } from "react";
import { style } from "typestyle";
import { useHistory } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../assets/graphics/symbols/arrow-left.svg";

export const GoBackButton: FC = () => {
  const history = useHistory();
  return (
    <ArrowLeft
      onClick={() => {
        history.goBack();
      }}
      className={styles.navBackImage}
    />
  );
};

const styles = {
  navBackImage: style({
    display: "block",
    height: "100%",
    width: 32,
    marginRight: "auto",
    color: "rgb(var(--rgb-color-white))",
  }),
};
