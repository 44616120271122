import {
  PropsWithChildren,
  forwardRef,
  TextareaHTMLAttributes,
  useRef,
} from "react";
import { classes, style } from "typestyle";
import { ReactComponent as Problem } from "../../assets/graphics/symbols/problem.svg";
import { useFormIsDisabled } from "../../state/common/hooks/use-form-is-disabled";

export interface ITextAreaProps {
  attributes?: TextareaHTMLAttributes<HTMLTextAreaElement>;
  error?: string;
}

export const TextArea = forwardRef<
  HTMLTextAreaElement,
  PropsWithChildren<ITextAreaProps>
>((props, ref) => {
  const localRef = useRef<HTMLTextAreaElement | null>(null);
  const isFormDisabled = useFormIsDisabled(localRef);
  const isDisabled = isFormDisabled || props.attributes?.disabled;

  return (
    <div className={styles.inputContainer}>
      <textarea
        {...{
          ...props.attributes,
          disabled: isDisabled,
          className: classes(
            styles.input,
            props.attributes?.className,
            !!props.error && styles.inputError,
          ),
        }}
        ref={(node) => {
          localRef.current = node;
          if (typeof ref === "function") {
            ref(node);
          }
        }}
      />
      {props.error && <Problem className={styles.icon} />}
    </div>
  );
});

const styles = {
  inputContainer: style({
    position: "relative",
  }),
  input: style({
    fontFamily: "inherit",
    position: "relative",
    outline: "none",
    fontSize: "0.93rem",
    color: "rgb(var(--rgb-color-black))",
    backgroundColor: "rgb(var(--rgb-color-gray-lighter))",
    padding: "19px 14px",
    border: "1px solid transparent",
    borderRadius: "var(--border-radius-large)",
    width: "100%",
    resize: "none",
    $nest: {
      "&:focus": {
        border: "1px solid rgb(var(--rgb-color-primair-basis))",
      },
      "&::placeholder": {
        color: "rgba(var(--rgb-color-black), 0.5)",
        fontSize: "0.93rem",
      },
    },
  }),
  inputError: style({
    backgroundColor: "rgb(var(--rgb-color-dangerous-lighter))",
    border: "1px solid rgb(var(--rgb-color-dangerous))",
    color: "rgb(var(--rgb-color-dangerous))",
  }),

  icon: style({
    position: "absolute",
    width: 29,
    height: 29,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    right: 16,
    top: 0,
    bottom: 0,
    margin: "auto 0",
    color: "rgb(var(--rgb-color-dangerous))",
  }),
};
