import { FC } from "react";
import { classes, style } from "typestyle";
import { graphicSet } from "../../constants/graphic-set.constants";
import { SvgIcon } from "./svg-icon.element";

interface IProps {
  color: string;
  className?: string;
  size?: number;
  contentClassName?: string;
  onClick?: () => void;
}

export const GeometricShape: FC<IProps> = (props) => (
  <div
    className={classes(styles.container, props.className)}
    onClick={props.onClick}
    style={{ width: props.size, height: props.size }}
  >
    <SvgIcon
      icon={graphicSet.geometricShapeGraphic}
      width={"100%"}
      height={"100%"}
      color={props.color}
    />
    {props.children && (
      <div className={classes(styles.content, props.contentClassName)}>
        {props.children}
      </div>
    )}
  </div>
);

const styles = {
  container: style({
    position: "relative",
    width: 100,
    height: 100,
  }),
  content: style({
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
};
