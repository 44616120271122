import { forwardRef } from "react";
import { classes, stylesheet } from "typestyle";
import { IInputProps, Input } from "./input.element";

export const InputDate = forwardRef<HTMLInputElement, IInputProps>(
  (props, ref) => (
    <Input
      {...props}
      attributes={{
        ...props.attributes,
        type: "date",
        className: classes(props.attributes?.className, styles.input),
      }}
      ref={ref}
    />
  ),
);

const styles = stylesheet({
  input: {
    // fixes native styling issues
    "-webkit-appearance": "none",
    $nest: {
      // align input text to the left
      "&::-webkit-date-and-time-value": {
        textAlign: "left",
      },
    },
  },
});
