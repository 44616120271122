import { FC } from "react";
import { useHistory } from "react-router";
import { style } from "typestyle";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { userService } from "../../state/user/user.service";
import { formatPairingCode } from "../../utils/format-pairing-code.utils";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import Toast from "../statics/toast";
import { InvitationForm } from "../templates/forms/user/invitation-form.template";

export const PairClientScreen: FC = () => {
  const { session } = useAuthContext();
  const history = useHistory();

  const submit = async (formData: FormData) => {
    try {
      await userService.inviteClient(formData);
      Toast.info({
        title: dictionary.texts.inviteSentSuccess,
        body: dictionary.texts.inviteSentSuccessLong,
      });
      history.goBack();
    } catch (error) {
      Toast.error({
        body: dictionary.texts.inviteSentErrorLong,
      });
    }
  };

  return (
    <Page>
      <PageBody>
        <Header>{dictionary.literals.addClient}</Header>
        {!session?.user?.pairingCode && (
          <div className={styles.instructions}>
            {dictionary.texts.unknownPairingCode}
          </div>
        )}
        {session?.user?.pairingCode && (
          <>
            <div className={styles.instructions}>
              {dictionary.texts.yourPairingCodeIsX(
                <b key={0}>{formatPairingCode(session.user.pairingCode)}</b>,
              )}
            </div>
            <InvitationForm onSubmit={submit} />
          </>
        )}
      </PageBody>
    </Page>
  );
};

const styles = {
  instructions: style({
    marginTop: 20,
    marginBottom: 20,
  }),
  inputArea: style({}),
};
