/**
 *
 * Returns the weekday for the language globally set in app locale
 *
 * Example for en-US: 'Monday' for 1st day of the week, 'Tuesday' for 2nd day of the week
 *
 * @param date
 * @param weekday the format of the weekday
 * @returns
 */
export const dateToWeekday = (
  date: Date,
  weekday: "long" | "short" | "narrow" = "long",
): string => {
  const { format } = new Intl.DateTimeFormat(process.env.REACT_APP_LOCALE, {
    weekday,
  });

  return format(date);
};
