import { IQuestion } from "@hulanbv/platformapp";
import { IHttpOptions } from "nest-utilities-client";
import { FC, useCallback, useMemo } from "react";
import { Table } from "../../../elements/table.element";
import { questionService } from "../../../../state/question/question.service";
import { QuestionUserAnswerRowTemplate } from "../../table-rows/question-user-answer-row.template";

interface IProps {
  clientId: string;
  questionnaireId: string;
  exerciseInviteId: string;
}

export const ClientQuestionnaireResultsTable: FC<IProps> = (props: IProps) => {
  const memoizedHttpOptions: IHttpOptions<IQuestion> = useMemo(
    () => ({
      match: {
        questionnaireId: props.questionnaireId,
      },
      populate: [
        {
          path: "userAnswers",
          match: {
            userId: props.clientId,
            exerciseInviteId: props.exerciseInviteId,
          },
        },
      ],
      sort: ["questionIndex"],
    }),
    [props.clientId, props.exerciseInviteId, props.questionnaireId],
  );

  const questionUserAnswerRowTemplate = useCallback(
    (question: IQuestion) => (
      <QuestionUserAnswerRowTemplate question={question} key={question._id} />
    ),
    [],
  );

  return (
    <Table<IQuestion>
      service={questionService}
      options={memoizedHttpOptions}
      rowTemplate={questionUserAnswerRowTemplate}
    ></Table>
  );
};
