import { FC, ReactNode } from "react";
import { IClientGroup } from "@hulanbv/platformapp";
import { TableRow } from "../../elements/table-row.element";
import { TableRowContent } from "../../elements/table-row-content.element";
import { dictionary } from "../../../state/common/constants/dictionary.constants";

interface IProps {
  clientGroup: IClientGroup;
  controls?: ReactNode;
  onViewClick?: () => Promise<void> | void;
}

export const ClientGroupRowTemplate: FC<IProps> = (props) => (
  <TableRow>
    <TableRowContent
      header={props.clientGroup.name}
      subHeader={`${props.clientGroup.clientIds.length} ${
        props.clientGroup.clientIds.length === 1
          ? dictionary.literals.member.toLowerCase()
          : dictionary.literals.members.toLowerCase()
      }`}
      controls={props.controls}
    />
  </TableRow>
);
