import { FC } from "react";
import { classes, style } from "typestyle";
import { imageSet } from "../../constants/image-set.constants";

interface IProps {
  className?: string;
}

export const TextLogo: FC<IProps> = (props) => (
  <img
    src={imageSet.logoFull}
    className={classes(styles.image, props.className)}
    alt={"logo"}
  />
);

const styles = {
  image: style({
    width: 200,
    margin: 17,
  }),
};
