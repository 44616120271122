import { classes, style } from "typestyle";

import { FC } from "react";
import { ReactComponent as crossIcon } from "../../../../assets/graphics/symbols/cross.svg";
import { ReactComponent as checkIcon } from "../../../../assets/graphics/symbols/checkmark.svg";

import { useAuthContext } from "../../../../state/authentication/authentication.context";
import { Button } from "../../../elements/button.element";
import { Form } from "../../../elements/form.element";
import { Header } from "../../../elements/header.element";
import { Input } from "../../../elements/input.element";
import { Flex } from "../../../elements/flex.element";
import { Row } from "../../../elements/row.element";
import { FlexItem } from "../../../elements/flex-item.element";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";

export interface IProps {
  onSubmit: (formData: FormData | null) => void;
  onCancelClick: () => void;
}

export const ClientGroupForm: FC<IProps> = (props) => {
  const { session } = useAuthContext();
  return (
    <div
      {...{
        className: classes(styles.container),
      }}
    >
      <Flex direction="column">
        <FlexItem>
          <Header>{dictionary.literals.newClientGroup}</Header>
        </FlexItem>
        <Form onSubmit={props.onSubmit} attributes={{ className: styles.form }}>
          <input type={"hidden"} name={"ownerId"} value={session?.userId} />
          <Row>
            <Input
              attributes={{
                name: "name",
                type: "text",
                placeholder: dictionary.literals.groupName,
                required: true,
              }}
            />
          </Row>
          <Button
            flavour={"primary"}
            icon={checkIcon}
            hideSpinnerOnSubmit
            attributes={{
              type: "submit",
              className: styles.primaryButton,
            }}
          >
            {dictionary.literals.confirm}
          </Button>
          <Button
            flavour={"secondary"}
            icon={crossIcon}
            hideSpinnerOnSubmit
            attributes={{
              onClick: props.onCancelClick,
            }}
          >
            {dictionary.literals.cancel}
          </Button>
        </Form>
      </Flex>
    </div>
  );
};

const styles = {
  form: style({
    width: "100%",
  }),
  container: style({
    display: "flex",
    flexDirection: "column",
    padding: "20px",
  }),
  primaryButton: style({
    marginBottom: 10,
  }),
};
