import { FunctionComponent, SVGProps } from "react";
import { branding } from "./branding.constants";

import { ReactComponent as TrainingDefault } from "../assets/graphics/symbols/trainings-default.svg";
import { ReactComponent as TrainingActguide } from "../assets/graphics/symbols/trainings-actguide.svg";
import { ReactComponent as TrainingPsychflex } from "../assets/graphics/symbols/trainings-psychflex.svg";

import { ReactComponent as PlaygroundDefault } from "../assets/graphics/symbols/playground-default.svg";
import { ReactComponent as PlaygroundActguide } from "../assets/graphics/symbols/playground-actguide.svg";
import { ReactComponent as PlaygroundPsychflex } from "../assets/graphics/symbols/playground-psychflex.svg";

import { ReactComponent as ELearningDefault } from "../assets/graphics/symbols/e-learning-default.svg";
import { ReactComponent as ELearningActguide } from "../assets/graphics/symbols/e-learning-actguide.svg";
import { ReactComponent as ELearningPsychflex } from "../assets/graphics/symbols/e-learning-psychflex.svg";

import { ReactComponent as GeometricDefault } from "../assets/graphics/symbols/geometric-shape-default.svg";
import { ReactComponent as GeometricActguide } from "../assets/graphics/symbols/geometric-shape-actguide.svg";
import { ReactComponent as GeometricPsychflex } from "../assets/graphics/symbols/geometric-shape-psychflex.svg";

type GraphicSet = {
  playgroundGraphic: FunctionComponent<SVGProps<SVGSVGElement>>;
  eLearningGraphic: FunctionComponent<SVGProps<SVGSVGElement>>;
  trainingsGraphic: FunctionComponent<SVGProps<SVGSVGElement>>;
  geometricShapeGraphic: FunctionComponent<SVGProps<SVGSVGElement>>;
};

const graphicSets: { [name: string]: GraphicSet } = {
  default: {
    trainingsGraphic: TrainingDefault,
    playgroundGraphic: PlaygroundDefault,
    eLearningGraphic: ELearningDefault,
    geometricShapeGraphic: GeometricDefault,
  },
  actguide: {
    trainingsGraphic: TrainingActguide,
    playgroundGraphic: PlaygroundActguide,
    eLearningGraphic: ELearningActguide,
    geometricShapeGraphic: GeometricActguide,
  },
  psychflex: {
    trainingsGraphic: TrainingPsychflex,
    playgroundGraphic: PlaygroundPsychflex,
    eLearningGraphic: ELearningPsychflex,
    geometricShapeGraphic: GeometricPsychflex,
  },
};

const graphicSet =
  graphicSets[branding.graphicSet ?? "default"] || graphicSets.default;

export { graphicSet };
