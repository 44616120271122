import { FC } from "react";
import { useHistory } from "react-router";
import { routes } from "../../state/common/constants/routes.constants";
import { Page } from "../elements/page.element";

export const TemplateScreen: FC = () => {
  const history = useHistory();

  return (
    <Page>
      Available routes:
      <ul>
        {Object.values(routes).map((route, key) => (
          <li key={key} onClick={() => history.push(route.path)}>
            {route.path}
          </li>
        ))}
      </ul>
    </Page>
  );
};
