import { IClientGroup } from "@hulanbv/platformapp";
import { CrudService, IHttpOptions, IResponse } from "nest-utilities-client";
import { environment } from "../../constants/environment.constants";
import { httpService } from "../common/http.service";

class Service extends CrudService<IClientGroup> {
  constructor() {
    super([environment.apiUrl, "client-groups"].join("/"), httpService);
  }

  /**
   * Gets all the users that are not part of client-group with clientGroupId
   *
   * @param clientGroupId
   * @returns
   */
  async getNonMembers<ResponseType>(
    clientGroupId: string,
    options?: IHttpOptions<ResponseType>,
  ): Promise<IResponse<ResponseType[]>> {
    return this.http.get<ResponseType[]>(
      [this.controller, clientGroupId, "non-members"].join("/"),
      options,
    );
  }
}

export const clientGroupService = new Service();
