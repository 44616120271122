import { ITraining, TrainingType } from "@hulanbv/platformapp";
import { IHttpOptions } from "nest-utilities-client";
import { FC, useMemo } from "react";
import { style } from "typestyle";
import { generatePath, useHistory } from "react-router";
import { urlUtils } from "../../utils/url.utils";
import { ExpandableRow } from "../elements/expandable-row.element";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { SearchInput } from "../elements/search-input.element";
import { Table } from "../elements/table.element";
import { trainingService } from "../../state/training/training.service";
import { useUrlState } from "../../state/common/hooks/url-state-hook/use-url-state.hook";
import { SessionRowTemplate } from "../templates/table-rows/session-row.template";
import { routes } from "../../state/common/constants/routes.constants";
import { BoxShadowHead } from "../elements/box-shadow-head.element";
import { dictionary } from "../../state/common/constants/dictionary.constants";

const defaultHttpOptions: IHttpOptions<ITraining> = {
  populate: [{ path: "sessions", sort: ["sortOrder"] }],
  match: {
    type: TrainingType.ELEARNING,
  },
  sort: ["sortOrder"],
};

export const ElearningScreen: FC = () => {
  const history = useHistory();

  const [searchParamValue, setSearchParamValue] =
    useUrlState<string>("search-filter");

  const httpOptions = useMemo(
    () => ({
      ...defaultHttpOptions,
      match: {
        ...defaultHttpOptions.match,
        ...(searchParamValue &&
          urlUtils.createSearchQuery(searchParamValue, [
            "name",
            "sessions.name",
          ])),
      },
    }),
    [searchParamValue],
  );

  return (
    <Page>
      <PageBody fullWidth>
        <Header className={styles.centerHeader}>
          {dictionary.literals.eLearning}
        </Header>
        <BoxShadowHead>
          <SearchInput
            onChangeTimeoutMilliseconds={500}
            attributes={{
              defaultValue: searchParamValue ?? "",
              onChange: (event) => setSearchParamValue(event.target.value),
            }}
          />
        </BoxShadowHead>
        <Table<ITraining>
          rowTemplate={(training) => (
            <ExpandableRow
              header={training.name}
              expandedColor={"rgb(var(--rgb-color-black))"}
              subHeader={dictionary.texts.xSubCategories(
                training.sessions?.length ?? 0,
              )}
              key={training._id}
              id={training.id}
              expandableItem={
                <div className={styles.expandableItemContainer}>
                  {training.sessions?.map((session) => (
                    <SessionRowTemplate
                      session={session}
                      key={session._id}
                      onViewClick={() =>
                        history.push(
                          generatePath(routes.session.path, {
                            trainingId: training._id,
                            sessionId: session._id,
                          }),
                        )
                      }
                    />
                  ))}
                </div>
              }
            />
          )}
          service={trainingService}
          options={httpOptions}
        />
      </PageBody>
    </Page>
  );
};

const styles = {
  searchContainer: style({
    margin: "0 20px",
  }),
  filterContainer: style({
    display: "flex",
    justifyContent: "center",
  }),
  centerHeader: style({
    display: "flex",
    justifyContent: "center",
    paddingBottom: 20,
  }),
  expandableItemContainer: style({
    border: "1px solid rgb(var(--rgb-color-black))",
  }),
};
