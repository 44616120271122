import {
  IForgotPasswordRequestBody,
  IInviteClientBody,
  IUser,
} from "@hulanbv/platformapp";
import { CrudService, IResponse } from "nest-utilities-client";
import { environment } from "../../constants/environment.constants";
import { httpService } from "../common/http.service";

class Service extends CrudService<IUser> {
  constructor() {
    super([environment.apiUrl, "users"].join("/"), httpService);
  }

  register(body: FormData | IUser): Promise<IResponse<IUser>> {
    return this.http.post<IUser>([this.controller, "register"].join("/"), body);
  }

  async forgotPassword(
    body: IForgotPasswordRequestBody | FormData,
  ): Promise<IResponse<IForgotPasswordRequestBody>> {
    const response = await this.http.post(
      [this.controller, "forgot-password"].join("/"),
      body,
    );

    return response;
  }

  async resetPassword(
    formData: FormData | { password: string },
    token: string,
    userId: string,
  ): Promise<IResponse<IUser>> {
    return this.http.post<IUser>(
      [this.controller, userId, "token", token, "reset-password"].join("/"),
      formData,
    );
  }

  /**
   * Creates a new user object with role.client if user with formData.email doesn't exist yet
   * Otherwise it will automatically pair to the requesting user (if user with formData.email is not paired yet)
   *
   * @param formData
   *
   * @returns the created (paired) client
   */
  async inviteClient(
    formData: FormData | IInviteClientBody,
  ): Promise<IResponse<IUser>> {
    return this.http.post<IUser>(
      [this.controller, "invite-client"].join("/"),
      formData,
    );
  }

  async pairPractitioner(
    clientId: string,
    pairingCode: number,
  ): Promise<IResponse<IUser>> {
    return this.http.post<IUser>(
      [this.controller, clientId, "pair", pairingCode].join("/"),
      {},
    );
  }

  /**
   * User role of logged in user will be upgraded from Role.USER to Role.PRACTITIONER
   * And a default trial license will be automatically created
   *
   * @param formData
   * @returns
   */
  async registerClientAsPractitioner(): Promise<IResponse<IUser>> {
    return this.http.post<IUser>(
      [this.controller, "register-as-practitioner"].join("/"),
      {},
    );
  }

  async subscribeNewsletter(userId: string) {
    return this.http.post<IUser>(
      [this.controller, userId, "newsletter", "subscribe"].join("/"),
      {},
    );
  }
}

export const userService = new Service();
