/**
 *
 * @param pairingCode the pairing code of the professional
 *
 * @returns the pairing code seperated by spaces
 */
export const formatPairingCode = (pairingCode: number): string =>
  pairingCode
    .toString()
    .match(/.{1,3}/g)
    ?.join(" ") ?? "";
