import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

export function useUrlState<DataType>(
  paramKey: string,
  initialValue?: DataType,
  parser?: (value: string) => DataType,
): [DataType, (value: DataType) => void] {
  const history = useHistory();

  const getInitialValue = useCallback(() => {
    // get current url search param
    const currentURLParamValue = new URLSearchParams(
      window.location.search,
    ).get(paramKey);

    // prioritize url param value over getting initial value
    if (currentURLParamValue) {
      try {
        const decoded = decodeURIComponent(currentURLParamValue);

        let jsonParsed;
        try {
          // Try to parse the value as JSON
          jsonParsed = JSON.parse(decoded);
        } catch {
          // If it fails, use the decoded value instead
          jsonParsed = decoded;
        }

        // if parser parse
        if (parser) {
          return parser(jsonParsed);
        }
        return jsonParsed;
      } catch {
        // eslint-disable-next-line no-console -- only show error in console
        console.error(`Cannot parse value: ${currentURLParamValue}`);
      }
    }
    return initialValue;
  }, [initialValue, paramKey, parser]);

  const [value, setValue] = useState<DataType>(getInitialValue);

  // when the value changes, set it in the path and replace history
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if ([null, undefined, initialValue, ""].includes(value)) {
      params.delete(paramKey);
    } else {
      const stringifiedValue =
        typeof value === "string" ? value : JSON.stringify(value);
      params.set(paramKey, stringifiedValue);
    }

    const searchParams = params.toString();

    history.replace({
      pathname: window.location.pathname,
      search: searchParams,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps -- only when value changes
  }, [value]);

  return [value, setValue];
}
