import {
  DeviceOS,
  ICredentialsBody,
  ISessionToken,
  Role,
} from "@hulanbv/platformapp";
import { IHttpOptions, IResponse } from "nest-utilities-client";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ExpiredLicenseModal } from "../../components/templates/modals/expired-license-modal.template";
import { dictionary } from "../common/constants/dictionary.constants";
import { IModalContextType } from "../common/contexts/modal.context";
import { authenticationService } from "./authentication.service";
import { InjectionService } from "../common/injection.service";
import { deviceService } from "../device/device.service";
import { branding } from "../../constants/branding.constants";

export interface IAuthContextType {
  session?: ISessionToken;
  login: (
    credentials: ICredentialsBody | FormData,
    options?: IHttpOptions,
  ) => Promise<IResponse<ISessionToken, null> | null>;
  logout: (options?: IHttpOptions) => Promise<IResponse<void, null>> | void;
  validate: (
    options?: IHttpOptions,
  ) => Promise<IResponse<ISessionToken, null> | null>;
}

export function useAuth(modalHook: IModalContextType): IAuthContextType {
  const [session, setSession] = useState<ISessionToken>();
  const { openModal } = modalHook;

  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      try {
        const {
          name,
          data: { deviceToken, isAuthorized },
        } = JSON.parse(decodeURIComponent(event.data));

        if (name !== "NotificationAuthorizationStatus") {
          return;
        }

        if (session?.userId === undefined || isAuthorized === false) {
          return;
        }

        deviceService.post({
          deviceToken,
          os: DeviceOS.IOS,
          userId: session.userId,
        });
      } catch (error) {
        // There is no need to do anything with the error.
      }
    }
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  });

  const validateLicense = useCallback(
    async (session: ISessionToken) => {
      const expiredLicenseDate = new Date(
        session?.user?.license?.endDate ?? "",
      );
      if (expiredLicenseDate < new Date() || !session?.user?.license) {
        await openModal(() => (
          <ExpiredLicenseModal
            title={dictionary.literals.licenseExpired}
            description={
              <div>
                <p>{dictionary.texts.yourLicenseIsExpired}</p>
              </div>
            }
          />
        ));
        authenticationService.setSession(null);
        throw Error();
      }
    },
    [openModal],
  );

  const login = useCallback(
    async (
      credentials: ICredentialsBody | FormData,
      options?: IHttpOptions,
    ) => {
      const response = await authenticationService.login(credentials, {
        ...options,
        populate: [
          ...(options?.populate ?? []),
          "user.license",
          "user.practitioner",
        ],
      });

      if (response.data.user?.role === Role.ADMIN) {
        throw new Error(dictionary.texts.noAccessToEnvironment);
      }
      if (response.data.user?.role === Role.PRACTITIONER) {
        await validateLicense(response.data);
      }

      if (branding.features?.hasPushNotifications === true) {
        InjectionService.requestNotificationAuthorization();
      }

      authenticationService.setSession(response.data);
      setSession(response.data);
      return response;
    },
    [validateLicense],
  );

  // Call the logout endpoint and remove the session from the state.
  const logout = useCallback(async (options?: IHttpOptions) => {
    try {
      const response = await authenticationService.logout(options);
      setSession(undefined);
      return response;
    } catch (error) {
      setSession(undefined);
      throw error;
    }
  }, []);

  const validate = useCallback(
    async (
      options?: IHttpOptions,
    ): Promise<IResponse<ISessionToken, null> | null> => {
      const localStorageSession = authenticationService.getSession();

      if (localStorageSession?.token) {
        const response = await authenticationService.validate({
          ...options,
          populate: [
            ...(options?.populate ?? []),
            "user.license",
            "user.practitioner",
          ],
        });

        if (branding.features?.hasPushNotifications === true) {
          InjectionService.requestNotificationAuthorization();
        }

        if (response.data.user?.role === Role.PRACTITIONER) {
          await validateLicense(response.data);
        }

        authenticationService.setSession(response.data);
        setSession(response.data);
        return response;
      }
      return null;
    },
    [validateLicense],
  );

  return useMemo(
    () => ({
      session,
      validate,
      login,
      logout,
    }),
    [session, validate, login, logout],
  );
}

export const AuthContext = createContext<ReturnType<typeof useAuth>>({
  login: (credentials: ICredentialsBody | FormData, options?: IHttpOptions) =>
    authenticationService.login(credentials, options),
  logout: (options?: IHttpOptions) => authenticationService.logout(options),
  validate: (options?: IHttpOptions) => authenticationService.validate(options),
  session: undefined,
});

export const useAuthContext = (): ReturnType<typeof useAuth> =>
  useContext(AuthContext);
