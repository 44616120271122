import { Role } from "@hulanbv/platformapp";
import { useById } from "@hulanbv/nest-utilities-client-state";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { userService } from "../../state/user/user.service";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { ProfileClientTemplate } from "../templates/profiles/profile-client.template";
import { ProfileProfessionalTemplate } from "../templates/profiles/profile-professional.template";

export const ProfileScreen: FC = () => {
  const params = useParams<{ userId: string }>();
  const { data } = useById(userService, params.userId, {}, { distinct: true });

  return (
    <Page>
      <PageBody fullWidth>
        {data && data.role === Role.PRACTITIONER && (
          <ProfileProfessionalTemplate user={data} />
        )}
        {data && data.role === Role.USER && (
          <ProfileClientTemplate user={data} />
        )}
      </PageBody>
    </Page>
  );
};
