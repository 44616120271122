import { IUserAnswer } from "@hulanbv/platformapp";
import { CrudService, IHttpOptions, IResponse } from "nest-utilities-client";
import { httpService } from "../common/http.service";

class Service extends CrudService<IUserAnswer> {
  constructor() {
    super(
      [process.env.REACT_APP_API_URL, "user-answers"].join("/"),
      httpService,
    );
  }

  /**
   * With this endpoint you will get the results seven days from the targetDate.
   * Also need to provide the resultIdentifier to get the correct results
   *
   * @param targetDate
   * @param resultIdentifier
   * @returns
   */
  async getWeekResults(
    targetDate: Date,
    resultIdentifier: string,
    httpOptions?: IHttpOptions,
  ): Promise<IResponse<string[]>> {
    return this.http.get<string[]>(
      [
        this.controller,
        "week-results",
        targetDate.toISOString(),
        "identifier",
        resultIdentifier,
      ].join("/"),
      httpOptions,
    );
  }
}

export const userAnswerService = new Service();
