import { ISession, ITraining } from "@hulanbv/platformapp";
import { IHttpOptions } from "nest-utilities-client";
import { FC, useCallback, useMemo } from "react";
import { generatePath, useHistory } from "react-router";
import { style } from "typestyle";
import { routes } from "../../../state/common/constants/routes.constants";
import { useUrlState } from "../../../state/common/hooks/url-state-hook/use-url-state.hook";
import { Table } from "../../elements/table.element";
import { urlUtils } from "../../../utils/url.utils";
import { dictionary } from "../../../state/common/constants/dictionary.constants";
import { usePermissions } from "../../../state/common/hooks/use-permissions.hook";
import { trainingService } from "../../../state/training/training.service";
import { BoxShadowHead } from "../../elements/box-shadow-head.element";
import { ExpandableRow } from "../../elements/expandable-row.element";
import { SearchInput } from "../../elements/search-input.element";
import { SessionRowTemplate } from "../table-rows/session-row.template";

interface IProps {
  httpOptions: IHttpOptions<ITraining>;
}

export const TrainingsTableTemplate: FC<IProps> = (props: IProps) => {
  const [searchParamValue, setSearchParamValue] =
    useUrlState<string>("search-filter");
  const history = useHistory();

  const { hasSendExerciseInvitePermission } = usePermissions();
  // Populate sessions so we can access them below
  // In the training screen only sessions need to be shown that are part of
  // a training.type that equals to TrainingType.DEFAULT
  const memoizedHttpOptions: IHttpOptions<ITraining> = useMemo(
    () => ({
      ...props.httpOptions,
      ...(searchParamValue && {
        match: urlUtils.createSearchQuery(searchParamValue, [
          "name",
          "sessions.name",
        ]),
      }),
    }),
    [props.httpOptions, searchParamValue],
  );

  const getSessionRowAttributes = useCallback(
    (training: ITraining, session: ISession) => {
      const sessionPath = generatePath(routes.session.path, {
        trainingId: training._id,
        sessionId: session.id,
      });
      const sendSessionInvitePath = generatePath(
        routes.sendSessionInvites.path,
        {
          sessionId: session.id,
        },
      );

      return {
        session,
        key: session._id,
        onViewClick: () => history.push(sessionPath),
        ...(hasSendExerciseInvitePermission && {
          onSendClick: () => history.push(sendSessionInvitePath),
        }),
      };
    },
    [hasSendExerciseInvitePermission, history],
  );

  return (
    <>
      <BoxShadowHead>
        <SearchInput
          onChangeTimeoutMilliseconds={500}
          attributes={{
            defaultValue: searchParamValue ?? "",
            onChange: (event) => setSearchParamValue(event.target.value),
          }}
        />
      </BoxShadowHead>
      <Table<ITraining>
        service={trainingService}
        options={memoizedHttpOptions}
        rowTemplate={(training) => (
          <ExpandableRow
            header={training.name}
            subHeaderWeight={500}
            subHeader={dictionary.texts.xSessions(
              training.sessions?.length ?? 0,
            )}
            key={training._id}
            id={training.id}
            expandableItem={
              <div className={styles.expandableItemContainer}>
                {training.sessions?.map((session) => (
                  <SessionRowTemplate
                    {...getSessionRowAttributes(training, session)}
                  />
                ))}
              </div>
            }
          />
        )}
      />
    </>
  );
};

const styles = {
  expandableItemContainer: style({
    border: "1px solid rgb(var(--rgb-color-primair-shade-3))",
  }),
};
