import { FC } from "react";
import { style } from "typestyle";
import { useAll } from "@hulanbv/nest-utilities-client-state";
import { ExerciseTag, IExercise } from "@hulanbv/platformapp";
import { Header } from "../elements/header.element";
import { exerciseService } from "../../state/exercise/exercise.service";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { ExerciseRowTemplate } from "./table-rows/exercise-row.template";

interface IProps {
  onFavouriteClick?: (exercise: IExercise) => Promise<void> | void;
  onSendClick?: (exercise: IExercise) => Promise<void> | void;
  onViewClick?: (exercise: IExercise) => Promise<void> | void;
}

export const HomeProfessionalHighlightsTemplate: FC<IProps> = (
  props: IProps,
) => {
  const { data } = useAll(exerciseService, {
    match: { tags: ExerciseTag.HIGHLIGHT },
    sort: ["-updatedAt"],
  });

  return (
    <div className={styles.container}>
      <Header center size="medium" className={styles.header}>
        {dictionary.literals.featured}
      </Header>
      {data?.map((exercise) => (
        <ExerciseRowTemplate
          hasSeparationBorder={data.length > 1}
          isDefaultExpanded
          key={exercise._id}
          exercise={exercise}
          onFavoriteClick={() => props.onFavouriteClick?.(exercise)}
          onSendClick={() => props.onSendClick?.(exercise)}
          onViewClick={() => props.onViewClick?.(exercise)}
        />
      ))}
    </div>
  );
};

const styles = {
  container: style({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    paddingTop: 37,
    paddingBottom: 37,
    backgroundImage:
      "linear-gradient(180deg, rgba(var(--rgb-color-primair-shade-4), 0.15), rgba(var(--rgb-color-primair-basis), 0.15))",
    borderRadius: "var(--border-radius-large)",
  }),
  header: style({
    marginBottom: 20,
  }),
  exercisePreview: style({
    marginBottom: 12,
    borderRadius: "var(--border-radius-large)",
    overflow: "hidden",
  }),
};
