import {
  FormEvent,
  FormHTMLAttributes,
  forwardRef,
  PropsWithChildren,
  useState,
} from "react";

export interface IFormProps {
  attributes?: Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit">;
  onSubmit?: (formData: FormData, e: FormEvent) => void | Promise<void>;
  error?: string;
  disabled?: boolean;
}

export const Form = forwardRef<HTMLFormElement, PropsWithChildren<IFormProps>>(
  (props, ref) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const handleSubmit = async (evt: FormEvent<HTMLFormElement>) => {
      evt.preventDefault();
      setIsDisabled(true);

      const formData = new FormData(evt.currentTarget);
      await props.onSubmit?.(formData, evt);

      setIsDisabled(false);
    };

    return (
      <form
        {...{
          ...props.attributes,
          onSubmit: handleSubmit,
        }}
        ref={ref}
        data-disabled={props.disabled || isDisabled}
      >
        {props.children}
      </form>
    );
  },
);
