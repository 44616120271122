import { FC } from "react";
import { style } from "typestyle";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { environment } from "../../constants/environment.constants";

const isTestEnvironment = ["ACC", "DEV"].includes(environment.environment);

export const TestEnvironmentBar: FC = () => {
  if (!isTestEnvironment) {
    return <></>;
  }
  return (
    <div className={styles.topBar}>
      {dictionary.literals.developmentEnviroment}
    </div>
  );
};

const styles = {
  topBar: style({
    position: "fixed",
    zIndex: 999,
    top: "calc(env(safe-area-inset-top) + 10px)",
    left: 10,
    padding: "0.5em 1em",
    backgroundColor: "rgb(var(--rgb-color-dangerous))",
    color: "rgb(var(--rgb-color-white))",
    fontWeight: 500,
    fontSize: 10,
    textAlign: "center",
    borderRadius: "var(--border-radius-small)",
    pointerEvents: "none",
  }),
};
