import { ExerciseType } from "@hulanbv/platformapp";
import { FC, HTMLAttributes } from "react";
import { classes, style } from "typestyle";
import { exerciseIcons } from "../../state/common/constants/exercise-icons.constants";
import { exerciseColors } from "../../constants/exercise-colors.constant";

interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  exerciseType: ExerciseType;
}

export const ExerciseTypeListTile: FC<IProps> = (props) => {
  const ExerciseIcon = exerciseIcons[props.exerciseType];

  return (
    <div
      {...props.attributes}
      style={{
        ...props.attributes?.style,
        backgroundColor: exerciseColors[props.exerciseType],
      }}
      className={classes(styles.container, props.attributes?.className)}
    >
      <ExerciseIcon className={styles.icon} />
    </div>
  );
};

const styles = {
  container: style({
    display: "flex",
    position: "relative",
    alignItems: "stretch",
    width: 83,
    flexShrink: 0,
  }),
  icon: style({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    width: 30,
    color: "rgb(var(--rgb-color-white))",
  }),
};
