import { ICollection, IExercise } from "@hulanbv/platformapp";
import { FC, useCallback } from "react";
import { dictionary } from "../../../state/common/constants/dictionary.constants";
import { exerciseCollectionService } from "../../../state/exercise/exercise-collection.service";
import Toast from "../../statics/toast";
import { SelectCollectionsModal } from "./select-collections-modal.template";

export interface IProps {
  exercise: IExercise;
  resolve: (collections: ICollection[] | null) => Promise<void> | void;
}

export const AddToCollectionsModal: FC<IProps> = (props) => {
  const handleOnSubmit = useCallback(
    async (collectionIds: string[] | null) => {
      try {
        if (collectionIds === null || props.exercise === null) {
          props.resolve(null);
          return;
        }

        if (!collectionIds.length || !props.exercise) {
          throw Error();
        }

        const responses = await Promise.all(
          collectionIds.map(async (collectionId) => {
            const collectionResponse =
              await exerciseCollectionService.addToCollection(
                [props.exercise._id],
                collectionId,
              );
            return collectionResponse.data;
          }),
        );

        props.resolve(responses);

        Toast.info({
          body: dictionary.texts.collectionAddSuccess,
        });
      } catch {
        props.resolve(null);
        Toast.error({
          body: dictionary.texts.collectionAddError,
        });
      }
    },
    [props],
  );

  return <SelectCollectionsModal onSubmit={handleOnSubmit} />;
};
