import { FC, useCallback, useState } from "react";
import { style } from "typestyle";
import { FetchState, useAll } from "@hulanbv/nest-utilities-client-state";
import { ReactComponent as crossIcon } from "../../../../assets/graphics/symbols/cross.svg";
import { ReactComponent as checkIcon } from "../../../../assets/graphics/symbols/checkmark.svg";
import { ReactComponent as plusIcon } from "../../../../assets/graphics/symbols/add.svg";
import { useAuthContext } from "../../../../state/authentication/authentication.context";
import { exerciseCollectionService } from "../../../../state/exercise/exercise-collection.service";
import { Button } from "../../../elements/button.element";
import { Form } from "../../../elements/form.element";
import { Header } from "../../../elements/header.element";
import Toast from "../../../statics/toast";
import { CheckboxRowTemplate } from "../../checkbox-row.template";
import { LoadingSpinner } from "../../../elements/loading-spinner.element";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";
import { useModalContext } from "../../../../state/common/contexts/modal.context";

export interface IProps {
  onSubmit: (selectedCollectionIds: string[] | null) => void;
  selectedIds?: string[];
  onAddScreenClick?: () => void;
}

export const SelectCollectionsForm: FC<IProps> = (props) => {
  const { session } = useAuthContext();
  const { closeModal } = useModalContext();

  const { data: collections, fetchState } = useAll(exerciseCollectionService, {
    match: { ownerId: session?.userId },
  });

  const [selectedIds, setSelectedIds] = useState<string[] | null>(
    props.selectedIds ?? [],
  );

  const onSubmitForm = () => {
    try {
      props.onSubmit(selectedIds);
    } catch {
      Toast.error({
        body: dictionary.texts.editCollectionError,
      });
    }
  };

  const toggleSelectedCallBack = useCallback(
    (id) => {
      let ids = [...(selectedIds ?? [])];

      if (selectedIds?.includes(id)) {
        // if its already set
        ids = selectedIds.filter((selectedId) => selectedId !== id);
      } else {
        // if its not set yet

        ids = (selectedIds ?? []).concat(id);
      }

      setSelectedIds(ids);
    },
    [selectedIds],
  );

  return (
    <>
      <Header className={styles.headerText}>
        {dictionary.literals.collections}
      </Header>
      <div className={styles.description}>
        {dictionary.texts.addExerciseToNewOrExistingCollection}
      </div>
      <div>
        <Form onSubmit={onSubmitForm}>
          {fetchState === FetchState.Pending && (
            <LoadingSpinner color={"black"} />
          )}
          {collections?.map((collection) => (
            <CheckboxRowTemplate
              key={collection.id}
              label={collection.name}
              attributes={{
                value: collection.id,
                name: "collectionId",
                required: !selectedIds?.length,
                onChange: () => toggleSelectedCallBack(collection.id),
                isDefaultChecked: selectedIds?.includes(collection.id),
              }}
            />
          ))}
          {!collections?.length && fetchState === FetchState.Fulfilled && (
            <div className={styles.emptyContent}>
              {dictionary.literals.noCollectionsFound}
            </div>
          )}
          <div className={styles.buttonsContainer}>
            <Button
              flavour={"primary"}
              icon={plusIcon}
              attributes={{
                onClick: props.onAddScreenClick,
                className: styles.marginBottom,
              }}
            >
              <div className={styles.buttonText}>
                {dictionary.literals.newCollection}
              </div>
            </Button>
            <Button
              flavour={"primary"}
              icon={checkIcon}
              attributes={{
                type: "submit",
                className: styles.marginBottom,
              }}
            >
              <div className={styles.buttonText}>
                {dictionary.literals.confirm}
              </div>
            </Button>
            <Button
              flavour={"secondary"}
              icon={crossIcon}
              attributes={{
                onClick: closeModal,
                className: styles.marginBottom,
              }}
            >
              <div className={styles.buttonText}>
                {dictionary.literals.cancel}
              </div>
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

const styles = {
  marginBottom: style({ marginBottom: 10 }),
  description: style({
    fontSize: "1.06rem",
    margin: "0 0 25px 0",
    textAlign: "center",
  }),
  headerText: style({
    color: "rgb(var(--rgb-color-primair-basis))",
    textAlign: "center",
    margin: "25px 0",
  }),
  buttonsContainer: style({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginTop: 20,
  }),
  buttonIcon: style({
    width: 25,
  }),
  buttonText: style({
    fontSize: "0.95rem",
  }),
  emptyContent: style({
    padding: "var(--spacing-vertical-regular)",
    textAlign: "center",
  }),
};
