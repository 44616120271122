import { ICollection } from "@hulanbv/platformapp";
import { FC, HTMLAttributes, useCallback } from "react";
import { classes, style } from "typestyle";
import { useModalContext } from "../../../state/common/contexts/modal.context";
import { exerciseCollectionService } from "../../../state/exercise/exercise-collection.service";
import { Button } from "../../elements/button.element";
import { ReactComponent as trashIcon } from "../../../assets/graphics/symbols/trash.svg";
import { ReactComponent as checkIcon } from "../../../assets/graphics/symbols/checkmark.svg";
import { ReactComponent as crossIcon } from "../../../assets/graphics/symbols/cross.svg";
import { Header } from "../../elements/header.element";
import Toast from "../../statics/toast";
import { Input } from "../../elements/input.element";
import { Form } from "../../elements/form.element";
import { ConfirmModal } from "./confirm-modal.template";
import { dictionary } from "../../../state/common/constants/dictionary.constants";
import { HorizontalRule } from "../../elements/horizontal-rule.element";

export interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  collection: ICollection;
}

export const EditCollectionModal: FC<IProps> = (props) => {
  const { attributes, collection } = props;
  const { closeModal, openModal } = useModalContext();

  const onSubmitEditCollection = useCallback(
    async (formData: FormData) => {
      try {
        await exerciseCollectionService.patch(formData);
        closeModal();
        Toast.info({
          title: `${dictionary.literals.success}!`,
          body: dictionary.texts.collectionEditedSuccessful,
        });
      } catch {
        Toast.error({
          body: dictionary.texts.collectionEditError,
        });
      }
    },
    [closeModal],
  );

  const onClickRemoveCollection = useCallback(async () => {
    const isConfirmed = await openModal((resolve) => (
      <ConfirmModal
        resolve={resolve}
        title={dictionary.texts.deleteCollection}
        description={dictionary.texts.confirmCollectionDelete(
          dictionary.literals.delete,
          collection.name,
        )}
        confirmButtonText={dictionary.literals.delete}
        confirmInputText={dictionary.literals.delete}
        type={"danger"}
      />
    ));
    if (isConfirmed) {
      await exerciseCollectionService.delete(collection.id);
      Toast.info({
        title: `${dictionary.literals.success}!`,
        body: dictionary.texts.collectionDeleteSuccess,
      });
    }
  }, [openModal, collection.name, collection.id]);

  return (
    <div
      {...{
        ...attributes,
        className: classes(styles.container, attributes?.className),
      }}
    >
      <Header className={styles.headerText}>
        {dictionary.literals.editCollection}
      </Header>
      <div className={styles.description}>
        {dictionary.texts.editCollectionDescription}
      </div>
      <Form onSubmit={onSubmitEditCollection}>
        <input type={"hidden"} name={"id"} value={collection.id} />
        <Input
          attributes={{
            name: "name",
            type: "text",
            placeholder: dictionary.literals.collectionName,
            required: true,
            className: styles.nameInput,
            defaultValue: collection.name,
          }}
        />
        <div>
          <Button
            flavour="primary"
            attributes={{
              className: styles.controlButton,
              type: "submit",
            }}
            icon={checkIcon}
          >
            {dictionary.literals.confirm}
          </Button>
          <Button
            flavour="secondary"
            attributes={{
              onClick: closeModal,
            }}
            icon={crossIcon}
          >
            {dictionary.literals.cancel}
          </Button>

          <HorizontalRule />

          <Button
            flavour={"danger"}
            icon={trashIcon}
            attributes={{
              className: styles.controlButton,
              onClick: onClickRemoveCollection,
            }}
          >
            {dictionary.texts.deleteCollection}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const styles = {
  container: style({
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100%",
    width: "100%",
  }),
  description: style({
    fontSize: "1.06rem",
    margin: "0 0 25px 0",
    textAlign: "center",
  }),
  buttonsContainer: style({
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: 10,
  }),
  headerText: style({
    color: "rgb(var(--rgb-color-primair-basis))",
    textAlign: "center",
    margin: "25px 0",
  }),
  controlButton: style({ marginBottom: 10 }),
  nameInput: style({ margin: "10px 0 10px 0" }),
};
