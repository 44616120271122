import { IExerciseInvite, IUser } from "@hulanbv/platformapp";
import { FC, useCallback, useMemo, useRef } from "react";
import { useHistory } from "react-router";
import { useAuthContext } from "../../../../state/authentication/authentication.context";
import { routes } from "../../../../state/common/constants/routes.constants";
import { exerciseInviteService } from "../../../../state/exercise-invite/exercise-invite.service";
import { urlUtils } from "../../../../utils/url.utils";
import { Table, TableActions } from "../../../elements/table.element";
import { ExerciseInviteRowTemplate } from "../../table-rows/exercise-invite-row.template";
import { useUrlState } from "../../../../state/common/hooks/url-state-hook/use-url-state.hook";
import Toast from "../../../statics/toast";
import { useModalContext } from "../../../../state/common/contexts/modal.context";
import { ConfirmModal } from "../../modals/confirm-modal.template";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";
import { TableHeadTemplate } from "../../table-head.template";

interface IProps {
  client: IUser;
}

export const ClientExerciseInvitesTable: FC<IProps> = (props) => {
  const { openModal } = useModalContext();
  const { session } = useAuthContext();
  const history = useHistory();

  const [searchParamValue, setSearchParamValue] =
    useUrlState<string>("search-filter");

  const tableActionsRef = useRef<TableActions>(null);

  const defaultHttpOptions = useMemo(
    () => ({
      match: {
        senderId: session?.userId,
        inviteeId: props.client.id,
        scheduledDate: { $lt: new Date().toISOString() },
      },
      sort: ["-createdAt"],
      populate: ["exercise"],
    }),
    [props.client.id, session?.userId],
  );

  const httpOptions = useMemo(
    () => ({
      ...defaultHttpOptions,
      match: {
        ...defaultHttpOptions.match,
        ...(searchParamValue &&
          urlUtils.createSearchQuery(searchParamValue, ["exercise.name"])),
      },
    }),
    [defaultHttpOptions, searchParamValue],
  );

  const rowTemplate = useCallback(
    (exerciseInvite: IExerciseInvite) => (
      <ExerciseInviteRowTemplate
        exerciseInvite={exerciseInvite}
        key={exerciseInvite.id}
        onClickRemove={async () => {
          try {
            const isConfirmed = await openModal<boolean>((resolve) => (
              <ConfirmModal
                resolve={resolve}
                title={dictionary.literals.confirm}
                description={dictionary.texts.confirmDeleteExerciseInvite}
                type="danger"
                confirmButtonText={dictionary.literals.yes}
                cancelButtonText={dictionary.literals.cancel}
              />
            ));

            if (!isConfirmed) {
              return;
            }

            if (!exerciseInvite.exercise) {
              throw Error();
            }

            await exerciseInviteService.delete(exerciseInvite._id);
            tableActionsRef.current?.refreshItems();

            Toast.info({
              body: dictionary.texts.deleteExerciseInviteSuccess,
            });
          } catch {
            Toast.error({
              body: dictionary.texts.deleteExerciseInviteError,
            });
          }
        }}
        onClickView={() =>
          history.push(
            routes.exercise.path.replace(
              ":exerciseId",
              exerciseInvite.exerciseId,
            ),
          )
        }
      />
    ),
    [history, openModal],
  );

  return (
    <div>
      <TableHeadTemplate
        header={dictionary.literals.sentExercises}
        headerAttributes={{ center: false }}
        searchInputAttributes={{
          defaultValue: searchParamValue ?? "",
          onChange: (event) => setSearchParamValue(event.target.value),
        }}
      />
      <Table<IExerciseInvite>
        service={exerciseInviteService}
        options={httpOptions}
        actionsRef={tableActionsRef}
        rowTemplate={rowTemplate}
      ></Table>
    </div>
  );
};
