import { FC } from "react";
import { useAuthContext } from "../../../state/authentication/authentication.context";
import { useModalContext } from "../../../state/common/contexts/modal.context";
import { Button } from "../../elements/button.element";
import Toast from "../../statics/toast";
import { ConfirmModal } from "../modals/confirm-modal.template";
import { dictionary } from "../../../state/common/constants/dictionary.constants";

export const LogoutButton: FC = () => {
  const { logout } = useAuthContext();
  const { openModal } = useModalContext();

  const showConfirmModal = async () => {
    try {
      const isConfirmed = await openModal<boolean>((resolve) => (
        <ConfirmModal
          resolve={resolve}
          title={dictionary.literals.confirm}
          description={dictionary.texts.confirmLogout}
          type="danger"
          confirmButtonText={dictionary.literals.yes}
          cancelButtonText={dictionary.literals.cancel}
        />
      ));

      if (!isConfirmed) {
        return;
      }
      logout();
    } catch {
      Toast.error({ body: dictionary.texts.logoutError });
    }
  };

  return (
    <Button
      flavour={"danger"}
      attributes={{
        onClick: () => showConfirmModal(),
      }}
    >
      {dictionary.literals.logout}
    </Button>
  );
};
