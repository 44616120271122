import { FC } from "react";
import { Button } from "../../../elements/button.element";
import { Form, IFormProps } from "../../../elements/form.element";
import { Input } from "../../../elements/input.element";
import { Row } from "../../../elements/row.element";
import { ReactComponent as sendIcon } from "../../../../assets/graphics/symbols/send.svg";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";

export const PairPractitionerForm: FC<IFormProps> = (props) => (
  <Form attributes={props.attributes} onSubmit={props.onSubmit}>
    <Row label={`${dictionary.literals.pairingCode} *`}>
      <Input
        attributes={{
          name: "pairingCode",
          type: "number",
          min: 111111,
          max: 999999,
          placeholder: dictionary.literals.pairingCode,
          required: true,
        }}
      />
    </Row>
    <Row>
      <Button attributes={{ type: "submit" }} icon={sendIcon}>
        {dictionary.literals.pair}
      </Button>
    </Row>
  </Form>
);
