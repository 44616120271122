import { FC, HTMLAttributes, useState } from "react";
import { classes, style } from "typestyle";
import { dictionary } from "../../../state/common/constants/dictionary.constants";
import { exerciseCollectionService } from "../../../state/exercise/exercise-collection.service";
import Toast from "../../statics/toast";
import { AddCollectionsForm } from "../forms/collections/add-new-collection-form.template";
import { SelectCollectionsForm } from "../forms/collections/select-collections-form.template";

export interface IProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  onSubmit: (selectedCollectionIds: string[] | null) => void;
  selectedIds?: string[];
}

export const SelectCollectionsModal: FC<IProps> = (props) => {
  const [shouldShowAddCollectionForm, setShouldShowAddCollectionForm] =
    useState<boolean>(false);

  const toggleSwitchScreen = () => {
    setShouldShowAddCollectionForm(!shouldShowAddCollectionForm);
  };

  const addCollectionToDatabase = async (formData: FormData) => {
    try {
      await exerciseCollectionService.post(formData);
      toggleSwitchScreen();
    } catch {
      Toast.error({
        body: dictionary.texts.collectionCreateError,
      });
    }
  };

  return (
    <div
      {...{
        ...props.attributes,
        className: classes(styles.container, props.attributes?.className),
      }}
    >
      {!shouldShowAddCollectionForm && (
        <SelectCollectionsForm
          onAddScreenClick={() => toggleSwitchScreen()}
          onSubmit={(collectionIds) => {
            props.onSubmit(collectionIds);
          }}
        ></SelectCollectionsForm>
      )}

      {shouldShowAddCollectionForm && (
        <AddCollectionsForm
          onSubmit={(formData) => {
            if (formData) {
              addCollectionToDatabase(formData);
            }
          }}
          onCancelClick={toggleSwitchScreen}
        />
      )}
    </div>
  );
};

const styles = {
  container: style({
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100%",
    width: "100%",
  }),
};
