import { FC } from "react";
import { classes, style } from "typestyle";
import { Button } from "../../../elements/button.element";
import { InjectionService } from "../../../../state/common/injection.service";
import { Form, IFormProps } from "../../../elements/form.element";
import { ReactComponent as mailIcon } from "../../../../assets/graphics/symbols/mail.svg";
import { ReactComponent as telephoneIcon } from "../../../../assets/graphics/symbols/telephone.svg";
import { ReactComponent as checkIcon } from "../../../../assets/graphics/symbols/checkmark.svg";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";
import { branding } from "../../../../constants/branding.constants";

export const RestrictedAreaForm: FC<IFormProps> = (props) => (
  <Form
    {...{
      ...props,
      attributes: {
        ...props.attributes,
        className: classes(styles.container, props.attributes?.className),
      },
    }}
  >
    <div className={styles.content}>{props.children}</div>
    <div className={styles.buttonsContainer}>
      <Button
        flavour="secondary"
        attributes={{
          onClick: () => InjectionService.call("+31402094081"),
        }}
        icon={telephoneIcon}
      >
        Bel
      </Button>
      <Button
        flavour="secondary"
        attributes={{
          onClick: () =>
            InjectionService.mail(branding.mailAddresses?.support ?? ""),
        }}
        icon={mailIcon}
      >
        Mail
      </Button>
      <Button
        flavour="primary"
        attributes={{
          type: "submit",
        }}
        icon={checkIcon}
      >
        {dictionary.literals.goBack}
      </Button>
    </div>
  </Form>
);

const styles = {
  container: style({
    padding: "var(--spacing-vertical-regular)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100%",
    width: "100%",
  }),
  buttonsContainer: style({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginBottom: 10,
    gap: 10,
  }),
  content: style({
    marginBottom: "var(--spacing-vertical-regular)",
  }),
};
