import {
  useCallback,
  useEffect,
  useState,
  FocusEvent,
  forwardRef,
  PropsWithChildren,
} from "react";
import { style } from "typestyle";

import { Button } from "../../../elements/button.element";
import { Form, IFormProps } from "../../../elements/form.element";
import { Input } from "../../../elements/input.element";
import { Row } from "../../../elements/row.element";
import { ReactComponent as sendIcon } from "../../../../assets/graphics/symbols/send.svg";
import { routes } from "../../../../state/common/constants/routes.constants";
import { Anchor } from "../../../elements/anchor.element";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";
import { branding } from "../../../../constants/branding.constants";

export const LoginForm = forwardRef<
  HTMLFormElement,
  PropsWithChildren<IFormProps>
>((props, ref) => {
  const [error, setError] = useState(props.error);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const onFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (error) {
        // eslint-disable-next-line no-param-reassign -- needed to empty input field
        event.currentTarget.value = "";
      }
    },
    [error],
  );

  return (
    <Form {...props.attributes} onSubmit={props.onSubmit} ref={ref}>
      <Row>
        <Input
          attributes={{
            name: "email",
            type: "email",
            placeholder: dictionary.literals.email,
            defaultValue: "",
            required: true,
            autoFocus: true,
            className: styles.input,
          }}
          error={error}
        />
      </Row>
      <Row>
        <Input
          attributes={{
            name: "password",
            type: "password",
            placeholder: dictionary.literals.password,
            required: true,
            onFocus,
            className: styles.input,
          }}
          error={error}
        />
      </Row>
      <Row>
        <Button attributes={{ type: "submit" }} centerChildren icon={sendIcon}>
          {dictionary.literals.login}
        </Button>
      </Row>
      {branding.restrictions?.canRegisterAsClient !== false && (
        <Row>
          <Anchor href={routes.register.path}>
            <Button
              icon={sendIcon}
              flavour={"secondary"}
              centerChildren
              hideSpinnerOnSubmit
            >
              {dictionary.literals.register}
            </Button>
          </Anchor>
        </Row>
      )}
    </Form>
  );
});

const styles = {
  input: style({
    backgroundColor: "rgb(var(--rgb-color-white))",
    $nest: {
      "&:focus": {
        border: "1.5px solid transparent",
      },
      "&::placeholder": {},
    },
  }),
};
