import { ExerciseTag, IExercise } from "@hulanbv/platformapp";
import { FC } from "react";
import { classes, style } from "typestyle";
import { exerciseIcons } from "../../state/common/constants/exercise-icons.constants";
import { exerciseColors } from "../../constants/exercise-colors.constant";
import { IconButton } from "./icon-button.element";
import { ReactComponent as favouriteIcon } from "../../assets/graphics/symbols/favorite.svg";
import { ReactComponent as sendIcon } from "../../assets/graphics/symbols/send.svg";
import { ReactComponent as viewIcon } from "../../assets/graphics/symbols/eye.svg";
import { usePermissions } from "../../state/common/hooks/use-permissions.hook";

export interface IExerciseHeaderProps {
  className?: string;
  exercise: IExercise;
  onFavouriteClick?: () => Promise<void> | void;
  onSendClick?: () => Promise<void> | void;
  onViewClick?: () => Promise<void> | void;
}

export const ExerciseHeader: FC<IExerciseHeaderProps> = (props) => {
  const { exercise } = props;

  const { hasSendExerciseInvitePermission } = usePermissions();
  const hasElearningTag = exercise.tags.includes(ExerciseTag.ELEARNING);

  /** Exercise is only sendable whenever the user has the right license permissions
   *  and the exercise doesn't contain highlight tag
   */
  const isSendableExercise =
    hasSendExerciseInvitePermission && hasElearningTag === false;

  const ExerciseIcon = exerciseIcons[exercise.type];

  return (
    <div
      className={classes(styles.container, props.className)}
      style={{ backgroundColor: exerciseColors[exercise.type] }}
    >
      <div className={styles.iconContainer}>
        <ExerciseIcon className={styles.exerciseTypeImage} />
      </div>
      <div className={styles.titleContainer}>{exercise.name}</div>
      <div className={styles.actionsContainer}>
        {props.onFavouriteClick && hasElearningTag === false && (
          <IconButton
            icon={favouriteIcon}
            attributes={{ onClick: props.onFavouriteClick }}
          />
        )}
        {props.onSendClick && isSendableExercise && (
          <IconButton
            icon={sendIcon}
            attributes={{ onClick: props.onSendClick }}
          />
        )}
        {props.onViewClick && (
          <IconButton
            icon={viewIcon}
            attributes={{ onClick: props.onViewClick }}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: style({
    display: "flex",
    alignItems: "center",
    padding: "27px 17px",
    gap: 12,
  }),
  exerciseTypeImage: style({
    height: 39,
    color: "rgb(var(--rgb-color-white))",
  }),
  iconContainer: style({
    flex: "0 45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  titleContainer: style({
    color: "rgb(var(--rgb-color-white))",
    fontSize: "1.125rem",
    fontWeight: 600,
  }),
  actionsContainer: style({
    display: "flex",
    justifyContent: "flex-end",
    flex: "1 0 auto",
    gap: 12,
  }),
};
