import { FC, FunctionComponent, SVGProps } from "react";
import { classes, style } from "typestyle";
import { Button, IButtonProps } from "./button.element";

export interface IIconButtonProps extends IButtonProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const IconButton: FC<IIconButtonProps> = (props) => (
  <Button
    {...props}
    attributes={{
      ...props.attributes,
      className: classes(styles.button, props.attributes?.className),
    }}
    icon={props.icon}
  />
);

const styles = {
  button: style({
    padding: 0,
    // prevent shrinking of button when in flexbox container
    flexShrink: 0,
    width: 50,
    height: 50,
    justifyContent: "center",
  }),
};
