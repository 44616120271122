import { FC, useCallback } from "react";
import { IUser } from "@hulanbv/platformapp";
import { style } from "typestyle";

import { generatePath, useHistory } from "react-router";
import { ConfirmModal } from "../modals/confirm-modal.template";
import { useAuthContext } from "../../../state/authentication/authentication.context";
import { routes } from "../../../state/common/constants/routes.constants";
import { useModalContext } from "../../../state/common/contexts/modal.context";
import { userService } from "../../../state/user/user.service";
import { Button } from "../../elements/button.element";
import { ProfileRow } from "../../elements/profile-row.element";
import Toast from "../../statics/toast";
import { LogoutButton } from "../buttons/logout-button.template";
import { ProfileBasicDetailsTemplate } from "./profile-basic-details.template";
import { Anchor } from "../../elements/anchor.element";
import { dictionary } from "../../../state/common/constants/dictionary.constants";
import { branding } from "../../../constants/branding.constants";

interface IProps {
  user: IUser;
  onEditClick?: () => void;
}

export const ProfileClientTemplate: FC<IProps> = (props) => {
  const { session } = useAuthContext();
  const { user } = props;
  const { openModal } = useModalContext();
  const history = useHistory();

  const removeClient = async (clientId: string) => {
    try {
      const isConfirmed = await openModal<boolean>((resolve) => (
        <ConfirmModal
          resolve={resolve}
          title={dictionary.literals.confirm}
          description={dictionary.texts.confirmUnpairingClient}
          type="danger"
          confirmButtonText={dictionary.literals.yes}
          cancelButtonText={dictionary.literals.no}
        />
      ));
      if (!isConfirmed) {
        return;
      }
      const { data: client } = await userService.get(clientId);
      delete client.practitionerId;
      await userService.put(client);
      history.replace(routes.clients.path);
    } catch {
      Toast.error({
        body: dictionary.texts.unpairClientError,
      });
    }
  };

  const handleOnSetPasswordClick = useCallback(() => {
    // Whenever its the client of the logged in user
    if (session?.userId === props.user.practitionerId) {
      const clientPath = generatePath(routes.profileEditPassword.path, {
        userId: user._id,
      });
      history.push(clientPath);
      return;
    }

    history.push(routes.myProfileEditPassword.path);
  }, [history, props.user.practitionerId, session?.userId, user._id]);

  return (
    <div className={styles.profileContainer}>
      <ProfileBasicDetailsTemplate
        user={user}
        onEditClick={props.onEditClick}
      />
      <div className={styles.marginSide}>
        <div className={styles.bottomContainer}>
          <ProfileRow label={dictionary.literals.email} value={user.email} />
          <ProfileRow
            label={dictionary.literals.phoneNumber}
            value={user.phoneNumber}
          />
          <ProfileRow
            label={dictionary.literals.cityOfResidence}
            value={user.city}
          />
        </div>

        <div className={styles.buttonContainer}>
          <Button
            flavour={"primary"}
            attributes={{
              onClick: handleOnSetPasswordClick,
            }}
          >
            {dictionary.literals.setPassword}
          </Button>
          {props.user.practitionerId === session?.userId && (
            <Button
              flavour={"primary"}
              attributes={{
                onClick: () => removeClient(user.id),
              }}
            >
              {dictionary.literals.unpairClient}
            </Button>
          )}
          <Anchor
            href={branding.urls?.privacyPolicy ?? "https://hulan.nl/legal"}
          >
            <Button flavour="secondary">
              {dictionary.texts.companyNameFormal(branding.name ?? "PLAP")}
            </Button>
          </Anchor>
          {session?.userId === props.user._id && <LogoutButton />}
        </div>
      </div>
    </div>
  );
};

const styles = {
  profileContainer: style({ width: "100%" }),
  marginSide: style({
    margin: "0 22px",
  }),
  bottomContainer: style({
    padding: "29px 18px 0px",
  }),
  buttonContainer: style({
    paddingTop: "var(--spacing-vertical-small)",
    display: "flex",
    flexDirection: "column",
    gap: "var(--spacing-vertical-small)",
  }),
};
