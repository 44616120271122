import { Role } from "@hulanbv/platformapp";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { useById } from "@hulanbv/nest-utilities-client-state";
import { Page } from "../elements/page.element";
import { useAuthContext } from "../../state/authentication/authentication.context";
import { routes } from "../../state/common/constants/routes.constants";
import { userService } from "../../state/user/user.service";
import { PageBody } from "../elements/page-body.element";
import { ProfileClientTemplate } from "../templates/profiles/profile-client.template";
import { ProfileProfessionalTemplate } from "../templates/profiles/profile-professional.template";

export const MyProfileScreen: FC = () => {
  const { session } = useAuthContext();
  const { data } = useById(
    userService,
    session?.userId,
    {
      populate: ["clients"],
    },
    { distinct: true },
  );
  const history = useHistory();

  return (
    <Page hideGoBack>
      <PageBody fullWidth>
        {data && data.role === Role.PRACTITIONER && (
          <ProfileProfessionalTemplate
            user={data}
            onEditClick={() => history.push(routes.editProfile.path)}
          />
        )}
        {session?.user && session?.user.role === Role.USER && (
          <ProfileClientTemplate
            user={session.user}
            onEditClick={() => history.push(routes.editProfile.path)}
          />
        )}
      </PageBody>
    </Page>
  );
};
