/**
 *
 * @param callback
 * @param wait
 * @returns
 */
export function debounce<T extends unknown[], U>(
  callback: (...args: T) => PromiseLike<U> | U,
  wait: number,
): (...args: T) => Promise<U> {
  let timer: number;

  return (...args: T): Promise<U> => {
    window.clearTimeout(timer);
    return new Promise((resolve) => {
      timer = window.setTimeout(() => resolve(callback(...args)), wait);
    });
  };
}
