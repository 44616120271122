import { ExerciseType, IExercise, Role } from "@hulanbv/platformapp";
import { FC, useCallback } from "react";
import { style } from "typestyle";
import { generatePath, useHistory } from "react-router-dom";
import { Image } from "../elements/image.element";
import { AudioPlayer } from "../elements/audio-player.element";
import { ExerciseLinkButton } from "../elements/exercise-link-button.element";
import { VideoPlayer } from "../elements/video-player/video-player.element";
import { ExerciseHeader } from "../elements/exercise-header.element";
import { ExerciseTextualContent } from "../elements/exercise-textual-content.element";
import { Button } from "../elements/button.element";
import { routes } from "../../state/common/constants/routes.constants";
import { ReactComponent as questionnaireIcon } from "../../assets/graphics/symbols/questionnaire.svg";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { useAuthContext } from "../../state/authentication/authentication.context";

interface IProps {
  exercise: IExercise;
  exerciseInviteId?: string;
  onFavouriteClick?: () => void;
  onSendClick?: () => void;
}

const exerciseLinkButtonTypes = [
  ExerciseType.PDF,
  ExerciseType.GAME,
  ExerciseType.URL,
];

export const ExerciseTemplate: FC<IProps> = (props) => {
  const { exercise } = props;
  const { type, questionnaireId, id } = exercise;
  const { session } = useAuthContext();
  const history = useHistory();

  /** Navigates to the intro of the questionnaire */
  const handleOnQuestionnaireClick = useCallback(async () => {
    const questionnaireIntroPath = generatePath(
      session?.user?.role === Role.USER
        ? routes.questionnaireIntro.path
        : routes.questionnaireOverview.path,
      {
        ...(session?.user?.role === Role.USER && {
          exerciseInviteId: props.exerciseInviteId,
        }),
        ...(session?.user?.role === Role.PRACTITIONER && {
          exerciseId: id,
        }),
        questionnaireId,
      },
    );
    history.push(questionnaireIntroPath);
  }, [
    history,
    id,
    props.exerciseInviteId,
    questionnaireId,
    session?.user?.role,
  ]);

  return (
    <div>
      <ExerciseHeader
        className={styles.exerciseHeader}
        exercise={exercise}
        onFavouriteClick={props.onFavouriteClick}
        onSendClick={props.onSendClick}
      />
      <ExerciseTextualContent
        exercise={exercise}
        className={styles.exerciseContent}
      />

      {exerciseLinkButtonTypes.includes(type) && exercise.mediaPath && (
        <div className={styles.exerciseLinkContainer}>
          <ExerciseLinkButton
            attributes={{ className: styles.exerciseLinkButton }}
            link={exercise.mediaPath}
            exerciseType={exercise.type}
          />
        </div>
      )}

      {type === ExerciseType.IMAGE && exercise.mediaPath && (
        <Image
          attributes={{
            src: exercise.mediaPath,
            className: styles.image,
          }}
        />
      )}

      {type === ExerciseType.VIDEO && exercise.mediaPath && (
        <div className={styles.container}>
          <VideoPlayer url={exercise.mediaPath} />
        </div>
      )}

      {type === ExerciseType.AUDIO && exercise.mediaPath && (
        <div className={styles.container}>
          <AudioPlayer url={exercise.mediaPath} />
        </div>
      )}
      {questionnaireId && (
        <div className={styles.container}>
          <Button
            attributes={{ onClick: handleOnQuestionnaireClick }}
            icon={questionnaireIcon}
          >
            {dictionary.literals.goToQuestionnaire}
          </Button>
        </div>
      )}
    </div>
  );
};

const marginTop = 19;
const marginSide = 40;

const styles = {
  image: style({
    width: "100%",
    padding: 10,
  }),
  exerciseHeader: style({
    padding: `${marginTop}px ${marginSide}px`,
  }),
  exerciseContent: style({
    // reset default padding on the exercise content
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: marginSide,
    marginRight: marginSide,
  }),
  exerciseLinkContainer: style({
    display: "flex",
  }),
  exerciseLinkButton: style({
    margin: "0 40px var(--spacing-vertical-regular)",
  }),
  container: style({
    margin: `0px ${marginSide}px calc(var(--spacing-vertical-regular) * 2)`,
  }),
};
