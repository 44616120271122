import { ComponentProps, FC, HTMLAttributes, ReactNode } from "react";
import { classes, stylesheet } from "typestyle";
import { Header } from "./header.element";

export interface ITableRowContentProps {
  attributes?: HTMLAttributes<HTMLDivElement>;
  header: ReactNode;
  headerSize?: ComponentProps<typeof Header>["size"];
  headerWeight?: ComponentProps<typeof Header>["weight"];
  subHeader?: ReactNode;
  subHeaderSize?: ComponentProps<typeof Header>["size"];
  subHeaderWeight?: ComponentProps<typeof Header>["weight"];
  controls?: ReactNode;
}

export const TableRowContent: FC<ITableRowContentProps> = (props) => (
  <div
    {...props.attributes}
    className={classes(styles.row, props.attributes?.className)}
  >
    <div className={styles.content}>
      <Header size={props.headerSize || "regular"} weight={props.headerWeight}>
        {props.header}
      </Header>
      {props.subHeader && (
        <Header
          size={props.subHeaderSize || "small"}
          weight={props.subHeaderWeight}
        >
          {props.subHeader}
        </Header>
      )}
    </div>
    <div className={styles.controls}>{props.controls}</div>
  </div>
);

const styles = stylesheet({
  row: { display: "flex", flex: 1 },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 100,
    gap: "calc(var(--spacing-vertical-small) / 2)",
  },
  controls: {
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    paddingLeft: "var(--spacing-horizontal-small)",
    gap: "calc(var(--spacing-horizontal-small) / 2)",
  },
});
