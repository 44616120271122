import { useById } from "@hulanbv/nest-utilities-client-state";
import { FC, useCallback } from "react";
import { generatePath, useHistory, useParams } from "react-router";
import { style } from "typestyle";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { routes } from "../../state/common/constants/routes.constants";
import { questionnaireService } from "../../state/questionnaire/questionnaire.service";
import { Button } from "../elements/button.element";
import { Card } from "../elements/card.element";
import { Flex } from "../elements/flex.element";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { ReactComponent as playIcon } from "../../assets/graphics/symbols/media-play.svg";

export const QuestionnaireIntroScreen: FC = () => {
  const history = useHistory();
  const params = useParams<{
    exerciseInviteId: string;
    questionnaireId: string;
  }>();

  const { data: questionnaire } = useById(
    questionnaireService,
    params.questionnaireId,
    {
      select: ["title", "introText", "description"],
    },
  );

  const handleOnStartClick = useCallback(async () => {
    const questionsPath = generatePath(routes.questionnaireQuestions.path, {
      exerciseInviteId: params.exerciseInviteId,
      questionnaireId: params.questionnaireId,
    });
    history.push(questionsPath);
  }, [history, params.exerciseInviteId, params.questionnaireId]);

  if (!questionnaire) {
    return <></>;
  }

  return (
    <Page hideGoBack className={styles.page}>
      <PageBody fullHeight>
        <Card header={<Header size="regular">{questionnaire.title}</Header>}>
          <Flex alignItems="stretch" direction="column">
            <Header size="regular">{dictionary.literals.introduction}</Header>
            {questionnaire.introText}

            <Header size="regular">{dictionary.literals.description}</Header>
            {questionnaire.description}

            <Button
              attributes={{
                onClick: handleOnStartClick,
              }}
              icon={playIcon}
            >
              {dictionary.texts.beginQuestionnaireButtonText}
            </Button>
          </Flex>
        </Card>
      </PageBody>
    </Page>
  );
};

const styles = {
  page: style({
    $debugName: "questionnaire-intro",
    backgroundImage:
      "linear-gradient(180deg, rgba(var(--rgb-color-primair-shade-4), 0.15), rgba(var(--rgb-color-primair-basis), 0.15))",
  }),
};
