import { FC, FunctionComponent, SVGProps } from "react";
import { classes, style } from "typestyle";
import { IconButton } from "./icon-button.element";

interface IProps {
  actions: {
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    onClick: () => void;
    isActive?: boolean;
  }[];
  className?: string;
}

export const ActionBar: FC<IProps> = (props) => (
  <div className={classes(styles.container, props.className)}>
    {props.actions.map((action, index) => (
      <IconButton
        icon={action.icon}
        attributes={{
          className: classes(
            styles.button,
            action.isActive && styles.buttonActive,
          ),
          onClick: () => action.onClick(),
        }}
        key={index}
      />
    ))}
  </div>
);

const styles = {
  container: style({
    display: "flex",
    justifyContent: "center",
    marginBottom: "var(--spacing-vertical-small)",
    gap: 10,
    flexWrap: "wrap",
  }),
  button: style({
    width: 108,
    height: 55,
  }),
  buttonActive: style({
    backgroundColor: "rgb(var(--rgb-color-secundair))",
  }),
  icon: style({
    height: 28,
    width: 28,
    margin: "auto",
  }),
};
