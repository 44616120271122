import { FC } from "react";
import { IExerciseInvite } from "@hulanbv/platformapp";
import { style } from "typestyle";
import { ReactComponent as eyeIcon } from "../../../assets/graphics/symbols/eye.svg";
import { ReactComponent as trashIcon } from "../../../assets/graphics/symbols/trash.svg";
import { SeenAt } from "../../elements/seen-at/seen-at.element";
import { IconButton } from "../../elements/icon-button.element";

interface IProps {
  exerciseInvite: IExerciseInvite;
  onClickView: () => void;
  onClickRemove: () => void;
}

export const ExerciseInviteRowTemplate: FC<IProps> = (props) => (
  <div className={styles.rowContainer}>
    <div className={styles.rowLeftText}>
      <div className={styles.rowHeader}>
        {props.exerciseInvite.exercise?.name}
      </div>
      {props.exerciseInvite.createdAt &&
        (props.exerciseInvite.seenAt ? (
          <SeenAt
            sentAt={props.exerciseInvite.createdAt}
            seenAt={props.exerciseInvite.seenAt}
          />
        ) : (
          <SeenAt sentAt={props.exerciseInvite.createdAt} />
        ))}
    </div>
    <div className={styles.rowRight}>
      <div className={styles.rowRightAction}>
        <IconButton
          flavour={"danger"}
          icon={trashIcon}
          attributes={{
            onClick: props.onClickRemove,
          }}
        />
        <IconButton
          flavour={"primary"}
          icon={eyeIcon}
          attributes={{
            onClick: props.onClickView,
          }}
        />
      </div>
    </div>
  </div>
);

const styles = {
  rowContainer: style({
    display: "flex",
    padding: "25px 30px",
    $nest: {
      "&:nth-child(odd)": {
        backgroundColor: "rgb(var(--rgb-color-gray-lighter))",
      },
    },
  }),
  rowHeader: style({
    fontSize: "1.125rem",
    fontWeight: 400,
    paddingBottom: 14,
  }),
  rowLeftText: style({
    width: "70%",
    marginRight: 20,
  }),
  rowRight: style({
    display: "flex",
    alignItems: "center",
    width: "30%",
    justifyContent: "flex-end",
  }),
  rowRightAction: style({
    display: "flex",
    alignItems: "center",
    gap: 10,
    justifyContent: "flex-end",
  }),
};
