import { FC, FormHTMLAttributes, useCallback, useRef } from "react";
import { Button } from "../../../elements/button.element";
import { Form } from "../../../elements/form.element";
import { Input } from "../../../elements/input.element";
import { Row } from "../../../elements/row.element";
import { ReactComponent as checkIcon } from "../../../../assets/graphics/symbols/checkmark.svg";
import { dictionary } from "../../../../state/common/constants/dictionary.constants";

interface IProps {
  attributes?: Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit">;
  onSubmit: (formData: FormData) => void | Promise<void>;
  userId: string;
}

export const PasswordForm: FC<IProps> = (props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const passwordRepeatInputRef = useRef<HTMLInputElement>(null);

  const handleOnSubmit = useCallback(
    (formData: FormData) => {
      const password = passwordInputRef.current?.value;
      const passwordRepeat = passwordRepeatInputRef.current?.value;
      passwordRepeatInputRef.current?.setCustomValidity(
        password !== passwordRepeat
          ? dictionary.texts.passwordsNotIdentical
          : "",
      );

      if (formRef.current?.reportValidity()) {
        props.onSubmit(formData);
      }
    },
    [props],
  );

  return (
    <Form
      attributes={{
        ...props.attributes,
        noValidate: true,
      }}
      onSubmit={handleOnSubmit}
      ref={formRef}
    >
      <input type="hidden" name="_id" value={props.userId} />
      <Row>
        <Input
          attributes={{
            name: "password",
            type: "password",
            placeholder: dictionary.literals.password,
            required: true,
            minLength: 6,
          }}
          ref={passwordInputRef}
        />
      </Row>
      <Row>
        <Input
          attributes={{
            type: "password",
            placeholder: dictionary.literals.repeatPassword,
            required: true,
          }}
          ref={passwordRepeatInputRef}
        />
      </Row>
      <Row>
        <Button attributes={{ type: "submit" }} centerChildren icon={checkIcon}>
          {dictionary.literals.confirm}
        </Button>
      </Row>
    </Form>
  );
};
