import { ITraining, TrainingType } from "@hulanbv/platformapp";

import { IHttpOptions } from "nest-utilities-client";
import { FC } from "react";
import { style } from "typestyle";
import { Header } from "../elements/header.element";
import { PageBody } from "../elements/page-body.element";
import { Page } from "../elements/page.element";
import { dictionary } from "../../state/common/constants/dictionary.constants";
import { TrainingsTableTemplate } from "../templates/tables/trainings-table.template";

const defaultHttpOptions: IHttpOptions<ITraining> = {
  populate: [{ path: "sessions", sort: ["sortOrder"] }],
  match: {
    type: TrainingType.MINDGRAPHER_CONTENT,
  },
  sort: ["sortOrder"],
};

export const MindgrapherContentScreen: FC = () => (
  <Page>
    <PageBody fullWidth>
      <Header className={styles.centerHeader}>
        {dictionary.literals.mindgrapherContent}
      </Header>
      <TrainingsTableTemplate httpOptions={defaultHttpOptions} />
    </PageBody>
  </Page>
);

const styles = {
  centerHeader: style({
    display: "flex",
    justifyContent: "center",
    paddingBottom: 20,
  }),
};
